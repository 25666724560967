import { Service } from "components/sections";
import { SEO } from "components/utils/Analytics";
import {
  dataHeroServicesPage as dataHero,
  dataServiceServicePage as dataService2,
} from "data";
import { dataServicesPage } from "data/dataPages";
import { Fragment } from "react";
import { Hero2 as Hero } from ".";

// NOTE :

const ServicesPage = () => {
  return (
    <Fragment>
      <SEO data={dataServicesPage.meta}>
        <link rel="canonical" href="https://psywealth.com/services/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="services-page" id="main">
        <Hero data={dataHero} />
        <Service data={dataService2} type="style1" />
        <Service data={dataService2} type="style2" />
      </main>
    </Fragment>
  );
};

export default ServicesPage;
