import { Banner } from "components/sections";
import { Fragment } from "react";
import CustomerEntry from "./CustomerEntry";

const dataProfilePage = {
  title: "User Profile",
  subtitle: "User Profile",
  description: "User Profile",
};

/**
 * Component for showing Projects Page of the site.
 *
 * @component
 */
const CustomerEntryPage = () => {
  return (
    <Fragment>
      <main className="page-profile">
        <Banner data={dataProfilePage} />
        <CustomerEntry data={dataProfilePage} />
      </main>
    </Fragment>
  );
};

export default CustomerEntryPage;
