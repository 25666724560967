import { Banner } from "components/sections";
import { SEO } from "components/utils/Analytics";
import { dataDailyVideosPage } from "data";
import { Fragment, useEffect } from "react";
import VideoCallsSection from "./VideoCalls.section";

// NOTE :

const VideoCallsPage = () => {
  useEffect(() => {}, []);

  return (
    <Fragment>
      <SEO data={dataDailyVideosPage.meta}>
        <link rel="canonical" href="https://psywealth.com/meetings/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="video-calls-page" id="main">
        <Banner data={dataDailyVideosPage} />
        {/* <ChatSection data={{ ...dataDailyVideosPage }} />*/}
        <VideoCallsSection data={{ ...dataDailyVideosPage }} />
      </main>
    </Fragment>
  );
};

export default VideoCallsPage;
