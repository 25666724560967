import { SEO } from "components/utils/Analytics";
import { dataPsychologistProfilePage as dataPage } from "data";
import { Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { Banner } from "../../sections/Banner";
import PaymentSuccessful from "./PaymentSuccessful.section";

// NOTE :

const MSG_ORDER_NOT_FOUND_OR_NOT_SET = "Нет данных об оплате";

/**
 * Component for showing Page "" of the site.
 *
 * @page
 **/
const PaymentSuccessfulPage = () => {
  // const params = useParams();
  const [searchParams] = useSearchParams();

  console.log(searchParams);
  console.log(searchParams.get("order"));

  const currentOrder = searchParams.get("order");
  // console.log(params);

  return (
    <Fragment>
      <SEO data={dataPage.meta}>
        <link
          rel="canonical"
          href="https://psywealth.com/psychologist/profile/"
        />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="page-psychologist-profile">
        <Banner data={dataPage} />
        {currentOrder && (
          <PaymentSuccessful
            data={{
              ...dataPage,
              order: searchParams.get("order"),
            }}
          />
        )}
        {!currentOrder && <h2>{MSG_ORDER_NOT_FOUND_OR_NOT_SET}</h2>}
      </main>
    </Fragment>
  );
};

export default PaymentSuccessfulPage;
