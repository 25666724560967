import { useQuery } from "@tanstack/react-query";
import PsychologistService from "services/psychologist.service";

export const useGetTopPsychologists = (props) => {
  // console.log("!!!", URL, props);

  return useQuery({
    queryFn: () => PsychologistService.getTop(),
    queryKey: ["topPsychologist"],
  });
};
