import { useEffect } from "react";
import { useParams } from "react-router-dom";

//import * as htmlText from "./Articles/article1.html";
import { Spinner } from "components/utils/Spinner";
import { BLOG_API_URI, BLOG_ID } from "config/config";
import { dataMessages } from "data";
import { toast } from "react-toastify";
import { _debug } from "utils";
import BlogRelatedPosts from "./BlogRelatedPosts";
import SinglePost from "./SinglePost";
import { useGetSingleBlogPostBySlug } from "./utils/useGetSingleBlogPost";

const dataRelatedPosts = {
  title: "Похожее в блоге",
  subtitle: "Похожее в блоге",
  description: "Похожее в блоге",
};

const SinglePostPage = (props) => {
  const { id = "blog", cn = "pt-130" } = props;
  const { slug } = useParams();
  const URL = `${BLOG_API_URI}/posts/v2/${slug}?blog=${BLOG_ID}`;
  // const {
  //   data: postData,
  //   isLoading,
  //   updateUrl,
  //   hasError,
  //   errorMessage,
  // } = useFetch(URL);
  //let [htmlFileString, setHtmlFileString] = useState();
  //let [postData, setPostData] = useState();

  const {
    data: postData,
    isLoading: isLoadingPostData,
    isError: isErrorPostData,
    error: errorPostData,
  } = useGetSingleBlogPostBySlug(slug);

  const isLoading = isLoadingPostData;
  const isError = isErrorPostData;
  const errors = [errorPostData];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (postData) {
      _debug("POST:", postData);
    }
  }, [postData]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isErrorPostData && errorPostData?.status === 204) {
    return <div className="error">Нет данных о посте</div>;
  }

  return (
    <div className="single-posts-wrapper">
      <SinglePost data={postData} />
      <BlogRelatedPosts data={dataRelatedPosts} />
    </div>
  );
};

export default SinglePostPage;
