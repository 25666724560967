import { APP_API_URI } from "config/config";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import { createQueryString } from "utils/helpers";

// import "./Therapist.scss";

// NOTE :

const msgSubmit = "Обратиться";

const CardImage = (props) => {
  const { data, type } = props;

  // console.log(data, type);

  const getImageURL = (id) => {
    return `${APP_API_URI}/psychologists/psychologist/profile/${id}/image`;
  };

  return (
    <img
      className="img-fluid"
      src={
        data.id
          ? getImageURL(data.id)
          : "https://www.bootdey.com/image/180x180/00FFFF/000000"
      }
      alt={data.title}
      loading="lazy"
    />
  );
};

/**
 * Component for component "Card Psychologist" of the site.
 *
 * @component
 */
const CardPsychologist2 = (props) => {
  const { data, index, type } = props;
  // console.log("@@@@", data, index, type);

  const appointmentURL = `/test2${createQueryString({ therapist: data.slug })}`;
  const therapistURL = `/therapists/${data.slug}`;

  return (
    <div className="col-lg-3 col-md-12 col-12">
      <div className="expert-card card text-white border-0 justify-content-end position-relative">
        <div className="image-wrapper">
          <CardImage data={data} />
        </div>
        <div
          className="position-absolute bottom-0 w-100 p-2 p-lg-4 bg-dark"
          style={{ "--bs-bg-opacity": 0.3 }}
        >
          <Link to={therapistURL}>
            <h5 className="fw-bold">{data.name}</h5>
          </Link>

          <div>Психолог</div>
        </div>
      </div>
      {/*
      <div className="panel-box psychologists-card">
        <div className="card-image psychologists-card-img text-center">
          <CardImage data={data} />
        </div>
        <div className="card-content psychologists-card-text p-4">
          <h2 className="card-title h5 mb-3 text-center">
            <Link to={therapistURL}>{data.name}</Link>
          </h2>
          <div className="psychologist__subtitle">
            <span className="header__psychologist-bio-position">
              <div>Психолог</div>
            </span>
          </div>

          <p className="display-30">{data.description}</p>
          <Link to={appointmentURL} className="btn btn-primary theme-btn">
            {msgSubmit}
          </Link>
        </div>
      </div>
      */}
    </div>
  );
};

CardPsychologist2.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  type: PropTypes.oneOf(["top"]),
};

CardPsychologist2.defaultProp = {};

export default CardPsychologist2;
