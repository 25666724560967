import { useGetCustomerPayments } from "components/pages/Customer/utils/useGetCustomerPayments";
import { PaymentsTable } from "components/pages/Profile/components/ProfileTabs";
import { Spinner } from "components/utils/Spinner";
import { dataMessages } from "data";
import { PropTypes } from "prop-types";
import { Fragment, useEffect } from "react";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { NoContentCard } from "../../NoContent";

const MSG_TABLE_PAYMENT_TITLE = "Платежи";
const MSG_NO_CONTENT_TITLE = "Нет платежей";

/**
 * Component for showing Section "Customer Profile (Tab) - Customer Payments" of the site.
 *
 * @component
 **/
const ProfileTabPayments = (props) => {
  const {
    data: payments,
    isLoading: isLoadingCustomerOrders,
    isError: isErrorCustomerOrders,
    error: errorCustomerOrders,
  } = useGetCustomerPayments();

  const isLoading = isLoadingCustomerOrders;
  const isError = isErrorCustomerOrders;
  const errors = [errorCustomerOrders];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (payments && payments.length) {
      _debug("CUSTOMER PAYMENTS:", payments);
    }
  }, [payments]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (errors && errors.some((err) => err?.status === 204)) {
    return <NoContentCard title={MSG_NO_CONTENT_TITLE} />;
  }

  return (
    <Fragment>
      {/*<!-- Offcanvas menu button -->*/}
      <div className="d-grid mb-0 d-lg-none w-100">
        <button
          className="btn btn-primary mb-4"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasSidebar"
          aria-controls="offcanvasSidebar"
        >
          <i className="fas fa-sliders-h"></i> Menu
        </button>
      </div>

      <div className="vstack gap-4">
        {/*<!-- Orders START -->*/}
        <div className="card border">
          {/*<!-- Card header -->*/}
          <div className="card-header border-bottom">
            <h4 className="card-header-title">{MSG_TABLE_PAYMENT_TITLE}</h4>
          </div>

          {/*<!-- Card body Orders -->*/}
          <div className="card-body vstack gap-4">
            {/*<!-- Orders item START -->*/}
            <div className="card">
              <div className="table-responsive border-0 mb-3">
                <PaymentsTable
                  data={payments}
                  tableClass="align-middle p-4 mb-0 table-hover"
                  headerClass="table-dark"
                />
              </div>
              {/*<!-- Orders item END -->*/}
            </div>
            {/* <!-- Card body END -->*/}
          </div>
        </div>
        {/*<!-- Orders END -->*/}
      </div>
    </Fragment>
  );
};

ProfileTabPayments.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default ProfileTabPayments;
