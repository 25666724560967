import classNames from "classnames";
import { formatPrice } from "components/utils/Utils";
import { PropTypes } from "prop-types";
import { Fragment, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PageSection, SectionHeader } from "./CommonSection";

const msgPurchase = "Заказать";

const ContentItem = (props) => {
  const { data, index, cn, handleClick } = props;

  return (
    <div className="col-xl-4 col-lg-4 col-md-6">
      <div className={classNames("single-pricing mb-50", cn)}>
        <h4 className="mb-3">{data.title}</h4>
        {data.showSubtitle && <p className="mb-2">{data.subtitle}</p>}
        {data.showPrice && <h3 className="mb-2">{formatPrice(data.price)}</h3>}
        <ul>
          {data.items.map((item, index) => {
            return <li key={index}>{item.title}</li>;
          })}
        </ul>
        {/*<Link to="/contacts" className="btn theme-btn">
          {msgPurchase}
        </Link>*/}
        <Button
          className="btn btn-primary theme-btn mx-auto"
          variant="primary"
          onClick={() => handleClick(index)}
        >
          {data?.msg?.msgPurchase || msgPurchase}
        </Button>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data, handleClick, ...otherProps } = props;

  return (
    <Fragment>
      <div className="row mt-5">
        {data.items.map((item, index) => {
          return (
            <Fragment key={index}>
              {index === 1 ? (
                <ContentItem
                  data={item}
                  index={index}
                  handleClick={handleClick}
                  cn="active"
                  {...otherProps}
                />
              ) : (
                <ContentItem
                  data={item}
                  index={index}
                  handleClick={handleClick}
                  cn=""
                  {...otherProps}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </Fragment>
  );
};

/**
 * Component for showing Section "Pricing" of the site.
 *
 * @component
 */
const Pricing = (props) => {
  const { data, id = "pricing", cn = "pt-100 pb-60" } = props;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClick = (index) => {
    console.log(index);
    navigate(`/gifts/purchase?good=${index + 1}`);
  };

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />
        <SectionContent data={data} handleClick={handleClick} />
      </div>
    </PageSection>
  );
};

Pricing.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default Pricing;
