import { createColumnHelper } from "@tanstack/react-table";
import { CustomTable } from "components/pages/Customer/components/core/CustomTable";
import { Fragment } from "react";

const columnHelper = createColumnHelper();

/**
 * Component for showing Orders table.
 *
 * @component
 */
const OrdersTable = (props) => {
  const {
    data: orders,
    tableClass,
    headerClass,
    onDelete,
    ...otherProps
  } = props;

  // console.log(orders);

  const columns = [
    columnHelper.accessor("product.name", {
      header: "Name",
    }),
    columnHelper.accessor("id", {
      header: "Id",
    }),
    columnHelper.accessor("price", {
      header: "Price",
    }),
    columnHelper.accessor("product.code", {
      header: "Code",
    }),
    columnHelper.accessor("totalAmount", {
      header: "Total",
    }),
  ];

  return (
    <Fragment>
      {orders && (
        <CustomTable
          data={orders}
          columns={columns}
          tableClass={tableClass}
          headerClass={headerClass}
          {...otherProps}
        />
      )}
    </Fragment>
  );
};

export default OrdersTable;
