import { SectionContentHeader } from "components/sections/CommonSection";
import DOMPurify from "isomorphic-dompurify";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { ProfileSection } from "./ProfileSection.section";

// NOTE : https://alter.ru/psychologists/kajjani/ivan-gorbachev?position=1&from=search_page&from_link=%2Fpsychologists%3Falter_campaign%3Dlanding-menu

const Tab1 = (props) => {
  const { data } = props;

  return (
    <div className="d-block">
      <div className="">
        <ul className="list-disc pl-4">
          {data.educations &&
            data.educations.length > 0 &&
            data.educations.map((item, index) => {
              return (
                <li className="mb-3 color-green-2" key={index}>
                  <span className="font-futura-400 line-height-2 font-weight-0 font-size-4 color-blue-4">
                    {`${item.name}, ${item.title}`}
                  </span>
                </li>
              );
            })}

          {/*<li className="mb-3 color-green-2">
            <span className="font-futura-400 line-height-2 font-weight-0 font-size-4 color-blue-4">
              НИПНИ им. В.М.Бехтерева. «Врач-психиатр».
            </span>
          </li>
          <li className="mb-3 color-green-2">
            <span className="font-futura-400 line-height-2 font-weight-0 font-size-4 color-blue-4">
              ПСПбГМУ им. акад. И.П.Павлова. «Врач-невролог».
            </span>
          </li>
          <li className="mb-3 color-green-2">
            <span className="font-futura-400 line-height-2 font-weight-0 font-size-4 color-blue-4">
              International society of schema therapy. «Advanced Level Certified
              Couples Schema Therapist».
            </span>
          </li>
          <li className="mb-3 color-green-2">
            <span className="font-futura-400 line-height-2 font-weight-0 font-size-4 color-blue-4">
              International society of schema therapy. «Standard Level Certified
              Individual Schema Therapist».
            </span>
          </li>*/}
        </ul>
        <div className="pl-4">
          <button className="px-0 font-weight-1 t-decor-underline mt-3 link-like-button">
            Читать дальше
          </button>
        </div>
      </div>
    </div>
  );
};

const Tab2 = (props) => {
  const { data } = props;

  return (
    <div className="d-block">
      <div className="">
        <div className="sb-5 d-flex flex-column">
          {data.content && (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.content),
              }}
            ></div>
          )}

          {/*
          <div>
            <div className="font-futura-400 mb-2 line-height-1 font-weight-1 font-size-5">
              Схематерапия
            </div>
            <p className="font-futura-400 mb-0 d-block line-height-2 font-weight-0 font-size-4">
              Схематерапия работает с понятиями схем и режимов. Схемы —
              усвоенные в детстве модели поведения и представления о мире.
              Некоторые схемы не соответствуют нам-взрослым и мешают быть
              счастливыми — они называются дезадаптивными. В процессе терапии
              психолог помогает найти ваши дезадаптивные схемы и «переписать» их
              с помощью анализа детского опыта, нового решения текущих задач,
              домашних заданий от психолога. Режимы — это состояния, которые
              возникают в ответ на активацию схемы. Они могут стремительно
              сменяться, что объясняет быструю смену эмоциональных состояний у
              некоторых клиентов. Схематерапия сочетает в себе сильные стороны
              когнитивно-поведенческого и гештальт-подхода: алгоритмы решения
              проблем и поддержку клиента. В первую очередь, схема-терапия
              зарекомендовала себя при работе с расстройствами личности, однако
              появляются все новые и новые данные о том, что в работе и с
              другими состояниями и проблемами схема-терапия также может быть
              эффективна.
            </p>
          </div>
          <div>
            <div className="font-futura-400 mb-2 line-height-1 font-weight-1 font-size-5">
              Психодрама
            </div>
            <p className="font-futura-400 mb-0 d-block line-height-2 font-weight-0 font-size-4">
              Психодрама — направление психотерапии, в котором клиент не
              рассказывает о проблеме, а показывает ее в ролевой игре. Часто это
              групповая работа, в которой вся группа под руководством терапевта
              «отыгрывает» проблемную ситуацию одного из участников. В этой игре
              клиент может как присоединяться к главному герою, так и к его
              антагонисту. Это позволяет посмотреть на ситуацию со стороны и
              увидеть детали, которые раньше оставались незамеченными. Также
              психодрама может быть способом «переписать» в памяти травмирующее
              событие, увидеть, как по-новому можно поступать в будущем в
              подобных ситауциях. Метод психодрамы помогает развить
              спонтанность, умение переключаться с одной точки зрения на другую,
              проработать сложные и травматические ситуации в прошлом.
            </p>
          </div>
        */}
        </div>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data, isMobile } = props;

  return (
    <Fragment>
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="mb-3"
        fill
      >
        <Tab eventKey="profile" title="Образование">
          <Tab1 data={data} />
        </Tab>

        <Tab eventKey="security" title="Особенности работы">
          <Tab2 data={data} />
        </Tab>
      </Tabs>
    </Fragment>
  );
};

/**
 * Component for showing Section "Therapist Qualification" of the site.
 *
 * @component
 */
const TherapistQualification = (props) => {
  const { data, id = "therapist-qualification", cn = "pt-80 pb-80" } = props;

  return (
    <ProfileSection id={id} cn={cn}>
      <div className="container">
        {/* <SectionHeader data={data} />*/}
        <SectionContentHeader data={data} className="mb-30" />
        <SectionContent data={data.therapist} isMobile={false} />
      </div>
    </ProfileSection>
  );
};

TherapistQualification.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default TherapistQualification;
