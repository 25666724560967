import { Fragment } from "react";
import { PageSection, SectionHeader } from "../../sections/CommonSection";
//import classNames from "classnames";
import { PropTypes } from "prop-types";
import { createGiftOrder } from "services";
import PurchaseForm from "./PurchaseForm";

// NOTE : https://demo.graygrids.com/themes/saasgrids/

const SectionContent = (props) => {
  const { data } = props;

  const TestForm = () => {
    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card">
              <div className="card-header">Register</div>
              <div className="card-body">
                <form action="" method="">
                  <div className="form-group row">
                    <label
                      htmlFor="email_address"
                      className="col-md-4 col-form-label text-md-right"
                    >
                      E-Mail Address
                    </label>
                    <div className="col-md-6">
                      <input
                        type="text"
                        id="email_address"
                        className="form-control"
                        name="email-address"
                        required
                        autoFocus
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="password"
                      className="col-md-4 col-form-label text-md-right"
                    >
                      Password
                    </label>
                    <div className="col-md-6">
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        name="password"
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-md-6 offset-md-4">
                      <div className="checkbox">
                        <label>
                          <input type="checkbox" name="remember" /> Remember Me
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 offset-md-4">
                    <button type="submit" className="btn btn-primary">
                      Register
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleSubmit = async (formData) => {
    //e.preventDefault();
    // if (!query) return;

    // async function fetchData() {
    //   const response = await fetch(
    //     `https://www.omdbapi.com/?apikey=2e8b5857&s=${query}`
    //   );
    //   const data = await response.json();
    //   const results = data.Search;
    //   setData(results);
    // }
    const fetchData = await createGiftOrder(formData);
    console.log(fetchData);
    return fetchData;
  };

  return (
    <Fragment>
      <PurchaseForm onSubmit={handleSubmit} />
    </Fragment>
  );
};

/**
 * Component for showing Section "PurchaseOrder" of the site.
 *
 * @component
 */
const PurchaseOrder = (props) => {
  const { data, id = "overview", cn = "pt-130 app-info" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} />
        <SectionContent data={data} />
      </div>
    </PageSection>
  );
};

PurchaseOrder.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default PurchaseOrder;
