import { createColumnHelper } from "@tanstack/react-table";
import { CustomTable } from "components/pages/Customer/components/core/CustomTable";
import { Fragment } from "react";

const columnHelper = createColumnHelper();

/**
 * Component for showing Orders table.
 *
 * @component
 */
const MethodsTable = (props) => {
  const {
    data: methods,
    tableClass,
    headerClass,
    onDelete,
    ...otherProps
  } = props;

  // console.log(props);

  const handleDelete = (cell, row) => {
    console.log("handleDelete!!!!", cell, row);
    onDelete(cell.row.original.id);
  };

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
    }),
    columnHelper.accessor("id", {
      header: "Id",
    }),

    columnHelper.accessor("action", {
      header: () => <span>Action</span>,
      accessor: "id",
      cell: ({ cell, row }) => (
        <button onClick={() => handleDelete(cell, row)}>Delete</button>
      ),
    }),
  ];

  return (
    <Fragment>
      {methods && (
        <CustomTable
          data={methods}
          columns={columns}
          tableClass={tableClass}
          headerClass={headerClass}
          {...otherProps}
        />
      )}
    </Fragment>
  );
};

export default MethodsTable;
