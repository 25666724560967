/**
 * Data of page "Psychologist" of the site.
 *
 *
 */

import {
  BsActivity,
  BsAspectRatio,
  BsBrightnessHigh,
  BsCameraVideo,
  BsClipboard2,
  BsEmojiWink,
  BsLayoutTextWindowReverse,
  BsMortarboard,
  BsPencilSquare,
  BsSpeedometer2,
  BsStar,
  BsXDiamond,
} from "react-icons/bs";

export const dataHeroPsychologistPage = {
  title: "4 100+ психологов уже развивают практику с PsyWealth",
  subtitle: "4 100+ психологов уже развивают практику с PsyWealth",
  description:
    "Мы всегда рады специалистам, которые относятся к профессии так же трепетно и бережно, как и мы.",
  //"Хранение документов еще никогда не было таким простым - услуги по организации и ведению электронных архивов",
  items: null,
  image: require("assets/images/hero/hero2-920x820.webp"),
  imageAlt: "Сервис психологической помощи - «PsyWealth»",
};

export const dataFeaturePsychologistPage = {
  title: "Ждём от вас",
  subtitle: "Ждём от вас",
  description:
    "Оперативное и архивное хранение электронных документов, их систематизация и поиск, а также контроль доступа к корпоративному контенту",
  items: [
    {
      title: "От 3-х лет непрерывного опыта",
      subtitle: "",
      description:
        "Речь о платном консультировании. Студенческая практика не засчитывается.",
      icon: null,
      image: null,
      imageAlt: "",
    },
    {
      title: "От 500 часов обучения методу",
      subtitle: "",
      description:
        "Образование в одном из методов от 500 часов. Наличие регулярных супервизий обязательно.",
      icon: null,
      image: null,
      imageAlt: "",
    },
    {
      title: "Опыт личной терапии",
      subtitle: "",
      description:
        "Обязательно наличие регулярной индивидуальной личной психотерапии.",
      icon: null,
      image: null,
      imageAlt: "",
    },
  ],
};

export const dataOverviewPsychologistPage = {
  title: "Вы получите",
  subtitle: "Вы получите",
  description:
    // "Цифровизация бизнес-процессов и комплекс автоматизированных архивных процедур по использованию и доступу к документации",
    // Типовые решения подготовлены для быстрого внедрения в любую сферу бизнеса и госуправления: банки, госучреждения, коммерческие компании, промыш­ленные предприятия и крупные холдинги повышают операционную эффективность и автоматизируют документационные процессы на платформе ЭЛАР Контекст
    // На основе стандартных решений можно быстро внедрить их в любой сфере деятельности, которая касается бизнеса и госуправления: банки, государственные учреждения, компании с большим количеством сотрудников, крупные предприятия и холдинги могут повысить эффективность своих бизнес-процессов благодаря использованию ЭЛАР Контекст.
    "Это не наши слова — так считают наши пользователи",
  items: [
    {
      title: "Клиентов",
      subtitle: "",
      description1: "В среднем каждый наш специалист работает с 10 клиентами.",
      description2: "",
      icon: null,
      image: {
        src: require("assets/images/feature/features11-1_600x600.webp"),
        alt: "Фото электронный архив финансовых и бухгалтерских документов",
        title: "",
      },
      list: {
        items: [],
      },
      control: null,
    },
    {
      title: "Супервизии (разумеется, бесплатные)",
      subtitle: "",
      description1:
        "В неделю у нас проходит 7 супервизионных групп – их можно посещать, как в качестве докладчика, так и в качестве слушателя.",
      description2: "",
      icon: null,
      image: {
        src: require("assets/images/feature/features11-2_600x600.webp"),
        alt: "",
        title: "",
      },
      list: {
        items: [],
      },
      control: null,
    },
    {
      title: "Семинары (тоже бесплатные)",
      subtitle: "",
      description1:
        "Каждую неделю мы организовываем семинары от самых выдающихся членов профессионального сообщества.",
      description2: "",
      icon: null,
      image: {
        src: require("assets/images/feature/features11-3_600x600.webp"),
        alt: "",
        title: null,
      },
      list: {
        items: [],
      },
      control: {
        show: false,
        msgSubmit: "Подробнее",
        title: "Архив кадровых документов и документов воинского учёта",
        description: "",
        descriptionHTML: null,
      },
    },
    {
      title: "Доступ к сообществу профессионалов",
      subtitle: "",
      description1:
        "Мы регулярно проводим общие онлайн-встречи, чтобы обменяться знаниями и опытом.",
      description2: "",
      icon: null,
      image: {
        src: require("assets/images/feature/features11-4_600x600.webp"),
        alt: "",
        title: null,
      },
      list: {
        items: [],
      },
      control: null,
    },
  ],
};

export const dataFAQComponent1 = {
  data:
    `<ul className="list-styled">` +
    `<li>Базовое высшее психологическое или смежное (педагогическое, медицинское, философское) образование</li>` +
    `<li>Подтвержденное сертификатами и дипломами образование в одном из психотерапевтических методов от 500 часов</li>` +
    `<li>Личная практика (за деньги, не учебная) от 3-х лет</li>` +
    `<li>Наличие регулярных суперивизий</li>` +
    `<li>Опыт личной психотерапии. Наличие регулярной личной психотерапии в текущий момент — весомый плюс.</li>` +
    `</ul>`,
};

export const dataFAQPsychologistPage = {
  title: "Вопросы и ответы",
  subtitle: "Вопросы и ответы",
  description: "Свяжитесь с нами, если у вас есть вопрос",
  content: {
    title: "Остались вопросы?",
    subtitle: "Остались вопросы?",
    description: "Напишите нам и специалист свяжется с вами",
    image: require("assets/images/question-image3_rb.webp"),
    imageAlt: "" || "",
  },
  items: [
    {
      title: "Как долго рассматривается заявка?",
      description: "В среднем мы рассматриваем анкету две-три недели.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Какие этапы после рассмотрения заявки?",
      description:
        "Если все условия соблюдены, то мы письмом приглашаем вас на собеседование с одним из наших кураторов сообщества. Кураторы — 18 опытных психотерапевтов, супервизоров, работающих на платформе PsyWealth более одного года.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Каковы условия принятия заявки на рассмотрение?",
      description: "",
      componentHTML: dataFAQComponent1,
      main: true,
      disable: false,
    },
    {
      title: "Сколько психотерапевт получает за сессию на платформе?",
      description:
        "Это зависит от процентной ставки, но в среднем за одну сессию с клиентом специалист получает около 2000 рублей при начале сотрудничества. У специалиста есть возможность увеличить стоимость своей работы при условии устойчивой практики на сервисе.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Как проходят встречи с клиентами?",
      description:
        "Все сессии проходят только онлайн. У специалиста есть удобный личный кабинет с расписанием, системой оплаты и информацией о клиентах. Внутри кабинета и проводятся сессии — через видеосвязь. Использовать сторонние программы не нужно.",
      componentHTML: null,
      main: true,
      disable: false,
    },
  ],
};

export const dataCTAPsychologistPage = {
  title: "Развивайте свою практику с нами",
  title1: "Заявка на консультацию",
  title2: "",
  subtitle: "Оставить заявку",
  description:
    "Оставить заявку на консультацию, наш менеджер свяжется с вами и поможет подобрать решение для вашей задачи",
  msg: {
    msgCTA: "Заполнить анкету",
  },
  backgroundImage: require("assets/images/bg/cta-bg2.webp"),
};

export const dataAboutPsychologistPage = {
  title: "Ждём от вас",
  subtitle: "Ждём от вас",
  description:
    "Оперативное и архивное хранение электронных документов, их систематизация и поиск, а также контроль доступа к корпоративному контенту",
  image: {
    src: require("assets/images/feature/about.webp"),
    alt: "",
    title: "",
  },
};

export const dataOurAdvantagesPsychologistPage = {
  title: "Что делает психотерапию с нами удобной?",
  subtitle: "Что делает психотерапию с нами удобной?",
  description:
    "Мы взяли все организационные заботы на себя, чтобы вы могли сосредоточиться на самом важном — себе и своих эмоциях",
  image: {
    src: require("assets/images/feature/about.webp"),
    alt: "",
    title: "",
  },
  items: [
    {
      title: "Ведение базы клиентов",
      subtitle: "",
      description:
        "С PsyWealth все данные о клиентах у вас под рукой в любое время. Теперь вы точно не забудете ни одного из ваших клиентов.",
      suffix: "",
      prefix: "",
      icon: <BsAspectRatio />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Управление расписанием",
      subtitle: "",
      description:
        "Сервис позволит вам легко и быстро вести рабочее расписание. Зарегистрируйтесь и убедитесь в этом на практике.",
      suffix: "",
      prefix: "",
      icon: <BsEmojiWink />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "История работы с клиентами",
      subtitle: "",
      description:
        "Быстрый доступ к сохраненным данным о работе с клиентом позволит вам сэкономить время и разгрузить голову.",
      suffix: "",
      prefix: "",
      icon: <BsLayoutTextWindowReverse />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Управление финансами",
      subtitle: "",
      description:
        "PsyWealth имеет гибкую систему управления финансовыми взаимоотношениями между специалистом и клиентом.",
      suffix: "",
      prefix: "",
      icon: <BsPencilSquare />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Уведомления",
      subtitle: "",
      description:
        "Мы уведомим вас о важных аспектах вашей работы с клиентами, будь то начало сеанса или необходимость напомнить клиенту об оплате.",
      suffix: "",
      prefix: "",
      icon: <BsSpeedometer2 />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Бережём ваше время",
      subtitle: "",
      description:
        "Занимайтесь, где и когда вам удобно. Перенести или отменить сессию можно в личном кабинете в два клика.",
      suffix: "",
      prefix: "",
      icon: <BsClipboard2 />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
  ],
};

export const dataMobileAppPsychologistPage = {
  title: "А вот что PsyWealth сделает за вас",
  subtitle: "А вот что PsyWealth сделает за вас",
  subtitle2: (
    <>
      This is a text in the first line;
      <br />
      this is a text in a second line
    </>
  ),
  description:
    "Мы взяли все организационные заботы на себя, чтобы вы могли сосредоточиться на самом важном — себе и своих эмоциях",
  image: {
    src: require("assets/images/feature/iphone14-2.webp"),
    alt: "",
    title: "",
  },
  items: [
    {
      title: "Запомнит все о клиентах",
      subtitle: "",
      description: "Запомнит все о клиентах",
      suffix: "",
      prefix: "",
      icon: <BsAspectRatio />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Запомнит расписание",
      subtitle: "",
      description: "Запомнит расписание",
      suffix: "",
      prefix: "",
      icon: <BsEmojiWink />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Проконтролирует длительность сеансов",
      subtitle: "",
      description: "Проконтролирует длительность сеансов",
      suffix: "",
      prefix: "",
      icon: <BsMortarboard />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Сохранит информацию о сеансах",
      subtitle: "",
      description: "Сохранит информацию о сеансах",
      suffix: "",
      prefix: "",
      icon: <BsStar />,
      image: {
        src: null,
        imageAlt: "",
      },
    },

    {
      title: "Проконтролирует длительность сеансов",
      subtitle: "",
      description: "Проконтролирует длительность сеансов",
      suffix: "",
      prefix: "",
      icon: <BsXDiamond />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Сохранит информацию о сеансах",
      subtitle: "",
      description: "Сохранит информацию о сеансах",
      suffix: "",
      prefix: "",
      icon: <BsCameraVideo />,
      image: {
        src: null,
        imageAlt: "",
      },
    },

    {
      title: " Делаем оплату сессий удобной",
      subtitle: "",
      description:
        "Деньги за сессию списываются автоматически, но специалист получает их только после встречи. Если психолог отменит консультацию, деньги вернутся на вашу карту.",
      suffix: "",
      prefix: "",
      icon: <BsBrightnessHigh />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Бережём ваше время",
      subtitle: "",
      description:
        "Занимайтесь, где и когда вам удобно. Перенести или отменить сессию можно в личном кабинете в два клика.",
      suffix: "",
      prefix: "",
      icon: <BsActivity />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
  ],
};
