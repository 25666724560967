import { OrdersTable } from "components/pages/Profile/components/ProfileTabs";
import { useGetPsychologistOrders } from "components/pages/Psychologist/utils/useGetPsychologistOrders";
import { Spinner } from "components/utils/Spinner";
import { dataMessages } from "data";
import { PropTypes } from "prop-types";
import { Fragment, useEffect } from "react";
import { toast } from "react-toastify";
import { _debug } from "utils";

const MSG_TABLE_ORDER_TITLE = "Заказы";

/**
 * Component for showing Section "Psychologist Profile (Tab) - Psychologist Orders" of the site.
 *
 * @component
 **/
const ProfileTabOrders = (props) => {
  const {
    data: orders,
    isLoading: isLoadingPsychologistOrders,
    isError: isErrorPsychologistOrders,
    error: errorPsychologistOrders,
  } = useGetPsychologistOrders();

  const isLoading = isLoadingPsychologistOrders;
  const isError = isErrorPsychologistOrders;
  const errors = [errorPsychologistOrders];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (orders && orders.length) {
      _debug("PSYCHOLOGIST ORDERS:", orders);
    }
  }, [orders]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  return (
    <Fragment>
      {/*<!-- Offcanvas menu button -->*/}
      <div className="d-grid mb-0 d-lg-none w-100">
        <button
          className="btn btn-primary mb-4"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasSidebar"
          aria-controls="offcanvasSidebar"
        >
          <i className="fas fa-sliders-h"></i> Menu
        </button>
      </div>

      <div className="vstack gap-4">
        {/*<!-- Orders START -->*/}
        <div className="card border">
          {/*<!-- Card header -->*/}
          <div className="card-header border-bottom">
            <h4 className="card-header-title">{MSG_TABLE_ORDER_TITLE}</h4>
          </div>

          {/*<!-- Card body Orders -->*/}
          <div className="card-body vstack gap-4">
            {/*<!-- Orders item START -->*/}
            <div className="card">
              <div className="table-responsive border-0 mb-3">
                <OrdersTable
                  data={orders}
                  tableClass="align-middle p-4 mb-0 table-hover"
                  headerClass="table-dark"
                />
              </div>
              {/*<!-- Orders item END -->*/}
            </div>
            {/* <!-- Card body END -->*/}
          </div>
        </div>
        {/*<!-- Orders END -->*/}
      </div>
    </Fragment>
  );
};

ProfileTabOrders.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default ProfileTabOrders;
