import { SectionHeader } from "components/sections/CommonSection";
import { PageSection } from "core/components";
import { PropTypes } from "prop-types";
import { Fragment } from "react";

const SectionContentItem = (props) => {
  const { data } = props;

  if (!data) return;

  return (
    <Fragment>
      <div className="text-muted">{data?.icon}</div>
      <div className="ms-4">
        <h5 className="mb-3 fw-bold">{data.title}</h5>
        <p className="fw-light">{data.description}</p>
      </div>
    </Fragment>
  );
};

const SectionContent = (props) => {
  const { data, showTitle, showDescription } = props;

  const showImage = false;

  return (
    <div className="row align-items-center">
      <div
        className={
          showImage
            ? "col-md-4 order-2 order-md-1"
            : "col-md-6 order-2 order-md-1"
        }
      >
        <div className="d-flex mb-4 mt-4 mt-md-0">
          <SectionContentItem data={data.items[0]} />
        </div>
        <div className="d-flex mb-4">
          <SectionContentItem data={data.items[1]} />
        </div>
        <div className="d-flex">
          <SectionContentItem data={data.items[2]} />
        </div>
      </div>
      {showImage && (
        <div className="col-md-4 pe-lg-5 order-1 order-md-2">
          <img
            src={data.image.src}
            alt={data.image.alt}
            className="img-fluid rounded"
          />
        </div>
      )}

      <div
        className={
          showImage
            ? "col-md-4 ps-lg-5 order-3 order-md-3"
            : "col-md-6 ps-lg-5 order-3 order-md-3"
        }
      >
        <div className="d-flex mb-4 mt-5 mt-md-0">
          <SectionContentItem data={data.items[3]} />
        </div>
        <div className="d-flex mb-4">
          <SectionContentItem data={data.items[4]} />
        </div>
        <div className="d-flex">
          <SectionContentItem data={data.items[5]} />
        </div>
      </div>
    </div>
  );
};

/**
 * Component for showing Section "Feature - Our Advantages" of the site.
 *
 * @component
 */
const OurAdvantages = (props) => {
  const {
    data,
    id = "our-advantages",
    cn = "pt-130 pb-80",

    showHeaderSubtitle,
    showHeaderDescription,
    showTitle = true,
    showDescription = true,
  } = props;

  //console.log("11", props);

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader
          data={data}
          showSubtitle={showHeaderSubtitle}
          showDescription={showHeaderDescription}
        />
        <SectionContent
          data={data}
          showTitle={showTitle}
          showDescription={showDescription}
        />
      </div>
    </PageSection>
  );
};

OurAdvantages.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showHeaderSubtitle: PropTypes.bool ?? true,
  showHeaderDescription: PropTypes.bool ?? true,
  showTitle: PropTypes.bool ?? false,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default OurAdvantages;
