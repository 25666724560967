import { PropTypes } from "prop-types";
import { Fragment } from "react";

/**
 * Component for showing Section "Customer Profile (Tab) - Customer Wishlist" of the site.
 *
 * @component
 **/
const ProfileTabWishlist = (props) => {
  const { data } = props;

  return (
    <Fragment>
      {/*<!-- Offcanvas menu button -->*/}
      <div className="d-grid mb-0 d-lg-none w-100">
        <button
          className="btn btn-primary mb-4"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasSidebar"
          aria-controls="offcanvasSidebar"
        >
          <i className="fas fa-sliders-h"></i> Menu
        </button>
      </div>

      {/*<!-- Wishlist START -->*/}
      <div className="card border bg-transparent">
        {/*<!-- Card header -->*/}
        <div className="card-header bg-transparent border-bottom">
          <h4 className="card-header-title">My Wishlist</h4>
        </div>

        {/*<!-- Card body START -->*/}
        <div className="card-body vstack gap-4">
          {/*<!-- Select and button -->*/}
          <form className="d-flex justify-content-between">
            <div className="col-6 col-xl-3">
              <div
                className="choices"
                data-type="select-one"
                tabindex="0"
                role="listbox"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="choices__inner">
                  <select
                    className="form-select form-select-sm js-choice border-0 choices__input"
                    hidden=""
                    tabindex="-1"
                    data-choice="active"
                  >
                    <option value="" data-custom-properties="[object Object]">
                      Sort by
                    </option>
                  </select>
                  <div className="choices__list choices__list--single">
                    <div
                      className="choices__item choices__placeholder choices__item--selectable"
                      data-item=""
                      data-id="1"
                      data-value=""
                      data-custom-properties="[object Object]"
                      aria-selected="true"
                    >
                      Sort by
                    </div>
                  </div>
                </div>
                <div
                  className="choices__list choices__list--dropdown"
                  aria-expanded="false"
                >
                  <div className="choices__list" role="listbox">
                    <div
                      id="choices--vbj5-item-choice-1"
                      className="choices__item choices__item--choice is-selected choices__placeholder choices__item--selectable is-highlighted"
                      role="option"
                      data-choice=""
                      data-id="1"
                      data-value=""
                      data-select-text="Press to select"
                      data-choice-selectable=""
                      aria-selected="true"
                    >
                      Sort by
                    </div>
                    <div
                      id="choices--vbj5-item-choice-2"
                      className="choices__item choices__item--choice choices__item--selectable"
                      role="option"
                      data-choice=""
                      data-id="2"
                      data-value="Recently search"
                      data-select-text="Press to select"
                      data-choice-selectable=""
                    >
                      Recently search
                    </div>
                    <div
                      id="choices--vbj5-item-choice-3"
                      className="choices__item choices__item--choice choices__item--selectable"
                      role="option"
                      data-choice=""
                      data-id="3"
                      data-value="Most popular"
                      data-select-text="Press to select"
                      data-choice-selectable=""
                    >
                      Most popular
                    </div>
                    <div
                      id="choices--vbj5-item-choice-4"
                      className="choices__item choices__item--choice choices__item--selectable"
                      role="option"
                      data-choice=""
                      data-id="4"
                      data-value="Top rated"
                      data-select-text="Press to select"
                      data-choice-selectable=""
                    >
                      Top rated
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- Button -->*/}
            <button className="btn btn-danger-soft mb-0">
              <i className="fas fa-trash me-2"></i>Remove all
            </button>
          </form>

          {/*<!-- Wishlist item START -->*/}
          <div className="card shadow p-2">
            <div className="row g-0">
              {/*<!-- Card img -->*/}
              <div className="col-md-3">
                <img
                  src="assets/images/category/hotel/4by3/10.jpg"
                  className="card-img rounded-2"
                  alt="Card image"
                />
              </div>

              {/*<!-- Card body -->*/}
              <div className="col-md-9">
                <div className="card-body py-md-2 d-flex flex-column h-100">
                  {/*<!-- Rating and buttons -->*/}
                  <div className="d-flex justify-content-between align-items-center">
                    <ul className="list-inline small mb-0">
                      <li className="list-inline-item me-0">
                        <i className="fa-solid fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item me-0">
                        <i className="fa-solid fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item me-0">
                        <i className="fa-solid fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item me-0">
                        <i className="fa-solid fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="fa-solid fa-star-half-alt text-warning"></i>
                      </li>
                    </ul>

                    <ul className="list-inline mb-0">
                      {/*<!-- Heart icon -->*/}
                      <li className="list-inline-item">
                        <a
                          href="#"
                          className="btn btn-sm btn-round btn-danger mb-0"
                        >
                          <i className="fa-solid fa-fw fa-heart"></i>
                        </a>
                      </li>
                      {/*<!-- Share icon -->*/}
                      <li className="list-inline-item dropdown">
                        {/*<!-- Share button -->*/}
                        <a
                          href="#"
                          className="btn btn-sm btn-round btn-light mb-0"
                          role="button"
                          id="dropdownShare2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fa-solid fa-fw fa-share-alt"></i>
                        </a>
                        {/*<!-- dropdown button -->*/}
                        <ul
                          className="dropdown-menu dropdown-menu-end min-w-auto shadow rounded"
                          aria-labelledby="dropdownShare2"
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="fab fa-twitter-square me-2"></i>
                              Twitter
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="fab fa-facebook-square me-2"></i>
                              Facebook
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="fab fa-linkedin me-2"></i>LinkedIn
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="fa-solid fa-copy me-2"></i>Copy link
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>

                  {/*<!-- Title -->*/}
                  <h5 className="card-title mb-1">
                    <a href="hotel-detail.html">
                      Pride moon Village Resort &amp; Spa
                    </a>
                  </h5>
                  <small>
                    <i className="bi bi-geo-alt me-2"></i>31J W Spark Street,
                    California - 24578
                  </small>

                  {/*<!-- Price and Button -->*/}
                  <div className="d-sm-flex justify-content-sm-between align-items-center mt-3 mt-md-auto">
                    {/*<!-- Button -->*/}
                    <div className="d-flex align-items-center">
                      <h5 className="fw-bold mb-0 me-1">$980</h5>
                      <span className="mb-0 me-2">/day</span>
                    </div>
                    {/*<!-- Price -->*/}
                    <div className="mt-3 mt-sm-0">
                      <a
                        href="hotel-detail.html"
                        className="btn btn-sm btn-dark w-100 mb-0"
                      >
                        View hotel
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- Wishlist item END -->*/}

          {/*<!-- Wishlist item START -->*/}
          <div className="card shadow p-2">
            <div className="row g-0">
              {/*<!-- Card img -->*/}
              <div className="col-md-3">
                <img
                  src="assets/images/category/hotel/4by3/11.jpg"
                  className="card-img rounded-2"
                  alt="Card image"
                />
              </div>

              {/*<!-- Card body -->*/}
              <div className="col-md-9">
                <div className="card-body d-flex flex-column h-100 py-md-2">
                  {/*<!-- Rating and buttons -->*/}
                  <div className="d-flex justify-content-between align-items-center">
                    <ul className="list-inline small mb-0">
                      <li className="list-inline-item me-0">
                        <i className="fa-solid fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item me-0">
                        <i className="fa-solid fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item me-0">
                        <i className="fa-solid fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item me-0">
                        <i className="fa-solid fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="fa-solid fa-star-half-alt text-warning"></i>
                      </li>
                    </ul>

                    <ul className="list-inline mb-0">
                      {/*<!-- Heart icon -->*/}
                      <li className="list-inline-item">
                        <a
                          href="#"
                          className="btn btn-sm btn-round btn-danger mb-0"
                        >
                          <i className="fa-solid fa-fw fa-heart"></i>
                        </a>
                      </li>
                      {/*<!-- Share icon -->*/}
                      <li className="list-inline-item dropdown">
                        <a
                          href="#"
                          className="btn btn-sm btn-round btn-light mb-0"
                          role="button"
                          id="dropdownShare3"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fa-solid fa-fw fa-share-alt"></i>
                        </a>
                        {/*<!-- dropdown button -->*/}
                        <ul
                          className="dropdown-menu dropdown-menu-end min-w-auto shadow rounded"
                          aria-labelledby="dropdownShare3"
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="fab fa-twitter-square me-2"></i>
                              Twitter
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="fab fa-facebook-square me-2"></i>
                              Facebook
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="fab fa-linkedin me-2"></i>LinkedIn
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="fa-solid fa-copy me-2"></i>Copy link
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>

                  {/*<!-- Title -->*/}
                  <h5 className="card-title mb-1">
                    <a href="hotel-detail.html">Royal Beach Resort</a>
                  </h5>
                  <small>
                    <i className="bi bi-geo-alt me-2"></i>Manhattan street,
                    London - 24578
                  </small>

                  {/*<!-- Price and Button -->*/}
                  <div className="d-sm-flex justify-content-sm-between align-items-center mt-3 mt-md-auto">
                    {/*<!-- Button -->*/}
                    <div className="d-flex align-items-center">
                      <h5 className="fw-bold mb-0 me-1">$540</h5>
                      <span className="mb-0 me-2">/day</span>
                    </div>
                    {/*<!-- Price -->*/}
                    <div className="mt-3 mt-sm-0">
                      <a
                        href="hotel-detail.html"
                        className="btn btn-sm btn-dark w-100 mb-0"
                      >
                        View hotel
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- Wishlist item END -->*/}
        </div>
        {/*<!-- Card body END -->*/}
      </div>
      {/*<!-- Wishlist END -->*/}
    </Fragment>
  );
};

ProfileTabWishlist.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default ProfileTabWishlist;
