import { createColumnHelper } from "@tanstack/react-table";
import { CustomFormInputTime } from "components/pages/Customer/components/core/FormInput/FormInput";
import { DateTime } from "luxon";
import { Fragment } from "react";
import { Form as BSForm, Button, Col, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CustomTable from "../../ProfileOld/CustomTable";

// TODO : Добавить проверку, что дата конца меньше даты начала, пока так

const MSG_TABLE_PRODUCT_TITLE = "Продукты";

const columnHelper = createColumnHelper();

const BreaksTable = (props) => {
  const {
    data: breaks,
    tableClass,
    headerClass,
    onDelete,
    ...otherProps
  } = props;

  // console.log(breaks);

  const handleDelete = (cell, row) => {
    console.log("handleDelete!!!!", cell, row);
    onDelete(cell.row.original.id);
  };

  const columns = [
    columnHelper.accessor("startTime", {
      header: "startTime",
    }),
    columnHelper.accessor("endTime", {
      header: "endTime",
    }),

    columnHelper.accessor("action", {
      header: () => <span>Action</span>,
      accessor: "id",
      cell: ({ cell, row }) => (
        <button onClick={() => handleDelete(cell, row)}>Delete</button>
      ),
    }),
  ];

  return (
    <Fragment>
      {breaks && (
        <CustomTable
          data={breaks}
          columns={columns}
          tableClass={tableClass}
          headerClass={headerClass}
          {...otherProps}
        />
      )}
    </Fragment>
  );
};

/**
 * Component for showing Orders table.
 *
 * @component
 */
const DayPlanModalForm = (props) => {
  const {
    showModal,
    handleClose,
    setShowModal,
    modalFormData = {},
    setModalFormData,
  } = props;

  // console.log(
  //   "!!!!!!!",
  //   modalFormData,
  //   DateTime.fromISO(modalFormData.startTime).toJSDate()
  // );
  console.log(modalFormData.startTime);
  console.log(modalFormData.endTime);
  console.log(modalFormData);

  const {
    //methods,
    register,
    control,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      startTime: modalFormData.startTime
        ? DateTime.fromFormat(modalFormData.startTime, "hh:mm").toJSDate()
        : DateTime.now().toJSDate(),
      endTime: modalFormData.endTime
        ? DateTime.fromFormat(modalFormData.endTime, "hh:mm").toJSDate()
        : DateTime.now().toJSDate(),
    },
  });

  const onSubmit = (data) => {
    console.log("SUBMIT:", data);
    // setModalFormData(data);
    handleClose({ ...data, key: modalFormData.key });
  };
  const onError = (error) => {
    console.log("ERROR:::", error);
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{`Appointment Details ${modalFormData.title}`}</Modal.Title>
      </Modal.Header>
      <BSForm className="" onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Body>
          <div className="timing-modal">
            <Row className="mb-3">
              <BSForm.Group className="" as={Col} controlId="formStartTime">
                <CustomFormInputTime
                  control={control}
                  name="startTime"
                  label="Start time"
                  required
                  error={errors.startDate}
                />
              </BSForm.Group>
              <BSForm.Group className="" as={Col} controlId="formEndTime">
                <CustomFormInputTime
                  control={control}
                  name="endTime"
                  label="End time"
                  required
                  error={errors.startDate}
                />
              </BSForm.Group>
            </Row>
            <Row className="mb-3">
              {modalFormData.breaks && (
                <BreaksTable data={modalFormData?.breaks} />
              )}
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
      </BSForm>
    </Modal>
  );
};

export default DayPlanModalForm;
