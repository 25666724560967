import { APP_API_URI } from "config/config";
import { _debug } from "utils";
import { getGlobalAuthHeader, requestGlobalOptions } from "./auth.service";
import { getResultOrError } from "./course.service";

const SERVICE_DEBUG = true;

const SERVICE_URL = `${APP_API_URI}/appointments`;

const getServiceURL = (URL) =>
  URL ? `${SERVICE_URL}/${URL}` : `${SERVICE_URL}`;

const LOGGER = (message, obj, type) => {
  if (SERVICE_DEBUG) _debug(message, obj, type);
};

export const getByCustomer = async (customerId) => {
  const URL = getServiceURL(`customers/all`);

  _debug("Appointments fetched all by customer id: ", {
    URL,
    customerId,
  });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getByPsychologist = async (psychologistId) => {
  const URL = getServiceURL(`psychologists/all`);

  _debug("Appointments fetched all by psychologist id: ", {
    URL,
    psychologistId,
  });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const create = async ({ psychologistId, date, time, datetime }) => {
  const URL = getServiceURL(
    `new?psychologistId=${psychologistId}&date=${date}&time=${time}&datetime=${datetime}`
  );

  _debug("Create new appointment: ", {
    URL,
    psychologistId,
    date,
    time,
    datetime,
  });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "POST",
    headers: new Headers(requestHeaders),
    // body: JSON.stringify(body),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

const AppointmentService = { getByCustomer, getByPsychologist, create };

export default AppointmentService;
