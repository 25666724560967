import { CallToAction, Feature, Overview2, Pricing } from "components/sections";
import { SEO } from "components/utils/Analytics";
import {
  dataCTALetsContact,
  dataFAQGiftsPage as dataFAQ,
  dataFeatureGiftsPage as dataFeature,
  dataHeroGiftsPage as dataHero,
  dataOverviewMainPage,
  dataPricingGiftsPage as dataPricing,
  dataPsychologistPage,
} from "data";
import { Fragment, useRef } from "react";
import { Hero2 as Hero } from ".";
import SectionFAQ from "./FAQ";
import { StepsSection } from "./components/StepsSection";

// NOTE : https://demo.graygrids.com/themes/space/index.html

const GiftPage = () => {
  const refCAT = useRef(null);

  const executeScroll = (e) => {
    refCAT.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  return (
    <Fragment>
      <SEO data={dataPsychologistPage.meta}>
        <link rel="canonical" href="https://psywealth.com/gifts/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="gift-page" id="main">
        <Hero data={dataHero} />

        <Pricing data={dataPricing} />

        <StepsSection data={dataPricing} />

        <Feature
          data={dataFeature}
          type="style2"
          showTitle={false}
          showDescription
        />
        <Overview2 data={dataOverviewMainPage} cn="pt-120 pb-120" />

        <CallToAction
          data={dataCTALetsContact}
          innerRef={refCAT}
          useAnimation={true}
        />
        <SectionFAQ data={dataFAQ} />
      </main>
    </Fragment>
  );
};

export default GiftPage;
