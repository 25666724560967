import classNames from "classnames";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { PageSection, SectionHeader } from "../../sections/CommonSection";

import "./PurchaseProcess.scss";

// NOTE : https://bootsnipp.com/snippets/O4O8
// NOTE : https://bootsnipp.com/snippets/8ONK

const SectionItem = (props) => {
  const { data, index } = props;
  const isStyle2 = index % 2 === 0;

  const SectionItemImage = ({ data }) => {
    return (
      <img
        className="img-fluid"
        src={data.image}
        alt={data.imageAlt}
        width="400"
        height="400"
        loading="lazy"
      />
    );
  };

  return (
    <div
      className={classNames(
        "info-one",
        isStyle2 ? "" : "style2",
        index > 0 ? "pt-150" : ""
      )}
      key={index}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-12 col-12">
          {isStyle2 ? (
            <div className="info-text wow fadeInLeft">
              <h2>{data.title}</h2>
              <p>{data.description}</p>
            </div>
          ) : (
            <div className="info-image wow fadeInLeft">
              <SectionItemImage data={data} />
            </div>
          )}
        </div>
        <div className="col-lg-6 col-md-12 col-12">
          {isStyle2 ? (
            <div className="info-image wow fadeInRight">
              <SectionItemImage data={data} />
            </div>
          ) : (
            <div className="info-text wow fadeInRight">
              <h2>{data.title}</h2>
              <p>{data.description}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data } = props;

  return (
    <Fragment>
      <div className="row bs-wizard" style={{ borderBottom: "0" }}>
        <div className="col-lg-4 bs-wizard-step active">
          <div className="text-center bs-wizard-stepnum">Step 1</div>
          <div className="progress">
            <div className="progress-bar"></div>
          </div>
          <a href="#" className="bs-wizard-dot"></a>
          <div className="bs-wizard-info text-center">
            Lorem ipsum dolor sit amet.
          </div>
        </div>

        <div className="col-lg-4 bs-wizard-step active">
          <div className="text-center bs-wizard-stepnum">Step 2</div>
          <div className="progress">
            <div className="progress-bar"></div>
          </div>
          <a href="#" className="bs-wizard-dot"></a>
          <div className="bs-wizard-info text-center">
            Nam mollis tristique erat vel tristique. Aliquam erat volutpat.
            Mauris et vestibulum nisi. Duis molestie nisl sed scelerisque
            vestibulum. Nam placerat tristique placerat
          </div>
        </div>

        <div className="col-lg-4 bs-wizard-step disabled">
          <div className="text-center bs-wizard-stepnum">Step 3</div>
          <div className="progress">
            <div className="progress-bar"></div>
          </div>
          <a href="#" className="bs-wizard-dot"></a>
          <div className="bs-wizard-info text-center">
            Integer semper dolor ac auctor rutrum. Duis porta ipsum vitae mi
            bibendum bibendum
          </div>
        </div>
      </div>
    </Fragment>
  );
};

/**
 * Component for showing Section "PurchaseProcess" of the site.
 *
 * @component
 */
const PurchaseProcess = (props) => {
  const { data, id = "overview", cn = "pt-130 app-info" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} />
        <SectionContent data={data} />
      </div>
    </PageSection>
  );
};

PurchaseProcess.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default PurchaseProcess;
