import { SEO } from "components/utils/Analytics";
import {
  dataHeroGiftsPage as dataHero,
  dataOverviewMainPage,
  dataPsychologistPage,
} from "data";
import { Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { Hero2 as Hero } from ".";
import MultiStepForm from "../Home/Form/MultiStepForm";
import PurchaseOrder from "./PurchaseOrder";
import PurchaseProcess from "./PurchaseProcess";

// NOTE : https://demo.graygrids.com/themes/space/index.html

const GiftPurchasePage = () => {
  const [searchParams] = useSearchParams();

  console.log(searchParams);
  console.log(searchParams.get("good"));

  return (
    <Fragment>
      <SEO data={dataPsychologistPage.meta}>
        <link rel="canonical" href="https://psywealth.com/gifts/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="gift-page" id="main">
        <Hero data={dataHero} />
        <PurchaseProcess data={dataOverviewMainPage} />
        <PurchaseOrder data={dataOverviewMainPage} />
        <MultiStepForm />
      </main>
    </Fragment>
  );
};

export default GiftPurchasePage;
