import { SEO } from "components/utils/Analytics";
import { dataGlossaryPage } from "data";
import { Fragment } from "react";

// NOTE :

const GlossaryPage = () => {
  return (
    <Fragment>
      <SEO data={dataGlossaryPage.meta}>
        <link rel="canonical" href="https://psywealth.com/glossary/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="services-page" id="main">
        {/*  <Hero data={dataHero} />
        <Service data={dataService2} type="style1" />
        <Service data={dataService2} type="style2" />*/}
      </main>
    </Fragment>
  );
};

export default GlossaryPage;
