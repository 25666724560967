import { Spinner } from "components/utils/Spinner";
import { APP_API_URI } from "config/config";
import { dataMessages } from "data";
import DOMPurify from "isomorphic-dompurify";
import { Fragment, useEffect } from "react";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { PageSection, SectionHeader } from "../Home";
import { useGetLessonBySlug } from "./useGetLessonBySlug";

// NOTE :

const SectionVideo = (props) => {
  const { videoId } = props;

  const URL = videoId
    ? `${APP_API_URI}/course/lessons/video/${videoId}`
    : "https://youtube.com/embed/VA8PzkQa8d8?rel=0&amp;enablejsapi=1";

  return (
    <section className="video1 cid-svGLmnD9Wg" id="video01-6">
      <div className="align-center container">
        <div className="mbr-section-head pb-5">
          <h4 className="mbr-section-title mbr-fonts-style mb-0 display-2">
            <strong>Watch Video</strong>
          </h4>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-12">
            <div className="box">
              <div
                className="mbr-media show-modal align-center"
                data-modal=".modalWindow"
              >
                <img src="assets/images/background1.jpg" alt="Mobirise" />
                <div className="icon-wrap">
                  <a className="text-primary" href="#">
                    <span className="mbr-iconfont socicon-youtube socicon"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="modalWindow">
          <div className="modalWindow-container">
            <div className="modalWindow-video-container">
              <div className="modalWindow-video">
                <iframe
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allowFullScreen
                  src={URL}
                ></iframe>
              </div>
              <a className="close" role="button" data-dismiss="modal">
                <span
                  aria-hidden="true"
                  className="mbr-iconfont mobi-mbri-close mobi-mbri closeModal"
                ></span>
                <span className="sr-only">Close</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionContent = (props) => {
  const { data } = props;
  const { lesson } = data;

  console.log(data);
  console.log(lesson);

  return (
    <Fragment>
      <div className="row">
        <h1>{lesson.name}</h1>
        <div>
          {lesson.content ? (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(lesson.content),
              }}
            ></div>
          ) : (
            <p>{lesson.description}</p>
          )}
        </div>
        <SectionVideo videoId={lesson.videoId} />
      </div>
    </Fragment>
  );
};

/**
 * Component for showing Section "Lesson" of the site.
 *
 * @component
 */
const LessonSection = (props) => {
  const { data, id = "lesson", cn = "pt-100 pb-100" } = props;
  const { courseSlug: slug, lessonSlug } = data;

  console.log("$$$$", data);

  const {
    data: lesson,
    isLoading: isLoadingCourse,
    isError: isErrorCourse,
    error: errorCourse,
  } = useGetLessonBySlug(slug, lessonSlug);

  const isLoading = isLoadingCourse;
  const isError = isErrorCourse;
  const errors = [errorCourse];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (lesson) {
      _debug("LESSON:", lesson);
    }
  }, [lesson]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isErrorCourse && errorCourse?.status === 204) {
    return <div className="error">Нет курсов</div>;
  }

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader
          data={{ ...data, title: lesson.name, lesson: lesson }}
          showSubtitle={false}
          showDescription={false}
        />
        <SectionContent data={{ ...data, lesson: lesson }} />
      </div>
    </PageSection>
  );
};

export default LessonSection;
