import { PageSection } from "core/components";
import { PropTypes } from "prop-types";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { SectionHeader } from "../../../sections/CommonSection";

const SectionContentItem = (props) => {
  const { data } = props;

  return (
    <div className="m-4">
      <p>{data.description}</p>
      <img className="img-fluid" src={data.image.src} alt="" />
    </div>
  );
};

const SectionContent = (props) => {
  const { data, id } = props;

  // _debug(data);

  return (
    <Tab.Container id={id} defaultActiveKey={data.items[0]?.id}>
      <Row>
        <Col sm={4}>
          <Nav variant="pills" className="flex-column">
            {data.items.map((item, index) => (
              <Nav.Item key={index}>
                <Nav.Link eventKey={item.id}>{item.title}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col sm={8}>
          <Tab.Content>
            {data.items.map((item, index) => {
              return (
                <Tab.Pane eventKey={item.id} key={index}>
                  <SectionContentItem data={item} />
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

/**
 * Component for showing Section "PsyStartTabs" of the site.
 *
 * @component
 */
const PsyStartTabs = (props) => {
  const { data, id = "psy-start-tabs", cn = "pt-130" } = props;

  if (!data.items && data.items.length === 0) {
    return;
  }

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} />
        <SectionContent data={data} id={id} />
      </div>
    </PageSection>
  );
};

PsyStartTabs.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default PsyStartTabs;
