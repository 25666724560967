import { useQuery } from "@tanstack/react-query";
import PsychologistService from "services/psychologist.service";

export const useGetPsychologistProfileEducationById = (id, options = {}) => {
  console.log("useGetBlogEntityById enabled", Boolean(id) && id !== "new");

  return useQuery({
    queryFn: () => PsychologistService.getProfileEducationById(id),
    queryKey: ["psychologistProfileEducationById", id],
    enabled: Boolean(id) && id !== "new",
  });
};
