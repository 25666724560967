import classnames from "classnames";
import { SectionTitle } from "components/sections/CommonSection";
import { PropTypes } from "prop-types";

/**
 * Component for showing section "Profile Section" of the site.
 *
 * @component
 */
export const ProfileSection = (props) => {
  const { children, id, cn, ...otherProps } = props;
  let cnTmp = id
    ? `page-section profile-section ${id}-section`
    : `page-section profile-section`;

  return (
    <section className={classnames(cnTmp, cn)} id={id} {...otherProps}>
      {children}
    </section>
  );
};

ProfileSection.propTypes = {
  id: PropTypes.string,
  cn: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export const SectionHeader = (props) => {
  const { data, showDescription, showSubtitle } = props;

  //  <div className="col-xl-7 col-lg-8 col-md-9 mx-auto">
  return (
    <div className="row">
      <div className="mx-auto">
        <div className="section-title mb-lg-5 mb-3">
          <SectionTitle
            data={data}
            showSubtitle={showSubtitle}
            showDescription={showDescription}
            {...props}
          />
        </div>
      </div>
    </div>
  );
};
