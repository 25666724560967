import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

// NOTE : https://stackoverflow.com/questions/66265608/react-router-v6-get-path-pattern-for-current-route

const useMatchPath = (routes) => {
  const { pathname } = useLocation();
  const [route, setRoute] = useState(undefined);

  useEffect(() => {
    const currentRoute = routes.find((pattern) => {
      return matchPath(pattern, pathname);
    });
    setRoute(currentRoute);
  }, [pathname]);

  return route;
};

// const useMatchRoute = (routes) => {
//   return routes.find((route) => useMatch(route));
// };

export default useMatchPath;
