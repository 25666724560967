import classnames from "classnames";
import { useComponentPrefix } from "components/core/utils/utils";
import { PropTypes } from "prop-types";
import React from "react";

/**
 * Component for showing page "Section" of the site.
 *
 * @component
 * @version 1.0.2
 **/
export const PageSection = React.forwardRef(
  (
    {
      id,
      className,
      // Retained for backward compatibility with previous component specification
      cn,
      componentPrefix,
      container = false,
      children,
      // Need to define the default "as" during prop destructuring to be compatible with styled-components
      as: Component = "section",
      ...otherProps
    },
    ref
  ) => {
    //let prefix = id ? `page-section ${id}-section` : `page-section`;
    const prefix = useComponentPrefix(
      componentPrefix,
      id ? `page-section ${id}-section` : `page-section`
    );

    return (
      <Component
        ref={ref}
        {...otherProps}
        className={classnames(className, cn, prefix)}
        id={id}
      >
        {container ? <div className="container">{children}</div> : children}
      </Component>
    );
  }
);

PageSection.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,

  /**
   * When this prop is set, it creates a Card with a Card.Body inside
   * passing the children directly to it
   */
  body: PropTypes.bool,
  as: PropTypes.elementType,
};
