import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { useOutletContext } from "react-router-dom";
import FormProfileBio from "./FormProfileBio";

/**
 * Component for showing Section "Customer Profile (Tab) - Customer Bio" of the site.
 *
 * @component
 **/
const _ProfileTabBio = (props) => {
  const { data } = props;

  return (
    <Fragment>
      {/*<!-- Offcanvas menu button -->*/}
      <div className="d-grid mb-0 d-lg-none w-100">
        <button
          className="btn btn-primary mb-4"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasSidebar"
          aria-controls="offcanvasSidebar"
        >
          <i className="fas fa-sliders-h"></i> Menu
        </button>
      </div>

      <div className="vstack gap-4">
        {/*<!-- Verified message -->*/}
        <div className="bg-light rounded p-3">
          {/*<!-- Progress bar -->*/}
          <div className="overflow-hidden">
            <h6>Complete Your Profile</h6>
            <div className="progress progress-sm bg-success bg-opacity-10">
              <div
                className="progress-bar bg-success aos aos-init aos-animate"
                role="progressbar"
                data-aos="slide-right"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                style={{ width: "85%" }}
                aria-valuenow="85"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <span className="progress-percent-simple h6 fw-light ms-auto">
                  85%
                </span>
              </div>
            </div>
            <p className="mb-0">
              Get the best out of booking by adding the remaining details!
            </p>
          </div>
          {/*<!-- Content -->*/}
          <div className="bg-body rounded p-3 mt-3">
            <ul className="list-inline hstack flex-wrap gap-2 justify-content-between mb-0">
              <li className="list-inline-item h6 fw-normal mb-0">
                <a href="#">
                  <i className="bi bi-check-circle-fill text-success me-2"></i>
                  Verified Email
                </a>
              </li>
              <li className="list-inline-item h6 fw-normal mb-0">
                <a href="#">
                  <i className="bi bi-check-circle-fill text-success me-2"></i>
                  Verified Mobile Number
                </a>
              </li>
              <li className="list-inline-item h6 fw-normal mb-0">
                <a href="#" className="text-primary">
                  <i className="bi bi-plus-circle-fill me-2"></i>Complete Basic
                  Info
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/*<!-- Personal info START -->*/}
        <div className="card border">
          {/*<!-- Card header -->*/}
          <div className="card-header border-bottom">
            <h4 className="card-header-title">Personal Information</h4>
          </div>

          {/*<!-- Card body START -->*/}
          <div className="card-body">
            {/*<!-- Form START -->*/}
            <form className="row g-3">
              {/*<!-- Profile photo -->*/}
              <div className="col-12">
                <label className="form-label">
                  Upload your profile photo
                  <span className="text-danger">*</span>
                </label>
                <div className="d-flex align-items-center">
                  <label
                    className="position-relative me-4"
                    for="uploadfile-1"
                    title="Replace this pic"
                  >
                    {/*<!-- Avatar place holder -->*/}
                    <span className="avatar avatar-xl">
                      <img
                        id="uploadfile-1-preview"
                        className="avatar-img rounded-circle border border-white border-3 shadow"
                        src="assets/images/avatar/01.jpg"
                        alt=""
                      />
                    </span>
                  </label>
                  {/*<!-- Upload button -->*/}
                  <label
                    className="btn btn-sm btn-primary-soft mb-0"
                    for="uploadfile-1"
                  >
                    Change
                  </label>
                  <input
                    id="uploadfile-1"
                    className="form-control d-none"
                    type="file"
                  />
                </div>
              </div>

              {/*<!-- Name -->*/}
              <div className="col-md-6">
                <label className="form-label">
                  Full Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value="Jacqueline Miller"
                  placeholder="Enter your full name"
                />
              </div>

              {/*<!-- Email -->*/}
              <div className="col-md-6">
                <label className="form-label">
                  Email address<span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  value="hello@gmail.com"
                  placeholder="Enter your email id"
                />
              </div>

              {/*<!-- Mobile -->*/}
              <div className="col-md-6">
                <label className="form-label">
                  Mobile number<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value="222 555 666"
                  placeholder="Enter your mobile number"
                />
              </div>

              {/*<!-- Nationality -->*/}
              <div className="col-md-6">
                <label className="form-label">
                  Nationality<span className="text-danger">*</span>
                </label>
                <div
                  className="choices"
                  data-type="select-one"
                  tabIndex="0"
                  role="listbox"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="choices__inner">
                    <select
                      className="form-select js-choice choices__input"
                      hidden=""
                      tabIndex="-1"
                      data-choice="active"
                    >
                      <option
                        value="Paris"
                        data-custom-properties="[object Object]"
                      >
                        Paris
                      </option>
                    </select>
                    <div className="choices__list choices__list--single">
                      <div
                        className="choices__item choices__item--selectable"
                        data-item=""
                        data-id="1"
                        data-value="Paris"
                        data-custom-properties="[object Object]"
                        aria-selected="true"
                      >
                        Paris
                      </div>
                    </div>
                  </div>
                  <div
                    className="choices__list choices__list--dropdown"
                    aria-expanded="false"
                  >
                    <div className="choices__list" role="listbox">
                      <div
                        id="choices--yd7e-item-choice-1"
                        className="choices__item choices__item--choice choices__placeholder choices__item--selectable is-highlighted"
                        role="option"
                        data-choice=""
                        data-id="1"
                        data-value=""
                        data-select-text="Press to select"
                        data-choice-selectable=""
                        aria-selected="true"
                      >
                        Select your country
                      </div>
                      <div
                        id="choices--yd7e-item-choice-2"
                        className="choices__item choices__item--choice choices__item--selectable"
                        role="option"
                        data-choice=""
                        data-id="2"
                        data-value="USA"
                        data-select-text="Press to select"
                        data-choice-selectable=""
                      >
                        USA
                      </div>
                      <div
                        id="choices--yd7e-item-choice-3"
                        className="choices__item choices__item--choice is-selected choices__item--selectable"
                        role="option"
                        data-choice=""
                        data-id="3"
                        data-value="Paris"
                        data-select-text="Press to select"
                        data-choice-selectable=""
                      >
                        Paris
                      </div>
                      <div
                        id="choices--yd7e-item-choice-4"
                        className="choices__item choices__item--choice choices__item--selectable"
                        role="option"
                        data-choice=""
                        data-id="4"
                        data-value="India"
                        data-select-text="Press to select"
                        data-choice-selectable=""
                      >
                        India
                      </div>
                      <div
                        id="choices--yd7e-item-choice-5"
                        className="choices__item choices__item--choice choices__item--selectable"
                        role="option"
                        data-choice=""
                        data-id="5"
                        data-value="UK"
                        data-select-text="Press to select"
                        data-choice-selectable=""
                      >
                        UK
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*<!-- Date of birth -->*/}
              <div className="col-md-6">
                <label className="form-label">
                  Date of Birth<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control flatpickr flatpickr-input"
                  value="29 Aug 1996"
                  placeholder="Enter date of birth"
                  data-date-format="d M Y"
                  readOnly="readOnly"
                />
              </div>

              {/*<!-- Gender -->*/}
              <div className="col-md-6">
                <label className="form-label">
                  Select Gender<span className="text-danger">*</span>
                </label>
                <div className="d-flex gap-4">
                  <div className="form-check radio-bg-light">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked=""
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Male
                    </label>
                  </div>
                  <div className="form-check radio-bg-light">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                    />
                    <label className="form-check-label" for="flexRadioDefault2">
                      Female
                    </label>
                  </div>
                  <div className="form-check radio-bg-light">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault3"
                    />
                    <label className="form-check-label" for="flexRadioDefault3">
                      Others
                    </label>
                  </div>
                </div>
              </div>

              {/*<!-- Address -->*/}
              <div className="col-12">
                <label className="form-label">Address</label>
                <textarea className="form-control" rows="3" spellCheck="false">
                  2119 N Division Ave, New Hampshire, York, United States
                </textarea>
              </div>

              {/*<!-- Button -->*/}
              <div className="col-12 text-end">
                <a href="#" className="btn btn-primary mb-0">
                  Save Changes
                </a>
              </div>
            </form>
            {/*<!-- Form END -->*/}
          </div>
          {/*<!-- Card body END -->*/}
        </div>
        {/*<!-- Personal info END -->*/}
      </div>
    </Fragment>
  );
};

/**
 * Component for showing Section "Customer Profile (Tab) - Customer Bio" of the site.
 *
 * @component
 **/
const ProfileTabBio = (props) => {
  // const { data } = props;
  const { profile } = useOutletContext();

  console.log("111!", profile);

  return (
    <Fragment>
      <div className="vstack gap-4">
        {/*<!-- Personal info START -->*/}
        <div className="card border">
          {/*<!-- Card header -->*/}
          <div className="card-header border-bottom">
            <h4 className="card-header-title">Personal Information</h4>
          </div>

          {/*<!-- Card body START -->*/}
          <div className="card-body">
            {/*<!-- Form START -->*/}

            <FormProfileBio data={profile} />

            {/*<!-- Form END -->*/}
          </div>
          {/*<!-- Card body END -->*/}
        </div>
        {/*<!-- Personal info END -->*/}
      </div>
    </Fragment>
  );
};

ProfileTabBio.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default ProfileTabBio;
