import { useQuery } from "@tanstack/react-query";
import DailyVideoService from "services/dailyvideo.service";

export const useGetTopVideo = (props) => {
  // console.log("!!!", URL, props);

  return useQuery({
    queryFn: () => DailyVideoService.getTop(),
    queryKey: ["topDailyVideo"],
  });
};
