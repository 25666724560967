import { useQuery } from "@tanstack/react-query";
import BlogService from "services/blog.service";

export const useGetSingleBlogPostBySlug = (slug, options = {}) => {
  return useQuery({
    queryFn: () => BlogService.getSingleBlogPostBySlug(slug),
    queryKey: ["blog_single_post", slug],
    enabled: typeof slug !== typeof undefined,
  });
};
