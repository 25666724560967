import { SectionHeader } from "components/sections/CommonSection";
import { APP_API_URI } from "config/config";
import { useIsMobile } from "hooks";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { ProfileSection } from "./ProfileSection.section";

// NOTE : https://alter.ru/psychologists/kajjani/ivan-gorbachev?position=1&from=search_page&from_link=%2Fpsychologists%3Falter_campaign%3Dlanding-menu

const SectionContent = (props) => {
  const { data, isMobile } = props;

  const getImageURL = (id) => {
    return `${APP_API_URI}/psychologists/psychologist/profile/${id}/image`;
  };

  return (
    <Fragment>
      <div className="d-flex is-align-items-flex-start">
        <div className="psychologist-avatar avatar-in-profile">
          <img
            src={
              data.id
                ? getImageURL(data.id)
                : "https://www.bootdey.com/image/180x180/00FFFF/000000"
            }
            alt={
              data.id
                ? `${data.name}, Семейный психолог, Врач-психотерапевт, ${data.qualification}`
                : ""
            }
            srcSet={`${getImageURL(data.id)} 2x`}
            className="rounded-circle"
            style={{ width: "185px", minWidth: "185px", height: "185px" }}
          />
        </div>
        <div>
          <h1>
            <span className="font-futura-400 mb-2 mb-md-3 line-height-2 font-weight-1 font-size-7">
              {data.name}{" "}
            </span>
            <div className="d-flex flex-column flex-md-row flex-wrap header__psychologist-bio-position mb-2 mb-md-3">
              <span className="font-futura-400 mr-3 line-height-2 font-weight-0 font-size-4 color-blue-3">
                Семейный психолог
              </span>
              <span className="font-futura-400 mr-3 line-height-2 font-weight-0 font-size-4 color-blue-3">
                Врач-психотерапевт
              </span>
              <span className="font-futura-400 mr-3 line-height-2 font-weight-0 font-size-4 color-blue-3">
                {data.qualification}
              </span>
            </div>
          </h1>
          <div className="d-flex align-items-center flex-wrap">
            <span>
              <span>{data.experience}</span>
              <span className="mgr-3 mgl-4">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  className=" flex-shrink-0"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C6.47967 21.9939 2.00606 17.5203 2 12V11.8C2.10993 6.30453 6.63459 1.92796 12.1307 2.00088C17.6268 2.0738 22.0337 6.56888 21.9978 12.0653C21.9619 17.5618 17.4966 21.9989 12 22ZM11.984 20H12C16.4167 19.9956 19.9942 16.4127 19.992 11.996C19.9898 7.57929 16.4087 4 11.992 4C7.57528 4 3.99421 7.57929 3.992 11.996C3.98979 16.4127 7.56729 19.9956 11.984 20ZM13 18H11V16H13V18ZM13 15H11C10.9684 13.6977 11.6461 12.4808 12.77 11.822C13.43 11.316 14 10.88 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10H8V9.91C8.01608 8.48094 8.79333 7.16901 10.039 6.4684C11.2846 5.76779 12.8094 5.78494 14.039 6.5134C15.2685 7.24185 16.0161 8.57094 16 10C15.9284 11.079 15.3497 12.0603 14.44 12.645C13.6177 13.1612 13.0847 14.0328 13 15Z"
                    fill="#323648"
                    fillOpacity="0.3"
                  ></path>
                </svg>
              </span>
            </span>
            <div
              role="button"
              className="color-green-2 cursor-pointer align-items-center mgl-3 d-none d-md-inline-flex"
            >
              <svg
                mr="2"
                width="19"
                height="19"
                viewBox="0 0 25 25"
                fill="none"
                className="mr-2 flex-shrink-0"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 11.4486V12.5066C23.9986 14.9865 23.1956 17.3995 21.7107 19.3857C20.2259 21.3719 18.1388 22.8249 15.7607 23.5281C13.3825 24.2312 10.8408 24.1468 8.51464 23.2873C6.18843 22.4279 4.20235 20.8396 2.8526 18.7592C1.50286 16.6788 0.86176 14.2179 1.02493 11.7433C1.1881 9.26883 2.14679 6.91336 3.75802 5.02821C5.36925 3.14307 7.5467 1.82927 9.96561 1.28275C12.3845 0.736229 14.9153 0.98627 17.1805 1.99558M24 3.3066L12.5 14.8181L9.05 11.3681"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.5"
                ></path>
              </svg>
              Проверено
            </div>
          </div>
          <div
            role="button"
            className="color-green-2 cursor-pointer align-items-center mt-3 main-info-in-profile__checked-by-alter d-inline-flex d-md-none"
          >
            <svg
              mr="2"
              width="19"
              height="19"
              viewBox="0 0 25 25"
              fill="none"
              className="mr-2 flex-shrink-0"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 11.4486V12.5066C23.9986 14.9865 23.1956 17.3995 21.7107 19.3857C20.2259 21.3719 18.1388 22.8249 15.7607 23.5281C13.3825 24.2312 10.8408 24.1468 8.51464 23.2873C6.18843 22.4279 4.20235 20.8396 2.8526 18.7592C1.50286 16.6788 0.86176 14.2179 1.02493 11.7433C1.1881 9.26883 2.14679 6.91336 3.75802 5.02821C5.36925 3.14307 7.5467 1.82927 9.96561 1.28275C12.3845 0.736229 14.9153 0.98627 17.1805 1.99558M24 3.3066L12.5 14.8181L9.05 11.3681"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
              ></path>
            </svg>
            Проверено
          </div>
          <div className="">
            <div className="labels__desktop">
              <div className="is-flex is-align-items-center px-1 session-count-label__wrapper labels__label">
                <img
                  alt=""
                  className="labels__emoji"
                  src="https://alter.ru/images/fire-emoji.png"
                />
                <div>Болee 800 сессий</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

/**
 * Component for showing Section "Therapist Profile" of the site.
 *
 * @component
 */
const TherapistProfile = (props) => {
  const { data, id = "therapist-profile", cn = "pt-80 pb-80" } = props;
  const isMobile = useIsMobile(768);

  return (
    <ProfileSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} />
        <SectionContent data={data.therapist} isMobile={isMobile} />
      </div>
    </ProfileSection>
  );
};

TherapistProfile.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default TherapistProfile;
