import { BLOCK_SPAM_MS } from "config/config";
import { registerSubscription } from "services";
//import useAnalyticsEventTracker from "hooks/useAnalyticsEventTracker";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { FaTelegramPlane } from "react-icons/fa";
import { Link } from "react-router-dom";
import { _debug } from "utils";

// NOTE : https://blog.logrocket.com/using-dangerouslysetinnerhtml-react-application/

const msgSubmitted = "Ваш вопрос успешно отправлен";
const msgSubscribed = "Вы успешно подписаны";
const msgFormEmailPlaceholder = "Ваша почта";
const msgFormEmailRequired = "Требуется ввести адрес электронной почты"; // "Email is required",

const isValidEmail = (email) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

/**
 * Component for showing Form "Subscription" of the site.
 *
 * @component
 */
const FormSubscription = (props) => {
  //const methods = useForm();
  const {
    methods,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  //const gaEventTracker = useAnalyticsEventTracker("User subscribe");

  const handleSubscriptionSubmit = async (data) => {
    //e.preventDefault();
    _debug("Form submit: ", data);

    setError(null);
    try {
      //gaEventTracker("Subscribe");

      const response = await registerSubscription({
        email: data.email,
      });

      if (response && response.error) {
        setError(response.error);
        setSuccess(false);
        return;
      }

      setSuccess(true);
    } catch (error) {
      setError(error.message);
      setSuccess(false);
    }
  };

  const onSubmit = (data) => {
    console.log("onSubmit", data);

    setIsSubmitted(true);

    // User subscribe
    handleSubscriptionSubmit(data);
    reset();
  };

  useEffect(() => {
    setTimeout(() => {
      if (isSubmitted) setIsSubmitted(false);
      _debug(isSubmitted);
    }, BLOCK_SPAM_MS);
  }, [isSubmitted]);

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="subscribe-form wow fadeInRight"
        data-wow-delay=".4s"
        style={{
          visibility: "visible",
          animationDelay: "0.4s",
          animationName: "fadeInRight",
        }}
      >
        <Form.Group>
          <Form.Control
            type="text"
            name="email"
            id="email"
            placeholder={msgFormEmailPlaceholder}
            isInvalid={!!errors.email}
            {...register("email", {
              required: { msgFormEmailRequired },
              validate: {
                maxLength: (v) =>
                  v.length <= 50 ||
                  "The email should have at most 50 characters",
                matchPattern: (v) =>
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                  "Email address must be a valid address",
              },
            })}
            aria-invalid={errors?.email ? "true" : "false"}
          />
          <button type="submit" disabled={isSubmitted} aria-label="Submit">
            <FaTelegramPlane />
          </button>
          <Form.Control.Feedback type="invalid" tooltip>
            {errors.email && (
              <p>
                <small>{errors.email.message}</small>
              </p>
            )}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Text id="submitHelpBlock" className="help-block">
          Нажимая кнопку Вы соглашаетесь с{" "}
          <Link to="/policy">политикой конфиденциальности</Link>
        </Form.Text>
      </Form>

      {error && (
        <p className="text-warning" style={{ textAlign: "center" }}>
          <small>{error}</small>
        </p>
      )}
      {success && (
        <p className="text-success" style={{ textAlign: "center" }}>
          <small>{msgSubmitted}</small>
        </p>
      )}
    </FormProvider>
  );
};

export default FormSubscription;
