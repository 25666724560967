import { useEffect, useState } from "react";
import { isApiSupported, isClient } from "../components/utils/Utils";

const errorMessage =
  "matchMedia is not supported, this could happen both because window.matchMedia is not supported by" +
  " your current browser or you're using the useMediaQuery hook whilst server side rendering.";

// https://devsday.ru/blog/details/58778
// https://github.com/antonioru/beautiful-react-hooks/blob/master/src/useMediaQuery.ts

const useMediaQuery = (width) => {
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(`(max-width: ${width}px)`);
    const documentChangehandle = () => setIsVerified(!!mediaQueryList.matches);

    try {
      mediaQueryList.addEventListener("change", documentChangehandle);
    } catch (e) {
      // Safari isn't supporting mediaQueryList.addEventListener
      mediaQueryList.addListener(documentChangehandle);
    }

    documentChangehandle();

    return () => {
      try {
        mediaQueryList.removeEventListener("change", documentChangehandle);
      } catch (e) {
        // Safari isn't supporting mediaQueryList.removeEventListener
        mediaQueryList.removeListener(documentChangehandle);
      }
    };
  }, [width]);

  if (!isClient || !isApiSupported("matchMedia")) {
    // eslint-disable-next-line no-console
    console.warn(errorMessage);
    return null;
  }

  return isVerified;
};

export default useMediaQuery;
