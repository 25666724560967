import { useMutation } from "@tanstack/react-query";
import RichTextEditor from "components/pages/Customer/components/core/Editor/RichTextEditor";
import { CustomFormInputTextarea } from "components/pages/Customer/components/core/FormInput/FormInput";
import { useState } from "react";
import { Form as BSForm, Button } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import PsychologistService from "services/psychologist.service";
import { _debug } from "utils";

/**
 * Component for showing component "Form Psychologist Bio" of the site.
 *
 * @component
 **/
const FormProfileBio = (props) => {
  const { data } = props;

  console.log("222!", data);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const {
    methods,
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      bio2: data?.bio2 || "3",
      content: data?.content || "4",
    },
  });

  const mutation = useMutation({
    mutationFn: async (newOrder) =>
      PsychologistService.updateBio({ id: data.id, data: newOrder }),
    onSuccess: (data) => {
      console.log("useMutation", data);
    },
  });

  const onSubmit = async (data) => {
    //e.preventDefault();
    _debug("Form submit: ", data);

    const newBio = {
      description: data.description,
      bio: data.bio,
      bio2: data.bio2,
      content: data.content,
    };

    mutation.mutate(newBio);

    // console.log("!!!", data, mode, props);

    // setError(null);
    // try {
    //   //gaEventTracker("submitFromCountDown");

    //   const response = await props.onSubmit({
    //     username: data.username,
    //     email: data.email,
    //     phone: data?.phone,
    //     password: data.password,
    //   });

    //   // console.log("!!!-1", response);
    //   if (response && response.error) {
    //     setError(response.error);
    //     setSuccess(false);
    //     return;
    //   }

    //   setSuccess(true);
    // } catch (error) {
    //   console.error("API request error:", error.message);
    //   setError(error.message);
    //   setSuccess(false);
    // } finally {
    //   reset();
    // }
  };

  const onError = (error) => {
    console.log("ERROR:::", error);
  };

  return (
    <FormProvider {...methods}>
      <BSForm className="row g-3" onSubmit={handleSubmit(onSubmit, onError)}>
        {/*<!-- Description -->*/}
        {/*<div className="col-12">
          <label className="form-label">Description</label>
          <textarea className="form-control" rows="3" spellCheck={false}>
            Description
          </textarea>
        </div>*/}
        <BSForm.Group className="mb-3" controlId="formDescription">
          <CustomFormInputTextarea
            label="Description"
            placeholder="Enter description"
            rows="3"
            spellCheck={false}
            defaultValue={data?.description}
            error={errors?.description}
            {...register("description", {
              maxLength: 1024,
              pattern: /[A-Za-z]{3}/,
            })}
          />
        </BSForm.Group>

        {/*<!-- Bio -->*/}
        {/*<div className="col-12">
          <label className="form-label">Bio</label>
          <textarea className="form-control" rows="3" spellCheck={false}>
            Bio
          </textarea>
        </div>*/}
        <BSForm.Group className="mb-3" controlId="formBio">
          <CustomFormInputTextarea
            label="Bio"
            placeholder="Enter your bio"
            rows="3"
            spellCheck={false}
            defaultValue={data?.bio}
            error={errors?.bio}
            {...register("bio", {
              maxLength: 1024,
              pattern: /[A-Za-z]{3}/,
            })}
          />
        </BSForm.Group>

        {/*<!-- Bio2 -->*/}
        <div className="col-12">
          <label className="form-label">Bio2</label>
          {/*<textarea className="form-control" rows="3" spellCheck={false}>
            Bio2
          </textarea>*/}
        </div>

        {/*<!-- Content -->*/}
        {/*<div className="col-12">
          <label className="form-label">Content</label>
          <textarea className="form-control" rows="3" spellCheck={false}>
            Content
          </textarea>
        </div>*/}

        <BSForm.Group className="mb-3" controlId="formContent">
          <label className="form-label">Content</label>
          <RichTextEditor name="draft" control={control} />
        </BSForm.Group>

        {/*<!-- Button -->*/}
        <div className="col-12 text-end">
          <Button
            className="btn btn-primary mb-0"
            variant="primary"
            type="submit"
          >
            Save Changes
          </Button>
        </div>
      </BSForm>
    </FormProvider>
  );
};

FormProfileBio.propTypes = {};

export default FormProfileBio;
