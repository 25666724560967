import { createColumnHelper } from "@tanstack/react-table";
import { CustomTable } from "components/pages/Customer/components/core/CustomTable";
import { Fragment } from "react";

const columnHelper = createColumnHelper();

/*

  private UUID id;
  private String name;
  private String title;
  private LocalDate startDate;
  private LocalDate endDate;
*/

/**
 * Component for showing Orders table.
 *
 * @component
 */
const EducationTable = (props) => {
  const {
    data: education,
    tableClass,
    headerClass,
    onDelete,
    ...otherProps
  } = props;

  // console.log(props);

  const handleDelete = (cell, row) => {
    console.log("handleDelete!!!!", cell, row);
    onDelete(cell.row.original.id);
  };

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
    }),
    columnHelper.accessor("id", {
      header: "Id",
    }),
    columnHelper.accessor("title", {
      header: "title",
    }),
    columnHelper.accessor("startDate", {
      header: "startDate",
    }),
    columnHelper.accessor("endDate", {
      header: "endDate",
    }),

    columnHelper.accessor("action", {
      header: () => <span>Action</span>,
      accessor: "name",
      cell: ({ cell, row }) => (
        <button onClick={() => handleDelete(cell, row)}>Delete</button>
      ),
    }),
  ];

  return (
    <Fragment>
      {education && (
        <CustomTable
          data={education}
          columns={columns}
          tableClass={tableClass}
          headerClass={headerClass}
          {...otherProps}
        />
      )}
    </Fragment>
  );
};

export default EducationTable;
