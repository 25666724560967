import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useState } from "react";
import AuthService from "services/auth.service";
import { useAuth } from "./AuthProvider";

const initialState = {
  items: [],
};

export const profileContext = createContext({});

const ProfileProvider = ({ children }) => {
  const { auth, isAuthenticated } = useAuth();
  const [isInitialized, setIsInitialized] = useState(false);

  const [profile, setProfile] = useState(initialState);

  const { data, isLoading, isError, error } = useQuery({
    queryFn: () => {
      console.log(auth);
      if (auth.isCustomer) {
        return AuthService.customerGetMe();
      } else if (auth.isPsychologist) {
        return AuthService.psychologistGetMe();
      } else {
        console.error("APP Error!");
      }
    },
    queryKey: ["userProfile"],
    enabled: isAuthenticated && (auth.isCustomer || auth.isPsychologist),
    onError: (error) => {
      console.error("Error user profile initialization:", error);
      setIsInitialized(false);
    },
  });

  useEffect(() => {
    console.log(isLoading);
    if (data) {
      console.log(data);
      setProfile(data);
      setIsInitialized(true);
    }
  }, [data, isLoading]);

  return (
    <profileContext.Provider
      value={{
        profile,
      }}
    >
      {children}
    </profileContext.Provider>
  );
};

const useProfile = () => {
  return useContext(profileContext);
};

export { ProfileProvider, useProfile };
