import {
  CustomFormInputText2,
  CustomFormSelect,
} from "components/pages/Customer/components/core/FormInput/FormInput";
import { useGetPayProducts } from "components/pages/Psychologist/utils/useGetPayProducts";
import { Spinner } from "components/utils/Spinner";
import { dataMessages } from "data";
import { useEffect } from "react";
import { Form as BSForm, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { _debug } from "utils";

/**
 * Component for showing Orders table.
 *
 * @component
 */
const ProductModalForm = (props) => {
  const {
    showModal,
    handleClose,
    modalFormData = {},
    setModalFormData,
  } = props;

  // console.log(props);

  const {
    // methods,
    register,
    control,
    handleSubmit,
    formState: { formErrors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      product: modalFormData?.product,
      price: modalFormData?.price,
      currency: modalFormData?.currency,
    },
  });

  const {
    data: products,
    isLoading: isLoadingPayProducts,
    isError: isErrorPayProducts,
    error: errorPayProducts,
  } = useGetPayProducts();

  const isLoading = isLoadingPayProducts;
  const isError = isErrorPayProducts;
  const errors = [errorPayProducts];

  // useEffect(() => {
  //   reset({
  //     mail,
  //   });
  // }, [reset, mail]);

  const onSubmit = (data) => {
    const methodId = products.filter((item) => item.name === data.product);

    console.log("SUBMIT:", data, methodId[0].id, products);
    // setModalFormData(data);
    handleClose({ id: methodId[0].id, price: data.price });
  };
  const onError = (error) => {
    console.log("ERROR:::", error);
  };

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (products && products.length) {
      _debug("PAY PRODUCTS:", products);
    }
  }, [products]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Modal heading</Modal.Title>
      </Modal.Header>
      <form className="" onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Body>
          <BSForm.Group className="mb-3" controlId="formProduct">
            <CustomFormSelect
              label="Product"
              required
              error={errors.product}
              options={products}
              control={control}
              name="product"
            />
          </BSForm.Group>

          <BSForm.Group className="mb-3" controlId="formPrice">
            <CustomFormInputText2
              label="Price"
              required
              error={errors.price}
              {...register("price", { required: "Correo es obligatorio" })}
            />
          </BSForm.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ProductModalForm;
