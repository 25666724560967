import { dataSite } from "data/dataSite";

export const dataMetaTherapistsPage = {
  // <meta name="title" content="Записаться к психологу на консультацию | Запись на прием психолога в сервисе PsyWealth">
  title: "Все наши психологи и психотерапевты",

  // <meta content="Услуги психологов в онлайн-сервисе PsyWealth ☀️ Быстрая запись на прием! Помогаем выбрать личного психолога или психотерапевта из 3300 уже проверенных специалистов." name="description">

  description: `Подбираем психотерапевтов для совместной работы. Телефон: ${dataSite.phone}, почта: ${dataSite.email}.`,
  name: "",
  type: "",
  author: "PsyWealth LLC, Inc.",
  ExtraMetaTags: [],
  OG: [
    {
      property: "og:title",
      content: "Все наши психологи",
    },
    {
      property: "og:description",
      content: `Ищем психотерапевтов для совместной работы. Телефон: ${dataSite.phone}, почта: ${dataSite.email}.`,
    },
    {
      property: "og:url",
      content: "https://psywealth.com/therapists/",
    },
    {
      property: "og:type",
      content: "article",
    },
    {
      property: "og:image",
      content:
        "https://static.tildacdn.com/tild6464-3961-4635-b834-633364363338/Group_4.svg",
    },
    {
      property: "og:image:alt",
      content: "Все наши психологи",
    },
    {
      property: "og:locale",
      content: "ru_RU",
    },
    {
      property: "og:site_name",
      content: "https://psywealth.com",
    },
  ],
  TW: [
    { property: "twitter:creator", content: "" },
    { property: "twitter:card", content: "summary_large_image" },
    { property: "twitter:title", content: "" },
    { property: "twitter:description", content: "" },
    { property: "twitter:label1", content: "Примерное время для чтения" },
    { property: "twitter:data1", content: "10 минуты" },
  ],
};
