export const dataAbout = {
  title: "Электронный архив документов",
  subtitle: "Цифровая трансформация бизнеса",
  description:
    "Организуйте архивное хранение электронных документов с помощью нашего облачного сервиса. " +
    "Наш сервис поможет организовать архивное хранение документов с учетом их типа и необходимых параметров для хранения.",
  list: {
    items: [
      {
        description:
          "Все электронные документы в едином информационном пространстве",
      },
      {
        description:
          "Найти и скачать документы или создать реестр, выгрузку для проверяющих организаций",
      },
      {
        description: "Документы доступны в любом месте и в любое время",
      },
      {
        description:
          "Данные надежно защищены шифрованием и сертификатами безопасности",
      },
    ],
  },
  items: [
    {
      title: "8Тб данных",
      description: "Уже организовали архивное хранение",
      image: require("assets/images/about-img.webp"),
      imageAlt: "",
    },
    {
      title: "Happy Client",
      subtitle: "30k",
      description: "We Crafted an awesome design library that is robust and",
      image: null,
      imageAlt: "",
    },
    {
      title: "Project Done",
      subtitle: "42k",
      description: "We Crafted an awesome design library that is robust and",
      image: null,
      imageAlt: "",
    },
  ],
};

export const dataAboutAboutPage = {
  title: "Простые Изобретения - Технологический Стартап",
  subtitle: "О Компании",
  description:
    "Мы предлагаем готовые высокотехнологичные решения, направленные на цифровизацию бизнеса",
  list: {
    items: [],
  },
  items: [
    {
      title: "Наша Миссия",
      description:
        "развитие новой цифровой экономики через создание эффективных цифровых продуктов и решений",
      image: require("assets/images/about-img.webp"),
      imageAlt: "",
    },
    {
      title: "Happy Client",
      subtitle: "30k",
      description: "We Crafted an awesome design library that is robust and",
      image: null,
      imageAlt: "",
    },
    {
      title: "Project Done",
      subtitle: "42k",
      description: "We Crafted an awesome design library that is robust and",
      image: null,
      imageAlt: "",
    },
  ],
};
