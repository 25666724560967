import { SEO } from "components/utils/Analytics";
import { dataHomePage, ldJson } from "data";
import React, { Fragment, useRef } from "react";
import MultiStepForm from "./Form/MultiStepForm";

const BlogTopPosts = React.lazy(() => import("../Blog/BlogTopPosts"));
const SectionFAQ = React.lazy(() => import("./FAQ"));
//const Pricing = React.lazy(() => import("../../sections/Pricing"));
//const Process = React.lazy(() => import("../../sections/Process"));
//const Service = React.lazy(() => import("../../sections/Service"));
//const FeatureTabs = React.lazy(() => import("../../sections/FeatureTabs"));

// NOTE : https://demo.graygrids.com/themes/space/index.html
// NOTE : https://demo.graygrids.com/themes/digimark/services.html
// NOTE : https://www.sleepinggiantmedia.co.uk/posts/canonical-tags-implementation-guide/

// NOTE : https://elar-context.ru

// NOTE : https://scrapfly.io/blog/search-engine-using-web-scraping/

/**
 * Component for showing Page "Home" of the site.
 *
 * @component
 */
const Profile = () => {
  const refCAT = useRef(null);

  const executeScroll = (e) => {
    refCAT.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  return (
    <Fragment>
      <SEO data={dataHomePage.meta}>
        {/*Only canonicalise pages which are a duplicate or are near-identical.*/}
        <link rel="canonical" href="https://psywealth.com/" />
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </SEO>
      <main className="profile-main" id="top">
        <MultiStepForm />
      </main>
    </Fragment>
  );
};

export default Profile;
