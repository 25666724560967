import { APP_API_URI } from "config/config";
import { createContext, useContext, useEffect, useState } from "react";
import AuthService from "services/auth.service";
import { _debug } from "utils";

const initialAuth = {
  loading: false,
  isAuthenticated: false,
  isCustomer: null,
  isPsychologist: null,
  roles: null,
  permissions: [],
  error: false,
  message: "",
  status: 0,
  data: {},
};

const STORE_AUTH_KEY = "authData";

export const authContext = createContext({});

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(
    !localStorage.getItem(STORE_AUTH_KEY)
      ? initialAuth
      : localStorage.getItem(STORE_AUTH_KEY) === undefined
      ? initialAuth
      : JSON.parse(localStorage.getItem(STORE_AUTH_KEY))
  );
  const [isAuthenticated, setIsAuthenticated] = useState(
    !localStorage.getItem(STORE_AUTH_KEY)
      ? false
      : JSON.parse(localStorage.getItem(STORE_AUTH_KEY)).isAuthenticated
  );

  const setAuthData = (data) => {
    setAuth({ data: data });
  };

  useEffect(() => {
    var localData = window.localStorage.getItem(STORE_AUTH_KEY);

    _debug("Read local cache:", JSON.parse(localData));

    setAuth({ ...JSON.parse(localData), loading: false });
  }, []);

  //2. if object with key 'authData' exists in localStorage, we are putting its value in auth.data and we set loading to false.
  //This function will be executed every time component is mounted (every time the user refresh the page);
  useEffect(() => {
    console.log("Change Auth context:", auth, isAuthenticated);
    window.localStorage.setItem(
      STORE_AUTH_KEY,
      JSON.stringify(auth == null ? {} : auth)
    );
    //var localData = window.localStorage.getItem("authData");
    //console.log(localData);
  }, [auth, isAuthenticated]);

  // 1. when **auth.data** changes we are setting **auth.data** in localStorage with the key 'authData'.
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // login the user
  const customerLogin = async (credentials) => {
    _debug("Let's customer login...", credentials);

    const requestBody = {
      ...credentials,
      deviceInfo: {
        deviceId: "12-3",
        deviceType: "DEVICE_TYPE_IOS",
        notificationToken: "",
      },
    };

    // const requestOptions = {
    //   method: "POST",
    //   headers: new Headers(requestHeaders),
    //   // no-cors, *cors, same-origin
    //   mode: "cors",
    //   // *default, no-cache, reload, force-cache, only-if-cached
    //   cache: isDevelopment ? "no-cache" : "default",
    //   // include, *same-origin, omit
    //   credentials: "include",
    //   body: JSON.stringify(requestBody),
    // };

    // console.log(requestOptions, `${APP_API_URI}/customer/auth/login`);

    try {
      const fetchData = await AuthService.customerLogin(requestBody);
      _debug("Data fetched:", fetchData);

      setAuth({
        ...auth,
        isAuthenticated: true,
        isCustomer: true,
        isPsychologist: false,
        roles: fetchData?.roles,
        permissions: ["CUSTOMER"],
        error: false,
        message: "",
        data: {
          accessToken: fetchData?.accessToken,
          refreshToken: fetchData?.refreshToken,
          getstreamToken: fetchData?.getstreamToken,
          email: fetchData?.email,
          username: fetchData?.username,
        },
      });
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Customer login error: ", error);
      setAuth({
        ...auth,
        isAuthenticated: false,
        isCustomer: null,
        isPsychologist: null,
        roles: null,
        permissions: null,
        error: true,
        message: error.message,
      });

      let errorEx = new Error("Auth provider error");
      errorEx = { ...errorEx, ...error };
      throw errorEx;
    }
  };

  // register the user
  const customerRegister = async (credentials) => {
    _debug("Let's customer register...", credentials);

    try {
      const fetchData = await AuthService.customerRegister(credentials);
      _debug("Data fetched:", fetchData);

      setAuth({ ...auth, error: false, message: "" });
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Customer register error: ", error);
      setAuth({
        ...auth,
        isAuthenticated: false,
        isCustomer: null,
        isPsychologist: null,
        roles: null,
        permissions: null,
        error: true,
        message: error.message,
      });

      let errorEx = new Error("Auth provider error");
      errorEx = { ...errorEx, ...error };
      throw errorEx;
    }
  };

  // restore the user password
  const forgot = async (credentials) => {
    _debug("Let's restore password...", credentials);
    try {
      const response = await fetch(`${APP_API_URI}/auth/register`, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify({ ...credentials }),
      });

      let result = await response.json();
      console.log(result);

      //console.log(auth.data);
      //console.log(authresult);
      //let res = { ...authresult.data };
      //userObj.token = authresult.data?.accessToken;
      //setUser(userObj);
      //toastsuccess("Login Successfull");
      //navigate(location.state.from || "/");

      setAuth({ ...auth, error: false, message: "" });
    } catch (error) {
      console.error("Register error: ", error);
      setAuth({
        ...auth,
        isAuthenticated: false,
        isCustomer: null,
        isPsychologist: null,
        roles: null,
        permissions: null,
        error: true,
        message: error.message,
      });

      let errorEx = new Error("Auth provider error");
      errorEx = { ...errorEx, ...error };
      throw errorEx;
    }
  };

  // make a login request
  const psychologistLogin = async (credentials) => {
    _debug("Let's psychologist login...", credentials);

    const requestBody = {
      ...credentials,
      deviceInfo: {
        deviceId: "12-3",
        deviceType: "DEVICE_TYPE_IOS",
        notificationToken: "",
      },
    };

    try {
      const fetchData = await AuthService.psychologistLogin(requestBody);
      _debug("Data fetched:", fetchData);

      setAuth({
        ...auth,
        isAuthenticated: true,
        isCustomer: false,
        isPsychologist: true,
        roles: fetchData?.roles,
        permissions: ["PSYCHOLOGIST"],
        error: false,
        message: "",
        data: {
          accessToken: fetchData?.accessToken,
          refreshToken: fetchData?.refreshToken,
          getstreamToken: fetchData?.getstreamToken,
          email: fetchData?.email,
          username: fetchData?.username,
        },
      });
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Psychologist login error: ", error);
      setAuth({
        ...auth,
        isAuthenticated: false,
        isCustomer: null,
        isPsychologist: null,
        roles: null,
        permissions: null,
        error: true,
        message: error.message,
      });

      let errorEx = new Error("Auth provider error");
      errorEx = { ...errorEx, ...error };
      throw errorEx;
    }
  };

  // register the user
  const psychologistRegister = async (credentials) => {
    _debug("Let's psychologist register...", credentials);

    try {
      const fetchData = await AuthService.psychologistRegister(credentials);
      _debug("Data fetched:", fetchData);

      setAuth({ ...auth, error: false, message: "" });
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Psychologist register error: ", error);
      setAuth({
        ...auth,
        isAuthenticated: false,
        isCustomer: null,
        isPsychologist: null,
        roles: null,
        permissions: null,
        error: true,
        message: error.message,
      });

      let errorEx = new Error("Auth provider error");
      errorEx = { ...errorEx, ...error };
      throw errorEx;
    }
  };

  const logout = async () => {
    _debug("Let's logout...");
    // clear the token in localStorage and the user data

    localStorage.removeItem(STORE_AUTH_KEY);
    setAuth(initialAuth);
    setIsAuthenticated(false);

    const fetchData = await AuthService.logout();
    _debug("Data fetched:", fetchData);

    // return new Promise((resolve) => {
    //   // Using setTimeout to simulate network latency.
    //   setAuth(initialAuth);
    //   localStorage.removeItem(STORE_AUTH_KEY);
    //   resolve();

    //   // setTimeout(() => {
    //   //   localStorage.removeItem(keyUser);
    //   //   resolve();
    //   // }, 1000);
    // });
  };

  // Memoized value of the authentication context
  /*const contextValue = useMemo(
      () => ({
        token,
        setToken,
      }),
      [token]
    );*/

  return (
    <authContext.Provider
      value={{
        auth,
        setAuthData,
        psychologistLogin,
        psychologistRegister,
        isAuthenticated,
        customerLogin,
        customerRegister,
        forgot,
        logout,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

const useAuth = () => {
  return useContext(authContext);
};

export { AuthProvider, useAuth };
