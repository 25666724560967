import { useQuery } from "@tanstack/react-query";
import CourseService from "services/course.service";

export const useGetFAQByCourse = (CourseId) => {
  //const { id, slug } = props;
  console.log("!!!", CourseId);

  return useQuery({
    queryFn: () => CourseService.getFAQByCourse(CourseId),
    queryKey: ["faqByCourse", CourseId],
  });
};
