import { Spinner } from "components/utils/Spinner";
import { dataMessages } from "data";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { _debug } from "utils";

export const withAsync = (WrappedComponent) => (props) => {
  const {
    data,
    isLoading,
    isError,
    errors,
    msgLoading = "",
    ...otherProps
  } = props;

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (data) {
      _debug(msgLoading ? `${msgLoading}:` : "DATA:", data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  return (
    <WrappedComponent
      // Pass through whatever other props we give `WrappedComponent`.
      data={data}
      isLoading={isLoading}
      isError={isError}
      errors={errors}
      {...otherProps}
    />
  );
};
