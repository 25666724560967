import { CallToAction, Feature, Process, Subscribe } from "components/sections";
import { SEO } from "components/utils/Analytics";
import {
  dataCTALetsContact as dataCTA,
  dataFeaturePartnersPage as dataFeature,
  dataFeature2PartnersPage as dataFeature2,
  dataProcessPartnersPage as dataProcess,
  dataSubscribe,
} from "data";
import { dataPartnersPage } from "data/dataPages";
import { Fragment, useRef } from "react";

// NOTE : https://nopaper.ru/partners/program?ysclid=lu6cb9p3da701541748
// NOTE : https://www.diadoc.ru/partnership?ysclid=lu6cb6wrtz980269156
// NOTE : https://www.distate.ru/partnership/?ysclid=lu6cb8luhl328935006

/**
 * Component for showing Page "Partners" of the site.
 *
 * @component
 */
const PartnersPage = () => {
  const refCAT = useRef(null);

  const executeScroll = (e) => {
    refCAT.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  return (
    <Fragment>
      <SEO data={dataPartnersPage.meta}>
        <link rel="canonical" href="https://psywealth.com/partners/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="partners-page" id="main">
        {/* <Hero data={dataHero} />*/}
        <Feature data={dataFeature} type="style1" />
        <Process data={dataProcess} />
        <Feature data={dataFeature2} type="style2" />
        <CallToAction data={dataCTA} innerRef={refCAT} />
        {/* <Pricing data={dataPricing} />*/}
        <Subscribe data={dataSubscribe} />
      </main>
    </Fragment>
  );
};

export default PartnersPage;
