import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PropTypes } from "prop-types";
import { Fragment, useRef, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { useOutletContext } from "react-router-dom";
import PsychologistService from "services/psychologist.service";
import { _debug } from "utils";
import EducationDocuments from "./EducationDocuments";
import EducationModalForm from "./EducationModalForm";
import EducationTable from "./EducationTable";
import MethodModalForm from "./MethodModalForm";
import MethodsTable from "./MethodsTable";
import SpecializationModalForm from "./SpecializationModalForm";
import SpecializationsTable from "./SpecializationsTable";

function formatDate(inputDate, format) {
  if (!inputDate) return "";

  const padZero = (value) => (value < 10 ? `0${value}` : `${value}`);
  const parts = {
    yyyy: inputDate.getFullYear(),
    MM: padZero(inputDate.getMonth() + 1),
    dd: padZero(inputDate.getDate()),
    HH: padZero(inputDate.getHours()),
    hh: padZero(
      inputDate.getHours() > 12
        ? inputDate.getHours() - 12
        : inputDate.getHours()
    ),
    mm: padZero(inputDate.getMinutes()),
    ss: padZero(inputDate.getSeconds()),
    tt: inputDate.getHours() < 12 ? "AM" : "PM",
  };

  return format.replace(/yyyy|MM|dd|HH|hh|mm|ss|tt/g, (match) => parts[match]);
}

const demoCard = () => {
  return (
    <Fragment>
      {/*<!-- Education (documents)  END -->*/}
      <div className="card-body">
        {/*<!-- Table START -->*/}
        <div className="table-responsive border-0">
          <table className="table align-middle p-4 mb-0 table-hover">
            {/*<!-- Table head -->*/}
            <thead className="table-dark">
              <tr>
                <th scope="col" className="border-0 rounded-start">
                  Browser
                </th>
                <th scope="col" className="border-0">
                  IP
                </th>
                <th scope="col" className="border-0">
                  Time
                </th>
                <th scope="col" className="border-0 rounded-end">
                  Action
                </th>
              </tr>
            </thead>

            {/*<!-- Table body START -->*/}
            <tbody>
              {/*<!-- Table row -->*/}
              <tr>
                {/*<!-- Table data -->*/}
                <td>Chrome On Window</td>

                {/*<!-- Table data -->*/}
                <td>173.238.198.108</td>

                {/*<!-- Table data -->*/}
                <td>12 Nov 2021</td>

                {/*<!-- Table data -->*/}
                <td>
                  <button className="btn btn-sm btn-danger-soft me-1 mb-1 mb-md-0">
                    Sign out
                  </button>
                </td>
              </tr>

              {/*<!-- Table row -->*/}
              <tr>
                {/*<!-- Table data -->*/}
                <td>Mozilla On Window</td>

                {/*<!-- Table data -->*/}
                <td>107.222.146.90</td>

                {/*<!-- Table data -->*/}
                <td>08 Nov 2021</td>

                {/*<!-- Table data -->*/}
                <td>
                  <button className="btn btn-sm btn-danger-soft me-1 mb-1 mb-md-0">
                    Sign out
                  </button>
                </td>
              </tr>

              {/*<!-- Table row -->*/}
              <tr>
                {/*<!-- Table data -->*/}
                <td>Chrome On iMac</td>

                {/*<!-- Table data -->*/}
                <td>231.213.125.55</td>

                {/*<!-- Table data -->*/}
                <td>06 Nov 2021</td>

                {/*<!-- Table data -->*/}
                <td>
                  <button className="btn btn-sm btn-danger-soft me-1 mb-1 mb-md-0">
                    Sign out
                  </button>
                </td>
              </tr>

              {/*<!-- Table row -->*/}
              <tr>
                {/*<!-- Table data -->*/}
                <td>Mozilla On Window</td>

                {/*<!-- Table data -->*/}
                <td>37.242.105.138</td>

                {/*<!-- Table data -->*/}
                <td>02 Nov 2021</td>

                {/*<!-- Table data -->*/}
                <td>
                  <button className="btn btn-sm btn-danger-soft me-1 mb-1 mb-md-0">
                    Sign out
                  </button>
                </td>
              </tr>
            </tbody>
            {/*<!-- Table body END -->*/}
          </table>
        </div>
        {/*<!-- Table END -->*/}
      </div>
    </Fragment>
  );
};

/**
 * Component for showing Section "Customer Profile (Tab) - Customer Education" of the site.
 *
 * @component
 **/
const ProfileTabEducation = () => {
  const { profile } = useOutletContext();

  const queryClient = useQueryClient();

  const [modalFormData, setModalFormData] = useState({
    fullName: "1",
  });

  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const inputRef = useRef(null);

  const mutationAddEducation = useMutation({
    mutationFn: async (newEducation) =>
      PsychologistService.addEducation({ id: profile.id, data: newEducation }),
    onSuccess: (data) => {
      console.log("Education added successfully:", data);
      queryClient.refetchQueries("profile");
    },
    onError: (error) => {
      console.error("Error add Education:", error);
    },
  });

  const mutationDeleteEducation = useMutation({
    mutationFn: async (educationId) =>
      PsychologistService.deleteEducation({ id: educationId }),
    onSuccess: (data) => {
      console.log("Education deleted successfully:", data);
      queryClient.refetchQueries("profile");
    },
    onError: (error) => {
      console.error("Error delete Education:", error);
    },
  });

  const mutationAddMethod = useMutation({
    mutationFn: async (newMethod) =>
      PsychologistService.addMethod({ data: newMethod }),
    onSuccess: (data) => {
      console.log("Method added successfully:", data);
      queryClient.refetchQueries("profile");
    },
    onError: (error) => {
      console.error("Error add Method:", error);
    },
  });

  const mutationDeleteMethod = useMutation({
    mutationFn: async (methodId) =>
      PsychologistService.deleteMethod({ id: methodId }),
    onSuccess: (data) => {
      console.log("Method deleted successfully:", data);
      queryClient.refetchQueries("profile");
    },
    onError: (error) => {
      console.error("Error delete Method:", error);
    },
  });

  const mutationAddSpecialization = useMutation({
    mutationFn: async (newSpecialization) =>
      PsychologistService.addSpecialization({ data: newSpecialization }),
    onSuccess: (data) => {
      console.log("Specializations added successfully:", data);
      queryClient.refetchQueries("profile");
    },
    onError: (error) => {
      console.error("Error add Specializations:", error);
    },
  });

  const mutationDeleteSpecialization = useMutation({
    mutationFn: async (specializationId) =>
      PsychologistService.deleteSpecialization({ id: specializationId }),
    onSuccess: (data) => {
      console.log("Specialization deleted successfully:", data);
      queryClient.refetchQueries("profile");
    },
    onError: (error) => {
      console.error("Error delete Specialization:", error);
    },
  });

  const handleDeleteEducation = (id) => {
    console.log(id);
    mutationDeleteEducation.mutate(id);
  };

  const handleClose = (data) => {
    console.log(data);
    setShowModal(false);

    _debug("Form submit: ", data);

    const newEducation = {
      name: data.name,
      title: data.title,
      startDate: formatDate(data.startDate, "yyyy-MM-dd"),
      endDate: formatDate(data.endDate, "yyyy-MM-dd"),
      description: data.description,
    };

    mutationAddEducation.mutate(newEducation);
  };

  const handleClose1 = (data) => {
    console.log(data);
    setShowModal1(false);

    _debug("Form submit1: ", data);

    const newMethod = {
      id: data.id,
    };

    mutationAddMethod.mutate(newMethod);
  };

  const handleClose2 = (data) => {
    console.log(data);
    setShowModal2(false);

    _debug("Form submit2: ", data);

    const newSpecializations = {
      id: data.id,
    };

    mutationAddSpecialization.mutate(newSpecializations);
  };

  // const handleShow = () => setShowModal(true);

  const handleAddEducationModal = () => {
    setShowModal(true);
  };

  const handleAddMethodModal = () => {
    console.log("object");
    setShowModal1(true);
  };

  const handleAddSpecializationsModal = () => {
    console.log("object");
    setShowModal2(true);
  };

  const handleAddDocuments = () => {
    console.log("!!!Add documents");
    inputRef?.current.click();
  };

  const handleNewDocument = () => {
    console.log("!!!New documents", inputRef);
    inputRef?.current.click();
  };

  const handleDeleteMethod = (id) => {
    console.log("handleDeleteMethod", id);
    // mutationDeleteEducation.mutate(id);
  };

  // useEffect(() => {
  //   if (modalFormData) {
  //     console.log("****", modalFormData);
  //   }
  // }, [modalFormData]);

  return (
    <Fragment>
      {/*<!-- Working with a modal pop up -->*/}
      {showModal && (
        <EducationModalForm
          showModal={showModal}
          handleClose={handleClose}
          modalFormData={modalFormData}
        />
      )}
      {showModal1 && (
        <MethodModalForm
          showModal={showModal1}
          handleClose={handleClose1}
          modalFormData={modalFormData}
        />
      )}
      {showModal2 && (
        <SpecializationModalForm
          showModal={showModal2}
          handleClose={handleClose2}
          modalFormData={modalFormData}
        />
      )}
      <div className="vstack gap-4">
        {/*<!-- Education START -->*/}
        <div className="card border">
          {/*<!-- Card header -->*/}
          <div className="card-header border-bottom">
            <h4 className="card-header-title">Образование</h4>
          </div>

          {/*<!-- Card body Education -->*/}
          <div className="card-body vstack gap-4">
            {/*<!-- Traveler item START -->*/}
            <div className="card">
              <div className="table-responsive border-0 mb-3">
                <EducationTable
                  data={profile.educations}
                  tableClass="align-middle p-4 mb-0 table-hover"
                  headerClass="table-dark"
                  onDelete={handleDeleteEducation}
                />
              </div>
              {/*<!-- Traveler item END -->*/}

              {/*<!-- Button -->*/}
              <div className="text-end">
                <button
                  className="btn btn-primary mb-0"
                  type="button"
                  onClick={handleAddEducationModal}
                >
                  <i className="me-2">
                    <BsPlusCircle />
                  </i>
                  New Education
                </button>
              </div>
            </div>
            {/* <!-- Card body END -->*/}
          </div>
        </div>
        {/*<!-- Education END -->*/}

        {/*<!-- Method START -->*/}
        <div className="card border">
          {/*<!-- Card header -->*/}
          <div className="card-header border-bottom">
            <h4 className="card-header-title">Методы</h4>
          </div>

          {/*<!-- Card body Method -->*/}
          <div className="card-body vstack gap-4">
            {/*<!-- Traveler item START -->*/}
            <div className="card">
              <div className="table-responsive border-0 mb-3">
                <MethodsTable
                  data={profile.methods}
                  tableClass="align-middle p-4 mb-0 table-hover"
                  headerClass="table-dark"
                  onDelete={handleDeleteMethod}
                />
              </div>
              {/*<!-- Traveler item END -->*/}

              {/*<!-- Button -->*/}
              <div className="text-end">
                <button
                  className="btn btn-primary mb-0"
                  type="button"
                  onClick={handleAddMethodModal}
                >
                  <i className="me-2">
                    <BsPlusCircle />
                  </i>
                  New Method
                </button>
              </div>
            </div>
            {/* <!-- Card body END -->*/}
          </div>
        </div>
        {/*<!-- Method END -->*/}

        {/*<!-- Specializations START -->*/}
        <div className="card border">
          {/*<!-- Card header -->*/}
          <div className="card-header border-bottom">
            <h4 className="card-header-title">Специализация</h4>
          </div>

          {/*<!-- Card body specializations -->*/}
          <div className="card-body vstack gap-4">
            {/*<!-- Traveler item START -->*/}
            <div className="card">
              <div className="table-responsive border-0 mb-3">
                <SpecializationsTable
                  data={profile.specializations}
                  tableClass="align-middle p-4 mb-0 table-hover"
                  headerClass="table-dark"
                  onDelete={handleDeleteMethod}
                />
              </div>
              {/*<!-- Traveler item END -->*/}

              {/*<!-- Button -->*/}
              <div className="text-end">
                <button
                  className="btn btn-primary mb-0"
                  type="button"
                  onClick={handleAddSpecializationsModal}
                >
                  <i className="me-2">
                    <BsPlusCircle />
                  </i>
                  New Specializations
                </button>
              </div>
            </div>
            {/* <!-- Card body END -->*/}
          </div>
        </div>
        {/*<!-- Specializations END -->*/}

        {/*<!-- Education (documents) START -->*/}
        <div className="card border">
          {/*<!-- Card header -->*/}
          <div className="card-header border-bottom">
            <h4 className="card-header-title">Документы</h4>
          </div>
          {/*<!-- Card body EDUCATION -->*/}
          <div className="card-body vstack gap-4">
            {/*<!-- Traveler item START -->*/}
            <div className="card">
              <EducationDocuments
                inputRef={inputRef}
                onSubmit={handleNewDocument}
              />
              {/*<!-- Button -->*/}
              <div className="text-end">
                <button
                  className="btn btn-primary mb-0"
                  type="button"
                  onClick={handleAddDocuments}
                >
                  <i className="me-2">
                    <BsPlusCircle />
                  </i>
                  New Document
                </button>
              </div>
              {/*<!-- Button END -->*/}
            </div>
            {/*<!-- Traveler item END -->*/}
          </div>
          {/* <!-- Card body END -->*/}
        </div>
      </div>
    </Fragment>
  );
};

ProfileTabEducation.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default ProfileTabEducation;

/*


<Fragment>

      <div className="d-grid mb-0 d-lg-none w-100">
        <button
          className="btn btn-primary mb-4"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasSidebar"
          aria-controls="offcanvasSidebar"
        >
          <i className="fas fa-sliders-h"></i> Menu
        </button>
      </div>

      <div className="vstack gap-4">
       
        <div className="bg-light rounded p-3">
         
          <div className="overflow-hidden">
            <h6>Complete Your Profile</h6>
            <div className="progress progress-sm bg-success bg-opacity-10">
              <div
                className="progress-bar bg-success aos aos-init aos-animate"
                role="progressbar"
                data-aos="slide-right"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                style={{ width: "85%" }}
                aria-valuenow="85"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <span className="progress-percent-simple h6 fw-light ms-auto">
                  85%
                </span>
              </div>
            </div>
            <p className="mb-0">
              Get the best out of booking by adding the remaining details!
            </p>
          </div>
    
          <div className="bg-body rounded p-3 mt-3">
            <ul className="list-inline hstack flex-wrap gap-2 justify-content-between mb-0">
              <li className="list-inline-item h6 fw-normal mb-0">
                <a href="#">
                  <i className="bi bi-check-circle-fill text-success me-2"></i>
                  Verified Email
                </a>
              </li>
              <li className="list-inline-item h6 fw-normal mb-0">
                <a href="#">
                  <i className="bi bi-check-circle-fill text-success me-2"></i>
                  Verified Mobile Number
                </a>
              </li>
              <li className="list-inline-item h6 fw-normal mb-0">
                <a href="#" className="text-primary">
                  <i className="bi bi-plus-circle-fill me-2"></i>Complete Basic
                  Info
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="card-body">
         
          <div className="table-responsive border-0">
            <EducationTable
              data={profile.educations}
              tableClass="align-middle p-4 mb-0 table-hover"
              headerClass="table-dark"
            />

            <table className="table align-middle p-4 mb-0 table-hover">

              <thead className="table-dark">
                <tr>
                  <th scope="col" className="border-0 rounded-start">
                    Browser
                  </th>
                  <th scope="col" className="border-0">
                    IP
                  </th>
                  <th scope="col" className="border-0">
                    Time
                  </th>
                  <th scope="col" className="border-0 rounded-end">
                    Action
                  </th>
                </tr>
              </thead>

    
              <tbody>
  
                <tr>
        
                  <td>Chrome On Window</td>

                 
                  <td>173.238.198.108</td>


                  <td>12 Nov 2021</td>

                 
                  <td>
                    <button className="btn btn-sm btn-danger-soft me-1 mb-1 mb-md-0">
                      Sign out
                    </button>
                  </td>
                </tr>

              
                <tr>
         
                  <td>Mozilla On Window</td>

                 
                  <td>107.222.146.90</td>

                  
                  <td>08 Nov 2021</td>

                 
                  <td>
                    <button className="btn btn-sm btn-danger-soft me-1 mb-1 mb-md-0">
                      Sign out
                    </button>
                  </td>
                </tr>

               
                <tr>
         
                  <td>Chrome On iMac</td>

                  
                  <td>231.213.125.55</td>

                
                  <td>06 Nov 2021</td>

                
                  <td>
                    <button className="btn btn-sm btn-danger-soft me-1 mb-1 mb-md-0">
                      Sign out
                    </button>
                  </td>
                </tr>

               
                <tr>
                 
                  <td>Mozilla On Window</td>

            
                  <td>37.242.105.138</td>

                 
                  <td>02 Nov 2021</td>

              
                  <td>
                    <button className="btn btn-sm btn-danger-soft me-1 mb-1 mb-md-0">
                      Sign out
                    </button>
                  </td>
                </tr>
              </tbody>
             
            </table>
          </div>
    
        </div>

        <div className="card-body">
       
          <div className="table-responsive border-0">
            <table className="table align-middle p-4 mb-0 table-hover">
            
              <thead className="table-dark">
                <tr>
                  <th scope="col" className="border-0 rounded-start">
                    Browser
                  </th>
                  <th scope="col" className="border-0">
                    IP
                  </th>
                  <th scope="col" className="border-0">
                    Time
                  </th>
                  <th scope="col" className="border-0 rounded-end">
                    Action
                  </th>
                </tr>
              </thead>

      
              <tbody>
             
                <tr>
        
                  <td>Chrome On Window</td>

                 
                  <td>173.238.198.108</td>

       
                  <td>12 Nov 2021</td>

              
                  <td>
                    <button className="btn btn-sm btn-danger-soft me-1 mb-1 mb-md-0">
                      Sign out
                    </button>
                  </td>
                </tr>

             
                <tr>
                
                  <td>Mozilla On Window</td>

   
                  <td>107.222.146.90</td>

               
                  <td>08 Nov 2021</td>

                
                  <td>
                    <button className="btn btn-sm btn-danger-soft me-1 mb-1 mb-md-0">
                      Sign out
                    </button>
                  </td>
                </tr>

              
                <tr>
      
                  <td>Chrome On iMac</td>

       
                  <td>231.213.125.55</td>

     
                  <td>06 Nov 2021</td>

                 
                  <td>
                    <button className="btn btn-sm btn-danger-soft me-1 mb-1 mb-md-0">
                      Sign out
                    </button>
                  </td>
                </tr>

  
                <tr>
                
                  <td>Mozilla On Window</td>

               
                  <td>37.242.105.138</td>

                
                  <td>02 Nov 2021</td>

                
                  <td>
                    <button className="btn btn-sm btn-danger-soft me-1 mb-1 mb-md-0">
                      Sign out
                    </button>
                  </td>
                </tr>
              </tbody>
            
            </table>
          </div>

        </div>

    */
