import {
  CustomFormInputDate,
  CustomFormInputText2,
  CustomFormInputTextarea,
} from "components/pages/Customer/components/core/FormInput/FormInput";
import { PsyGender } from "components/utils/Utils";
import { APP_API_URI } from "config/config";
import { DateTime } from "luxon";
import { useRef, useState } from "react";
import { Form as BSForm, Button, Col, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import PsychologistService from "services/psychologist.service";
import { _debug } from "utils";
import { v4 as uuidv4 } from "uuid";

// NOTE :

/**
 * Component for showing component "Form Customer Profile" of the site.
 *
 * @component
 **/
const ProfileForm = (props) => {
  const { profile, handleSubmit } = props;
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  // console.log(profile?.dateOfBirth instanceof Date);
  // console.log(isValidDate(profile?.dateOfBirth));
  // console.log(DateTime.fromISO(profile?.dateOfBirth).toJSDate());

  const {
    methods,
    register,
    control,
    handleSubmit: formSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      fullName: profile?.name || "",
      firstName: profile?.firstName || "",
      lastName: profile?.lastName || "",
      middleName: profile?.middleName || "",
      gender: profile?.gender || PsyGender.OTHER,
      username: profile?.username || "",
      email: profile?.email || "",
      phone: profile?.phone || "",
      description: profile?.description || "",
      location: profile?.location || "",
      dateOfBirth: profile.dateOfBirth
        ? DateTime.fromISO(profile?.dateOfBirth).toJSDate()
        : 0,
    },
  });

  const [file, setFile] = useState();

  const imageUploaderRef = useRef(null);

  const handleImageUpload = async (e) => {
    e.preventDefault();

    const uploadFile = e.target.files[0];

    if (uploadFile) {
      setFile(URL.createObjectURL(uploadFile));

      const { name } = uploadFile;

      try {
        const imageName = uuidv4() + "." + name?.split(".")?.pop();

        const uploadData = new FormData();
        uploadData.append("image", uploadFile, imageName);

        await PsychologistService.setProfileImage(uploadData);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onSubmit = async (data) => {
    //e.preventDefault();
    _debug("Form submit: ", data);
    // console.log("!!!", data, mode, props);

    setError(null);
    try {
      const updateProfile = {
        username: data?.username || "",
        firstName: data?.firstName || "",
        lastName: data?.lastName || "",
        middleName: data?.middleName || "",
        dateOfBirth:
          DateTime.fromJSDate(data?.dateOfBirth).toFormat("dd.MM.yyyy") ||
          undefined,
        location: data?.location || "",
        gender: data?.gender || PsyGender.OTHER,
        description: data?.description || "",
      };

      await handleSubmit.mutateAsync(updateProfile);

      setSuccess(true);
    } catch (error) {
      console.error("API request error:", error.message);
      setError(error.message);
      setSuccess(false);
    } finally {
      // reset();
    }
  };

  const onError = (error) => {
    console.log("ERROR:::", error);
  };

  return (
    <FormProvider {...methods}>
      <BSForm
        className="row g-3"
        method="POST"
        onSubmit={formSubmit(onSubmit, onError)}
      >
        {/*<!-- Profile photo -->*/}
        <BSForm.Group
          className="g-3 mb-3"
          as={Row}
          controlId="formProfileImage"
        >
          <BSForm.Label>Upload your profile photo</BSForm.Label>
          <div className="d-flex align-items-center">
            <BSForm.Label
              className="position-relative me-4"
              title="User profile image"
            >
              <BSForm.Control
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={imageUploaderRef}
                style={{
                  display: "none",
                }}
                hidden
              />

              {/*<!-- Avatar place holder -->*/}
              <span className="avatar avatar-xl">
                <img
                  id="uploadfile-1-preview"
                  className="avatar-img rounded-circle border border-white border-3 shadow"
                  src={
                    profile
                      ? `${APP_API_URI}/psychologists/psychologist/profile/${profile?.id}/image`
                      : undefined
                  }
                  alt=""
                />
              </span>
            </BSForm.Label>
            {/*<!-- Upload button -->*/}

            <Button
              className="btn btn-sm btn-primary-soft mb-0"
              type="button"
              onClick={() => imageUploaderRef.current.click()}
            >
              Change
            </Button>
          </div>
        </BSForm.Group>

        {/*<!-- Display name -->*/}
        <BSForm.Group className="mb-3" controlId="formDisplayName">
          <CustomFormInputText2
            label="Display name"
            disabled
            required={false}
            error={errors.fullName}
            {...register("fullName")}
          />
        </BSForm.Group>

        <BSForm.Group
          className="g-0 mb-3"
          as={Row}
          controlId="formFirstNameAndLastName"
        >
          {/*<!-- First name -->*/}
          <BSForm.Group className="g-3" as={Col} controlId="formFirstName">
            <CustomFormInputText2
              label="First name"
              required={false}
              error={errors.firstName}
              {...register("firstName")}
            />
          </BSForm.Group>

          {/*<!-- Last name -->*/}
          <BSForm.Group className="g-3" as={Col} controlId="formLastName">
            <CustomFormInputText2
              label="Last name"
              required={false}
              error={errors.lastName}
              {...register("lastName")}
            />
          </BSForm.Group>
        </BSForm.Group>

        {/*<!-- Middle name -->*/}
        <BSForm.Group className="g-3 mb-3" as={Col} controlId="formFirstName">
          <CustomFormInputText2
            label="Middle name"
            required={false}
            error={errors.middleName}
            {...register("middleName")}
          />
        </BSForm.Group>

        <BSForm.Group
          className="g-0 mb-3"
          as={Row}
          controlId="formEmailAndGender"
        >
          {/*<!-- Email -->*/}
          <BSForm.Group className="g-3" as={Col} controlId="formEmail">
            <CustomFormInputText2
              label="Email"
              disabled
              error={errors.email}
              {...register("email")}
            />
          </BSForm.Group>

          {/*<!-- Mobile -->*/}
          <BSForm.Group className="g-3" as={Col} controlId="formPhone">
            <CustomFormInputText2
              label="Phone"
              disabled
              error={errors.phone}
              {...register("phone")}
            />
          </BSForm.Group>
        </BSForm.Group>

        {/*<!-- Gender & Date of birth -->*/}
        <BSForm.Group
          className="g-0 mb-3"
          as={Row}
          controlId="formEmailAndGender"
        >
          {/*<!-- Date of birth -->*/}
          <BSForm.Group
            className="g-3 mb-3"
            as={Col}
            controlId="formDateOfBirth"
          >
            <CustomFormInputDate
              control={control}
              name="dateOfBirth"
              label="Date of Birth"
              required={false}
              error={errors.dateOfBirth}
              defaultValue={profile?.dateOfBirth}
              dateFormat="dd.MM.yyyy"
            />
          </BSForm.Group>

          {/*<!-- Gender -->*/}
          <BSForm.Group className="" as={Col} controlId="formGender">
            <BSForm.Label className="">Select Gender</BSForm.Label>
            <div className="d-flex gap-4">
              <div className="form-check radio-bg-light">
                <BSForm.Group
                  as={Row}
                  className=""
                  controlId="formGender"
                  key="inline-radio"
                >
                  <BSForm.Check
                    inline
                    type="radio"
                    label="Male"
                    id="inline-radio-1"
                    value={PsyGender.MALE}
                    name="gender"
                    {...register("gender", {
                      required: "Nación es obligatorio",
                    })}
                  />
                  <BSForm.Check
                    inline
                    type="radio"
                    label="Female"
                    id="inline-radio-2"
                    value={PsyGender.FEMALE}
                    name="gender"
                    {...register("gender", {
                      required: "Nación es obligatorio",
                    })}
                  />
                  <BSForm.Check
                    inline
                    type="radio"
                    label="Others"
                    id="inline-radio-3"
                    value={PsyGender.OTHER}
                    name="gender"
                    {...register("gender", {
                      required: "Nación es obligatorio",
                    })}
                  />

                  {errors.gender && (
                    <BSForm.Text className="text-danger">
                      {errors?.gender?.message}
                    </BSForm.Text>
                  )}
                </BSForm.Group>
              </div>
            </div>
          </BSForm.Group>
        </BSForm.Group>

        {/*<!-- Description -->*/}
        <BSForm.Group className="mb-3" as={Col} controlId="formDescription">
          <CustomFormInputTextarea
            label="Description"
            placeholder="Enter description"
            rows="3"
            spellCheck={false}
            defaultValue={profile?.description}
            error={errors?.description}
            {...register("description", {
              maxLength: 1024,
              pattern: /[A-Za-z]{3}/,
            })}
          />
        </BSForm.Group>

        {/*<!-- Button -->*/}
        <div className="col-12 text-end">
          <Button
            className="btn btn-primary mb-0"
            variant="primary"
            type="submit"
          >
            Save Changes
          </Button>
        </div>

        {handleSubmit.isError ? (
          <div>An error occurred: {handleSubmit.error.message}</div>
        ) : null}

        {handleSubmit.isSuccess ? <div>Profile updated!</div> : null}
      </BSForm>
    </FormProvider>
  );
};

ProfileForm.propTypes = {};

export default ProfileForm;
