import classNames from "classnames";
import { PageSection } from "core/components";
import FeaturePsyFormats1 from "./FeaturePsyFormats1";
import FeaturePsyFormats2 from "./FeaturePsyFormats2";

/**
 * Component for showing Section "Feature - Psychotherapy Formats" of the site.
 *
 * @component
 */
const FeaturePsyFormats = (props) => {
  const {
    data,
    id = "feature-psy-formats",
    cn = "pt-130 pb-80",
    type,
    otherProps,
  } = props;

  //console.log("11", props);

  return (
    <PageSection id={id} cn={classNames(cn, `feature-psy-formats-${type}`)}>
      {(() => {
        if (type === "style2") {
          return <FeaturePsyFormats2 data={data} {...otherProps} />;
        }
        return <FeaturePsyFormats1 data={data} {...otherProps} />;
      })()}
    </PageSection>
  );
};

export default FeaturePsyFormats;
