import {
  InputFloatingLabelEmail,
  InputFloatingLabelGifts,
  InputFloatingLabelName,
} from "components/utils/Forms";
import { dataForm } from "data";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { _debug } from "utils";

const msgSubmitted = "Сообщение успешно отправлено";
const msgSubmit = "Оплатить";

const options = ["1 консультация", "5 консультаций", "10 консультаций"];

const PurchaseForm = (props) => {
  const {
    methods,
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "Aleks",
      email: "lovkova@mail.ru",
      gift: "5 консультаций",
    },
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  //const gaEventTracker = useAnalyticsEventTracker("User contact had send");

  // const [username, setUsername] = useState("User1");
  // const [email, setEmail] = useState("user1@local.loc");
  // const [message, setMessage] = useState("");
  //const [validate, setValidate] = useState({});

  const onSubmit = async (data) => {
    //e.preventDefault();
    _debug("Form submit: ", data);

    setError(null);
    try {
      //gaEventTracker("submitFromCountDown");

      const response = await props.onSubmit({
        email: data.email,
        name: data.name,
        gift: data.gift,
      });

      if (response && response.error) {
        setError(response.error);
        setSuccess(false);
      } else {
        setSuccess(true);
      }
    } catch (error) {
      setError(error.message);
      setSuccess(false);
    } finally {
      reset();
    }
  };

  return (
    <div className="gift-purchase-form-wrapper">
      <FormProvider {...methods}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete={"off"}
          method="POST"
          className="gift-purchase-form g-3"
        >
          <Row className="mb-3">
            <Form.Group controlId="formGiftOrderName" as={Col} mb="6">
              <InputFloatingLabelName
                label={dataForm.giftOrderName.msgLabel}
                placeholder={dataForm.giftOrderName.msgPlaceholder}
                required={dataForm.giftOrderName.msgRequired}
                register={register}
                errors={errors}
              />
            </Form.Group>
          </Row>

          <Row className="g-3 mb-3">
            <Form.Group controlId="formGiftOrderEmail" as={Col} mb="6">
              <InputFloatingLabelEmail
                label={dataForm.giftOrderEmail.msgLabel}
                placeholder={dataForm.giftOrderEmail.msgPlaceholder}
                required={dataForm.giftOrderEmail.msgRequired}
                register={register}
                errors={errors}
              />
            </Form.Group>
          </Row>

          <Row className="g-3 mb-3">
            <Form.Group controlId="formGiftOrderGift" as={Col} mb="6">
              <InputFloatingLabelGifts
                label={dataForm.giftOrderGift.msgLabel}
                placeholder={dataForm.giftOrderGift.msgPlaceholder}
                required={dataForm.giftOrderGift.msgRequired}
                options={options}
                register={register}
                control={control}
                errors={errors}
              />
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Check me out" />
          </Form.Group>

          <div className="text-center">
            <button type="submit" className="btn btn-primary theme-btn mx-auto">
              {msgSubmit}
            </button>
          </div>
        </Form>
        {error && (
          <p className="text-warning" style={{ textAlign: "center" }}>
            <small>{error}</small>
          </p>
        )}
        {success && (
          <p className="text-success" style={{ textAlign: "center" }}>
            <small>{msgSubmitted}</small>
          </p>
        )}
      </FormProvider>
    </div>
  );
};

export default PurchaseForm;
