import { SEO } from "components/utils/Analytics";
import { dataTherapistsPage } from "data";
import { Fragment, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PageSection } from "../Home";

import "./CalendarTest.style.scss";

// NOTE : https://epicbootstrap.com/snippets/team-cards
// NOTE : https://codesandbox.io/p/sandbox/load-more-functionality-using-react-hooks-kllwg?file=%2Fsrc%2FList.jsx%3A54%2C7-54%2C24

const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  console.log(result);
  return result;
};

const SectionContent = () => {
  const [date, setDate] = useState(new Date());

  return (
    <Fragment>
      <div className="session-date">
        <div className="session-date__headline mgb-3">
          Когда хотите провести онлайн-сессию?
        </div>
        <div className="booking-online-off-offline-tabs">
          <div className="booking-online-off-offline-tabs__list">
            <div className="booking-online-off-offline-tabs__item is-selected">
              <div className="d-flex">
                <img
                  alt=""
                  className="booking__emoji"
                  src="https://alter.ru/images/person-with-laptop-emoji.png"
                />
                Онлайн
              </div>
            </div>
            <div className="booking-online-off-offline-tabs__item">
              <div className="d-flex">
                <img
                  alt=""
                  className="booking__emoji"
                  src="https://alter.ru/images/person-emoji.png"
                />
                Лично
              </div>
            </div>
          </div>
        </div>
        <div className="price-and-time">
          <span className="price-and-time__first">
            <div className="price-and-time__discount__container">
              <span>
                <span className="is-nowrap">3500 ₽</span>
              </span>
            </div>
            , 60 минут
          </span>
        </div>
        <DatePicker
          wrapperClassName="date-picker"
          className="date-picker"
          selected={date}
          onChange={(date) => setDate(date)}
          excludeDates={[addDays(new Date(), -1), addDays(new Date(), 5)]}
          inline
        />
        <div className="date-picker">
          <div className="date-picker__change-week">
            <svg
              className=" cursor-pointer flex-shrink-0 mr-4"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.28796 12L14.298 18.01L15.712 16.596L11.112 11.996L15.712 7.39599L14.298 5.98999L8.28796 12Z"
                fill="#323648"
              ></path>
            </svg>
            <div className="date-picker__week__short">22 июля—4 августа</div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              className=" cursor-pointer flex-shrink-0"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.713 12L9.70196 5.98999L8.28796 7.40399L12.888 12.004L8.28796 16.597L9.70196 18.011L15.713 12Z"
                fill="#323648"
              ></path>
            </svg>
          </div>
          <div className="date-picker__week-day-short-container">
            <div className="date-picker__week-day-short">пн</div>
            <div className="date-picker__week-day-short">вт</div>
            <div className="date-picker__week-day-short">ср</div>
            <div className="date-picker__week-day-short">чт</div>
            <div className="date-picker__week-day-short">пт</div>
            <div className="date-picker__week-day-short">сб</div>
            <div className="date-picker__week-day-short">вс</div>
          </div>
          <div className="date-picker__week">
            <div className="date-picker__day date-picker__day-no-available-hours">
              22
            </div>
            <div className="date-picker__day date-picker__day-no-available-hours">
              23
            </div>
            <div className="date-picker__day date-picker__day-has-available-hours date-picker__day-selected">
              24
            </div>
            <div className="date-picker__day date-picker__day-no-available-hours">
              25
            </div>
            <div className="date-picker__day date-picker__day-no-available-hours">
              26
            </div>
            <div className="date-picker__day date-picker__day-no-available-hours">
              27
            </div>
            <div className="date-picker__day date-picker__day-no-available-hours">
              28
            </div>
          </div>
          <div className="date-picker__week">
            <div className="date-picker__day date-picker__day-no-available-hours">
              29
            </div>
            <div className="date-picker__day date-picker__day-no-available-hours">
              30
            </div>
            <div className="date-picker__day date-picker__day-no-available-hours">
              31
            </div>
            <div className="date-picker__day date-picker__day-no-available-hours">
              1
            </div>
            <div className="date-picker__day date-picker__day-no-available-hours">
              2
            </div>
            <div className="date-picker__day date-picker__day-no-available-hours">
              3
            </div>
            <div className="date-picker__day date-picker__day-no-available-hours">
              4
            </div>
          </div>
          <div className="date-picker__select-time">Выберите время</div>
          <div className="date-picker__timezone">
            Указано ваше время: Europe/Moscow
          </div>
          <div className="date-picker__slots">
            <div className="date-picker__slots__slot date-picker__slots__slot-selected">
              14:00
            </div>
          </div>
        </div>
        <hr className="mgt-3 mgb-3" />
        <div className="request-time is-flex is-justify-content-space-between ">
          <div className="request-time__text">
            <img
              alt=""
              className="request-time__emoji"
              src="https://alter.ru/images/alarm-clock-emoji.png"
            />
            <span className="blm-text blm-text__with-icon blm-text-accent">
              Нет удобного времени?
            </span>
            <span className="blm-text is-block">
              Запросите любое подходящее вам время для сессии.
            </span>
          </div>
          <button className="button blm-button">
            <span className="blm-text blm-text-accent">Запросить время</span>
          </button>
        </div>
      </div>
    </Fragment>
  );
};

/**
 * Component for showing Section "Therapist List" of the site.
 *
 * @component
 **/
const CalendarTestSection = (props) => {
  const { data, id = "calendar-test", cn = "pt-100 pb-100" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        {/* <SectionHeader data={data} showSubtitle showDescription />
         */}
        <SectionContent data={data} />
      </div>
    </PageSection>
  );
};

const CalendarTestPage = () => {
  return (
    <Fragment>
      <SEO data={dataTherapistsPage.meta}>
        <link rel="canonical" href="https://psywealth.com/therapists/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="therapists-page" id="main">
        <CalendarTestSection />
      </main>
    </Fragment>
  );
};

export default CalendarTestPage;
