import { Avatar, useCall, useCallStateHooks } from "@stream-io/video-react-sdk";
import { MyPermissionRequestsPanel } from "./PermisionPanel";

export const MyDescriptionPanel = () => {
  const { useCallCustomData, useParticipants } = useCallStateHooks();
  const custom = useCallCustomData();
  const participants = useParticipants();

  return (
    <div className="description-panel">
      <h2 className="title">{custom?.title ?? "<Title>"}</h2>
      <h3 className="description">{custom?.description ?? "<Description>"}</h3>
      <p className="participant-count">{participants.length} participants</p>
    </div>
  );
};

export const MyParticipant = ({ participant }) => {
  // `isSpeaking` information is available on the participant object,
  // and it is automatically detected by our system and updated by our SDK.
  const { isSpeaking } = participant;
  return (
    <div className={`participant ${isSpeaking ? "speaking" : ""}`}>
      <Avatar imageSrc={participant.image} />
      <div className="name">{participant.name}</div>
    </div>
  );
};

export const MyParticipantsPanel = () => {
  const { useParticipants } = useCallStateHooks();
  const participants = useParticipants();

  return (
    <div className="participants-panel">
      <h4>Participants</h4>
      {participants.map((p) => (
        <MyParticipant participant={p} key={p.sessionId} />
      ))}
    </div>
  );
};

export const MyControlsPanel = () => {
  return (
    <div className="controls-panel">
      <MyMicButton />
      <MyLiveButton />
    </div>
  );
};

const MyMicButton = () => {
  const { useMicrophoneState } = useCallStateHooks();
  const { microphone, isMute } = useMicrophoneState();
  return (
    <button
      className="mic-button"
      onClick={async () => {
        if (isMute) {
          await microphone.enable();
        } else {
          await microphone.disable();
        }
      }}
    >
      {isMute ? "Unmute" : "Mute"}
    </button>
  );
};

const MyLiveButton = () => {
  // this utility hook returns the call object from the <StreamCall /> context
  const call = useCall();
  // will emit a new value whenever the call goes live or stops being live.
  // we can use it to update the button text or adjust any other UI elements
  const { useIsCallLive } = useCallStateHooks();
  const isLive = useIsCallLive();
  return (
    <button
      className={`live-button ${isLive ? "live" : ""}`}
      onClick={async () => {
        if (isLive) {
          await call?.stopLive();
        } else {
          await call?.goLive();
        }
      }}
    >
      {isLive ? "Stop Live" : "Go Live"}
    </button>
  );
};

export const MyUILayout = () => {
  return (
    <div className="ui-layout">
      <MyDescriptionPanel />
      <MyParticipantsPanel />
      <MyPermissionRequestsPanel />
      <MyControlsPanel />
    </div>
  );
};
