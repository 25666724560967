import { dataTherapistsPage } from "data";
import { useEffect, useState } from "react";
import { PageSection, SectionHeader } from "../../../Home";
import useMultiStepForm from "../../../Home/Form/useMultiStepForm";
import { AvailabilityCalendar } from "./Steps/AvailabilityCalendar";
import TypeOfTherapy from "./Steps/TypeOfTherapy/TypeOfTherapy";

import { useMutation } from "@tanstack/react-query";
import { useAuth } from "contexts/AuthProvider";
import { useProfile } from "contexts/ProfileProvider";
import { DateTime } from "luxon";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AppointmentService from "services/appointment.service";
import PaymentService from "services/payment.service";
import { PurchaseProcess } from "../PurchaseProcess";
import "./CalendarTest.style.scss";
import { PaymentDetails } from "./Steps/PaymentDetails";

// NOTE : https://epicbootstrap.com/snippets/team-cards
// NOTE : https://codesandbox.io/p/sandbox/load-more-functionality-using-react-hooks-kllwg?file=%2Fsrc%2FList.jsx%3A54%2C7-54%2C24

const INITIAL_DATA = {
  therapist: undefined,
  productId: undefined,
  time: undefined,
  type: undefined,
};

const MSG_TABLE_APPOINTMENT_TITLE = "ЗАГОЛОВОК";

/**
 * Component for showing Section "Therapist List" of the site.
 *
 * @component
 **/
const AppointmentStepsSection = (props) => {
  const {
    data,
    params,
    id = "appointment-steps",
    cn = "pt-100 pb-100",
  } = props;
  const { auth, isAuthenticated } = useAuth();
  const { profile } = useProfile();

  console.log(auth);

  // const { data, cn = "" } = props;
  const [dataForm, setDataForm] = useState(INITIAL_DATA);
  const therapist = "";

  /*  <PaymentConfirmation
      data={dataTherapistsPage}
      therapist={therapist}
      dataForm={dataForm}
      handleChange={setDataForm}
    />,*/
  const STEPS = [
    <TypeOfTherapy
      data={dataTherapistsPage}
      therapist={therapist}
      params={params}
      dataForm={dataForm}
      handleChange={setDataForm}
    />,
    <AvailabilityCalendar
      data={dataTherapistsPage}
      therapist={therapist}
      dataForm={dataForm}
      handleChange={setDataForm}
    />,
    <PaymentDetails
      data={dataTherapistsPage}
      therapist={therapist}
      dataForm={dataForm}
      handleChange={setDataForm}
    />,
  ];

  const {
    steps,
    currentStepIndex,
    step,
    isFirstStep,
    isLastStep,
    prevStep,
    nextStep,
  } = useMultiStepForm(STEPS);

  const [isProcessing, setIsProcessing] = useState(false);
  // const [isPaid, setIsPaid] = useState(false);
  const [redirectURL, setRedirectURL] = useState("");
  const [example, setExemple] = useState(false);
  const [actionStatus, setActionStatus] = useState(0);

  const navigate = useNavigate();

  const updateFields = (fields) => {
    setDataForm((prev) => {
      return { ...prev, ...fields };
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isLastStep) return nextStep();
    alert("Successful Account Creation");
    console.log(dataForm);
    handlePay();
  };

  const handlePay = () => {
    const newAppointment = {
      customer: profile.id,
      product: dataForm.productId,
      deliveryName: data.name,
      deliveryEmail: data.email,
      description: "",
    };
    createAppointment.mutate({
      psychologistId: dataForm.therapist.id,
      time: DateTime.fromJSDate(dataForm.time).toISO({
        includeOffset: false,
        extendedZone: false,
        suppressSeconds: true,
        suppressMilliseconds: true,
      }),
      date: DateTime.fromJSDate(dataForm.date).toISO({
        includeOffset: false,
        extendedZone: false,
        suppressSeconds: true,
        suppressMilliseconds: true,
      }),
      datetime: DateTime.fromJSDate(dataForm.datetime).toISO({
        includeOffset: false,
        extendedZone: false,
        suppressSeconds: false,
        suppressMilliseconds: true,
      }),
    });
  };

  const createAppointment = useMutation({
    mutationFn: async (newAppointment) =>
      AppointmentService.create(newAppointment),
    mutationKey: ["createAppointment"],
    onSuccess: (data) => {
      console.log("Appointment created successfully:", data);

      setActionStatus(1);

      //
      const requestBody = {
        customer: profile.id,
        product: dataForm.productId,
        deliveryName: data.name,
        deliveryEmail: data.email,
        description: "",
      };
      createPayment.mutate(requestBody);
    },
    onError: (error) => {
      console.error("Error create appointment:", error);
    },
  });

  const createPayment = useMutation({
    mutationFn: async (newOrder) =>
      PaymentService.createPaymentWithOrder(newOrder),
    mutationKey: ["createPaymentWithOrder"],
    onSuccess: (data) => {
      console.log("Payment created successfully:", data);
      // queryClient.refetchQueries("profile");
      console.log(data.confirmation.confirmation_url);
      setRedirectURL(data.confirmation.confirmation_url);
      setIsProcessing(true);
      setExemple(true);
      console.log(redirectURL, isProcessing, example);
      // redirect(redirectURL);
      // navigate(redirectURL, { replace: true });

      // window.location.replace(redirectURL);
      // window.location.replace("https://www.google.com");
    },
    onError: (error) => {
      console.error("Error create payment:", error);
    },
  });

  useEffect(() => {
    console.log("Redirecting...", isProcessing, redirectURL, example);
    if (redirectURL) {
      setTimeout(() => {
        // 👇 Redirects to about page, note the `replace: true`
        console.log(redirectURL);
        // redirect(redirectURL);
        // navigate(redirectURL, { replace: true });
        // window.location.replace(redirectURL);

        window.location.replace(redirectURL);
      }, 1000);
      setIsProcessing(false);
    }
  }, [isProcessing, redirectURL, example]);

  useEffect(() => {
    console.log(actionStatus);
  }, [actionStatus]);

  useEffect(() => {
    console.log("dataForm:", dataForm);
  }, [dataForm]);

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />

        <div className="vstack gap-4 mb-4">
          <div className="bg-light rounded p-3">
            <PurchaseProcess />
          </div>
        </div>

        <Row>
          {/*<!-- Sidebar START -->*/}
          <div className="col-lg-4 col-xl-3">
            <div className="vstack gap-4">
              {/*<!-- Orders START -->*/}
              <div className="card border">
                {/*<!-- Card header -->*/}
                <div className="card-header border-bottom">
                  <h4 className="card-header-title">
                    {MSG_TABLE_APPOINTMENT_TITLE}
                  </h4>
                </div>
                <div className="card-body vstack gap-4">
                  <p>{dataForm.therapist?.name}</p>
                  <p>{dataForm.therapist?.experience}</p>
                  <p>{dataForm?.productId}</p>
                  <p>{dataForm?.productName}</p>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- Sidebar END -->*/}

          {/*<!-- Main content START -->*/}
          <div className="col-lg-8 col-xl-9">
            <div className="vstack gap-4">
              {/*<!-- Orders START -->*/}
              <div className="card border">
                {/*<!-- Card header -->*/}
                <div className="card-header border-bottom">
                  <h4 className="card-header-title">
                    {MSG_TABLE_APPOINTMENT_TITLE}
                  </h4>
                </div>

                {/*<!-- Card body Orders -->*/}
                <div className="card-body vstack gap-4">
                  {/*<!-- Orders item START -->*/}
                  <div className="card">
                    <form onSubmit={onSubmit}>
                      <div
                        style={{
                          position: "absolute",
                          top: ".5rem",
                          right: ".5rem",
                        }}
                      >
                        {currentStepIndex + 1} / {steps.length}
                      </div>
                      {step}
                      {!isFirstStep && (
                        <button type="button" onClick={prevStep}>
                          Back
                        </button>
                      )}
                      <button type="submit">
                        {isLastStep ? "Finish" : "Next"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- Main content END -->*/}
        </Row>
      </div>
    </PageSection>
  );
};

export default AppointmentStepsSection;
