import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { useGetPsychologistWorkingPlan } from "components/pages/Psychologist/utils/useGetPsychologistWorkingPlan";
import { Spinner } from "components/utils/Spinner";
import { DateTime } from "luxon";
import { Fragment, useEffect, useState } from "react";
import { Button, Row, Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import PsychologistService from "services/psychologist.service";
import { _debug } from "utils";
import CustomTable from "../../ProfileOld/CustomTable";
import AvailableTimeModalForm from "./AvailableTimeModalForm";
import DayPlanModalForm from "./DayPlanModalForm";

import { dataMessages } from "data";
import "./PsychologistAvailableTimings.scss";

const columnHelper = createColumnHelper();

const daysOfWeek = [
  { key: "monday", title: "Понедельник" },
  { key: "tuesday", title: "Вторник" },
  { key: "wednesday", title: "Среда" },
  { key: "thursday", title: "Четверг" },
  { key: "friday", title: "Пятница" },
  { key: "saturday", title: "Суббота" },
  { key: "sunday", title: "Воскресенье" },
];

const WorkingPlanTable = (props) => {
  const {
    data: days,
    tableClass,
    headerClass,
    onDelete,
    ...otherProps
  } = props;

  // console.log(days);
  const [showModal, setShowModal] = useState(false);
  const [modalFormState, setModalFormState] = useState({});

  const handleEdit = (cell, row) => {
    console.log("Edit!!!!", cell, row);
    // onDelete(cell.row.original.id);
    const state = {
      startTime: cell.row.original.workingHours.startTime,
      endTime: cell.row.original.workingHours.endTime,
      breaks: cell.row.original.breaks,
      title: cell.row.original.title,
      key: cell.row.original.key,
    };
    console.log(state);
    setModalFormState(state);
    setShowModal(true);
  };

  const handleClose = (e, data) => {
    console.log("Close Edit!!!!", e, data);
    setShowModal(false);
  };

  const handleAddBreak = (e, data) => {
    console.log("Add Break!!!!", e, data);
    setShowModal(false);
  };

  const columns = [
    columnHelper.accessor("workingHours.startTime", {
      header: "startTime",
    }),
    columnHelper.accessor("workingHours.endTime", {
      header: "endTime",
    }),

    columnHelper.accessor("action", {
      header: () => <span>Action</span>,
      accessor: "id",
      cell: ({ cell, row }) => (
        <button onClick={() => handleEdit(cell, row)}>Edit</button>
      ),
    }),

    columnHelper.accessor("action2", {
      header: () => <span>Breaks</span>,
      accessor: "id",
      cell: ({ cell, row }) => (
        <button onClick={() => handleAddBreak(cell, row)}>Break</button>
      ),
    }),
  ];

  return (
    <Fragment>
      <DayPlanModalForm
        showModal={showModal}
        handleClose={handleClose}
        modalFormData={modalFormState}
      />
      {days && (
        <CustomTable
          data={days}
          columns={columns}
          tableClass={tableClass}
          headerClass={headerClass}
          {...otherProps}
        />
      )}
    </Fragment>
  );
};

const TimeSlots = (props) => {
  const { title, handleShowModal, handleDisableSlot } = props;

  return (
    <div className="tab-pane active show" id="monday">
      <div className="slot-box">
        <div className="slot-header">
          <h5>{title}</h5>
          <ul>
            <li>
              <Button className="add-slot" onClick={handleShowModal}>
                Add Slots
              </Button>
            </li>
            <li>
              <Button className="disable-slot" onClick={handleDisableSlot}>
                Disable
              </Button>
            </li>
            <li>
              <Button className="del-slot">Delete All</Button>
            </li>
          </ul>
        </div>
        <div className="slot-body">
          <ul className="time-slots">
            <li>
              <i className="fa-regular fa-clock"></i>
              09:00 AM
            </li>
            <li>
              <i className="fa-regular fa-clock"></i>09:30 AM
            </li>
            <li className="slot-space">Space : 2</li>
            <li>
              <i className="fa-regular fa-clock"></i>10:30 AM
            </li>
            <li>
              <i className="fa-regular fa-clock"></i>11:00 AM
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const Content = () => {
  const [showModal, setShowModal] = useState(false);
  const [WP, setWP] = useState([]);

  const queryClient = useQueryClient();

  const {
    data: workingPlan,
    isLoading: isLoadingPsychologistWorkingPlan,
    isError: isErrorPsychologistWorkingPlan,
    error: errorPsychologistWorkingPlan,
  } = useGetPsychologistWorkingPlan();

  const isLoading = isLoadingPsychologistWorkingPlan;
  const isError = isErrorPsychologistWorkingPlan;
  const errors = [errorPsychologistWorkingPlan];

  const mutationAddTimeSlot = useMutation({
    mutationFn: async (newSlot) => PsychologistService.addTimeSlot(newSlot),
    mutationKey: ["addTimeSlot"],

    onSuccess: (data) => {
      console.log("Time slot added successfully:", data);
      // queryClient.refetchQueries("profile");
    },
    onError: (error) => {
      console.error("Error add Time slot:", error);
    },
  });

  const handleClose = (data) => {
    console.log(data);
    setShowModal(false);

    const newSlot = {
      startTime: DateTime.fromJSDate(data.startTime).toISO(),
      endTime: DateTime.fromJSDate(data.endTime).toISO(),
    };
    console.log(newSlot);
    mutationAddTimeSlot.mutate(newSlot);
  };

  const handleShowModal = () => setShowModal(true);
  const handleDisableSlot = () => {
    console.log("Disable slot");
  };

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (workingPlan) {
      _debug("PSYCHOLOGIST WP:", workingPlan);

      let wp1 = [];
      // wp1 = daysOfWeek.map((item) =>
      //   item.key === "monday" ? { ...workingPlan.monday, ...item } : item
      // );

      wp1 = daysOfWeek.map((item) =>
        Object.assign({ ...workingPlan[item.key], ...item })
      );
      setWP(wp1);
      console.log(wp1);
    }
  }, [workingPlan]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  return (
    <Fragment>
      {showModal && (
        <AvailableTimeModalForm
          showModal={showModal}
          handleClose={handleClose}
          setShowModal={setShowModal}
        />
      )}
      <div className="card custom-card">
        <div className="card-body">
          <div className="card-header">
            <h3>Select Available Slots</h3>
          </div>

          <div className="available-tab">
            <label className="form-label">Select Available days</label>

            <Tabs
              defaultActiveKey="monday"
              id="fill-tab-example"
              className="mb-3"
              fill
              variant="pills"
            >
              {daysOfWeek.map((item, index) => {
                return (
                  <Tab eventKey={item.key} title={item.title} key={index}>
                    <TimeSlots
                      title={item.title}
                      handleShowModal={handleShowModal}
                      handleDisableSlot={handleDisableSlot}
                    />
                  </Tab>
                );
              })}
            </Tabs>
          </div>
        </div>
      </div>

      <Row>{WP && <WorkingPlanTable data={WP} />}</Row>
    </Fragment>
  );
};

const PsychologistAvailableTimings = () => {
  //const { slug } = useParams();

  // console.log(slug);

  return (
    <div className="row">
      <h2>Available Timings</h2>
      <Content />
    </div>
  );
};

export default PsychologistAvailableTimings;

/*          <div className="tab-content pt-0">
            <div className="tab-pane active show" id="monday">
              <div className="slot-box">
                <div className="slot-header">
                  <h5>Monday</h5>
                  <ul>
                    <li>
                      <a
                        className="add-slot"
                        data-bs-toggle="modal"
                        data-bs-target="#add_slot"
                        href="/react/template/doctor/available-timings"
                      >
                        Add Slots
                      </a>
                    </li>
                    <li>
                      <a
                        className="del-slot"
                        data-bs-toggle="modal"
                        data-bs-target="#delete_slot"
                        href="/react/template/doctor/available-timings"
                      >
                        Delete All
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="slot-body">
                  <ul className="time-slots">
                    <li>
                      <i className="fa-regular fa-clock"></i>
                      09:00 AM
                    </li>
                    <li>
                      <i className="fa-regular fa-clock"></i>09:30 AM
                    </li>
                    <li className="slot-space">Space : 2</li>
                    <li>
                      <i className="fa-regular fa-clock"></i>10:30 AM
                    </li>
                    <li>
                      <i className="fa-regular fa-clock"></i>11:00 AM
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="tuesday">
              <div className="slot-box">
                <div className="slot-header">
                  <h5>Tuesday</h5>
                  <ul>
                    <li>
                      <a
                        className="add-slot"
                        data-bs-toggle="modal"
                        data-bs-target="#add_slot"
                        href="/react/template/doctor/available-timings"
                      >
                        Add Slots
                      </a>
                    </li>
                    <li>
                      <a
                        className="del-slot"
                        data-bs-toggle="modal"
                        data-bs-target="#delete_slot"
                        href="/react/template/doctor/available-timings"
                      >
                        Delete All
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="slot-body">
                  <p>No Slots Available</p>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="wednesday">
              <div className="slot-box">
                <div className="slot-header">
                  <h5>Wednesday</h5>
                  <ul>
                    <li>
                      <a
                        className="add-slot"
                        data-bs-toggle="modal"
                        data-bs-target="#add_slot"
                        href="/react/template/doctor/available-timings"
                      >
                        dd Slots
                      </a>
                    </li>
                    <li>
                      <a
                        className="del-slot"
                        data-bs-toggle="modal"
                        data-bs-target="#delete_slot"
                        href="/react/template/doctor/available-timings"
                      >
                        Delete All
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="slot-body">
                  <p>No Slots Available</p>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="thursday">
              <div className="slot-box">
                <div className="slot-header">
                  <h5>Thursday</h5>
                  <ul>
                    <li>
                      <a
                        className="add-slot"
                        data-bs-toggle="modal"
                        data-bs-target="#add_slot"
                        href="/react/template/doctor/available-timings"
                      >
                        Add Slots
                      </a>
                    </li>
                    <li>
                      <a
                        className="del-slot"
                        data-bs-toggle="modal"
                        data-bs-target="#delete_slot"
                        href="/react/template/doctor/available-timings"
                      >
                        Delete All
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="slot-body">
                  <p>No Slots Available</p>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="friday">
              <div className="slot-box">
                <div className="slot-header">
                  <h5>Friday</h5>
                  <ul>
                    <li>
                      <a
                        className="add-slot"
                        data-bs-toggle="modal"
                        data-bs-target="#add_slot"
                        href="/react/template/doctor/available-timings"
                      >
                        Add Slots
                      </a>
                    </li>
                    <li>
                      <a
                        className="del-slot"
                        data-bs-toggle="modal"
                        data-bs-target="#delete_slot"
                        href="/react/template/doctor/available-timings"
                      >
                        Delete All
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="slot-body">
                  <p>No Slots Available</p>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="saturday">
              <div className="slot-box">
                <div className="slot-header">
                  <h5>Saturday</h5>
                  <ul>
                    <li>
                      <a
                        className="add-slot"
                        data-bs-toggle="modal"
                        data-bs-target="#add_slot"
                        href="/react/template/doctor/available-timings"
                      >
                        Add Slots
                      </a>
                    </li>
                    <li>
                      <a
                        className="del-slot"
                        data-bs-toggle="modal"
                        data-bs-target="#delete_slot"
                        href="/react/template/doctor/available-timings"
                      >
                        Delete All
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="slot-body">
                  <p>No Slots Available</p>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="sunday">
              <div className="slot-box">
                <div className="slot-header">
                  <h5>Sunday</h5>
                  <ul>
                    <li>
                      <a
                        className="add-slot"
                        data-bs-toggle="modal"
                        data-bs-target="#add_slot"
                        href="/react/template/doctor/available-timings"
                      >
                        Add Slots
                      </a>
                    </li>
                    <li>
                      <a
                        className="del-slot"
                        data-bs-toggle="modal"
                        data-bs-target="#delete_slot"
                        href="/react/template/doctor/available-timings"
                      >
                        Delete All
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="slot-body">
                  <p>No Slots Available</p>
                </div>
              </div>
            </div>
            <div className="form-wrap">
              <label className="col-form-label">Appointment Fees ($)</label>
              <input type="text" className="form-control" value="254" />
            </div>
            <div className="modal-btn text-end">
              <a
                className="btn btn-gray"
                href="/react/template/doctor/available-timings"
              >
                Cancel
              </a>
              <button className="btn btn-primary prime-btn">
                Save Changes
              </button>
            </div>
          </div>
*/
