import { dataPricing } from "data/dataPricing";

export const ldJson = {
  "@context": "https://schema.org",
  "@type": "WebApplication",
  name: "psywealth",
  url: "https://psywealth.com/",
  "@id": "https://psywealth.com/#webapplication",
  applicationCategory: "BusinessApplication",
  operatingSystem: "all",
  browserRequirements: ["requires HTML5 support", "requires JavaScript"],

  offers: {
    "@type": "AggregateOffer",
    highPrice: "19900.00",
    lowPrice: "4900.00",
    offerCount: "3",
    priceCurrency: "RUB",
    offeredBy: {
      "@type": "Organization",
      name: "psywealth, Inc.",
    },
    offers: [
      {
        "@type": "Offer",
        priceSpecification: {
          "@type": "UnitPriceSpecification",
          price: dataPricing.items[0].price,
          priceCurrency: "RUB",
          name: "Электронный архив, месячная подписка (Бронзовая)",
          url: "https://psywealth.com/signup?interval=monthly&plan=bronze",
          referenceQuantity: {
            "@type": "QuantitativeValue",
            value: "1",
            unitCode: "MON",
          },
        },
      },
      {
        "@type": "Offer",
        priceSpecification: {
          "@type": "UnitPriceSpecification",
          price: dataPricing.items[1].price,
          priceCurrency: "RUB",
          name: "Электронный архив, месячная подписка (Серебранная)",
          url: "https://psywealth.com/signup?interval=monthly&plan=silver",
          referenceQuantity: {
            "@type": "QuantitativeValue",
            value: "1",
            unitCode: "MON",
          },
        },
      },
      {
        "@type": "Offer",
        priceSpecification: {
          "@type": "UnitPriceSpecification",
          price: dataPricing.items[2].price,
          priceCurrency: "RUB",
          name: "Электронный архив, месячная подписка (Золотая)",
          url: "https://psywealth.com/signup?interval=monthly&plan=gold",
          referenceQuantity: {
            "@type": "QuantitativeValue",
            value: "1",
            unitCode: "MON",
          },
        },
      },
    ],
  },
  "@graph": [
    {
      "@type": "WebPage",
      "@id": "https://psywealth.com/",
      url: "https://psywealth.com/",
      name: "Простые Изобретения - Российский разработчик систем автоматизации",
      description:
        "Производитель и поставщик систем автоматизации документооборота и архивного дела psywealth.",

      isPartOf: { "@id": "https://psywealth.com/#website" },
      datePublished: "2016-05-10T03:48:21+00:00",
      dateModified: "2020-12-14T09:50:37+00:00",
      breadcrumb: { "@id": "https://psywealth.com/#breadcrumb" },
      inLanguage: "ru-RU",
      potentialAction: [
        { "@type": "ReadAction", target: ["https://psywealth.com/"] },
      ],
    },
  ],
};
