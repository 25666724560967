import { Spinner } from "components/utils/Spinner";
import { dataMessages } from "data";
import { Fragment, useEffect } from "react";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { PageSection, SectionHeader } from "../Home";
import CardDailyVideo from "./CardDailyVideo";
import { useGetDailyVideos } from "./useGetDailyVideos.hook";

// import "./Therapist.scss";

// NOTE : https://prium.github.io/reign-IV/

const SectionContent = (props) => {
  const { data } = props;

  const {
    data: videos,
    isLoading: isLoadingVideos,
    isError: isErrorVideos,
    error: errorVideos,
  } = useGetDailyVideos();

  const isLoading = isLoadingVideos;
  const isError = isErrorVideos;
  const errors = [errorVideos];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (videos && videos.content) {
      _debug("DAILY VIDEOS:", videos.content);
    }
  }, [videos]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isErrorVideos && errorVideos?.status === 204) {
    return <div className="error">Нет курсов</div>;
  }

  return (
    <Fragment>
      <div className="row no-gutters">
        {videos &&
          videos.content.map((item, index) => {
            return <CardDailyVideo data={item} key={index} index={index} />;
          })}
      </div>
    </Fragment>
  );
};

/**
 * Component for showing Section "Daily Video" of the site.
 *
 * @component
 */
const DailyVideosSection = (props) => {
  const { data, id = "daily-videos", cn = "pt-100 pb-100" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />
        <SectionContent data={data} />
      </div>
    </PageSection>
  );
};

export default DailyVideosSection;
