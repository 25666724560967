import { BiAward, BiCreditCard, BiDrink } from "react-icons/bi";

// import Image1 from "assets/icons/feature1-1.svg";
import Image1 from "assets/images/demo/feature1-1.webp";
import Image2 from "assets/images/demo/feature1-2.webp";
import Image3 from "assets/images/demo/feature1-3.webp";
import {
  BsActivity,
  BsAspectRatio,
  BsBrightnessHigh,
  BsCameraVideo,
  BsClipboard2,
  BsEmojiWink,
  BsLayoutTextWindowReverse,
  BsMortarboard,
  BsPencilSquare,
  BsSpeedometer2,
  BsStar,
  BsXDiamond,
} from "react-icons/bs";

export const dataHeroMainPage = {
  title: "Психологическая помощь лично или онлайн",
  subtitle: "Психологическая помощь лично или онлайн",
  description: (
    <>
      Работа с лучшими психологами и психотерапевтами.
      <br />
      Платформа PsyWealth™ надежный способ решения психологических проблем.
    </>
  ),
  // "Онлайн-сервис подбора психологов и психотерапевтов подходящих именно вам",
  //"Хранение документов еще никогда не было таким простым - услуги по организации и ведению электронных архивов",
  items: [
    {
      description: "повысить качество жизни",
    },
    { description: "справиться со стрессом" },
    { description: "наладить отношения с близкими" },
    { description: "найти своё призвание" },
  ],
  image: {
    src: require("assets/images/hero/hero-iphones.webp"),
    alt: "Сервис психологической помощи - «PsyWealth»",
    title: "",
  },
};

const dataOverviewImageAlt = "";
export const dataOverviewMainPage_old = {
  title: "Сервис устроен легко и удобно",
  subtitle: "Это не наши слова — так считают наши пользователи",
  description:
    // "Цифровизация бизнес-процессов и комплекс автоматизированных архивных процедур по использованию и доступу к документации",
    // Типовые решения подготовлены для быстрого внедрения в любую сферу бизнеса и госуправления: банки, госучреждения, коммерческие компании, промыш­ленные предприятия и крупные холдинги повышают операционную эффективность и автоматизируют документационные процессы на платформе ЭЛАР Контекст
    // На основе стандартных решений можно быстро внедрить их в любой сфере деятельности, которая касается бизнеса и госуправления: банки, государственные учреждения, компании с большим количеством сотрудников, крупные предприятия и холдинги могут повысить эффективность своих бизнес-процессов благодаря использованию ЭЛАР Контекст.
    "Это не наши слова — так считают наши пользователи",
  items: [
    {
      title: "Консультации по безопасному видеочату",
      subtitle: "",
      description1:
        "На нашей платформе видеоконсультации проходят в защищенном личном кабинете",
      description2: "",
      icon: null,
      image: {
        src: require("assets/images/feature/features10-1_600x300.webp"),
        alt: "Консультации по безопасному видеочату" || dataOverviewImageAlt,
        title: null,
      },
      list: {
        items: [],
      },
      control: null,
    },
    {
      title: "Простое управление расписанием",
      subtitle: "",
      description1:
        "Назначайте и переносите, мы напомним и не дадим вам забыть",
      description2: "",
      icon: null,
      image: {
        src: require("assets/images/feature/features10-2_600x300.webp"),
        alt: "Простое управление расписанием" || dataOverviewImageAlt,
        title: null,
      },
      list: {
        items: [],
      },
      control: null,
    },
    {
      title: "Сопровождение на всех этапах",
      subtitle: "",
      description1:
        "Ответим на вопросы о психологии, поможем, поддержим, объясним, направим",
      description2: "",
      icon: null,
      image: {
        src: require("assets/images/feature/features10-2_600x300.webp"),
        alt: "Сопровождение на всех этапах" || dataOverviewImageAlt,
        title: null,
      },
      list: {
        items: [],
      },
    },
  ],
};

export const dataOverviewMainPage = {
  title: "Поможем вам стать счастливее",
  subtitle: "Это не наши слова — так считают наши пользователи",
  description:
    // "Цифровизация бизнес-процессов и комплекс автоматизированных архивных процедур по использованию и доступу к документации",
    // Типовые решения подготовлены для быстрого внедрения в любую сферу бизнеса и госуправления: банки, госучреждения, коммерческие компании, промыш­ленные предприятия и крупные холдинги повышают операционную эффективность и автоматизируют документационные процессы на платформе ЭЛАР Контекст
    // На основе стандартных решений можно быстро внедрить их в любой сфере деятельности, которая касается бизнеса и госуправления: банки, государственные учреждения, компании с большим количеством сотрудников, крупные предприятия и холдинги могут повысить эффективность своих бизнес-процессов благодаря использованию ЭЛАР Контекст.
    "Это не наши слова — так считают наши пользователи",
  items: [
    {
      title: "Устроить личную жизнь и разобраться в семейных проблемах",
      subtitle: "",
      // description1: "Устроить личную жизнь и разобраться в семейных проблемах",
      description1: "",
      description2:
        "Часто проблемы в отношениях могут мешать нашему счастью. Наши психологи помогут вам разрешить конфликты, научат эффективному общению и помогут построить крепкие и доверительные отношения с близкими. Наш психолог поможет вам разобраться в своих чувствах и эмоциях, научит эффективным методам управления стрессом и тревогой, поможет обрести внутренний покой и радость от каждого дня.",
      icon: null,
      image: {
        src: require("assets/images/feature/features_demo_1.webp"),
        alt:
          "Устроить личную жизнь и разобраться в семейных проблемах" ||
          dataOverviewImageAlt,
        title: null,
      },
      list: {
        items: [],
      },
      control: null,
    },
    {
      title: "Поднять самооценку и понять, что вам нужно",
      subtitle: "",
      description1: "Найдите свое призвание и цель жизни",
      description2:
        "Наш психолог поможет вам разобраться в своих чувствах и эмоциях, научит эффективным методам управления стрессом и тревогой, поможет обрести внутренний покой и радость от каждого дня.",
      icon: null,
      image: {
        src: require("assets/images/feature/features_demo_2.webp"),
        alt:
          "Поднять самооценку и понять, что вам нужно" || dataOverviewImageAlt,
        title: null,
      },
      list: {
        items: [],
      },
      control: null,
    },
    {
      title: "Избавиться от страхов, панических атак и тревоги",
      subtitle: "",
      description1: "",
      description2:
        "Если вы ищете свое место в этом мире и стремитесь к смыслу жизни, наши психологи помогут вам определить свои ценности, найти свое призвание и построить жизнь, о которой вы мечтали.",
      icon: null,
      image: {
        src: require("assets/images/feature/features_demo_3.webp"),
        alt:
          "Избавиться от страхов, панических атак и тревоги" ||
          dataOverviewImageAlt,
        title: null,
      },
      list: {
        items: [],
      },
    },
    {
      title: "Преодолеть конфликты и наладить отношения с близкими",
      subtitle: "",
      description1: "Преодолеть конфликты и наладить отношения с близкими",
      description2: "",
      icon: null,
      image: {
        src: require("assets/images/feature/features_demo_4.webp"),
        alt:
          "Преодолеть конфликты и наладить отношения с близкими" ||
          dataOverviewImageAlt,
        title: null,
      },
      list: {
        items: [],
      },
    },
    {
      title: "Выйти из депрессиии и обрести спокойствие",
      subtitle: "",
      description1: "",
      description2:
        "Депрессия и страх могут стать серьезными препятствиями на пути к счастью. Наши психологи предложат вам поддержку, помощь в преодолении трудностей и научат эффективным стратегиям борьбы с негативными эмоциями.",
      icon: null,
      image: {
        src: require("assets/images/feature/features_demo_5.webp"),
        alt:
          "Выйти из депрессиии и обрести спокойствие" || dataOverviewImageAlt,
        title: null,
      },
      list: {
        items: [],
      },
    },
  ],
};

export const dataSloganMainPage = {
  title:
    "Большинство наших клиентов чувствуют результат уже после третьей консультации",
  subtitle: "",
  description1:
    "Подберем специалиста и метод терапии, который подойдет именно вам. Специалист поможет поставить цель терапии и приведет к результату",
  description2: "",
};

export const dataOurProductsMainPage = {
  title: "Наши решения и продукты",
  subtitle: "Наши решения и продукты",
  description:
    "Программное обеспечение собственной разработки, внесенное в реестр отечественного ПО",
  image: null,
  imageAlt: "",
  items: [
    {
      title: "Электронный архив",
      image: require("assets/icons/theme-icon13.png"),
      description:
        "Доступное и быстрое решение задач по обработке и хранению корпоративных документов.",
      link: "/archive",
    },
    {
      title: "Модуль поиска данных",
      image: require("assets/icons/theme-icon10.png"),
      description: "",
      link: "/archive",
    },
    {
      title: "Модуль распознавания",
      image: require("assets/icons/theme-icon9.png"),
      description: "",
      link: "/archive",
    },
    {
      title: "Модуль интеграции с 1С",
      image: require("assets/icons/theme-icon2.png"),
      description: "",
      link: "/archive",
    },
    {
      title: "Управление финансовой документацией",
      image: require("assets/icons/theme-icon3.png"),
      description:
        "Система обеспечивает централизованный сбор, хранение и эффективное управление всей бухгалтерской информацией: документами ЭДО и скан-копиями бумажных документов.",
      link: "/archive",
    },
    {
      title: "Управление технической документацией",
      image: require("assets/icons/theme-icon6.png"),
      description:
        "Система увеличивает скорость доступа к технической информации и автоматизирует процессы профильного документооборота.",
      link: "/archive",
    },
    {
      title: "Управление технической документацией",
      image: require("assets/icons/theme-icon7.png"),
      description:
        "Система увеличивает скорость доступа к технической информации и автоматизирует процессы профильного документооборота.",
      link: "/archive",
    },
  ],
};

export const dataServiceMainPage = {
  title: "С чем поможет психолог?",
  subtitle: "С чем поможет психолог?",
  description: "С чем поможет психолог?",
  items: [
    {
      title: null,
      subtitle: null,
      description: "Наладить гармоничные отношения с близкими людьми",
      image: require("assets/images/feature/helps-item-1.webp"),
      imageAlt: "",
      icon: null,
    },

    {
      title: null,
      subtitle: null,
      description: "Справиться со стрессом, тревогой и страхом",
      image: require("assets/images/feature/helps-item-2.webp"),
      imageAlt: "",
      icon: null,
    },

    {
      title: null,
      subtitle: null,
      description: "Принять себя и повысить самооценку",
      image: require("assets/images/feature/helps-item-3.webp"),
      imageAlt: "",
      icon: null,
    },

    {
      title: null,
      subtitle: null,
      description: "Научиться говорить «нет» и отстаивать личные границы",
      image: require("assets/images/feature/helps-item-4.webp"),
      imageAlt: "",
      icon: null,
    },

    {
      title: null,
      subtitle: null,
      description: "Пережить перемены: переезд, расставание, потеря работы",
      image: require("assets/images/feature/helps-item-5.webp"),
      imageAlt: "",
      icon: null,
    },

    {
      title: null,
      subtitle: null,
      description: "Понять, почему на работе проблемы и нет мотивации",
      image: require("assets/images/feature/helps-item-6.webp"),
      imageAlt: "",
      icon: null,
    },
  ],
};

export const dataTopCoursesMainPage = {
  title: "Обучение и развитие",
  subtitle: "Обучение и развитие",
  description: "Проходите дополнительные курсы, чтобы закрепить результат",
  items: [],
};

export const dataTopPsychologistsMainPage = {
  title: "Наши психологи",
  subtitle: "Наши психологи",
  description: "Выберите психолога с которым комфортно вам",
  items: [],
};

export const dataTopAdviceMainPage = {
  title: "Актуальная тема",
  subtitle: "Актуальная тема",
  description: "Каждый месяц мы говорим о самом актуальном для вас",
  items: [],
};

export const dataPsySelection = {
  title: "Как подобрать психолога на сайте?",
  subtitle: "Как подобрать психолога на сайте?",
  description: "Как подобрать психолога на сайте?",
  items: [
    {
      title: "Что бы вы хотели обсудить?",
      subtitle: "",
      description:
        "Подберите интересующую вас тему, сформулируйте запрос или укажите проблемную область",
      image: require("assets/images/app/app-1.webp"),
    },
    {
      title: "Заполните анкету",
      subtitle: "",
      description:
        "Ответите на вопросы о вашем состоянии в анкете и укажете предпочтения к психологу",
      image: require("assets/images/app/app-2.webp"),
    },
    {
      title: "Мы подберем вам психолога",
      subtitle: "",
      description:
        "Сервис подберет психолога из сотни профессионалов, после чего ваш будущий специалист ознакомится с анкетой",
      image: require("assets/images/app/app-2.webp"),
    },
    {
      title: "Когда вам удобно?",
      subtitle: "",
      description: "Выбирайте время, для удобной встречи с психологом",
      image: require("assets/images/app/app-3.webp"),
    },
    {
      title: "Оформите подписку и начните терапию",
      subtitle: "",
      description:
        "Специалист изучит вашу анкету и сам свяжется с вами, чтобы договориться о времени первой сессии",
      image: require("assets/images/app/app-3.webp"),
    },
  ],
};

export const dataTestimonialMainPage = {
  title: "Что говорят наши клиенты?",
  subtitle: "Отзывы",
  description: "",
  items: [
    {
      title: "Лукьянов Андрей",
      subtitle: "Коммерческий директор ООО «АСТРА ТЕХНОЛОГИИ»",
      description:
        "Для нашей задачи создания электронной библиотеки организации ELARCH подошел почти идеально. Хорошо, что продукт постоянно прогрессирует и радует новыми возможностями.",
      image: require("assets/images/testimonial/testimonial-1-150x150.webp"),
    },
    {
      title: "Ломакин Александр",
      subtitle: "Директор ООО “Скан Солюшн”",
      description:
        "ELARCH оказался для наших проектов по организации удалённой обработки большого количества документов единственным подходящим решением. Подобного решения раньше на рынке просто не было.",
      image: require("assets/images/testimonial/testimonial-2-150x150.webp"),
    },
  ],
};

export const dataFeatureMainPage = {
  title: "Форматы психотерапии",
  subtitle: "Форматы психотерапии",
  description: "Форматы психотерапии",
  items: [
    {
      title: "Для себя",
      subtitle: "Один на один",
      description:
        "Индивидуальная психотерапия, где участвуют двое: клиент и психолог",
      icon: null,

      image: {
        src: Image1,
        alt: "Простое управление расписанием" || dataOverviewImageAlt,
        title: null,
        props: { width: "300", height: "300" },
      },
    },
    {
      title: "Для пары",
      subtitle: "Парный",
      description:
        "К психологу обращаются партнеры, чтобы разобраться в отношениях друг с другом",
      icon: null,
      image: {
        src: Image2,
        alt: "Простое управление расписанием" || dataOverviewImageAlt,
        title: null,
        props: { width: "300", height: "300" },
      },
    },
    {
      title: "Для ребенка",
      subtitle: "Вместе с ребенком",
      description: "Психолог работает с ребенком и взрослым родственником",
      icon: null,
      image: {
        src: Image3,
        alt: "Простое управление расписанием" || dataOverviewImageAlt,
        title: null,
        props: { width: "300", height: "300" },
      },
    },
  ],
};

export const dataFeature2MainPage = {
  title: "Психотерапевты для занятий лично или онлайн",
  subtitle: "Психотерапевты для занятий лично или онлайн",
  description: "Психотерапевты для занятий лично или онлайн",
  items: [
    {
      title: "Для себя",
      subtitle: "Один на один",
      description:
        "Индивидуальная психотерапия, где участвуют двое: клиент и психолог",
      icon: null,

      image: {
        src: Image1,
        alt: "Простое управление расписанием" || dataOverviewImageAlt,
        title: null,
        props: { width: "300", height: "300" },
      },
    },
    {
      title: "Для пары",
      subtitle: "Парный",
      description:
        "К психологу обращаются партнеры, чтобы разобраться в отношениях друг с другом",
      icon: null,
      image: {
        src: Image2,
        alt: "Простое управление расписанием" || dataOverviewImageAlt,
        title: null,
        props: { width: "300", height: "300" },
      },
    },
    {
      title: "Для ребенка",
      subtitle: "Вместе с ребенком",
      description: "Психолог работает с ребенком и взрослым родственником",
      icon: null,
      image: {
        src: Image3,
        alt: "Простое управление расписанием" || dataOverviewImageAlt,
        title: null,
        props: { width: "300", height: "300" },
      },
    },
  ],
};

export const dataAltFeatureMainPage = {
  title: "Как мы отбираем психологов?",
  subtitle: "Как мы отбираем психологов?",
  // description: "Только 13% психологов проходят отбор в команду",
  description:
    "Мы уже проверили их образование, опыт, часы супервизии и этичность. Вам осталось только выбрать понравившегося!",
  items: [
    {
      title: "Образование",
      subtitle: "",
      description:
        "Проверяем дипломы: высшее психологическое, психиатрическое или переподготовка",
      icon: <BiAward />,
      image: { src: null, alt: "", title: "" },
    },
    {
      title: "Опыт работы",
      subtitle: "",
      description:
        "Рассматриваем специалистов с подтверждённой практикой от трёх лет",
      icon: <BiCreditCard />,
      image: { src: null, alt: "", title: "" },
    },
    {
      title: "Рекомендации",
      subtitle: "",
      description:
        "Просим предоставить рекомендацию от супервизора, старших коллег или психологической ассоциации",
      icon: <BiDrink />,
      image: { src: null, alt: "", title: "" },
    },
    {
      title: "Собеседование",
      subtitle: "",
      description:
        "Разбираем успешные кейсы и проверяем навыки прямо на вступительном интервью",
      icon: <BiAward />,
      image: { src: null, alt: "", title: "" },
    },
    {
      title: "Этический кодекс",
      subtitle: "",
      description:
        "Смотрим, разделяет ли кандидат наши ценности. Профессиональный психолог не оценивает и не осуждает",
      icon: <BiAward />,
      image: { src: null, alt: "", title: "" },
    },
    {
      title: "Обучение",
      subtitle: "",
      description:
        "Развиваем психологов через семинары и супервизии. Даже профессионалам важно учиться",
      icon: <BiAward />,
      image: { src: null, alt: "", title: "" },
    },
  ],
};

export const dataCountUpMainPage = {
  title: "Какой вид психотерапии вы ищете?",
  subtitle: "Какой вид психотерапии вы ищете?",
  description: "Какой вид психотерапии вы ищете?",
  items: [
    {
      title: "100000",
      subtitle: "",
      //description: "человек нашли своего психолога онлайн",
      description: "Человек получили поддержку",
      suffix: "",
      prefix: "",
      icon: null,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "400",
      subtitle: "",
      // description: "проверенных специалистов",
      description: "Квалифицированных специалистов",
      suffix: " +",
      icon: null,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "7",
      subtitle: "",
      //description: "средний опыт практики",
      description: "Средний опыт работы и практики",
      suffix: " лет",
      icon: null,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "80",
      subtitle: "",
      //description: "клиентов чувствуют результат после 5-й сессии",
      description: "Клиентов чувствуют результат после 3-й сессии",
      suffix: " %",
      icon: null,
      image: {
        src: null,
        imageAlt: "",
      },
    },
  ],
};

export const dataAboutMainPage = {
  title: "Ждём от вас",
  subtitle: "Ждём от вас",
  description:
    "Оперативное и архивное хранение электронных документов, их систематизация и поиск, а также контроль доступа к корпоративному контенту",
  image: {
    src: require("assets/images/feature/about.webp"),
    alt: "",
    title: "",
  },
};

export const dataOurAdvantagesMainPage = {
  title: "Что делает психотерапию с нами удобной?",
  subtitle: "Что делает психотерапию с нами удобной?",
  description:
    "Мы взяли все организационные заботы на себя, чтобы вы могли сосредоточиться на самом важном — себе и своих эмоциях",
  image: {
    src: require("assets/images/feature/about.webp"),
    alt: "",
    title: "",
  },
  items: [
    {
      title: "Рекомендуем профессионалов в которых уверены",
      subtitle: "",
      description:
        "Вам не нужно беспокоиться о квалификации специалиста. Мы всё проверили.",
      suffix: "",
      prefix: "",
      icon: <BsAspectRatio />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Помогаем найти именно «вашего» специалиста",
      subtitle: "",
      description:
        "Если психолог не подойдёт по любым причинам, мы предложим другого.",
      suffix: "",
      prefix: "",
      icon: <BsEmojiWink />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Заботимся о результате",
      subtitle: "",
      description:
        "Напоминаем о сессиях, регулярно собираем отзывы и приходим на помощь, если что-то идёт не так.",
      suffix: "",
      prefix: "",
      icon: <BsLayoutTextWindowReverse />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Гарантируем понятную фиксированную цену",
      subtitle: "",
      description:
        "Даже у психологов с похожим опытом цены за сессию могут различаться в несколько раз. У нас такого не бывает: в сервисе единая и справедливая цена.",
      suffix: "",
      prefix: "",
      icon: <BsPencilSquare />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: " Делаем оплату сессий удобной",
      subtitle: "",
      description:
        "Деньги за сессию списываются автоматически, но специалист получает их только после встречи. Если психолог отменит консультацию, деньги вернутся на вашу карту.",
      suffix: "",
      prefix: "",
      icon: <BsSpeedometer2 />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Бережём ваше время",
      subtitle: "",
      description:
        "Занимайтесь, где и когда вам удобно. Перенести или отменить сессию можно в личном кабинете в два клика.",
      suffix: "",
      prefix: "",
      icon: <BsClipboard2 />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
  ],
};

export const dataOurAdvantagesVideoMainPage = {
  title: "Что делает психотерапию с нами удобной?",
  subtitle: "Что делает психотерапию с нами удобной?",
  description:
    "Мы взяли все организационные заботы на себя, чтобы вы могли сосредоточиться на самом важном — себе и своих эмоциях",
  items: [],
};

export const dataBlogMainPage = {
  title: "Актуальные новости и события",
  subtitle: "Актуальные новости и события",
  description:
    "Каждый год мы проводим десятки вебинаров и семинаров, участвуем в отраслевых конференциях с нашими партнёрами и проводим собственные. Присоединяйтесь!",
};

export const dataStartTabsMainPage = {
  title: "Как начать психотерапию",
  subtitle: "Как начать психотерапию",
  description: "",
  items: [
    {
      id: 1,
      title: "1. Заполните анкету",
      subtitle: "",
      description:
        "Расскажите, что вас беспокоит и с каким специалистом хотите работать. Например, можно указать определённый возраст специалиста и выбрать удобное время для сессии.",
      icon: null,
      image: {
        src: require("assets/images/tabs/tabs-1.webp"),
        imageAlt: "",
      },
    },
    {
      id: 2,
      title: "2. Выберите специалиста",
      subtitle: "",
      description:
        "Умный алгоритм предложит специалистов, которые работают с вашим запросом и подходят по пожеланиям.",
      icon: null,
      image: {
        src: require("assets/images/tabs/tabs-2.webp"),
        imageAlt: "",
      },
    },
    {
      id: 3,
      title: "3. Начните психотерапию",
      subtitle: "",
      description:
        "Выберите дату и время первой сессии. Мы пришлём напоминание и расскажем в письме, как подготовиться к первой встрече.",
      icon: null,
      image: {
        src: require("assets/images/tabs/tabs-3.webp"),
        imageAlt: "",
      },
    },
    {
      id: 4,
      title: "4. Следите за процессом",
      subtitle: "",
      description:
        "Выберите дату и время первой сессии. Мы пришлём напоминание и расскажем в письме, как подготовиться к первой встрече.",
      icon: null,
      image: {
        src: require("assets/images/tabs/tabs-3.webp"),
        imageAlt: "",
      },
    },
    {
      id: 5,
      title: "6. Общайтесь через приложение",
      subtitle: "",
      description:
        "Выберите дату и время первой сессии. Мы пришлём напоминание и расскажем в письме, как подготовиться к первой встрече.",
      icon: null,
      image: {
        src: require("assets/images/tabs/tabs-3.webp"),
        imageAlt: "",
      },
    },
  ],
};

export const dataHelpDesk = {
  title: "Нужна помощь?",
  subtitle: "Подберем для вас психолога вручную бесплатно",
  description:
    "Если вам сложно выбрать психолога, разобраться в подходах и сформулировать первичный запрос самостоятельно — мы поможем!",
  descriptionHTML:
    "<p>Если вам сложно выбрать психолога, разобраться в подходах и сформулировать первичный запрос самостоятельно — мы поможем!</p>",
  items: [],
};

export const dataMobileAppMainPage = {
  title: "А вот что PsyWealth сделает за вас",
  subtitle: "А вот что PsyWealth сделает за вас",
  subtitle2: (
    <>
      This is a text in the first line;
      <br />
      this is a text in a second line
    </>
  ),
  description:
    "Мы взяли все организационные заботы на себя, чтобы вы могли сосредоточиться на самом важном — себе и своих эмоциях",
  image: {
    src: require("assets/images/feature/iphone14-2.webp"),
    alt: "",
    title: "",
  },
  items: [
    {
      title: "Запомнит все о клиентах",
      subtitle: "",
      description: "Запомнит все о клиентах",
      suffix: "",
      prefix: "",
      icon: <BsAspectRatio />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Запомнит расписание",
      subtitle: "",
      description: "Запомнит расписание",
      suffix: "",
      prefix: "",
      icon: <BsEmojiWink />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Проконтролирует длительность сеансов",
      subtitle: "",
      description: "Проконтролирует длительность сеансов",
      suffix: "",
      prefix: "",
      icon: <BsMortarboard />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Сохранит информацию о сеансах",
      subtitle: "",
      description: "Сохранит информацию о сеансах",
      suffix: "",
      prefix: "",
      icon: <BsStar />,
      image: {
        src: null,
        imageAlt: "",
      },
    },

    {
      title: "Проконтролирует длительность сеансов",
      subtitle: "",
      description: "Проконтролирует длительность сеансов",
      suffix: "",
      prefix: "",
      icon: <BsXDiamond />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Сохранит информацию о сеансах",
      subtitle: "",
      description: "Сохранит информацию о сеансах",
      suffix: "",
      prefix: "",
      icon: <BsCameraVideo />,
      image: {
        src: null,
        imageAlt: "",
      },
    },

    {
      title: " Делаем оплату сессий удобной",
      subtitle: "",
      description:
        "Деньги за сессию списываются автоматически, но специалист получает их только после встречи. Если психолог отменит консультацию, деньги вернутся на вашу карту.",
      suffix: "",
      prefix: "",
      icon: <BsBrightnessHigh />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Бережём ваше время",
      subtitle: "",
      description:
        "Занимайтесь, где и когда вам удобно. Перенести или отменить сессию можно в личном кабинете в два клика.",
      suffix: "",
      prefix: "",
      icon: <BsActivity />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
  ],
};
