import { Spinner } from "components/utils/Spinner";
import { Fragment, useEffect } from "react";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { PageSection, SectionHeader } from "../Home";
import { useGetTherapistList } from "./utils/useGetTherapistList.hook";

import { dataMessages } from "data";
import CardTherapist from "./CardTherapist";
import "./Therapist.style.scss";

// NOTE : https://epicbootstrap.com/snippets/team-cards
// NOTE : https://codesandbox.io/p/sandbox/load-more-functionality-using-react-hooks-kllwg?file=%2Fsrc%2FList.jsx%3A54%2C7-54%2C24

const SectionContent = (props) => {
  const { data } = props;

  const {
    data: therapists,
    isLoading: isLoadingTherapistsList,
    isError: isErrorTherapistsList,
    error: errorTherapistsList,
  } = useGetTherapistList();

  const isLoading = isLoadingTherapistsList;
  const isError = isErrorTherapistsList;
  const errors = [errorTherapistsList];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (therapists && therapists.content) {
      _debug("THERAPISTS:", therapists.content);
    }
  }, [therapists]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isErrorTherapistsList && errorTherapistsList?.status === 204) {
    return <div className="error">Нет психологов</div>;
  }

  return (
    <Fragment>
      <div className="row">
        {therapists &&
          therapists.content.map((item, index) => {
            return (
              <Fragment>
                <div className="col-lg-4 col-md-6 col-12 item">
                  <CardTherapist
                    data={item}
                    index={index}
                    key={index}
                    type="style2"
                  />
                </div>
                {/*<div className="col-lg-4 col-md-6 col-12 item">
                  <CardTherapist
                    data={item}
                    index={index}
                    key={index}
                    type="style1"
                  />
                </div>*/}
              </Fragment>
            );
          })}
      </div>
    </Fragment>
  );
};

/**
 * Component for showing Section "Therapist List" of the site.
 *
 * @component
 **/
const TherapistList = (props) => {
  const { data, id = "therapist-list", cn = "pt-100 pb-100" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />
        <SectionContent data={data.items} />
      </div>
    </PageSection>
  );
};

export default TherapistList;
