import classnames from "classnames";
import { CallTo } from "components/utils/Utils";
import { Fragment } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { PageSection, SectionHeader } from "../Home";

import "./Contacts.style.scss";

// NOTE :

const SectionContentItem = (props) => {
  const { data } = props;

  return (
    <div className="row">
      <div className="col-lg-6 col-md-6 col-12 mb-4 mb-lg-3">
        <div className="custom-block bg-white shadow-lg">
          <a href="topics-detail.html">
            <div className="d-flex">
              <div>
                <h5 className="mb-2">Graduation</h5>

                <p className="mb-0">Lorem Ipsum dolor sit amet consectetur</p>
              </div>

              <span className="badge bg-education rounded-pill ms-auto">
                80
              </span>
            </div>

            <img
              src="images/topics/undraw_Graduation_re_gthn.png"
              className="custom-block-image img-fluid"
              alt=""
            />
          </a>
        </div>
      </div>

      <div className="col-lg-6 col-md-6 col-12">
        <div className="custom-block bg-white shadow-lg">
          <a href="topics-detail.html">
            <div className="d-flex">
              <div>
                <h5 className="mb-2">Educator</h5>

                <p className="mb-0">Lorem Ipsum dolor sit amet consectetur</p>
              </div>

              <span className="badge bg-education rounded-pill ms-auto">
                75
              </span>
            </div>

            <img
              src="images/topics/undraw_Educator_re_ju47.png"
              className="custom-block-image img-fluid"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  );
};

const SectionContentItem2 = (props) => {
  const { data } = props;

  return (
    <div className="row">
      {data.items.map((item, index) => {
        return (
          <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-3">
            <div className="custom-block bg-white shadow-lg">
              <div className="d-flex">
                <div>
                  <h5 className="mb-2">{item.title}</h5>
                  <p className="mb-2">{item.description}</p>
                  {item.contacts && (
                    <p className={classnames(item.contacts2 ? "mb-2" : "mb-0")}>
                      <CallTo phone={item.contacts}>{item.contacts}</CallTo>
                    </p>
                  )}
                  {item.contacts2 && (
                    <p className="mb-0">
                      <a href={"tel:" + item.contacts2}>{item.contacts2}</a>
                    </p>
                  )}
                </div>
              </div>
              {item.image && item.image.src && (
                <img
                  src={item.image.src}
                  alt={item.image.alt}
                  className="custom-block-image img-fluid"
                />
              )}
            </div>
          </div>
        );
      })}

      {/*
      <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-3">
        <div className="custom-block bg-white shadow-lg">
          <a href="topics-detail.html">
            <div className="d-flex">
              <div>
                <h5 className="mb-2">Video Content</h5>

                <p className="mb-0">Lorem Ipsum dolor sit amet consectetur</p>
              </div>

              <span className="badge bg-advertising rounded-pill ms-auto">65</span>
            </div>

            <img
              src="images/topics/undraw_Group_video_re_btu7.png"
              className="custom-block-image img-fluid"
              alt=""
            />
          </a>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-3">
        <div className="custom-block bg-white shadow-lg">
          <a href="topics-detail.html">
            <div className="d-flex">
              <div>
                <h5 className="mb-2">Viral Tweet</h5>

                <p className="mb-0">Lorem Ipsum dolor sit amet consectetur</p>
              </div>

              <span className="badge bg-advertising rounded-pill ms-auto">50</span>
            </div>

            <img
              src="images/topics/undraw_viral_tweet_gndb.png"
              className="custom-block-image img-fluid"
              alt=""
            />
          </a>
        </div>
      </div>
      */}
    </div>
  );
};

const SectionContent = (props) => {
  const { data, id = "justify-tab-example" } = props;

  const defaultActiveKey = data.items[0].key || "main";

  return (
    <Fragment>
      <Tabs
        defaultActiveKey={defaultActiveKey}
        id={id}
        className="mb-5"
        justify
      >
        {data.items.map((item, index) => {
          return (
            <Tab
              eventKey={item.key}
              title={item.title}
              disabled={!item.available}
            >
              <SectionContentItem2 data={item} />
            </Tab>
          );
        })}

        {/* <Tab eventKey="profile" title="Profile">
          <SectionContentItem />
        </Tab>
        <Tab eventKey="longer-tab" title="Loooonger Tab">
          Tab content for Loooonger Tab
        </Tab>
        <Tab eventKey="contact" title="Contact" disabled>
          Tab content for Contact
        </Tab>*/}
      </Tabs>
    </Fragment>
  );
};

/**
 * Component for showing Section "Contacts" of the site.
 *
 * @component
 */
const ContactsSection = (props) => {
  const { data, id = "lesson", cn = "pt-100 pb-100" } = props;

  console.log(data);

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader
          data={{ ...data }}
          showSubtitle={false}
          showDescription={false}
        />
        <SectionContent data={{ ...data }} />
      </div>
    </PageSection>
  );
};

export default ContactsSection;
