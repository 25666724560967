import {
  CustomFormInputDate,
  CustomFormInputText2,
  CustomFormInputTextarea,
} from "components/pages/Customer/components/core/FormInput/FormInput";
import { Form as BSForm, Button, Col, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

/**
 * Component for showing Orders table.
 *
 * @component
 */
const EducationModalForm = (props) => {
  const {
    showModal,
    handleClose,
    modalFormData = {},
    setModalFormData,
  } = props;

  // console.log(props);

  const {
    methods,
    register,
    control,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      title: modalFormData?.title,
      name: modalFormData?.name,
      startDate: modalFormData?.startDate,
      endDate: modalFormData?.endDate,
    },
  });

  // useEffect(() => {
  //   reset({
  //     mail,
  //   });
  // }, [reset, mail]);

  const onSubmit = (data) => {
    console.log("SUBMIT:", data);
    // setModalFormData(data);
    handleClose(data);
  };
  const onError = (error) => {
    console.log("ERROR:::", error);
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Modal heading</Modal.Title>
      </Modal.Header>
      <form className="" onSubmit={handleFormSubmit(onSubmit, onError)}>
        <Modal.Body>
          <BSForm.Group className="mb-3" controlId="formTitle">
            <CustomFormInputText2
              label="Title"
              required
              error={errors.title}
              {...register("title", { required: "Correo es obligatorio" })}
            />
          </BSForm.Group>

          <BSForm.Group className="mb-3" controlId="formName">
            <CustomFormInputText2
              label="Name"
              required
              error={errors.name}
              {...register("name", { required: "Correo es obligatorio" })}
            />
          </BSForm.Group>

          <Row className="mb-3">
            <BSForm.Group className="" as={Col} controlId="formStartDate">
              <CustomFormInputDate
                control={control}
                name="startDate"
                label="Start date"
                required
                error={errors.startDate}
                defaultValue={modalFormData?.startDate}
              />
            </BSForm.Group>

            <BSForm.Group className="" as={Col} controlId="formEndDate">
              <CustomFormInputDate
                control={control}
                name="endDate"
                label="End date"
                required
                error={errors.endDate}
                defaultValue={modalFormData?.endDate}
              />
            </BSForm.Group>
          </Row>

          <BSForm.Group className="mb-3" controlId="formDescription">
            <CustomFormInputTextarea
              label="Description"
              placeholder="Enter description"
              rows="3"
              spellCheck={false}
              defaultValue={modalFormData?.description}
              error={errors?.description}
              {...register("description", {
                maxLength: 1024,
                pattern: /[A-Za-z]{3}/,
              })}
            />
          </BSForm.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EducationModalForm;
