// import Image1 from "assets/images/free-help/home_violence.svg";

import {
  BsAspectRatio,
  BsClipboard2,
  BsEmojiWink,
  BsLayoutTextWindowReverse,
  BsPencilSquare,
  BsSpeedometer2,
} from "react-icons/bs";

export const dataHeroFreeHelpPage = {
  title: "Бесплатная психологическая помощь",
  subtitle: "Бесплатная психологическая помощь",
  description: (
    <>
      Консультация психолога может понадобиться каждому.
      <br /> Если у вас нет возможности обратиться к психологу за деньги,
      существуют специализированные центры, где любой человек может получить
      бесплатную психологическую помощь.
    </>
  ),
  // "Онлайн-сервис подбора психологов и психотерапевтов подходящих именно вам",
  //"Хранение документов еще никогда не было таким простым - услуги по организации и ведению электронных архивов",
  items: [
    {
      description: "повысить качество жизни",
    },
    { description: "справиться со стрессом" },
    { description: "наладить отношения с близкими" },
    { description: "найти своё призвание" },
  ],
  image: {
    src: require("assets/images/hero/hero-iphones.webp"),
    alt: "Сервис психологической помощи - «PsyWealth»",
    title: "",
  },
};

export const dataContactsFreeHelpPage = {
  title: "Экстренная помощь и телефоны доверия",
  subtitle: "Экстренная помощь и телефоны доверия",
  description: "",
  image: {
    src: null,
    alt: "",
    title: "",
  },
  items: [
    {
      id: 1,
      key: "region_rus",
      available: true,
      title: "Россия",
      items: [
        {
          title: "Всероссийская служба детского телефона доверия",
          subtitle: "",
          description: "круглосуточно, анонимно",
          contacts: "+7 (800) 200-01-22",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title: "Бесплатная кризисная линия доверия по России",
          subtitle: "",
          description: "круглосуточно",
          contacts: "+7 (800) 333-44-34",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title:
            "Федеральная горячая линия по вопросам наркомании и алкозависимости",
          subtitle: "",
          description: "бесплатно, круглосуточно",
          contacts: "+7 (800) 700-50-50",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title:
            "Горячая линия по вопросам лечения наркотической, алкогольной и др. зависимостей «Точка трезвости»",
          subtitle: "",
          description: "бесплатно",
          contacts: "+7 (800) 200-32-02",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },

        {
          title:
            "Всероссийский бесплатный телефон доверия для женщин, подвергшихся домашнему насилию",
          subtitle: "",
          description: "по будням 7:00–21:00",
          contacts: "+7 (800) 700-06-00",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title: "Российский Красный Крест, горячая линия:",
          subtitle: "",
          description: "",
          contacts: "+7 (800) 250-18-59",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title:
            "Горячая линия социально-психологической помощи детям и семьям мигрантов, находящимся в трудной жизненной ситуации",
          subtitle: "",
          description: "",
          contacts: "+7 (499) 201-59-47",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
      ],
    },
    {
      id: 2,
      key: "region_mos",
      available: true,
      title: "Москва",
      items: [
        {
          title: "Экстренная психологическая служба помощи при МЧС",
          subtitle: "",
          description: "круглосуточно",
          contacts: "+7 (495) 989–50-50",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title:
            "Бесплатная экстренная психологическая помощь «Ярославна» при Институте Практической Психологии и Психоанализа",
          subtitle: "",
          description: "",
          contacts: "+7 (495) 120-16-60",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title: "Бесплатная кризисная линия доверия по Москве",
          subtitle: "",
          description: "круглосуточно",
          contacts: "+7 (495) 988-44-34",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title:
            "Экстренная медико-психологическая помощь в кризисных ситуациях города Москвы",
          subtitle: "",
          description: "",
          contacts: "+7 (499) 791-20-50",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title: "Линия столичного Кризисного центра помощи женщинам и детям",
          subtitle: "",
          description: "",
          contacts: "+7 (499) 977‑20‑10",
          contacts2: "+7 (499) 492-46-89",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title:
            "Телефон доверия для детей и родителей от Научно-практического Центра экстренной психологической помощи МГППУ",
          subtitle: "",
          description: "",
          contacts: "+7 (499) 795-15-01",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title:
            "Городской психолого-педагогический центр, телефон доверия для детей и родителей",
          subtitle: "",
          description: "",
          contacts: "+7 (800) 250-11-91",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title:
            "Горячая линия независимого благотворительного центра помощи пережившим сексуальное насилие «Сестры»",
          subtitle: "",
          description: "",
          contacts: "+7 (499) 901-02-01",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title: "Телефон доверия по вопросам наркомании, ВИЧ/СПИД",
          subtitle: "",
          description: "",
          contacts: "+7 (495) 421-55-55",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
      ],
    },
    {
      id: 3,
      key: "region_spb",
      available: true,
      title: "Санкт-Петербург",
      items: [
        {
          title: "Кризисная служба «Душевный разговор»",
          subtitle: "",
          description: "",
          contacts: "+7 812 246-27-80",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title:
            "Телефон экстренной психологической помощи для детей и взрослых",
          subtitle: "",
          description: "",
          contacts: "+7 (812) 708-40-41",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title: "Телефон доверия для детей, подростков и родителей",
          subtitle: "",
          description: "",
          contacts: "+7 (812) 576-10-10",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title: "Кризисный центр для женщин",
          subtitle: "",
          description: "",
          contacts: "+7 (812) 327-30-00",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
      ],
    },
    {
      id: 4,
      key: "region_other",
      available: true,
      title: "Другие города",
      items: [
        {
          title: "Казань, городские телефоны доверия, круглосуточно",
          subtitle: "",
          description: "",
          contacts: "+7 (843) 571-35-71",
          contacts2: "+7 (843) 277-00-00",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title: "Ростов, городские телефоны доверия",
          subtitle: "",
          description: "",
          contacts: "+7 (863) 267-93-04",
          contacts2: "+7 (863) 223-41-41",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title:
            "Новосибирск, городская служба экстренной психологической помощи",
          subtitle: "",
          description: "14:00–08:00",
          contacts: "+7 (383) 204-90-95",
          contacts2: "",
          src: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
      ],
    },
  ],
};

export const dataConsultationsFreeHelpPage = {
  title: "Бесплатные психологические консультации",
  subtitle: "Бесплатные психологические консультации",
  description: "",
  image: {
    src: null,
    alt: "",
    title: "",
  },
  items: [
    {
      id: 1,
      key: "region_rus",
      available: true,
      title: "Россия",
      items: [
        {
          title: "Единый круглосуточный телефон доверия для взрослых и детей",
          subtitle: "",
          description: "",
          contacts: "+7 (800) 100-49-94",
          contacts2: "",
          icon: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title: "Бесплатная кризисная линия телефона доверия по России",
          subtitle: "",
          description: "круглосуточно",
          contacts: "+7 (800) 333-44-34",
          contacts2: "",
          icon: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title: "Всероссийская служба детского телефона доверия",
          subtitle: "",
          description: "круглосуточно и анонимно",
          contacts: "+7 (800) 200-01-22",
          contacts2: "",
          icon: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title:
            "Поддержка онкологических больных и их близких «Ясное утро», круглосуточно:",
          subtitle: "",
          description: "круглосуточно",
          contacts: "+7 (800) 100-01-91",
          contacts2: "",
          icon: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
      ],
    },
    {
      id: 2,
      key: "region_mos",
      available: true,
      title: "Москва",
      items: [
        {
          title: "Московская служба психологической помощи населению",
          subtitle: "",
          description: "",
          contacts: "+7 (499) 173-09-09",
          contacts2: "051",
          icon: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
      ],
    },
    {
      id: 3,
      key: "region_spb",
      available: true,
      title: "Санкт-Петербург",
      items: [
        {
          title:
            "Телефон доверия Городской психиатрической больницы № 7 им. академика И. П. Павлова («Клиника неврозов»)",
          subtitle: "",
          description: "круглосуточно",
          contacts: "+7 (812) 323-43-43",
          contacts2: "",
          icon: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title:
            "Запись на консультацию к психиатру Городской психиатрической больницы № 7 им. академика И. П. Павлова («Клиника неврозов»)",
          subtitle: "",
          description: "",
          contacts: "+7 (812) 242-38-98",
          contacts2: "",
          icon: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title:
            "Запись на консультацию к психотерапевту Городской психиатрической больницы № 7 им. академика И. П. Павлова («Клиника неврозов»)",
          subtitle: "",
          description: "",
          contacts: "+7 (812) 246-21-80",
          contacts2: "",
          icon: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title: "Телефон психотерапевтического кабинета",
          subtitle: "",
          description: "",
          contacts: "+7 (812) 242-38-98",
          contacts2: "",
          icon: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title:
            "Городской мониторинговый центр, бесплатная психологическая помощь для детей и подростков",
          subtitle: "",
          description: "",
          contacts: "004",
          contacts2: "",
          icon: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title:
            "Психологическая помощь в Ленинградской области (круглосуточно, бесплатно, психотерапевты, мед.психологи, врачи-психиатры)",
          subtitle: "",
          description: "",
          contacts: "+7 (800) 200-47-03",
          contacts2: "",
          icon: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
      ],
    },
    {
      id: 4,
      key: "region_other",
      available: true,
      title: "Другие города",
      items: [
        {
          title: "Казань, медицинская психологическая служба «Сердэш 129»",
          subtitle: "",
          description:
            "запись на приём к психологу или дистанционная поддержка по телефону",
          contacts: "+7 (843) 279-55-80",
          contacts2: "",
          icon: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title: "Воронеж, бесплатная психологическая поддержка",
          subtitle: "",
          description: "9:00–21:00",
          contacts: "+7 (473) 207-06-27",
          contacts2: "",
          icon: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
        {
          title: "Челябинск, «Кризисный центр»:",
          subtitle: "",
          description: "",
          contacts: "+7 (351) 735-02-14",
          contacts2: "",
          icon: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
      ],
    },
    {
      id: 5,
      key: "region_online",
      available: true,
      title: "Онлайн",
      items: [
        {
          title: "Московская служба психологической помощи населению",
          subtitle: "",
          description:
            "запись на приём к психологу или дистанционная поддержка по телефону",
          contacts: "+7 (843) 279-55-80",
          contacts2: "",
          icon: null,
          image: {
            src: null,
            alt: "",
            title: "",
          },
        },
      ],
    },
  ],
};

export const dataFeature1FreeHelpPage = {
  title: "Экстренная помощь и телефоны доверия",
  subtitle: "Когда нет времени ждать",
  description:
    // "Мы делимся способами, как получить бесплатную квалифицированную психологическую помощь. Помните: просить о поддержке — это нормально и не имеет ничего постыдного. Исследования показывают, что бесплатная психотерапия может быть столь же эффективной, как и платная."
    "Экстренная психологическая помощь может понадобиться, когда человек оказывается в непредвиденной кризисной ситуации — переживает сильное эмоциональное потрясение, шок, не может справиться с эмоциями или думает о суициде. В таких ситуациях нет возможности планировать визит заранее, подбирать специалиста, ждать даты сессии — квалифицированная помощь психолога нужна немедленно. Для оперативного решения вопроса можно обратиться за консультацией в службы экстренной помощи или позвонить по круглосуточному телефону доверия. Мы собрали номера телефонов и онлайн-чаты экстренных служб поддержки в России.",
  descriptionHTML: (
    <>
      <p className="mb-3">
        Бывают ситуации, когда нет времени ждать консультации психолога — и
        помощь нужна срочно.
      </p>
      <p className="mb-3">
        Нет возможности получить платную психотерапию, но в жизни есть что‑то, с
        чем сложно справиться в одиночку.
      </p>
      <p className="mb-2">
        Мы делимся способами, как получить бесплатную квалифицированную
        психологическую помощь.
      </p>
    </>
  ),
  image: {
    src: null,
    alt: "",
    title: "",
  },
  items: [],
};

export const dataFeature2FreeHelpPage = {
  title: "Экстренная помощь и телефоны доверия",
  subtitle: "Экстренная помощь и телефоны доверия",
  description: "",

  image: {
    src: null,
    alt: "",
    title: "",
  },
  items: [],
};

export const dataCasesOfUseFreeHelpPage = {
  title: "Нет времени ждать консультации психолога — и помощь нужна срочно",
  subtitle: "Нет времени ждать консультации психолога — и помощь нужна срочно",
  description: "",
  image: {
    src: null,
    alt: "",
    title: "",
  },
  items: [
    {
      title: "Суицидальные мысли или попытки",
      subtitle: "",
      description:
        "Человек испытывает чувство безысходности и беспомощности, что приводит его к мыслям о самоповреждении или попыткам причинить себе вред.",
      icon: null,
      image: {
        src: require("assets/images/free-help/suicide_things.webp"),
        alt: "Суицидальные мысли или попытки",
        title: "",
      },
    },

    {
      title: "Домашнее насилие",
      subtitle: "",
      description:
        "Человек, испытывающий домашнее насилие, нуждается в поддержке для преодоления травмы и разработки стратегии обеспечения своей безопасности.",
      icon: null,
      image: {
        src: require("assets/images/free-help/home_violence.webp"),
        alt: "Домашнее насилие",
        title: "",
      },
    },
    {
      title: "Злоупотребление психоактивными веществами",
      subtitle: "",
      description:
        "Человек сталкивается с зависимостью от наркотиков или алкоголя и испытывает симптомы отмены или возвращения к прежним привычкам.",
      icon: null,
      image: {
        src: require("assets/images/free-help/drug_abuse.webp"),
        alt: "Злоупотребление психоактивными веществами",
        title: "",
      },
    },
    {
      title: "Травмирующее событие",
      subtitle: "",
      description:
        "В момент острого кризиса или под воздействием травматического события человек испытывает сильные эмоции, такие как аффект и шок, что затрудняет ему самостоятельное преодоление эмоциональных переживаний.",
      icon: null,
      image: {
        src: require("assets/images/free-help/traumatic_event.webp"),
        alt: "Травмирующее событие",
        title: "",
      },
    },
    {
      title: "Чувство одиночества и изоляции",
      subtitle: "",
      description:
        "Отсутствие мотивации что‑либо делать. Все, что лишает человека сил и влияет на качество жизни.",
      icon: null,
      image: {
        src: require("assets/images/free-help/loneliness-edeed.webp"),
        alt: "Чувство одиночества и изоляции",
        title: "",
      },
    },
    {
      title: "Горе и потеря",
      subtitle: "",
      description:
        "Потеря близкого человека — это тяжёлый эмоциональный испытание. Пройти через период горевания станет проще с помощью профессиональной психологической поддержки.",
      icon: null,
      image: {
        src: require("assets/images/free-help/sorrow_lose.webp"),
        alt: "Горе и потеря",
        title: "",
      },
    },
    {
      title: "Паническая атака",
      subtitle: "",
      description:
        "Человек сталкивается с внезапным эпизодом интенсивной и неконтролируемой тревоги, который сопровождается тревожными физическими проявлениями: учащенным сердцебиением, ощущением нехватки воздуха и головокружением.",
      icon: null,
      image: {
        src: require("assets/images/free-help/panic_attack.webp"),
        alt: "Паническая атака",
        title: "",
      },
    },
  ],
};

// 1.
// Доступность: Бесплатные услуги часто предоставляются в рамках государственных или некоммерческих организаций, что делает их доступными для широкой аудитории, включая людей с ограниченными финансовыми возможностями.
// Разнообразие форматов: Бесплатная психологическая помощь может быть представлена в различных формах — от индивидуальных консультаций до групповых сессий и телефонных горячих линий.
// Ограниченное время: Обычно бесплатные услуги имеют ограниченное количество сессий или времени, что может повлиять на глубину и продолжительность терапии.
// Квалификация специалистов: В некоторых случаях помощь предоставляют стажеры или студенты, что может означать менее опытный подход, но также и более свежий взгляд на проблемы.
// Анонимность и конфиденциальность: Многие бесплатные программы гарантируют анонимность клиентов, что может способствовать более открытому обсуждению личных проблем.
// 2.
// Ограниченные ресурсы: Бесплатные службы могут сталкиваться с нехваткой ресурсов, что может влиять на качество обслуживания и доступность специалистов.
// Ограниченное время и количество сессий: Часто бесплатные услуги имеют строгие лимиты на количество встреч, что может затруднить глубокую проработку проблемы.
// Качество услуг: В некоторых случаях помощь могут предоставлять менее опытные специалисты, такие как стажеры, что может влиять на качество консультации.
// Доступность: Бесплатные программы могут иметь ограниченное количество мест, что может привести к длительным ожиданиям или невозможности получить помощь в нужный момент.
// Ограниченные ресурсы: Нехватка финансирования может привести к недостаточному количеству специалистов или оборудования, что также может сказаться на качестве услуг.
// Сложные случаи: Бесплатные службы могут не предоставлять специализированную помощь для сложных психологических состояний или травм, что требует более глубокого вмешательства.
// Стигма: Некоторые люди могут испытывать стигму или предвзятость по отношению к бесплатным услугам, считая их менее ценными по сравнению с платными.

export const dataDisadvantagesFreeHelpPage = {
  title: "Особенности бесплатного консультирования",
  subtitle: "Особенности бесплатного консультирования",
  description: "",
  image: {
    src: null,
    alt: "",
    title: "",
  },
  items: [
    {
      title: "Нет возможности выбрать специалиста",
      subtitle: "",
      description:
        "Клиент не всегда может выбрать конкретного специалиста или изменить его. Число сессий, как правило, ограничено. Психолог назначается центром в зависимости от его возможностей.",
      icon: <BsAspectRatio />,
      image: {
        src: null,
        alt: "",
        title: "",
      },
    },
    {
      title: "Ограниченная доступность и ресурсы",
      subtitle: "",
      description:
        "Не все учреждения предлагают такие услуги. Нехватка финансирования может привести к недостаточному количеству специалистов или оборудования, что также может сказаться на качестве услуг",
      icon: <BsEmojiWink />,
      image: {
        src: null,
        alt: "",
        title: "",
      },
    },
    {
      title: "Ограниченное время и количество сеансов",
      subtitle: "",
      description:
        "Количество сеансов может варьироваться. Некоторые центры предлагают только одну пробную консультацию, в то время как другие — до 5–7 сеансов. Это может быть достаточным для выхода из кризисной ситуации, но, возможно, недостаточно для глубокой проработки проблемы.",
      icon: <BsLayoutTextWindowReverse />,
      image: {
        src: null,
        alt: "",
        title: "",
      },
    },
    {
      title: "Сложные случаи",
      subtitle: "",
      description:
        "Бесплатные службы могут не предоставлять специализированную помощь для сложных психологических состояний или травм, что требует более глубокого вмешательства.",
      icon: <BsClipboard2 />,
      image: {
        src: null,
        alt: "",
        title: "",
      },
    },
    {
      title: "Консультация в рамках образовательной программы",
      subtitle: "",
      description:
        "Консультации в учебных заведениях могут быть частью образовательного процесса. Это означает, что за проведением сессий будут наблюдать коллеги, и ваш случай будет обсужден.",
      icon: <BsPencilSquare />,
      image: {
        src: null,
        alt: "",
        title: "",
      },
    },
    {
      title: "Субъективная ценность",
      subtitle: "",
      description:
        "Когда клиент оплачивает услуги, их субъективная ценность увеличивается. В случае бесплатной помощи отсутствие финансовой мотивации может уменьшить стремление работать над собой. Поэтому важно найти внутреннюю мотивацию.",
      icon: <BsSpeedometer2 />,
      image: {
        src: null,
        alt: "",
        title: "",
      },
    },
  ],
};

export const dataFAQComponent1 = {
  data:
    `<p>Бесплатно получить консультацию психолога можно по полису ОМС — для этого нужно обратиться в психоневрологический диспансер по месту жительства.</p>` +
    `<p>Многие специалисты PsyWealth работают с тонкими настройками человеческой психики: защитами, бессознательным, установками, внутренними конфликтами — и тем, как всё это влияет на повседневную жизнь.</p>`,
};

export const dataFAQFreeHelpPage = {
  title: "Ответы на частые вопросы",
  subtitle: "Ответы на частые вопросы",
  description: "Свяжитесь с нами, если у вас есть вопрос",
  content: {
    title: "Остались вопросы?",
    subtitle: "Остались вопросы?",
    description: "Напишите нам и специалист свяжется с вами",
    image: require("assets/images/question-image3_rb.webp"),
    imageAlt:
      "" ||
      "Психолог онлайн, сервис онлайн консультация с психологами - PsyWealth",
  },
  items: [
    {
      title: "Можно ли поговорить с психологом бесплатно?",
      description:
        "Консультация психолога может понадобиться каждому. Если у вас нет возможности обратиться к психологу за деньги, существуют специализированные центры, где любой человек может получить бесплатную психологическую помощь.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Как получить помощь психолога бесплатно: центры поддержки",
      description:
        "Бесплатно получить консультацию психолога можно по полису ОМС — для этого нужно обратиться в психоневрологический диспансер по месту жительства.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Где можно пообщаться с психологом в онлайн чате 24 бесплатно?",
      description:
        "Квалифицированные психологи готовы ответить на ваши вопросы в любое время. Консультации проводятся в круглосуточном режиме бесплатно. Чтобы пообщаться с психологом в чате, не требуется предварительная регистрация.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Куда обратиться с депрессией бесплатно?",
      description:
        "В качестве экстренной и доступной помощи можно обратиться к специалистам круглосуточных анонимны центров психологической помощи по телефону или онлайн.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    // {
    //   title: "Какие документы можно хранить в электронном виде?",
    //   description: "",
    //   componentHTML: dataFAQComponent1,
    //   main: true,
    //   disable: true,
    // },
  ],
};

export const dataCTAFreeHelpPage = {
  title: "Оставить заявку на консультацию",
  title1: "Заявка на консультацию",
  title2: "",
  subtitle: "Оставить заявку",
  description:
    "Оставить заявку на консультацию, наш менеджер свяжется с вами и поможет подобрать решение для вашей задачи",
  msg: {
    msgCTA: "Отправить",
  },
  backgroundImage: require("assets/images/bg/cta-bg2.webp"),
};
