import { Banner } from "components/sections";
import { SEO } from "components/utils/Analytics";
import { dataCoursesPage } from "data";
import { Fragment, useEffect } from "react";
import MapSection from "./Map.section";

// NOTE :

const OurTeamPage = () => {
  useEffect(() => {}, []);

  return (
    <Fragment>
      <SEO data={dataCoursesPage.meta}>
        <link rel="canonical" href="https://psywealth.com/ourteam/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="our-team-page" id="main">
        <Banner data={dataCoursesPage} />
        <MapSection data={dataCoursesPage} />
      </main>
    </Fragment>
  );
};

export default OurTeamPage;
