import { useQuery } from "@tanstack/react-query";
import CourseService from "services/course.service";

export const useGetTopCourses = (props) => {
  // console.log("!!!", URL, props);

  return useQuery({
    queryFn: () => CourseService.getAll(),
    queryKey: ["topCourses"],
  });
};
