import { SectionHeader } from "components/sections/CommonSection";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { ProfileSection } from "./ProfileSection.section";

// NOTE :

const msgWithoutConsultation = "Психолог не оказывает консультаций";

const SectionContent = (props) => {
  const { data, isMobile } = props;
  const isProductSet = data.products && data.products.length > 0;

  return (
    <Fragment>
      {isProductSet ? (
        data.products.map((item, index) => {
          return (
            <div
              className="d-flex justify-content-between py-4 bt-blue-0 "
              key={index}
            >
              <span className="font-futura-400 line-height-2 font-weight-0 font-size-4">
                {item.product.name}
              </span>
              <span className="font-futura-400 line-height-2 font-weight-0 font-size-4 text-nowrap">
                <span className="font-weight-1">
                  <span className="is-nowrap">{item.price} ₽</span>
                </span>{" "}
              </span>
            </div>
          );
        })
      ) : (
        <div className="d-flex justify-content-between py-4 bt-blue-0 ">
          <span className="font-futura-400 line-height-2 font-weight-0 font-size-4">
            {msgWithoutConsultation}
          </span>
        </div>
      )}
    </Fragment>
  );
};

/**
 * Component for showing Section "Therapist Price" of the site.
 *
 * @component
 */
const TherapistPrice = (props) => {
  const { data, id = "therapist-bio", cn = "pt-80 pb-80" } = props;

  // _debug("Therapist price section:", data);

  return (
    <ProfileSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} />
        <SectionContent data={data.therapist} isMobile={false} />
      </div>
    </ProfileSection>
  );
};

TherapistPrice.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default TherapistPrice;
