import BlogTopPosts from "components/pages/Blog/BlogTopPosts";
import { SectionFAQ } from "components/pages/Home";
import {
  CallToAction as CTA,
  SectionSlogan,
  Service,
  Subscribe,
  Testimonial,
} from "components/sections";
import { SEO } from "components/utils/Analytics";
import {
  dataAboutMainPage as dataAbout,
  dataAltFeatureMainPage,
  dataBlog,
  dataCTALetsContact,
  dataFAQ,
  dataFeature2MainPage,
  dataFeatureMainPage,
  dataHeroMainPage as dataHero,
  dataHomePage,
  dataMobileAppMainPage as dataMobileApp,
  dataOurAdvantagesMainPage,
  dataOverviewMainPage as dataOverview,
  dataPsySelection,
  dataServiceMainPage as dataService,
  dataSloganMainPage as dataSlogan,
  dataSubscribe,
  dataTestimonialMainPage as dataTestimonial,
  dataTopAdviceMainPage as dataTopAdvice,
  dataTopCoursesMainPage as dataTopCourses,
  dataTopPsychologistsMainPage as dataTopPsychologists,
  ldJson,
} from "data";
import { Fragment, useRef } from "react";
import AltFeatures from "../components/pages/Home/AltFeatures";
import { Approaches } from "../components/pages/Home/Approaches";
import { CallToAction } from "../components/pages/Home/CTA";
import { VideoOfTheDay } from "../components/pages/Home/DailyVideos";
import { OurAdvantages } from "../components/pages/Home/Features1";
import { Hero } from "../components/pages/Home/Hero";
import { MobileAppSection } from "../components/pages/Home/MobileApp";
import { FeaturePsyFormats } from "../components/pages/Home/PsyFormats";
import PsySelection from "../components/pages/Home/PsySelection";
import Tabs from "../components/pages/Home/PsyStart/PsyStartTabs";
import ServicesGrid from "../components/pages/Home/ServicesGrid";
import { TopAdvice } from "../components/pages/Home/TopAdvice";
import { TopCourses } from "../components/pages/Home/TopCourses";
import { TopPsychologists } from "../components/pages/Home/TopPsychologist";
import SectionAbout from "../components/pages/Partners1/SectionAbout";
import Overview2 from "../components/sections/Overview2";

//const Pricing = React.lazy(() => import("../../sections/Pricing"));
//const Process = React.lazy(() => import("../../sections/Process"));
//const Service = React.lazy(() => import("../../sections/Service"));
//const FeatureTabs = React.lazy(() => import("../../sections/FeatureTabs"));

// NOTE : https://demo.graygrids.com/themes/space/index.html
// NOTE : https://demo.graygrids.com/themes/digimark/services.html
// NOTE : https://www.sleepinggiantmedia.co.uk/posts/canonical-tags-implementation-guide/

// NOTE : https://elar-context.ru

// NOTE : https://scrapfly.io/blog/search-engine-using-web-scraping/

// NOTE : https://bootstrapmade.com/demo/HeroBiz/

/**
 * Component for showing Page "Home" of the site.
 *
 * @component
 */
const DemoPage = () => {
  const refCAT = useRef(null);

  const executeScroll = (e) => {
    refCAT.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  return (
    <Fragment>
      <SEO data={dataHomePage.meta}>
        {/*Only canonicalise pages which are a duplicate or are near-identical.*/}
        <link rel="canonical" href="https://psywealth.com/demo" />
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </SEO>
      <main className="page-main" id="top">
        <Hero data={dataHero} onScroll={executeScroll} />
        {/*<SectionCountUp data={dataCountUpMainPage} />*/}
        <CallToAction data={dataTopPsychologists} type="style1" />
        <CallToAction data={dataTopPsychologists} type="style2" />
        <OurAdvantages data={dataOurAdvantagesMainPage} />
        <TopPsychologists data={dataTopPsychologists} cn="pt-120 pb-120" />
        <Overview2 data={dataOverview} cn="pt-120 pb-120" />
        <FeaturePsyFormats data={dataFeatureMainPage} type="style1" />
        <FeaturePsyFormats data={dataFeature2MainPage} type="style2" />
        <ServicesGrid data={dataService} />
        <PsySelection data={dataPsySelection} />
        <Tabs data={dataTopCourses} />
        {/* <Feature10 data={dataFeatureMainPage} type="style2" />*/}
        {/*<FeatureRound data={dataFeatureMainPage} />*/}
        {/*<Feature99 data={dataFeatureMainPage} type="style2" />*/}
        {/* <About data={dataAbout} type="style2" />*/}
        {/* <FeatureTabs data={dataAbout} />*/}
        {/*  <ServicesInfo data={dataServicesInfoMainPage} />*/}
        {/* <Pricing data={dataPricing} /> */}
        {/* <OurProducts data={dataOurProducts} />*/}
        <TopCourses data={dataTopCourses} />
        <SectionFAQ data={dataFAQ} />
        <BlogTopPosts data={dataBlog} />
        <Subscribe data={dataSubscribe} />
        <h2 className="text-center">ДЕМО СЕКЦИЙ - DEMO</h2>
        <Service data={dataService} type="style2" />
        <CTA data={dataCTALetsContact} innerRef={refCAT} />
        <Testimonial data={dataTestimonial} />
        <Approaches data={dataTopAdvice} />
        <TopAdvice data={dataTopAdvice} />
        <VideoOfTheDay data={dataTopAdvice} />
        <SectionSlogan data={dataSlogan} />
        <MobileAppSection data={dataMobileApp} />
        <AltFeatures data={dataAltFeatureMainPage} />
        <SectionAbout data={dataAbout} />
      </main>
    </Fragment>
  );
};

export default DemoPage;
