import { useQuery } from "@tanstack/react-query";
import AppointmentService from "services/appointment.service";

export const useGetAppointmentsByPsychologist = (
  psychologistId,
  options = {}
) => {
  return useQuery({
    queryFn: () => AppointmentService.getByPsychologist(psychologistId),
    queryKey: ["appointmentByPsychologist", psychologistId],
  });
};
