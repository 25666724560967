import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PropTypes } from "prop-types";
import { Fragment, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { useOutletContext } from "react-router-dom";
import PsychologistService from "services/psychologist.service";
import { _debug } from "utils";
import ProductModalForm from "./ProductModalForm";
import ProductsTable from "./ProductsTable";

const MSG_TABLE_PRODUCT_TITLE = "Продукты";

/**
 * Component for showing Section "Customer Profile (Tab) - Customer Education" of the site.
 *
 * @component
 **/
const ProfileTabProduct = (props) => {
  // const { data } = props;
  const { profile } = useOutletContext();

  const queryClient = useQueryClient();

  const [modalFormData, setModalFormData] = useState({
    fullName: "1",
  });

  const [showModal, setShowModal] = useState(false);

  const mutationAddProduct = useMutation({
    mutationFn: async (newProduct) =>
      PsychologistService.addProduct({ id: profile.id, data: newProduct }),
    onSuccess: (data) => {
      console.log("Product added successfully:", data);
      queryClient.refetchQueries("profile");
    },
    onError: (error) => {
      console.error("Error add Product:", error);
    },
  });

  const mutationDeleteProduct = useMutation({
    mutationFn: async (productId) =>
      PsychologistService.deleteProduct({ id: productId }),
    onSuccess: (data) => {
      console.log("Product deleted successfully:", data);
      queryClient.refetchQueries("profile");
    },
    onError: (error) => {
      console.error("Error delete Product:", error);
    },
  });

  const handleDelete = (id) => {
    console.log(id);
    mutationDeleteProduct.mutate(id);
  };

  const handleClose = (data) => {
    console.log(data);
    setShowModal(false);

    _debug("Form submit: ", data);

    const newProduct = {
      id: data.id,
      productId: data.id,
      price: data.price,
      currency: "RUB",
    };

    mutationAddProduct.mutate(newProduct);
  };

  const handleAddProductModal = () => {
    setShowModal(true);
  };

  return (
    <Fragment>
      {/*<!-- Working with a modal pop up -->*/}
      {showModal && (
        <ProductModalForm
          showModal={showModal}
          handleClose={handleClose}
          modalFormData={modalFormData}
        />
      )}
      <div className="vstack gap-4">
        {/*<!-- Product START -->*/}
        <div className="card border">
          {/*<!-- Card header -->*/}
          <div className="card-header border-bottom">
            <h4 className="card-header-title">{MSG_TABLE_PRODUCT_TITLE}</h4>
          </div>

          {/*<!-- Card body Product -->*/}
          <div className="card-body vstack gap-4">
            {/*<!-- Product item START -->*/}
            <div className="card">
              <div className="table-responsive border-0 mb-3">
                <ProductsTable
                  data={profile.products}
                  tableClass="align-middle p-4 mb-0 table-hover"
                  headerClass="table-dark"
                  onDelete={handleDelete}
                />
              </div>
              {/*<!-- Product item END -->*/}

              {/*<!-- Button -->*/}
              <div className="text-end">
                <button
                  className="btn btn-primary mb-0"
                  type="button"
                  onClick={handleAddProductModal}
                >
                  <i className="me-2">
                    <BsPlusCircle />
                  </i>
                  New Product
                </button>
              </div>
            </div>
            {/* <!-- Card body END -->*/}
          </div>
        </div>
        {/*<!-- Product END -->*/}
      </div>
    </Fragment>
  );
};

ProfileTabProduct.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default ProfileTabProduct;
