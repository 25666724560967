import { formatDataShort } from "components/utils/Utils";
import { BLOG_API_URI } from "config/config";
import { useFetch } from "hooks";
import { Fragment, useState } from "react";
import { Pagination, Row } from "react-bootstrap";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";
//import { logger } from "utils";
import { Spinner } from "components/utils/Spinner";
import { BLOG_ID, SITE } from "config/config";
import { dataForm } from "data";

const PAGES_LIMIT = SITE.BLOG.PAGES_LIMIT;

/**
 * Component for showing "Blog list of posts page" of the site.
 *
 * @component
 */
const PostsListPage = (props) => {
  const [pageState, setPageState] = useState({
    pageNo: 0,
    pageSize: PAGES_LIMIT,
    blog: BLOG_ID,
  });
  //const [state, setState] = useState({
  //  data: [],
  //  limit: 10,
  //  activePage: 1,
  //});

  const URL = `${BLOG_API_URI}/posts?`;
  const {
    data: postPages,
    isLoading,
    hasError,
    errorMessage,
    updateUrl,
    updateParams,
  } = useFetch(URL, pageState);

  // console.log(pageState);
  // console.log(postPages);

  //const [currentPage, setCurrentPage] = useState(1);

  // const currentTableData = useMemo(() => {
  //   const firstPageIndex = (currentPage - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;
  //   return blogPosts?.slice(firstPageIndex, lastPageIndex);
  // }, [currentPage]);

  const handlePageChange = (_pageNumber) => {
    console.log(_pageNumber);
    console.log(pageState);

    //setPageNumber(_pageNumber);
    setPageState((prev) => ({ ...prev, pageNo: _pageNumber }));
    updateParams({
      pageNo: _pageNumber,
      pageSize: PAGES_LIMIT,
      blog: BLOG_ID,
    });

    console.log(pageState);

    //  pageNo: _pageNumber,
    //});
  };

  const PostListItem = (props) => {
    const { data, index } = props;
    console.log(data);

    const LinkToPost = (props) => {
      const { slug, otherProps } = props;
      console.log(otherProps);
      return (
        <Link to={`/blog/${slug}`} {...otherProps}>
          {props.children}
        </Link>
      );
    };

    return (
      <div className="col-md-6 col-lg-12 col-xl-6">
        <div
          className="single-blog mb-40 wow fadeInUp"
          data-wow-delay=".2s"
          style={{
            visibility: "visible",
            animationDelay: "0.2s",
            animationName: "fadeInUp",
          }}
        >
          <div className="blog-img">
            <LinkToPost slug={data.slug}>
              <img src={data.image} alt="" />
            </LinkToPost>
            <span className="date-meta">15 June, 2025</span>
          </div>
          <div className="blog-content">
            <h4>
              <LinkToPost slug={data.slug}>{data.title}</LinkToPost>
            </h4>
            <p>{data.description}</p>
            <LinkToPost slug={data.slug} className="read-more-btn">
              {dataForm.msgReadMore} <FaArrowRightLong />
            </LinkToPost>
          </div>
        </div>
      </div>
    );
  };

  const PostListItem2 = (props) => {
    const { data, index } = props;
    //console.log(data);

    const LinkToPost = (props) => {
      const { slug, otherProps } = props;
      //console.log(otherProps);
      return (
        <Link to={`/blog/${slug}`} {...otherProps}>
          {props.children}
        </Link>
      );
    };

    return (
      <div
        className="single-blog blog-style-2 mb-60 wow fadeInUp"
        data-wow-delay=".2s"
        style={{
          visibility: "visible",
          animationDelay: "0.2s",
          animationName: "fadeInUp",
        }}
      >
        <div className="blog-img">
          <LinkToPost slug={data.slug}>
            <img
              src={`${BLOG_API_URI}/posts/imagedb/${data.imageURL}`}
              alt=""
            />
          </LinkToPost>
        </div>
        <div className="blog-content">
          <div className="small text-muted mb-2">
            {formatDataShort(
              data.updatedDate ? data.updatedDate : data.createdDate
            )}
          </div>
          <h4>
            <LinkToPost slug={data.slug}>{data.title}</LinkToPost>
          </h4>
          <p>{data.description}</p>
          <div className="blog-meta">
            <LinkToPost slug={data.slug} className="read-more-btn">
              {dataForm.msgReadMore} <FaArrowRightLong />
            </LinkToPost>
            {/*
            <span className="comment">
              <i className="lni lni-bubble"></i> 1K Comment
              </span>
              */}
          </div>
        </div>
      </div>
    );
  };

  const ListPagination = () => {
    const getPaginationItems = (items) => {
      let content = [];
      for (let index = 0; index < postPages.totalPages; index++) {
        //console.log(index);
        //const item = animals[i];
        content.push(
          <Pagination.Item
            onClick={() => handlePageChange(index)}
            key={index}
            active={index === postPages.page}
          >
            {index + 1}
          </Pagination.Item>
        );
      }
      return content;
    };

    return (
      <div className="pagination">
        <Pagination className="d-flex justify-content-center align-items-center px-4">
          <Pagination.First
            disabled={postPages.page === 0}
            onClick={() => handlePageChange(0)}
          />
          {getPaginationItems()}
          <Pagination.Last
            disabled={postPages.page === postPages.totalPages - 1}
            onClick={() => handlePageChange(postPages.totalPages)}
          />
        </Pagination>
      </div>
    );
  };

  if (isLoading || hasError) {
    return (
      <Fragment>
        {isLoading && <Spinner />}
        {hasError && <p>{errorMessage}</p>}
      </Fragment>
    );
  }

  if (!postPages) return null;

  if (!postPages.content || postPages.content.length === 0) {
    return <h2>No posts founds</h2>;
  }

  return (
    <div className="posts-list-wrapper">
      <Row>
        {postPages &&
          postPages.content &&
          postPages.content.length &&
          postPages.content.map((item, index) => {
            return <PostListItem2 data={item} key={index} />;
          })}
      </Row>
      <ListPagination />
    </div>
  );
};

export default PostsListPage;

/*
 {currentTableData.map((item, index) => {
          return (
            <div key={index}>
              <PostListItem data={item} key={index} />
              <hr />
            </div>
          );
        })}
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={blogPosts.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        /> */
