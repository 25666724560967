import placeholderSrc from "assets/images/hello-world.webp";
import { ProgressiveImg } from "components/utils";
import { isDevelopment } from "components/utils/Utils";
import { BLOG_API_URI, BLOG_ID } from "config/config";
import { dataForm } from "data";
import { PropTypes } from "prop-types";
import { useEffect } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { _debug } from "utils";
import useFetch from "../../../hooks/useFetch";
import { PageSection, SectionHeader } from "../Home";
import BlogPostCard from "./BlogPostCard";

const URL = `${BLOG_API_URI}/posts/top?blog=${BLOG_ID}`;

/**
 * Component for showing Section "Top 3 Blog Posts" of the site.
 *
 * @component
 */
const BlogTopPosts = (props) => {
  const { data, id = "top-posts", cn = "pt-100 pb-60" } = props;
  const { data: posts, isLoading, hasError, errorMessage } = useFetch(URL);

  const SectionContent = (props) => {
    const { data } = props;

    const _SectionItem = (props) => {
      const { data } = props;

      return (
        <div className="col-xl-4 col-lg-4 col-md-6">
          <div
            className="single-blog mb-40 wow fadeInUp"
            data-wow-delay={data.animationDelay}
            style={{
              visibility: "visible",
              animationDelay: "0.2s",
              animationName: "fadeInUp",
            }}
          >
            <div className="blog-img">
              <Link to={`/blog/${data.id}`}>
                <ProgressiveImg
                  src={`${BLOG_API_URI}/posts/imagedb/${data.imageURL}`}
                  placeholderSrc={placeholderSrc}
                  alt=""
                />
              </Link>
              <span className="date-meta">{data.postDate}</span>
            </div>
            <div className="blog-content">
              <h4>
                <Link to={`/blog/${data.slug}`}>{data.title}</Link>
              </h4>
              <p>{data.description}</p>
              <Link to={`/blog/${data.slug}`} className="btn read-more-btn">
                {dataForm.msgReadMore} <AiOutlineArrowRight />
              </Link>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="row equal">
        {posts &&
          posts.map((item, index) => {
            return <BlogPostCard data={item} index={index} key={index} />;
          })}
      </div>
    );
  };

  useEffect(() => {
    _debug("Data fetched: ", posts);
    if (hasError) _debug("Error: ", errorMessage);
    if (isDevelopment && !posts) {
    }
    //console.log(loading);
    //console.log(error);
  }, [isLoading, hasError, posts]);

  if (isLoading || hasError) return null;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />
        <SectionContent data={posts} />
      </div>
    </PageSection>
  );
};

BlogTopPosts.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

BlogTopPosts.defaultProp = {};

export default BlogTopPosts;
