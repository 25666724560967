import { useState } from "react";
import { PageSection, SectionHeader } from "../../sections/CommonSection";
import MultiStepForm from "../Home/Form/MultiStepForm";
import useMultiStepForm from "../Home/Form/useMultiStepForm";
import FormLauncher from "./FormLauncher";
import UserForm from "./Steps/UseForm";
import UserForm1 from "./Steps/UseForm1";

const INITIAL_DATA = {
  firstName: "",
  lastName: "",
  age: "",
  street: "",
  city: "",
  state: "",
  type: "",
  email: "",
  password: "",
};

const SectionContent = (props) => {
  const { data, cn = "" } = props;
  const [dataForm, setFormData] = useState(INITIAL_DATA);

  const updateFields = (fields) => {
    setFormData((prev) => {
      return { ...prev, ...fields };
    });
  };

  const STEPS = [
    <UserForm1 {...dataForm} updateFields={updateFields} />,
    <UserForm {...dataForm} updateFields={updateFields} />,
  ];
  const {
    steps,
    currentStepIndex,
    step,
    isFirstStep,
    isLastStep,
    prevStep,
    nextStep,
  } = useMultiStepForm(STEPS);

  function onSubmit(e) {
    e.preventDefault();
    if (!isLastStep) return nextStep();
    alert("Successful Account Creation");
    console.log(dataForm);
  }

  return (
    <div className="container">
      <div className="row">
        <form onSubmit={onSubmit}>
          <div style={{ position: "absolute", top: ".5rem", right: ".5rem" }}>
            {currentStepIndex + 1} / {steps.length}
          </div>
          {step}
          {!isFirstStep && (
            <button type="button" onClick={prevStep}>
              Back
            </button>
          )}
          <button type="submit">{isLastStep ? "Finish" : "Next"}</button>
        </form>
        <MultiStepForm />
        <FormLauncher />
      </div>
    </div>
  );
};

/**
 * Component for showing Section "Contact" of the site.
 *
 * @component
 */
const CustomerEntry = (props) => {
  const { data, id = "launcher", cn = "vh-100" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} />
        <SectionContent data={data} id={id} cn={cn} />
      </div>
    </PageSection>
  );
};

export default CustomerEntry;
