import { PropTypes } from "prop-types";
import { useRef, useState } from "react";
import { Carousel } from "react-bootstrap";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { PageSection, SectionHeader } from "../../sections/CommonSection";

const SectionContent = (props) => {
  const { data, showTitle = true, showDescription = true } = props;
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
  const refCarousel = useRef(null);

  const handleSelect = (selectedIndex) => {
    setCurrentCarouselIndex(selectedIndex);
  };

  const onPrevClick = () => {
    refCarousel.current.prev();
  };
  const onNextClick = () => {
    refCarousel.current.next();
  };

  const ContentItem = (props) => {
    const { data } = props;

    return (
      <div className="testimonial-item">
        <div className="testimonial-img">
          <img className="img-fluid" src={data.image} alt="" />
        </div>
        <div className="testimonial-content mt-5 mb-2 text-center">
          {showTitle && <h3 className="mb-2">{data.title}</h3>}
          {showDescription && (
            <p className="">
              <span>{data.description}</span>
            </p>
          )}
        </div>
      </div>
    );
  };

  const ContentControl = () => {
    return (
      <div className="testimonial-controls" aria-label="carousel navigation">
        <button type="button" data-controls="prev" onClick={onPrevClick}>
          <FaArrowLeftLong />
        </button>
        <button type="button" data-controls="next" onClick={onNextClick}>
          <FaArrowRightLong />
        </button>
      </div>
    );
  };

  return (
    <div className="testimonial-wrapper">
      <div className="testimonial-outer">
        <ContentControl />
        <div className="d-none" aria-live="polite" aria-atomic="true">
          slide <span className="current">{currentCarouselIndex + 1}</span> of{" "}
          {data.items.length}
        </div>

        <Carousel
          ref={refCarousel}
          activeIndex={currentCarouselIndex}
          onSelect={handleSelect}
          indicators={false}
          controls={false}
        >
          {data.items.map((item, index) => {
            return (
              <Carousel.Item className="item" key={index}>
                <ContentItem data={item} />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

/**
 * Component for showing Section "Psychologist Selection" of the site.
 *
 * @component
 */
const PsySelection = (props) => {
  const {
    data,
    id = "psy-selection",
    cn = "pt-120 pb-80",
    showHeaderSubtitle = false,
    showHeaderDescription = false,
    showTitle = true,
    showDescription = true,
    useAnimation = false,
  } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader
          data={data}
          showSubtitle={showHeaderSubtitle}
          showDescription={showHeaderDescription}
        />
        <SectionContent
          data={data}
          showTitle={showTitle}
          showDescription={showDescription}
          useAnimation={useAnimation}
        />
      </div>
    </PageSection>
  );
};

PsySelection.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showHeaderSubtitle: PropTypes.bool ?? false,
  showHeaderDescription: PropTypes.bool ?? false,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

PsySelection.defaultProp = {};

export default PsySelection;
