import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import TimezoneSelect from "react-timezone-select";

import "react-datepicker/dist/react-datepicker.css";
import "./FormInput.scss";

/**
 * Form - A Custom Form Input Error component.
 *
 * @component
 * @version 1.0.1
 **/
export const CustomFormInputError = (props) => {
  const { message } = props;

  if (!message) return null;

  return (
    <p className="text-warning">
      <small>{message}</small>
    </p>
  );
};

/**
 * Form - A Custom Form Text Input component.
 *
 * @component
 * @version 1.0.1
 **/
export const CustomFormInputText = (props) => {
  const { id, label, placeholder, required, error, ...otherProps } = props;

  return (
    <Form.Group className="mb-3" controlId={id}>
      {required ? (
        <Form.Label>
          {label || "Message"}
          <span className="text-danger">*</span>{" "}
        </Form.Label>
      ) : (
        <Form.Label>{label || "Message"}</Form.Label>
      )}
      <Form.Control
        type="text"
        placeholder={placeholder || "Enter email"}
        {...otherProps}
      />
      <Form.Text className="text-muted">
        We'll never share your email with anyone else.
      </Form.Text>

      <Form.Text muted>
        Your username must contain letters and numbers, and must not contain
        spaces, special characters, or emoji.
      </Form.Text>

      {error && (
        <Form.Control.Feedback type="invalid">
          <CustomFormInputError message={error.message} />
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

/**
 * Form - A Custom Form Text (2) Input component.
 *
 * @component
 * @version 1.0.1
 **/
export const CustomFormInputText2 = React.forwardRef(
  (
    {
      type = "text",
      label,
      placeholder,
      disabled = false,
      required,
      error,
      helpText = "",
      formControlClasses = "",
      ...otherProps
    },
    ref
  ) => {
    //const prefix = useBootstrapPrefix(bsPrefix, 'btn');

    return (
      <Fragment>
        {required ? (
          <Form.Label>
            {label || "Message"}
            <span className="text-danger">*</span>{" "}
          </Form.Label>
        ) : (
          <Form.Label>{label || "Message"}</Form.Label>
        )}
        <Form.Control
          ref={ref}
          disabled={disabled}
          type={type}
          placeholder={placeholder || label}
          className={formControlClasses}
          {...otherProps}
        />
        {/*<Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>*/}
        {helpText && (
          <Form.Text muted>
            {helpText ||
              "Your username must contain letters and numbers, and must not contain spaces, special characters, or emoji."}
          </Form.Text>
        )}
        {error && (
          <Form.Control.Feedback type="invalid">
            <CustomFormInputError message={error.message} />
          </Form.Control.Feedback>
        )}
      </Fragment>
    );
  }
);

/**
 * Form - A Custom Form Textarea Input component.
 *
 * @component
 * @version 1.0.1
 **/
export const CustomFormInputTextarea = React.forwardRef(
  (
    {
      type = "text",
      label,
      placeholder,
      disabled = false,
      required,
      error,
      helpText = "",
      ...otherProps
    },
    ref
  ) => {
    //const prefix = useBootstrapPrefix(bsPrefix, 'btn');

    return (
      <Fragment>
        {required ? (
          <Form.Label>
            {label || "Message"}
            <span className="text-danger">*</span>{" "}
          </Form.Label>
        ) : (
          <Form.Label>{label || "Message"}</Form.Label>
        )}
        <Form.Control
          ref={ref}
          disabled={disabled}
          as="textarea"
          placeholder={placeholder || "Enter email"}
          {...otherProps}
        />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
        {helpText && (
          <Form.Text muted>
            {helpText ||
              "Your username must contain letters and numbers, and must not contain spaces, special characters, or emoji."}
          </Form.Text>
        )}
        {error && (
          <Form.Control.Feedback type="invalid">
            <CustomFormInputError message={error.message} />
          </Form.Control.Feedback>
        )}
      </Fragment>
    );
  }
);

/**
 * Form - A Custom Form Time zone Input component.
 *
 * @component
 * @version 1.0.1
 **/
export const CustomFormInputTimeZone = React.forwardRef(
  (
    {
      type = "text",
      label,
      placeholder,
      disabled = false,
      dateFormat = "MM/yyyy",
      required,
      error,
      helpText = "",
      validationErrorMessage = "Validation message",
      control,
      name,
      defaultValue,
      ...otherProps
    },
    ref
  ) => {
    //const prefix = useBootstrapPrefix(bsPrefix, 'btn');

    return (
      <Fragment>
        {required ? (
          <Form.Label>
            {label || "Message"}
            <span className="text-danger">*</span>{" "}
          </Form.Label>
        ) : (
          <Form.Label>{label || "Message"}</Form.Label>
        )}

        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          ref={ref}
          render={({ field }) => {
            return (
              <TimezoneSelect
                onBlur={field.onBlur}
                selected={field.value}
                value={field.value}
                onChange={field.onChange}
                {...otherProps}
              />
            );
          }}
        />
        {helpText && (
          <Form.Text muted>
            {helpText ||
              "Your username must contain letters and numbers, and must not contain spaces, special characters, or emoji."}
          </Form.Text>
        )}
        {error && (
          <Form.Control.Feedback type="invalid">
            <CustomFormInputError
              message={error.message || validationErrorMessage}
            />
          </Form.Control.Feedback>
        )}
      </Fragment>
    );
  }
);

/**
 * Form - A Custom Form Date Input component.
 *
 * @component
 * @version 1.0.1
 **/
export const CustomFormInputDate = React.forwardRef(
  (
    {
      type = "text",
      label,
      placeholder,
      disabled = false,
      dateFormat = "MM/yyyy",
      required,
      error,
      helpText = "",
      validationErrorMessage = "Validation message",
      control,
      name,
      defaultValue,
      ...otherProps
    },
    ref
  ) => {
    //const prefix = useBootstrapPrefix(bsPrefix, 'btn');

    return (
      <Fragment>
        {required ? (
          <Form.Label>
            {label || "Message"}
            <span className="text-danger">*</span>{" "}
          </Form.Label>
        ) : (
          <Form.Label>{label || "Message"}</Form.Label>
        )}

        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          ref={ref}
          render={({ field }) => {
            return (
              <DatePicker
                onChange={field.onChange}
                onBlur={field.onBlur}
                selected={field.value}
                dateFormat={dateFormat}
                className={`form-control ${error ? "is-invalid" : ""}`}
                autoComplete="off"
                {...otherProps}
              />
            );
          }}
        />
        {/*   
        
        render={({ onChange, value }) => (
            <DatePicker
              selected={value}
              onChange={onChange}
              dateFormat="dd/MM/yyyy"
            />
          )}
        */}
        {/* <Form.Control
          as={<DatePicker />}
          ref={ref}
          disabled={disabled}
          type={type}
          placeholder={placeholder || "Enter email"}
          {...otherProps}
        />*/}
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
        {helpText && (
          <Form.Text muted>
            {helpText ||
              "Your username must contain letters and numbers, and must not contain spaces, special characters, or emoji."}
          </Form.Text>
        )}
        {error && (
          <Form.Control.Feedback type="invalid">
            <CustomFormInputError
              message={error.message || validationErrorMessage}
            />
          </Form.Control.Feedback>
        )}
      </Fragment>
    );
  }
);

/**
 * Form - A Custom Form Time Input component.
 *
 * @component
 * @version 1.0.1
 **/
export const CustomFormInputTime = React.forwardRef(
  (
    {
      type = "text",
      label,
      placeholder,
      disabled = false,
      required,
      error,
      helpText = "",
      validationErrorMessage = "Validation message",
      control,
      name,
      defaultValue,
      rules,
      ...otherProps
    },
    ref
  ) => {
    //const prefix = useBootstrapPrefix(bsPrefix, 'btn');

    return (
      <Fragment>
        {required ? (
          <Form.Label>
            {label || "Message"}
            <span className="text-danger">*</span>{" "}
          </Form.Label>
        ) : (
          <Form.Label>{label || "Message"}</Form.Label>
        )}

        <Controller
          ref={ref}
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => {
            console.log(field);
            return (
              <DatePicker
                onChange={field.onChange}
                onBlur={field.onBlur}
                selected={field.value}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                className={`form-control ${error ? "is-invalid" : ""}`}
                autoComplete="off"
                {...otherProps}
              />
            );
          }}
        />
        {/*   
        
        render={({ onChange, value }) => (
            <DatePicker
              selected={value}
              onChange={onChange}
              dateFormat="dd/MM/yyyy"
            />
          )}
        */}
        {/* <Form.Control
          as={<DatePicker />}
          ref={ref}
          disabled={disabled}
          type={type}
          placeholder={placeholder || "Enter email"}
          {...otherProps}
        />*/}
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
        {helpText && (
          <Form.Text muted>
            {helpText ||
              "Your username must contain letters and numbers, and must not contain spaces, special characters, or emoji."}
          </Form.Text>
        )}
        {error && (
          <Form.Control.Feedback type="invalid">
            <CustomFormInputError
              message={error.message || validationErrorMessage}
            />
          </Form.Control.Feedback>
        )}
      </Fragment>
    );
  }
);

/**
 * Form - A Custom Form Select Input component.
 *
 * @component
 * @version 1.0.1
 **/
export const CustomFormSelect = React.forwardRef(
  (
    {
      type = "select",
      label,
      placeholder,
      disabled = false,
      required,
      error,
      helpText = "",
      formControlClasses = "form-select",
      validationErrorMessage = "Validation message",
      control,
      name,
      defaultValue,
      options = [],
      ...otherProps
    },
    ref
  ) => {
    //const prefix = useBootstrapPrefix(bsPrefix, 'btn');

    return (
      <Fragment>
        {required ? (
          <Form.Label>
            {label || "Message"}
            <span className="text-danger">*</span>{" "}
          </Form.Label>
        ) : (
          <Form.Label>{label || "Message"}</Form.Label>
        )}

        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <Form.Control
                onChange={field.onChange}
                onBlur={field.onBlur}
                selected={field.value}
                disabled={disabled}
                as="select"
                className={formControlClasses}
                placeholder={placeholder || label}
                {...otherProps}
              >
                <option className="d-none" value="">
                  Select Option
                </option>
                {options.map((item, index) => {
                  // console.log(item);
                  return (
                    <option key={item.id}>
                      {item.name ? item.name : item.title}
                    </option>
                  );
                })}
              </Form.Control>
            );
          }}
        />

        {/* <Form.Control
          ref={ref}
          disabled={disabled}
          // type={type}
          as="select"
          className={formControlClasses}
          placeholder={placeholder || label}
          {...otherProps}
        >
          <option className="d-none" value="">
            Select Option
          </option>
          {options.map((item, index) => (
            <option key={item.id}>{item?.name}</option>
          ))}
        </Form.Control> */}
        {/*   
         <Form.Select>
          <option>Default select</option>
        </Form.Select>
        render={({ onChange, value }) => (
            <DatePicker
              selected={value}
              onChange={onChange}
              dateFormat="dd/MM/yyyy"
            />
          )}
        */}
        {/* <Form.Control
          as={<DatePicker />}
          ref={ref}
          disabled={disabled}
          type={type}
          placeholder={placeholder || "Enter email"}
          {...otherProps}
        />*/}
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
        {helpText && (
          <Form.Text muted>
            {helpText ||
              "Your username must contain letters and numbers, and must not contain spaces, special characters, or emoji."}
          </Form.Text>
        )}
        {error && (
          <Form.Control.Feedback type="invalid">
            <CustomFormInputError
              message={error.message || validationErrorMessage}
            />
          </Form.Control.Feedback>
        )}
      </Fragment>
    );
  }
);

// export const InputFloatingLabelMessage = (props) => {
//   const { label, placeholder, required, register, errors, ...otherProps } =
//     props;

//   return (
// <Form.Label>{label || "Message"}</Form.Label>
//       <Form.Control
//         as="textarea"
//         rows={5}
//         name="message"
//         placeholder={placeholder}
//         isInvalid={!!errors.message}
//         {...register("message", {
//           required: required,
//           validate: {
//             maxLength: (v) =>
//               v.length <= 250 ||
//               "The message should have not more 250 characters",
//             minLength: (v) => v.length > 1 || "The message should be entered",
//           },
//         })}
//         {...otherProps}
//         aria-invalid={errors?.message ? "true" : "false"}
//         aria-describedby="messageHelpBlock"
//         style={{ height: "10em" }}
//       />

//       {errors.message && (
//         <Form.Control.Feedback type="invalid">
//           <InputGroupError message={errors.message.message} />
//         </Form.Control.Feedback>
//       )}

//   );
// };

// const InputGroupMessage = (props) => {
//   const {
//     label,
//     placeholder,
//     help,
//     required,
//     register,
//     errors,
//     id,
//     ...otherProps
//   } = props;

//   return (
//     <Form.Group controlId={id || "message"}>
//       <InputFloatingLabelMessage
//         label={label}
//         placeholder={placeholder}
//         required={required}
//         register={register}
//         errors={errors}
//         {...otherProps}
//       />
//       {help && (
//         <Form.Text
//           id="messageHelpBlock"
//           muted
//           //hidden={!(errors.message && errors.message.message)}
//         >
//           {help}
//         </Form.Text>
//       )}
//     </Form.Group>
//   );
// };

// export default InputGroupMessage;
