import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { PageSection, SectionHeader } from "../Home";

import Background1 from "assets/images/feature/about-grid-img-1.webp";
import Background2 from "assets/images/feature/about-grid-img-2.webp";
import Background3 from "assets/images/feature/about-grid-img-3.webp";

// import "./Contacts.style.scss";

// NOTE : https://themes.getbootstrap.com/preview/?theme_id=143914

const SectionContentStyle1 = (props) => {
  const { data, id = "" } = props;

  return (
    <Fragment>
      <div className="row g-4">
        <div className="col-xl-5 col-lg-6 col-12">
          <div className="mb-4">
            <small className="text-uppercase ls-md fw-semibold">
              who we are
            </small>
            <h2 className="h1 mt-4 mb-3">{data.subtitle}</h2>
            {data.descriptionHTML ? (
              data.descriptionHTML
            ) : (
              <Fragment>
                {data.description1 && (
                  <p className="mb-2">{data.description1}</p>
                )}
                {data.description2 && (
                  <p className="mb-0">{data.description2}</p>
                )}
              </Fragment>
            )}
          </div>
        </div>
        <div className="col-xl-6 offset-xl-1 col-lg-6 col-12">
          <div className="row g-4">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="rounded-3 card-lift">
                <div
                  className="rounded-3 card-lift"
                  style={{
                    backgroundImage: `url(${Background1})`,
                    backgroundRepeat: "no-repeat",
                    height: "386px",
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div
                className="mb-4 rounded-3 card-lift"
                style={{
                  backgroundImage: `url(${Background2})`,
                  backgroundRepeat: "no-repeat",
                  height: "180px",
                  backgroundSize: "cover",
                }}
              ></div>

              <div
                className="mb-2 rounded-3 card-lift"
                style={{
                  backgroundImage: `url(${Background3})`,
                  backgroundRepeat: "no-repeat",
                  height: "180px",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const SectionContentStyle2 = (props) => {
  const { data, id = "" } = props;

  return (
    <Fragment>
      <section className="pt-12 pt-md-13 bg-gray-200">
        <div className="row align-items-center">
          <div className="col-12 col-md-5 col-lg-6 order-md-2">
            <img
              src={require("assets/images/feature/illustration-8.webp")}
              alt="..."
              className="img-fluid mb-6 mb-md-0"
            />
          </div>
          <div className="col-12 col-md-7 col-lg-6 order-md-1">
            <h2>
              Stay focused on your business. <br />
              <span className="text-primary">Let us handle the design</span>.
            </h2>

            <p className="fs-lg text-gray-700 mb-6">
              You have a business to run. Stop worring about cross-browser bugs,
              designing new pages, keeping your components up to date. Let us do
              that for you.
            </p>

            <div className="d-flex">
              <div className="pe-5">
                <h3 className="mb-0">
                  <span className="aos-init aos-animate">100</span>%
                </h3>
                <p className="text-gray-700 mb-0">Satisfaction</p>
              </div>
              <div className="border-start border-gray-300"></div>
              <div className="px-5">
                <h3 className="mb-0">
                  <span className="aos-init aos-animate">24</span>/
                  <span className="aos-init aos-animate">7</span>
                </h3>
                <p className="text-gray-700 mb-0">Support</p>
              </div>
              <div className="border-start border-gray-300"></div>
              <div className="ps-5">
                <h3 className="mb-0">
                  <span className="aos-init aos-animate">100</span>
                  k+
                </h3>
                <p className="text-gray-700 mb-0">Sales</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

const SectionContent = (props) => {
  const { data, id = "", type, showTitle, showDescription } = props;

  return (
    <Fragment>
      {type === "style1" && <SectionContentStyle1 data={data} />}
      {type === "style2" && <SectionContentStyle2 data={data} />}
    </Fragment>
  );
};

/**
 * Component for showing Section "Feature Additional" of the site.
 *
 * @component
 */
const FeatureAdditionalSection = (props) => {
  const {
    data,
    id = "lesson",
    cn = "pt-100 pb-100",
    type,
    showDescription = true,
    showSubtitle = false,
    useAnimation = false,
    ...otherProps
  } = props;

  console.log(data);

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader
          data={{ ...data }}
          showSubtitle={false}
          showDescription={false}
        />
        <SectionContent data={{ ...data }} type={type} {...otherProps} />
      </div>
    </PageSection>
  );
};

FeatureAdditionalSection.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1", "style2"]),
  showSubtitle: PropTypes.bool ?? false,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default FeatureAdditionalSection;
