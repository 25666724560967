import {
  StreamCall,
  StreamVideo,
  StreamVideoClient,
  useStreamVideoClient,
} from "@stream-io/video-react-sdk";
import { Banner } from "components/sections";
import { SEO } from "components/utils/Analytics";
import { Spinner } from "components/utils/Spinner";
import { useAuth } from "contexts/AuthProvider";
import { useProfile } from "contexts/ProfileProvider";
import { dataDailyVideosPage } from "data";
import { Fragment, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { MyUILayout } from "./Panel";

// NOTE :

export const STREAM_API_KEY = process.env.REACT_APP_STREAM_API_KEY;

// const apiKey = "mmhfdzb5evj2";
const apiKey = STREAM_API_KEY;
// const token ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiQ2Fybm9yX0pheCIsImlzcyI6Imh0dHBzOi8vcHJvbnRvLmdldHN0cmVhbS5pbyIsInN1YiI6InVzZXIvQ2Fybm9yX0pheCIsImlhdCI6MTcyNDIwNDMyNiwiZXhwIjoxNzI0ODA5MTMxfQ.sDFy_42-CzS_676CGZOX84xrMR2k-6Xy3dANkDi92SQ";
// const userId = "Carnor_Jax";
// const callId = "pwTNjKKPQBsl";
const callId = "bbe308c2-5f97-4765-860d-d1d6a7382f86";
// initialize the user object
// const user = {
//   id: userId,
//   name: "Oliver",
//   image: "https://getstream.io/random_svg/?id=oliver&name=Oliver",
// };

// const client = new StreamVideoClient({ apiKey, user, token });
// const call = client.call("audio_room", callId);
// call.join({
//   create: true,
//   data: {
//     members: [
//       /* { user_id: 'john_smith' }, { user_id: 'jane_doe' } */
//     ],
//     custom: {
//       title: "Test Room",
//       description: "Test React",
//     },
//   },
// });

const SectionContent = (props) => {
  const { data } = props;
  // v2
  const client = useStreamVideoClient();

  const call = client.call("audio_room", callId);
  call.join({
    create: true,
    data: {
      custom: {
        title: "Best Rooms",
        description: "Talking about Best fuck",
      },
    },
  });

  return (
    <Fragment>
      <StreamCall call={call}>
        {/** We will introduce the <MyUILayout /> component later */}
        <Container>
          <MyUILayout />
        </Container>
      </StreamCall>
    </Fragment>
  );
};

export const VideoProvider = ({ children }) => {
  const [videoClient, setVideoClient] = useState(null);
  const { profile } = useProfile();
  const { auth } = useAuth();

  console.log("!!!!", profile, auth);

  useEffect(() => {
    if (!profile || !profile.id || !auth || !auth.data?.getstreamToken) {
      return;
    }

    const initVideoClient = async () => {
      const user = {
        id: profile.id,
        name: profile.name ? profile.name : profile.username,
        image: "https://getstream.io/random_svg/?id=oliver&name=Oliver",
      };
      console.log(user);
      const client = new StreamVideoClient({
        apiKey,
        user,
        token: auth?.data?.getstreamToken,
      });
      setVideoClient(client);
    };

    initVideoClient();

    return () => {
      if (videoClient) {
        videoClient.disconnectUser();
      }
    };
  }, [profile]);

  if (!profile || !auth) {
    console.log("Login first");
  }

  if (!videoClient) {
    return (
      <Container
        style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
      >
        <Spinner />
      </Container>
    );
  }

  return <StreamVideo client={videoClient}>{children}</StreamVideo>;
};

const AudioRoomPage = () => {
  useEffect(() => {}, []);

  return (
    <Fragment>
      <SEO data={dataDailyVideosPage.meta}>
        <link rel="canonical" href="https://psywealth.com/meetings/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="audio-room-page" id="main">
        <Banner data={dataDailyVideosPage} />
        {/* <ChatSection data={{ ...dataDailyVideosPage }} />*/}
        <VideoProvider>
          <SectionContent />
        </VideoProvider>
      </main>
    </Fragment>
  );
};

export default AudioRoomPage;
