import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./RichTextEditor.scss";

const RichTextEditor = ({ control, defaultValue = "" }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [content, setContent] = useState("");

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));

    // Used draft-convert: https://blog.logrocket.com/build-rich-text-editors-react-draft-js-react-draft-wysiwyg/

    // let html = convertToHTML(editorState.getCurrentContent());
    // setConvertedContent(html);
  };

  useEffect(() => {
    setContent(defaultValue);

    /** Convert html string to draft JS */
    const contentBlock = htmlToDraft(defaultValue);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);

    setEditorState(editorState);
  }, []);

  useEffect(() => {
    if (content) {
      console.log(content);
    }
  }, [content]);

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      placeholder="The text goes here..."
      toolbarClassName="toolbarClassName"
      wrapperClassName="card"
      editorClassName="card-body"
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "list",
          "textAlign",
          "history",
          "embedded",
          "emoji",
          "image",
        ],
      }}
    />
  );
  /*
  return (
    <div
      style={{
        border: "1px solid #ccc",
        minHeight: 30,
        padding: 10,
      }}
    >
      <Controller
        name="DraftJS"
        control={control}
        render={({ value, onChange }) => (
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
          />
        )}
      />
    </div>
  );*/
};

export default RichTextEditor;
