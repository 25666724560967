export const dataMetaDailyVideosPage = {
  title: "Электронный архив «psywealth-Архив» в вопросах и ответах",
  //  Система электронного архива "«psywealth»": цифровое хранение документов
  description:
    "Как выбрать электронный архив? Сколько стоит система электронного документооборота? Самые популярные вопросы, которые задают клиенты при сравнении систем и проектов внедрения, и простые ответы от psywealth.",
  //`О компании «psywealth» - разработчике и поставщике сервисов для организации архива электронных документов и автоматизации делопроизводства. Телефон: ${dataSite.phone}, почта: ${dataSite.email}.`,
  name: "",
  type: "",
  author: "psywealth, Inc.",
  ExtraMetaTags: [],
  OG: [
    {
      property: "og:title",
      content: "Электронный архив «psywealth-Архив» в вопросах и ответах",
      // "Система электронного архива &quot;«psywealth»&quot;: цифровое хранение документов",
      //Облачный сервис Entera автоматически архивирует все отсканированные документы с учетом типа документа, регистра и параметров. Электронные копии документов будут доступны для экспорта на случай проверки или восстановления базы 1С. Копии автоматически “облегчаются” до размера, который полностью соответствует требованиям ФНС.
    },
    {
      property: "og:description",
      content:
        "Как выбрать электронный архив? Сколько стоит система электронного документооборота? Самые популярные вопросы, которые задают клиенты при сравнении систем и проектов внедрения, и простые ответы от psywealth.",
      //     "Электронный архив: создание и внедрение системы для организации и хранения ваших документов в цифровом виде. &quot;«psywealth»&quot;, звоните: +7 (812) 612-25-73!",
    },
    {
      property: "og:url",
      content: "https://psywealth.com/faq/",
    },
    {
      property: "og:type",
      content: "article",
    },
    {
      property: "og:image",
      content:
        "https://static.tildacdn.com/tild6464-3961-4635-b834-633364363338/Group_4.svg",
    },
    { property: "og:image:alt", content: "«psywealth-Архив»" },
    {
      property: "og:locale",
      content: "ru_RU",
    },
    {
      property: "og:site_name",
      content: "«psywealth-Архив»",
    },
  ],
  TW: [
    { property: "twitter:creator", content: "" },
    { property: "twitter:card", content: "summary_large_image" },
    { property: "twitter:title", content: "" },
    { property: "twitter:description", content: "" },
    { property: "twitter:label1", content: "Примерное время для чтения" },
    { property: "twitter:data1", content: "10 минуты" },
  ],
};
