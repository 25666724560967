import { BiCapsule } from "react-icons/bi";
import {
  TfiDashboard,
  TfiInfinite,
  TfiReload,
  TfiServer,
  TfiShortcode,
} from "react-icons/tfi";

export const dataServiceProductPage = {
  title: "Основные преимущества",
  subtitle: "Основные преимущества",
  description: "Основные преимущества электронного архива «psywealth-Архив»",
  items: [
    {
      title: "Безопасность и надежное хранение",
      subtitle: "",
      description:
        "Безопасность и надежное хранение неограниченного объема данных, быстрый поиск и удобная работа с информацией",
      image: null,
      icon: <BiCapsule />,
    },
    {
      title: "Современная архитектура",
      subtitle: "",
      description:
        "Современная архитектура, кроссплатформенность и масштабируемость позволяют быстро и эффективно внедрять высокопроизводительные решения в любой ИТ-среде",
      image: null,
      icon: <TfiShortcode />,
    },
    {
      title: "Средства интеграции с внешними системами",
      subtitle: "",
      description:
        "Средства интеграции с внешними системами с использованием SOAP-сервисов и модулей-адаптеров к внешним информационным системам (ERP, СЭД, CRM, порталам и т.д.)",
      image: null,
      icon: <TfiReload />,
    },
    {
      title: "Версионность документов и справочников",
      subtitle: "",
      description:
        "Версионность документов и справочников. Сохранение исторической информации и возможность поиска с учетом сохраненных версий",
      image: null,
      icon: <TfiServer />,
    },
    {
      title: "Поддержка любых баз данных MS SQL, Oracle, PostgreSQL и других",
      subtitle: "",
      description:
        "Поддержка любых баз данных MS SQL, Oracle, PostgreSQL и других",
      image: null,
      icon: <TfiInfinite />,
    },
    {
      title: "Непревзойденная производительность",
      subtitle: "",
      description:
        // Модули для расширения функционала системы: конструктор отчетов, модуль автоматизации бизнес-процессов, внеофисное хранение, учет бумажных документов и другие
        "Секторное распределение данных с постоянной репликацией хранения позволяет выдерживать высочайшие нагрузки без потери производительности и скорости доступа к информации",
      image: null,
      icon: <TfiDashboard />,
    },
  ],
};

export const dataServiceServicePage = {
  title: "Вы решите проблему, обретете гармонию в жизни и на работе",
  subtitle: "Наши сервисы",
  description: "",
  items: [
    {
      id: 1,
      title: "Выгорание",
      subtitle: "",
      description:
        "Выявите причину, научитесь говорить «нет» без угрызения совести",
      image: require("assets/images/services/service-1-1.webp"),
      icon: null,
    },
    {
      id: 2,
      title: "Отношения",
      subtitle: "",
      description:
        "Научитесь ценить себя в отношениях с партнером, семьей и друзьями ",
      image: require("assets/images/services/service-1-2.webp"),
      icon: null,
    },
    {
      id: 3,
      title: "Стресс",
      subtitle: "",
      description:
        "Найдем причины беспокойства и научимся справляться со стрессом",
      image: require("assets/images/services/service-1-3.webp"),
      icon: null,
    },
    {
      id: 4,
      title: "Материнство ",
      subtitle: "",
      description:
        "Проведем через беременность и роды, чтобы вы стали счастливой мамой",
      image: require("assets/images/services/service-1-4.webp"),
      icon: null,
    },
    {
      id: 5,
      title: "Послеродовая депрессия",
      subtitle: "",
      description:
        "Полюбите себя полностью, обретете в материнстве покой и любовь",
      image: require("assets/images/services/service-1-5.webp"),
      icon: null,
    },
    {
      id: 6,
      title: "Эмоциональная нестабильность",
      subtitle: "",
      description:
        "Обретёте покой в душе и сердце, научитесь беречь себя от раздражителей",
      image: require("assets/images/services/service-1-6.webp"),
      icon: null,
    },
    {
      id: 7,
      title: "Самооценка",
      subtitle: "",
      description:
        "Обретете уверенность и ценность себя, поймёте, как о себе заботиться",
      image: require("assets/images/services/service-1-7.webp"),
      icon: null,
    },
    {
      id: 8,
      title: "Панические атаки",
      subtitle: "",
      description: "Найдём корень панических атак и устраним их раз и навсегда",
      image: require("assets/images/services/service-1-8.webp"),
      icon: null,
    },
    {
      id: 9,
      title: "Болезни и психопатологии",
      subtitle: "",
      description:
        "Справитесь с результатом тяжелой/хронической болезни вашей или близких",
      image: require("assets/images/services/service-1-9.webp"),
      icon: null,
    },
  ],
};
