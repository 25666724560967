import { dataSite } from "data/dataSite";

export const dataMetaMaintenancePage = {
  title: "Страница не найдена - электронный архив «psywealth»",
  //  Система электронного архива "«psywealth»": цифровое хранение документов
  description: `О компании «psywealth» - разработчике и поставщике сервисов для организации архива электронных документов и автоматизации делопроизводства. Телефон: ${dataSite.phone}, почта: ${dataSite.email}.`,
  name: "",
  type: "",
  author: "psywealth, Inc.",
  ExtraMetaTags: [{ name: "robots", content: "noindex, follow" }],
  OG: [
    {
      property: "og:title",
      content: "Страница не найдена - электронный архив «psywealth»",
      // "Система электронного архива &quot;«psywealth»&quot;: цифровое хранение документов",
      //Облачный сервис Entera автоматически архивирует все отсканированные документы с учетом типа документа, регистра и параметров. Электронные копии документов будут доступны для экспорта на случай проверки или восстановления базы 1С. Копии автоматически “облегчаются” до размера, который полностью соответствует требованиям ФНС.
    },
    {
      property: "og:description",
      content: `О компании «psywealth» - разработчике и поставщике сервисов для организации архива электронных документов и автоматизации делопроизводства. Телефон: ${dataSite.phone}, почта: ${dataSite.email}.`,
      //     "Электронный архив: создание и внедрение системы для организации и хранения ваших документов в цифровом виде. &quot;«psywealth»&quot;, звоните: +7 (812) 612-25-73!",
    },
    {
      property: "og:url",
      content: "https://psywealth.com/about/",
    },
    {
      property: "og:type",
      content: "article",
    },
    {
      property: "og:image",
      content:
        "https://static.tildacdn.com/tild6464-3961-4635-b834-633364363338/Group_4.svg",
    },
    { property: "og:image:alt", content: "psywealth" },
    {
      property: "og:locale",
      content: "ru_RU",
    },
    {
      property: "og:site_name",
      content: "psywealth",
    },
  ],
  TW: [
    { property: "twitter:creator", content: "" },
    { property: "twitter:card", content: "summary_large_image" },
    { property: "twitter:title", content: "" },
    { property: "twitter:description", content: "" },
    { property: "twitter:label1", content: "Примерное время для чтения" },
    { property: "twitter:data1", content: "10 минуты" },
  ],
};
