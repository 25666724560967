import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classnames from "classnames";
import { Fragment } from "react";
import { Table as BSTable } from "react-bootstrap";

/**
 * Component Custom Table.
 *
 * @component
 */
const CustomTable = (props) => {
  const { data, columns, tableClass, headerClass } = props;
  // console.log("!!!!", data, props);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Fragment>
      {props.data && (
        <BSTable className={classnames("table", tableClass)}>
          <thead className={classnames(headerClass)}>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, indexHeader) => {
                    return (
                      <th
                        key={header.id}
                        id={header.id}
                        className={classnames("border-0", {
                          "rounded-start": indexHeader === 0,
                          "rounded-end":
                            indexHeader === headerGroup.headers.length - 1,
                        })}
                        scope="col"
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </BSTable>
      )}
    </Fragment>
  );
};

export default CustomTable;
