import { SEO } from "components/utils/Analytics";
import { dataCountDownPage, dataSite } from "data";
import { Fragment } from "react";
import Countdown from "../../sections/TypicalSections/Countdown/Countdown";

import "./CountDownPage.scss";

const msgTitle = dataSite.countdown.title;
const msgSubtitle = dataSite.countdown.subtitle;
const msgDescription = dataSite.countdown.description;

/**
 * Component for showing Page "Countdown" of the site.
 *
 * @component
 */
const CountDownPage = () => {
  return (
    <Fragment>
      <SEO data={dataCountDownPage.meta}>
        <link rel="canonical" href="https://psywealth.com/countdown/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="page-countdown">
        <Countdown
          title={msgTitle}
          subtitle={msgSubtitle}
          description={msgDescription}
        >
          {/* <FormSubscribe />*/}
        </Countdown>
      </main>
    </Fragment>
  );
};

export default CountDownPage;
