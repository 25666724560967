import { APP_API_URI } from "config/config";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

// NOTE :

const CardImage = (props) => {
  const { data } = props;

  const getURL = (imageId) => {
    return `${APP_API_URI}/psychologists/psychologist/profile/${imageId}/image`;
  };

  return (
    <img
      className="img-fluid rounded-circle"
      src={
        data.id
          ? getURL(data.id)
          : "https://www.bootdey.com/image/160x160/00FFFF/000000"
      }
      alt={data.tittle}
      width="160"
      height="160"
      loading="lazy"
    />
  );
};

const ComponentContextType1 = (props) => {
  const { data } = props;

  // console.log(data);

  const getURL = (imageId) => {
    return `${APP_API_URI}/psychologists/psychologist/profile/${imageId}/image`;
  };

  return (
    <Card>
      <Link to={`/therapists/${data.slug}`}>
        <Card.Img
          variant="top"
          className="img-fluid rounded-circle"
          src={
            data.id
              ? getURL(data.id)
              : "https://www.bootdey.com/image/160x160/00FFFF/000000"
          }
          alt={data.id || ""}
        />
      </Link>

      <div className="card-body blog-inner-fourt-main">
        <div className="blog-inner-right-fourt">
          <Link href={`/therapists/${data.slug}`}>
            <div className="blog-inner-right-img">
              <img
                src={`${APP_API_URI}/psychologists/psychologist/profile/${data.id}/image`}
                alt="image"
                className="img-fluid"
              />
              <div className="blog-inner-top-content">
                <img
                  src="/react/template/7058680646be673ef70f.jpg"
                  alt=""
                  className="me-2"
                />
                <span>{data.name}</span>
              </div>
            </div>
          </Link>
          <Link
            className="blog-inner-right-fourt-care"
            href={`/therapists/${data.slug}`}
          >
            {data.ex}
          </Link>
          <ul className="articles-list nav blog-articles-list">
            <li>
              <i>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-calendar "
                >
                  <g>
                    <rect
                      x="3"
                      y="4"
                      width="18"
                      height="18"
                      rx="2"
                      ry="2"
                    ></rect>
                    <line x1="16" y1="2" x2="16" y2="6"></line>
                    <line x1="8" y1="2" x2="8" y2="6"></line>
                    <line x1="3" y1="10" x2="21" y2="10"></line>
                  </g>
                </svg>
              </i>{" "}
              13 Aug, 2023
            </li>
            <li>
              <i>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-message-square "
                >
                  <g>
                    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                  </g>
                </svg>
              </i>{" "}
              68
            </li>
            <li>
              <i>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-eye "
                >
                  <g>
                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                    <circle cx="12" cy="12" r="3"></circle>
                  </g>
                </svg>
              </i>{" "}
              1.5k
            </li>
          </ul>
          <ul className="articles-list nav blog-articles-list-two">
            <li>Dog</li>
            <li>Health</li>
            <li>Diet Foods</li>
          </ul>
          <div className="text-grey text-sm">Психолог о себе</div>
          <p
            className="d-inline-block text-truncate"
            style={{ maxWidth: "450px" }}
          >
            {data.about}
          </p>
        </div>
      </div>
    </Card>
  );
};

const ComponentContextType2 = (props) => {
  const { data, index, type } = props;

  const therapistsProfileLink = `/therapists/${data.slug}`;

  console.log(data);

  // <div className="col-lg-6 col-md-12 col-12">
  //   <div className="card blog-grid">
  //     <div className="card-image blog-grid-img position-relative">
  //       <CardImage data={data} type={type} />
  //     </div>
  //     <div className="card-content blog-grid-text p-4">
  //       <h2 className="card-title h5 mb-3">
  //         {type === "lesson" ? (
  //           <Link to={`/courses/${data.courseSlug}/${data.slug}`}>
  //             {data.name}
  //           </Link>
  //         ) : (
  //           <Link to={`/courses/${data.slug}`}>{data.name}</Link>
  //         )}
  //       </h2>
  //       <p className="display-30">{data.description}</p>
  //     </div>
  //   </div>
  // </div>;

  return (
    <div className="box card">
      <Link to={therapistsProfileLink}>
        <CardImage data={data} />
      </Link>
      <Link to={therapistsProfileLink}>
        <h5 className="name">{data.name}</h5>
      </Link>
      <p className="qualification">{data.qualification}</p>
      <div className="text-grey text-sm">Психолог о себе</div>
      <p className="description">{data.experience}</p>
      <Link className="btn btn-link theme-btn" to={therapistsProfileLink}>
        Обратиться
      </Link>
    </div>
  );
};

/**
 * Component for showing Section "Card Course" of the site.
 *
 * @component
 */
const CardTherapist = (props) => {
  const { data, index, type } = props;
  // console.log("@@@@", data, index);

  return (
    <Fragment>
      {type === "style1" ? (
        <ComponentContextType1 data={data} />
      ) : (
        <ComponentContextType2 data={data} />
      )}
    </Fragment>
  );
};

CardTherapist.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  type: PropTypes.oneOf(["style1", "style2"]),
};

CardTherapist.defaultProp = {};

export default CardTherapist;
