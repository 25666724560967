import { InputFloatingLabelEmail } from "components/utils/Forms";
import { isDevelopment } from "components/utils/Utils";
import { dataForm } from "data";
import { Fragment, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { _debug } from "utils";

import "./AuthPage2.scss";

// NOTE : https://demo.bootstrapdash.com/login-templates-pro/login-8/#!

const msgSignIn = "Войти";
const msgSignUp = "Зарегистрироваться";
const msgAgreement =
  "Создавая аккаунт, вы соглашаетесь с условиями предоставления услуг и даёте согласие на обработку персональных данных.";
const msgSubmitted = "Сообщение успешно отправлено";
const msgSubmit = "Отправить сообщение";

const AuthForm = (props) => {
  const { mode, onChangeMode } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const {
    methods,
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      username: isDevelopment ? "User4" : "",
      email: isDevelopment ? "user4@mail.ru" : "",
      password: isDevelopment ? "user123" : "",
      confirmPassword: isDevelopment ? "user123" : "",
      remember: true,
      agreement: true,
    },
  });
  const [validate, setValidate] = useState({});
  // console.log("!!!", mode, props);

  const onSubmit = async (data) => {
    //e.preventDefault();
    _debug("Form submit: ", data);
    // console.log("!!!", data, mode, props);

    setError(null);
    try {
      //gaEventTracker("submitFromCountDown");

      const response = await props.onSubmit(
        mode === "signin"
          ? {
              username: data.email,
              password: data.password,
            }
          : {
              username: data.username,
              email: data.email,
              phone: data?.phone,
              password: data.password,
            }
      );

      // console.log("!!!-1", response);
      if (response && response.error) {
        setError(response.error);
        setSuccess(false);
        return;
      }

      setSuccess(true);
    } catch (error) {
      console.error("API request error:", error.message);
      setError(error.message);
      setSuccess(false);
    } finally {
      reset();
    }
  };

  const togglePassword = (e) => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const FormInputEmail = () => {
    return (
      <Form.Group controlId="form.email" className="form-group">
        <Form.Label className="sr-only">Почта</Form.Label>
        <Form.Control
          type="text"
          placeholder="Email"
          className={`${
            validate.validate && validate.validate.email ? "is-invalid " : ""
          }`}
          {...register("email", {
            required: true,
          })}
        />
        <Form.Text
          id="emailHelpBlock"
          muted
          hidden={!(validate.validate && validate.validate.email)}
        >
          Ваш адрес электронной почты должен содержать буквы и цифры и не должен
          содержать пробелы, специальные символы или эмодзи.
        </Form.Text>
        <div
          className={`invalid-feedback text-start ${
            validate.validate && validate.validate.email ? "d-block" : "d-none"
          }`}
        >
          {validate.validate && validate.validate.email
            ? validate.validate.email[0]
            : ""}
        </div>
      </Form.Group>
    );
  };

  const FormInputPassword = () => {
    return (
      <Form.Group controlId="form.password" className="form-group mb-3">
        <Form.Label className="sr-only">Пароль</Form.Label>
        <InputGroup className="">
          <Form.Control
            type={showPassword ? "text" : "password"}
            className={`${
              validate.validate && validate.validate.password
                ? "is-invalid "
                : ""
            }`}
            placeholder="Ваш пароль"
            {...register("password", {
              required: true,
            })}
            aria-label="User's password"
            aria-describedby="basic-addon2"
          />
          <Button
            variant="outline-secondary"
            onClick={(e) => togglePassword(e)}
            id="button-addon3"
          >
            {showPassword ? <FaEye /> : <FaEyeSlash />}
          </Button>
        </InputGroup>
        <Form.Text
          id="passwordHelpBlock"
          muted
          hidden={!(validate.validate && validate.validate.email)}
        >
          Ваш пароль должен состоять из 8-20 символов, содержать буквы и числа и
          не должны содержать пробелов, специальных символов или смайлики.
        </Form.Text>
      </Form.Group>
    );
  };

  const FormInputPassword1 = () => {
    return (
      <Form.Group controlId="form.password" className="form-group mb-3">
        <Form.Label className="sr-only">Пароль</Form.Label>
        <InputGroup className="">
          <Form.Control
            type={showPassword ? "text" : "password"}
            className={`${
              validate.validate && validate.validate.password
                ? "is-invalid "
                : ""
            }`}
            placeholder="Ваш пароль"
            {...register("password", {
              required: true,
            })}
            aria-label="User's password"
            aria-describedby="basic-addon2"
          />
          <Button
            variant="outline-secondary"
            onClick={(e) => togglePassword(e)}
            id="button-addon3"
          >
            {showPassword ? <FaEye /> : <FaEyeSlash />}
          </Button>
        </InputGroup>
        <Form.Text
          id="passwordHelpBlock"
          muted
          hidden={!(validate.validate && validate.validate.email)}
        >
          Ваш пароль должен состоять из 8-20 символов, содержать буквы и числа и
          не должны содержать пробелов, специальных символов или смайлики.
        </Form.Text>
      </Form.Group>
    );
  };

  return (
    <div className="auth-form-wrapper">
      <FormProvider {...methods}>
        <Form
          className="auth-form"
          method="POST"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete={"off"}
        >
          {mode === "signup" ? (
            <Fragment>
              <Form.Group controlId="formAuthEmail">
                <InputFloatingLabelEmail
                  label={dataForm.email.msgLabel}
                  placeholder={dataForm.email.msgPlaceholder}
                  required={dataForm.email.msgRequired}
                  register={register}
                  errors={errors}
                />
              </Form.Group>
              <FormInputPassword />
              <Form.Group
                controlId="form.confirm.password"
                className="form-group mb-3"
              >
                <Form.Label className="sr-only">
                  Подтверждение пароля
                </Form.Label>
                <InputGroup className="">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    className={`${
                      validate.validate && validate.validate.password
                        ? "is-invalid "
                        : ""
                    }`}
                    placeholder="Confirm Password"
                    {...register("confirmPassword", {
                      required: true,
                    })}
                    aria-label="User's confirm password"
                    aria-describedby="basic-addon2"
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={(e) => togglePassword(e)}
                    id="button-addon2"
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </Button>
                </InputGroup>
                <Form.Text
                  id="passwordHelpBlock"
                  muted
                  hidden={!(validate.validate && validate.validate.email)}
                >
                  Ваш пароль должен состоять из 8-20 символов, содержать буквы и
                  числа и не должны содержать пробелов, специальных символов или
                  смайлики.
                </Form.Text>
              </Form.Group>
              <Form.Group
                controlId="form.agreement"
                className="login-check-box"
              >
                <Form.Check
                  type="checkbox"
                  label={msgAgreement}
                  id="agreement"
                  {...register("agreement", {
                    required: true,
                  })}
                />
              </Form.Group>
            </Fragment>
          ) : (
            <Fragment>
              <Form.Group controlId="formAuthEmail">
                <InputFloatingLabelEmail
                  label={dataForm.email.msgLabel}
                  placeholder={dataForm.email.msgPlaceholder}
                  required={dataForm.email.msgRequired}
                  register={register}
                  errors={errors}
                />
              </Form.Group>
              <FormInputPassword />
              <Form.Group controlId="form.remember" className="login-check-box">
                <Form.Check
                  type="checkbox"
                  label="Запомнить"
                  id="remember"
                  {...register("remember", {
                    required: true,
                  })}
                />
              </Form.Group>
            </Fragment>
          )}

          {mode === "signup" ? (
            <div className="text-end">
              <Link className="btn btn-link" onClick={props.onChangeMode}>
                Уже есть аккаунт
              </Link>
            </div>
          ) : (
            <div className="text-end">
              Нет аккаунта?{" "}
              <Link className="btn btn-link" onClick={props.onChangeMode}>
                Зарегистрироваться
              </Link>
            </div>
          )}

          <div className="d-flex justify-content-between align-items-center mt-5 mb-5">
            <Button
              className="btn btn-primary theme-btn"
              variant="primary"
              type="submit"
            >
              {mode === "signin" ? msgSignIn : msgSignUp}
            </Button>
            <div className="forgot-password text-end">
              <Link to="/forgot-password">Забыли пароль?</Link>
            </div>
          </div>
        </Form>
        {error && (
          <p className="text-warning" style={{ textAlign: "center" }}>
            <small>{error}</small>
          </p>
        )}
        {success && (
          <p className="text-success" style={{ textAlign: "center" }}>
            <small>{msgSubmitted}</small>
          </p>
        )}
      </FormProvider>
    </div>
  );
};

export default AuthForm;
