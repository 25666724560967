import { Spinner } from "components/utils/Spinner";
import { useIsMobile } from "hooks";
import { PropTypes } from "prop-types";
import { Fragment, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { PageSection, SectionHeader } from "../../../sections/CommonSection";
import { useGetTopCourses } from "./useGetTopCourses";

import { CardCourse } from "components/pages/Courses";
import { dataMessages } from "data";
import "./TopCourses.scss";

// NOTE :

const SectionGoToCourses = (props) => {
  // const {} = props;
  // console.log("@@@@", data, index);

  return (
    <div className="col-lg-6 col-md-12 col-12">
      <div className="card blog-grid">
        <div className="card-content blog-grid-text p-4 align-middle">
          <h2 className="card-title h5 mb-3">
            <Link to={"/courses"} className="btn btn-primary theme-btn">
              Смотреть все курсы
            </Link>
          </h2>
          <p className="display-30">Смотреть все курсы</p>
        </div>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data, isMobile } = props;

  const {
    data: topCourses,
    isLoading: isLoadingTopCourses,
    isError: isErrorTopCourses,
    error: errorTopCourses,
  } = useGetTopCourses();

  const isLoading = isLoadingTopCourses;
  const isError = isErrorTopCourses;
  const errors = [errorTopCourses];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (topCourses) {
      _debug("TOP COURSES:", topCourses);
    }
  }, [topCourses]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isErrorTopCourses && errorTopCourses?.status === 204) {
    return <div className="error">Нет курсов</div>;
  }

  return (
    <Fragment>
      <div className="row">
        <Carousel variant="dark">
          {topCourses &&
            topCourses.length > 0 &&
            topCourses.map((item, index) => {
              return (
                <Carousel.Item index={index} key={index}>
                  <div className="row equal">
                    <CardCourse
                      data={item}
                      index={index}
                      key={index}
                      type="course"
                    />
                    {topCourses.length > index && topCourses[index + 1] ? (
                      <CardCourse
                        data={topCourses[index + 1]}
                        index={index + 1}
                        key={index + 1}
                        type="course"
                      />
                    ) : (
                      <SectionGoToCourses />
                    )}
                  </div>
                </Carousel.Item>
              );
            })}
        </Carousel>
      </div>
    </Fragment>
  );
};

/**
 * Component for showing Section "Top 5 Our Courses" of the site.
 *
 * @component
 */
const TopCourses = (props) => {
  const { data, id = "top-courses", cn = "pt-80" } = props;
  const isMobile = useIsMobile(768);

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} />
        <SectionContent data={data} isMobile={false} />
      </div>
    </PageSection>
  );
};

TopCourses.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default TopCourses;
