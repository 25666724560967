import {
  CountDownPage,
  FAQPage,
  HomePage,
  PayPage,
  SearchPage,
} from "components/pages";
import {
  CustomerAuthPage,
  CustomerLogoutPage,
  ForgotPage,
  PsychologistAuthPage,
} from "components/pages/Auth";
import {
  BlogLayout,
  PostsByCategoryPage,
  PostsByTagPage,
  PostsListPage,
  SinglePostPage,
} from "components/pages/Blog";
import { CoursePage, CoursesPage, LessonPage } from "components/pages/Courses";
import {
  CustomerProfileTabBio,
  CustomerProfileTabDelete,
  CustomerProfileTabOrders,
  CustomerProfileTabPayments,
  CustomerProfileTabProfile,
  CustomerProfileTabSettings,
  CustomerProfileTabWishlist,
} from "components/pages/Customer/components/ProfileTabs";
import { CustomerEntryPage } from "components/pages/CustomerForm";
import { DailyVideosPage } from "components/pages/DailyVideos";
import {
  GiftPage,
  GiftPayedPage,
  GiftPurchasePage,
} from "components/pages/Gifts";
import GlossaryPage from "components/pages/Glossary/GlossaryPage";
import Profile from "components/pages/Home/Profile";
import PartnersPage from "components/pages/Partners/PartnersPage";
import { TermsPage } from "components/pages/Policy";

import { CustomerProfileLayout } from "components/pages/Customer";
import { CustomerProfileTabAppointments } from "components/pages/Customer/components/ProfileTabs";
import { PsychologistPage } from "components/pages/Partners1";
import { PaymentSuccessfulPage } from "components/pages/Payment";
import { PsychologistProfileLayout } from "components/pages/Psychologist";
import {
  PsychologistAvailableTimings,
  PsychologistProfileEducationEdit,
  PsychologistProfileTabAppointments,
  PsychologistProfileTabBio,
  PsychologistProfileTabDelete,
  PsychologistProfileTabEducation,
  PsychologistProfileTabOrders,
  PsychologistProfileTabPayments,
  PsychologistProfileTabProduct,
  PsychologistProfileTabProfile,
  PsychologistProfileTabSettings,
  PsychologistProfileTabWishlist,
} from "components/pages/Psychologist/components/ProfileTabs";
import { ServicesPage } from "components/pages/Services";
import { OurTeamPage } from "components/pages/Team";
import {
  CustomerCalendar,
  TherapistsPage,
  TherapistsSinglePage,
} from "components/pages/Therapists";
import CalendarTestPage from "components/pages/Therapists/CalendarTest.page";
import { TherapistAppointmentPage } from "components/pages/Therapists/components/Appointment";
import { VideoCallsPage } from "components/pages/VideoCalls";
import AudioRoomPage from "components/pages/VideoCalls/Room/AudioRoom.page";
import { SendGA4, SendYA } from "components/utils/Analytics/Analytics";
import { isDevelopment } from "components/utils/Utils";
import { useAuth } from "contexts/AuthProvider";
import { DemoPage, FreePsychologicalHelpPage } from "pages";
import { lazy, useEffect } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom/dist";
import AppLayout from "./AppLayout";

const NotFoundPageLazy = lazy(() =>
  import("components/pages/NotFound/NotFoundPage")
);

const ContactsPageLazy = lazy(() =>
  import("components/pages/Contact/ContactsPage")
);
const TypographyPageLazy = lazy(() =>
  import("components/pages/Typography/SectionTypography")
);

const AppRoutes = () => {
  const location = useLocation();
  const { auth, isAuthenticated } = useAuth();

  const ProtectedRoute = ({
    isAllowed,
    redirectPath = "/landing",
    children,
  }) => {
    if (!isAllowed) {
      return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
  };

  useEffect(() => {
    // Google Analytics - Send pageview with a custom path
    //ReactGA.set({ page: location.pathname });
    //ReactGA.pageview(location.pathname + location.search);
    SendGA4(location.pathname + location.search);

    // Yandex Analytics - Send pageview with a custom path
    SendYA(location.pathname + location.search);
  }, [location]);

  return (
    <Routes>
      <Route path="/countdown" element={<CountDownPage />} />
      {/*<Route path="/login" element={<AuthPage />} />*/}
      {/*<Route path="/register" element={<RegisterPage />} />*/}
      <Route path="/auth" element={<CustomerAuthPage />} />
      {/*<Route path="/psy/auth" element={<PsychologistAuthPage />} />*/}
      <Route path="/logout" element={<CustomerLogoutPage />} />
      <Route path="/auth/forgot-password" element={<ForgotPage />} />

      <Route path="/psychologist/auth" element={<PsychologistAuthPage />} />
      <Route path="/psychologist/logout" element={<PsychologistAuthPage />} />

      <Route path="/" element={<AppLayout />}>
        <Route index element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/demo" element={<DemoPage />} />
        <Route path="/team" element={<OurTeamPage />} />
        <Route path="/partners" element={<PartnersPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/psychologist">
          <Route index element={<PsychologistPage />} />
        </Route>
        <Route path="/launcher" element={<CustomerEntryPage />} />
        <Route path="/gifts" element={<GiftPage />} />
        <Route path="/gifts/purchase" element={<GiftPurchasePage />} />
        <Route path="/gifts/payed" element={<GiftPayedPage />} />
        <Route path="/therapists">
          <Route index element={<TherapistsPage />} />
          <Route path=":slug" element={<TherapistsSinglePage />} />
          <Route path="cal" element={<CustomerCalendar />} />
        </Route>
        {/* OLD - 
        <Route path="/psy" element={<PsyProfilesPage />}>
          <Route index element={<PsyProfileDashboardPage />} />
          <Route path="dashboard" element={<PsyProfileDashboardPage />} />
          <Route path="request" element={<PsyProfileRequestPage />} />
          <Route path="appointments" element={<PsyProfileAppointmentsPage />} />
          <Route
            path="available-timings"
            element={<PsyProfileAvailableTimingsPage />}
          />
          <Route path="my-patients" element={<PsyProfileMyPatientsPage />} />
        </Route>*/}
        <Route path="/courses">
          <Route index element={<CoursesPage />} />
          <Route path=":courseSlug/:lessonSlug" element={<LessonPage />} />
          <Route path=":courseSlug" element={<CoursePage />} />
        </Route>
        <Route path="/top-themes">
          <Route index element={<DailyVideosPage />} />
        </Route>
        {/*<Route path="/me" element={<CustomerPage />} />*/}
        {/* CUSTOMER - PROFILE*/}
        <Route
          path="/customer"
          element={
            <ProtectedRoute
              redirectPath="/auth"
              isAllowed={
                !!isAuthenticated &&
                !!auth &&
                auth.permissions.includes("CUSTOMER")
              }
            />
          }
        >
          <Route path="profile" element={<CustomerProfileLayout />}>
            <Route index element={<CustomerProfileTabProfile />} />
            <Route path="bio" element={<CustomerProfileTabBio />} />
            <Route
              path="appointments"
              element={<CustomerProfileTabAppointments />}
            />
            <Route path="orders" element={<CustomerProfileTabOrders />} />
            <Route path="payments" element={<CustomerProfileTabPayments />} />
            <Route path="wishlist" element={<CustomerProfileTabWishlist />} />
            <Route path="settings" element={<CustomerProfileTabSettings />} />
            <Route
              path="account-delete"
              element={<CustomerProfileTabDelete />}
            />
          </Route>
        </Route>
        {/* PSYCHOLOGIST - PROFILE*/}
        <Route
          path="/psychologist"
          element={
            <ProtectedRoute
              redirectPath="/auth"
              isAllowed={
                !!isAuthenticated &&
                !!auth &&
                auth.permissions.includes("PSYCHOLOGIST")
              }
            />
          }
        >
          <Route path="profile" element={<PsychologistProfileLayout />}>
            <Route index element={<PsychologistProfileTabProfile />} />
            <Route path="bio" element={<PsychologistProfileTabBio />} />
            <Route
              path="education"
              element={<PsychologistProfileTabEducation />}
            >
              <Route
                path=":id"
                element={<PsychologistProfileEducationEdit />}
              />
            </Route>
            <Route path="product" element={<PsychologistProfileTabProduct />} />

            <Route
              path="available-timings"
              element={<PsychologistAvailableTimings />}
            />

            <Route
              path="appointments"
              element={<PsychologistProfileTabAppointments />}
            />

            <Route path="orders" element={<PsychologistProfileTabOrders />} />
            <Route
              path="payments"
              element={<PsychologistProfileTabPayments />}
            />
            <Route
              path="wishlist"
              element={<PsychologistProfileTabWishlist />}
            />
            <Route
              path="settings"
              element={<PsychologistProfileTabSettings />}
            />
            <Route
              path="account-delete"
              element={<PsychologistProfileTabDelete />}
            />
          </Route>
        </Route>
        {/* <Route path="/tests" element={<TestsPage />} />>*/}
        <Route path="/test" element={<Profile />} />
        <Route path="/test1" element={<CalendarTestPage />} />
        <Route path="/test2" element={<TherapistAppointmentPage />} />
        <Route path="/payment/successful" element={<PaymentSuccessfulPage />} />
        <Route path="/contacts" element={<ContactsPageLazy />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/policy">
          <Route index element={<TermsPage />} />
          <Route path="terms" element={<TermsPage />} />
          <Route path="pay" element={<PayPage />} />
        </Route>
        <Route path="/search" element={<SearchPage />} />
        <Route path="/blog" element={<BlogLayout />}>
          <Route index element={<PostsListPage />} />
          <Route path=":slug" element={<SinglePostPage />} />
          <Route path="category/:slug" element={<PostsByCategoryPage />} />
          <Route path="tag/:slug" element={<PostsByTagPage />} />
        </Route>
        <Route path="/glossary" element={<GlossaryPage />} />
        <Route path="/calls/video" element={<VideoCallsPage />} />
        <Route path="/calls/audio" element={<AudioRoomPage />} />
        {isDevelopment && (
          <Route path="/typography" element={<TypographyPageLazy />} />
        )}
        <Route
          path="/free/psychological-help"
          element={<FreePsychologicalHelpPage />}
        />

        {/* Catch all route */}
        <Route path="*" element={<NotFoundPageLazy />} status={404} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
