export const dataMetaFreeHelpPage = {
  title:
    "Бесплатная психологическая помощь - телефон доверия, общение | Куда обратиться?",
  //   Бесплатный онлайн психолог | Психологическая помощь анонимно
  description:
    "Бесплатная психологическая помощь ✔︎ Доступная консультация психолога ✔︎ Куда обратиться за поддержкой в сложной жизненной ситуации ✔︎ Телефоны доверия психотерапевта, горячая линия круглосуточно в Москве, Санкт-Петербурге и Краснодаре. Анонимно.",
  name: "",
  type: "",
  author: "psywealth, Inc.",
  ExtraMetaTags: [],
  OG: [
    {
      property: "og:title",
      content: "Бесплатная психологическая помощь - «PsyWealth»",
    },
    {
      property: "og:description",
      //      content: `✔︎ Бесплатные консультации психологов ✔︎ Доступная психологическая помощь по телефону ✔︎Контакты экстренных служб поддержки`,
      content:
        "Бесплатная психологическая помощь ✔️ Онлайн ✔️ Консультация психолога ✔️ Доступная психологическая помощь ✔️ Телефон доверия ✔️ Консультации психологов и психотерапевтов ✔️ Анонимное общение с психологом. Номера горячей линии в Москве и по всей России.",
    },
    {
      property: "og:url",
      content: "https://psywealth.com/free/psychological-help",
    },
    {
      property: "og:type",
      // content: "article",
      content: "website",
    },
    {
      property: "og:image",
      content:
        "https://static.tildacdn.com/tild6464-3961-4635-b834-633364363338/Group_4.svg",
    },
    { property: "og:image:alt", content: "psywealth" },
    {
      property: "og:locale",
      content: "ru_RU",
    },
    {
      property: "og:site_name",
      content: "psywealth",
    },
  ],
  TW: [],
};
