import classNames from "classnames";
import { VideoModal } from "components/sections/TypicalSections";
import { useState } from "react";
import { IoPlay } from "react-icons/io5";
import { Link } from "react-router-dom";

// NOTE : https://prium.github.io/reign-IV/

const videoURL = "https://www.youtube.com/embed/C0DPdy98e4c";

/**
 * Component for showing Section "Daily Video" of the site.
 *
 * @component
 */
const CardDailyVideo = (props) => {
  const { data, index } = props;
  const isOdd = index % 2 === 0;
  // if (index % 2 !== 0) return

  const [isOpen, setIsOpen] = useState(false);

  const openModal = (event) => {
    event.preventDefault();
    setIsOpen(true);
  };
  const closeModal = (event) => {
    event.preventDefault();
    setIsOpen(false);
  };

  console.log(props);

  return (
    <div className={classNames("col-lg-6 mb-5", isOdd ? "pl-lg-4" : "pr-lg-4")}>
      <div className="shadow-md hover-shadow-soft position-relative video-card">
        <img
          className="img-fluid w-100 rounded-soft"
          src={require("assets/images/demo/one-page.webp")}
          alt=""
        />

        <div className="btn-play-wrap">
          <Link
            className="btn btn-play"
            to="/"
            onClick={openModal}
            aria-label="Show video"
          >
            <span>
              <IoPlay />
            </span>
          </Link>
        </div>
      </div>

      <h5 className="mt-3">
        <Link
          className="text-900"
          to="/"
          onClick={openModal}
          aria-label="Show video"
        >
          {data.title}
        </Link>
      </h5>

      <VideoModal
        isOpen={isOpen}
        handleCloseModal={closeModal}
        videoURL={videoURL}
      />
    </div>
  );
};

export default CardDailyVideo;
