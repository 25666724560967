import { useQuery } from "@tanstack/react-query";
import BlogService from "services/blog.service";

export const useGetBlogTags = (options = {}) => {
  return useQuery({
    queryFn: () => BlogService.getBlogTags(),
    queryKey: ["blog_tags"],
    // enabled: typeof slug !== typeof undefined,
  });
};
