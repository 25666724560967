import { Spinner } from "components/utils/Spinner";
import { useIsMobile } from "hooks";
import { PropTypes } from "prop-types";
import { Fragment, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { PageSection, SectionHeader } from "../../../sections/CommonSection";
import { useGetTopPsychologists } from "./useGetTopPsychologists.hook";

import { dataMessages } from "data";
import useCarouselControll from "hooks/useCarousel";
import CardPsychologist1 from "./CardPsychologist1";
import CardPsychologist2 from "./CardPsychologist2";
import "./TopPsychologists.scss";

// NOTE :

const SectionGoToPsychologists = (props) => {
  // const {} = props;
  // console.log("@@@@", data, index);

  return (
    <div className="col-lg-4 col-md-12 col-12">
      <div className="card blog-grid">
        <div className="card-content blog-grid-text p-4 align-middle">
          <h2 className="card-title h5 mb-3">
            <Link to={"/therapists"} className="btn btn-primary theme-btn">
              Наши психологи
            </Link>
          </h2>
          <p className="display-30">Наши психологи</p>
        </div>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data, isMobile, type } = props;
  const {
    refCarousel,
    currentCaruselIndex,
    handleSelect,
    onPrevClick,
    onNextClick,
    ContentControl,
  } = useCarouselControll();

  const {
    data: topPsychologists,
    isLoading: isLoadingTopPsychologists,
    isError: isErrorTopPsychologists,
    error: errorTopPsychologists,
  } = useGetTopPsychologists();

  const isLoading = isLoadingTopPsychologists;
  const isError = isErrorTopPsychologists;
  const errors = [errorTopPsychologists];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (topPsychologists) {
      _debug("TOP PSYCHOLOGIST:", topPsychologists);
    }
  }, [topPsychologists]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (
    (isErrorTopPsychologists && errorTopPsychologists?.status === 204) ||
    (topPsychologists && topPsychologists.length === 0)
  ) {
    return <div className="error">Нет психологов</div>;
  }

  return (
    <Fragment>
      <div className="row">
        <ContentControl />
        <div className="d-none" aria-live="polite" aria-atomic="true">
          slide <span className="current">{currentCaruselIndex + 1}</span> of{" "}
          {data.items.length}
        </div>

        <Carousel
          ref={refCarousel}
          activeIndex={currentCaruselIndex}
          onSelect={handleSelect}
          variant="dark"
          slide
          indicators={false}
          controls={false}
        >
          {topPsychologists &&
            topPsychologists.length > 0 &&
            topPsychologists.map((item, index) => {
              const newItems = topPsychologists.slice(
                index,
                index + (type === "style2" ? 4 : 3)
              );

              if (!newItems || newItems.length < 2) return null;

              // console.log(newItems, item, index);

              return (
                <Carousel.Item index={index} key={index}>
                  <div className="row">
                    {newItems.map((itemNew, indexNew) => {
                      const idx = index + indexNew;

                      if (type === "style2") {
                        return (
                          <CardPsychologist2
                            data={itemNew}
                            index={idx}
                            key={idx}
                            type="top"
                          />
                        );
                      }

                      return (
                        <CardPsychologist1
                          data={itemNew}
                          index={idx}
                          key={idx}
                          type="top"
                        />
                      );
                    })}
                    {newItems.length === 2 && <SectionGoToPsychologists />}
                  </div>
                </Carousel.Item>
              );
            })}
        </Carousel>
      </div>
    </Fragment>
  );
};

/**
 * Component for showing Section "Top 5 Our Psychologist" of the site.
 *
 * @component
 */
const TopPsychologist = (props) => {
  const { data, id = "top-psychologist", cn = "pt-80", ...otherProps } = props;
  const isMobile = useIsMobile(768);

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} />
        <SectionContent data={data} isMobile={false} {...otherProps} />
      </div>
    </PageSection>
  );
};

TopPsychologist.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1", "style2"]),
};

export default TopPsychologist;
