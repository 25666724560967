import { useState } from "react";

// NOTE : https://medium.com/how-to-react/create-multi-step-form-in-react-with-validation-4ac09129a3a8

const useMultiStepForm = (steps) => {
  //state for steps
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setStep(step + 1);
    //
    setCurrentStepIndex((index) => {
      if (index >= steps.length - 1) return index;
      return index + 1;
    });
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setStep(step - 1);
    //
    setCurrentStepIndex((index) => {
      if (index <= 0) return index;
      return index - 1;
    });
  };

  function goTo(index) {
    setCurrentStepIndex(index);
  }

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input) => (e) => {
    // input value from the form
    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  return {
    currentStepIndex,
    step: steps[currentStepIndex],
    steps,
    isFirstStep: currentStepIndex === 0,
    isLastStep: currentStepIndex === steps.length - 1,
    goTo,
    nextStep,
    prevStep,
    handleInputData,
  };
};

export default useMultiStepForm;
