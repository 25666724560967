import { useMutation } from "@tanstack/react-query";
import {
  CustomFormInputDate,
  CustomFormInputText2,
  CustomFormInputTextarea,
} from "components/pages/Customer/components/core/FormInput/FormInput";
import { useGetPsychologistProfileEducationById } from "components/pages/Psychologist/utils/useGetPsychologistProfileEducationById";
import { Spinner } from "components/utils/Spinner";
import { dataMessages } from "data";
import { Fragment, useEffect } from "react";
import { Form as BSForm, Button, Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PsychologistService from "services/psychologist.service";
import { _debug } from "utils";

const MSG_NO_CONTENT_TITLE = "Нет данных о блоге";

const FormEdit = (props) => {
  const { handleClose, handleSubmit, handleError, formData = {} } = props;

  const {
    methods,
    register,
    control,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      id: formData?.id,
      title: formData?.title,
      name: formData?.name,
      startDate: formData?.startDate,
      endDate: formData?.endDate,
    },
  });

  // useEffect(() => {
  //   reset({
  //     mail,
  //   });
  // }, [reset, mail]);

  const onSubmit = (data) => {
    console.log("SUBMIT:", data);
    // setModalFormData(data);
    handleSubmit(data);
  };
  const onError = (error) => {
    console.log("ERROR:::", error);
    handleClose(error);
  };

  return (
    <form className="" onSubmit={handleFormSubmit(onSubmit, onError)}>
      <div>
        <BSForm.Group className="mb-3" controlId="formTitle">
          <CustomFormInputText2
            label="Title"
            required
            error={errors.title}
            {...register("title", { required: "Correo es obligatorio" })}
          />
        </BSForm.Group>

        <BSForm.Group className="mb-3" controlId="formName">
          <CustomFormInputText2
            label="Name"
            required
            error={errors.name}
            {...register("name", { required: "Correo es obligatorio" })}
          />
        </BSForm.Group>

        <Row className="mb-3">
          <BSForm.Group className="" as={Col} controlId="formStartDate">
            <CustomFormInputDate
              control={control}
              name="startDate"
              label="Start date"
              required
              error={errors.startDate}
              defaultValue={formData?.startDate}
            />
          </BSForm.Group>

          <BSForm.Group className="" as={Col} controlId="formEndDate">
            <CustomFormInputDate
              control={control}
              name="endDate"
              label="End date"
              required
              error={errors.endDate}
              defaultValue={formData?.endDate}
            />
          </BSForm.Group>
        </Row>

        <BSForm.Group className="mb-3" controlId="formDescription">
          <CustomFormInputTextarea
            label="Description"
            placeholder="Enter description"
            rows="3"
            spellCheck={false}
            defaultValue={formData?.description}
            error={errors?.description}
            {...register("description", {
              maxLength: 1024,
              pattern: /[A-Za-z]{3}/,
            })}
          />
        </BSForm.Group>
      </div>

      <div>
        <Button variant="secondary" onClick={handleClose} className="mr-3">
          {dataMessages.button.msgClose}
        </Button>
        <Button variant="primary" type="submit">
          {dataMessages.button.msgSave}
        </Button>
      </div>
    </form>
  );
};

/**
 * Component for showing Education Edit component.
 *
 * @component
 */
const EducationEdit = (props) => {
  const { ...otherProps } = props;

  const { id } = useParams();
  const isNew = !Boolean(id) && id === "new";

  const {
    data: education,
    isLoading: isLoadingEducation,
    isError: isErrorEducation,
    error: errorEducation,
  } = useGetPsychologistProfileEducationById(id);

  const isLoading = isLoadingEducation;
  const isError = isErrorEducation;
  const errors = [errorEducation];

  const handleSave = (data) => {
    console.log("handleSave", data);
    mutationCreateOrUpdate.mutate(data);
  };

  const handleCancel = (data) => {
    console.log("handleCancel", data);
  };

  const mutationCreateOrUpdate = useMutation({
    mutationFn: async (newEducation) => {
      //   return PsychologistService.create({
      //     id: newBlogEntity.id,
      //     data: newBlogEntity,
      //   });
      // return PsychologistService.update({
      //   id: newBlogEntity.id,
      //   data: newBlogEntity,
      // });

      if (isNew)
        return PsychologistService.addEducation({
          id: newEducation.id,
          data: newEducation,
        });
      return PsychologistService.addEducation({
        id: newEducation.id,
        data: newEducation,
      });
    },
    onSuccess: (data) => {
      console.log(
        `Blog Entity ${isNew ? "created" : "updated"} successfully:`,
        data
      );
      // queryClient.refetchQueries("profile");
    },
    onError: (error) => {
      console.error(`Error ${isNew ? "create" : "update"} Method:`, error);
    },
  });

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (education) {
      _debug("EDUCATION ENTITY:", education);
    }
  }, [education]);

  useEffect(() => {
    if (id !== "new") {
      console.log("Create new Education");
    } else {
      console.log("Edit Education by Id", id);
    }
  }, [id]);

  const title = <h2>{id ? "Edit Education" : "Add Education"}</h2>;
  const isFormAvailable = isNew === false && Boolean(education);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (errors && errors.some((err) => err?.status === 204)) {
    return <div className="error">{MSG_NO_CONTENT_TITLE}</div>;
  }

  return (
    <Fragment>
      <Container>
        {title}
        {(isNew || isFormAvailable) && (
          <FormEdit
            handleSubmit={handleSave}
            handleClose={handleCancel}
            formData={education}
          />
        )}
      </Container>
    </Fragment>
  );
};

export default EducationEdit;
