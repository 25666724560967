import { useRef, useState } from "react";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const useCarouselControll = () => {
  const [currentCaruselIndex, setCurrentCaruselIndex] = useState(0);
  const refCarousel = useRef(null);

  const handleSelect = (selectedIndex) => {
    setCurrentCaruselIndex(selectedIndex);
  };

  const onPrevClick = () => {
    refCarousel.current.prev();
  };
  const onNextClick = () => {
    refCarousel.current.next();
  };

  const ContentControl = () => {
    return (
      <div className="testimonial-controls" aria-label="Carousel Navigation">
        <button type="button" data-controls="prev" onClick={onPrevClick}>
          <FaArrowLeftLong />
        </button>
        <button type="button" data-controls="next" onClick={onNextClick}>
          <FaArrowRightLong />
        </button>
      </div>
    );
  };

  return {
    refCarousel,
    currentCaruselIndex,
    handleSelect,
    onPrevClick,
    onNextClick,
    ContentControl,
  };
};

export default useCarouselControll;
