import { Spinner as BSSpinner } from "react-bootstrap";

/*
<div className="preloader" style={{ opacity: "0", display: "none" }}>
      <div className="loader">
        <div className="ytp-spinner">
          <div className="ytp-spinner-container">
            <div className="ytp-spinner-rotator">
              <div className="ytp-spinner-left">
                <div className="ytp-spinner-circle"></div>
              </div>
              <div className="ytp-spinner-right">
                <div className="ytp-spinner-circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 
*/
const Spinner = ({ ...otherProps }) => {
  return (
    <div
      className=""
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <BSSpinner animation="grow" {...otherProps} />
    </div>
  );
};

export default Spinner;
