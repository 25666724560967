import { dataForm } from "data";
import { FloatingLabel, Form } from "react-bootstrap";

export const InputFloatingLabelGifts = (props) => {
  const {
    label,
    placeholder,
    required,
    register,
    errors,
    options,
    ...otherProps
  } = props;

  return (
    <FloatingLabel>
      <Form.Select
        {...register("gift", {})}
        {...otherProps}
        aria-label={dataForm.msgHelpSubject}
        aria-describedby="formGiftOrderHelpBlock"
        defaultValue="message"
      >
        {options.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Form.Select>
      <Form.Label>{label || "Gift"}</Form.Label>
    </FloatingLabel>
  );
};

const InputGroupGifts = (props) => {
  const {
    label,
    placeholder,
    help,
    required,
    register,
    errors,
    id,
    ...otherProps
  } = props;

  return (
    <Form.Group controlId={id || "formGiftOrder"}>
      <InputFloatingLabelGifts
        label={label}
        required={required}
        register={register}
        errors={errors}
        {...otherProps}
      />
      {help && (
        <Form.Text id={id + "HelpBlock" || "formGiftOrderHelpBlock"} muted>
          {help}
        </Form.Text>
      )}
    </Form.Group>
  );
};

export default InputGroupGifts;
