import { Subscribe } from "components/sections";
import { SEO } from "components/utils/Analytics";
import {
  dataBlogMainPage as dataBlog,
  dataCTALetsContact,
  dataFAQ,
  dataFeatureMainPage,
  dataHelpDesk,
  dataHeroMainPage as dataHero,
  dataHomePage,
  dataOurAdvantagesMainPage,
  dataOurAdvantagesVideoMainPage,
  dataOverviewMainPage as dataOverview,
  dataStartTabsMainPage,
  dataSubscribe,
  dataTopAdviceMainPage as dataTopAdvice,
  dataTopPsychologistsMainPage as dataTopPsychologists,
  ldJson,
} from "data";
import React, { Fragment, useRef } from "react";
import Overview2 from "../../sections/Overview2";
import { CallToAction } from "./CTA";
import { OurAdvantages } from "./Features1";
import OurAdvantagesVideo from "./Features1/OurAdvantagesVideo";
import { HelpDesk } from "./HelpDesk";
import { Hero } from "./Hero";
import { FeaturePsyFormats } from "./PsyFormats";
import PsyStartTabs from "./PsyStart/PsyStartTabs";
import { TopAdvice } from "./TopAdvice";
import { TopPsychologists } from "./TopPsychologist";

const BlogTopPosts = React.lazy(() => import("../Blog/BlogTopPosts"));
const SectionFAQ = React.lazy(() => import("./FAQ"));
//const Pricing = React.lazy(() => import("../../sections/Pricing"));
//const Process = React.lazy(() => import("../../sections/Process"));
//const Service = React.lazy(() => import("../../sections/Service"));
//const FeatureTabs = React.lazy(() => import("../../sections/FeatureTabs"));

// NOTE : https://demo.graygrids.com/themes/space/index.html
// NOTE : https://demo.graygrids.com/themes/digimark/services.html
// NOTE : https://www.sleepinggiantmedia.co.uk/posts/canonical-tags-implementation-guide/

// NOTE : https://elar-context.ru

// NOTE : https://scrapfly.io/blog/search-engine-using-web-scraping/

// NOTE : https://bootstrapmade.com/demo/HeroBiz/

/**
 * Component for showing Page "Home" of the site.
 *
 * @component
 */
const HomePage = () => {
  const refCAT = useRef(null);

  const executeScroll = (e) => {
    refCAT.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  return (
    <Fragment>
      <SEO data={dataHomePage.meta}>
        {/*Only canonicalise pages which are a duplicate or are near-identical.*/}
        <link rel="canonical" href="https://psywealth.com/" />
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </SEO>
      <main className="page-main" id="top">
        <Hero data={dataHero} type="style3" onScroll={executeScroll} />

        {/*<SectionCountUp data={dataCountUpMainPage} />*/}
        {/*<CallToAction data={dataTopPsychologists} type="style1" />*/}
        {/*<CallToAction data={dataTopPsychologists} type="style2" />*/}
        {/* <OurAdvantages data={dataOurAdvantagesMainPage} />*/}

        <FeaturePsyFormats data={dataFeatureMainPage} type="style2" />
        <Overview2 data={dataOverview} cn="pt-120 pb-120" />
        <TopPsychologists
          data={dataTopPsychologists}
          cn="pt-120 pb-120"
          type="style1"
        />
        {/*<TopPsychologists
          data={dataTopPsychologists}
          cn="pt-120 pb-120"
          type="style2"
        />*/}
        {/*  <ServicesGrid data={dataService} />*/}
        {/* <PsySelection data={dataPsySelection} />*/}

        <PsyStartTabs data={dataStartTabsMainPage} />

        <OurAdvantages data={dataOurAdvantagesMainPage} />
        <OurAdvantagesVideo data={dataOurAdvantagesVideoMainPage} />

        <HelpDesk data={dataHelpDesk} type="style2" />

        {/* <MobileAppSection data={dataMobileAppMainPage} />*/}
        {/* <Tabs data={dataTopCourses} />*/}
        {/* <Feature10 data={dataFeatureMainPage} type="style2" />*/}
        {/*<FeatureRound data={dataFeatureMainPage} />*/}
        {/*<Feature99 data={dataFeatureMainPage} type="style2" />*/}
        {/* <About data={dataAbout} type="style2" />*/}
        {/* <FeatureTabs data={dataAbout} />*/}
        {/*  <ServicesInfo data={dataServicesInfoMainPage} />*/}
        {/* <Pricing data={dataPricing} /> */}
        {/* <OurProducts data={dataOurProducts} />*/}
        {/* <TopCourses data={dataTopCourses} />*/}

        <BlogTopPosts data={dataBlog} />
        <SectionFAQ data={dataFAQ} />

        <Subscribe data={dataSubscribe} />

        {/*  <h2 className="text-center">ДЕМО СЕКЦИЙ - DEMO</h2>*/}
        {/*  <Service data={dataService} type="style2" />*/}

        <CallToAction data={dataCTALetsContact} innerRef={refCAT} />

        {/*  <Testimonial data={dataTestimonial} />*/}
        {/*   <Approaches data={dataTopAdvice} />*/}
        <TopAdvice data={dataTopAdvice} />
        {/*  <VideoOfTheDay data={dataTopAdvice} />*/}
        {/*  <SectionSlogan data={dataSlogan} />*/}
        {/*  <MobileAppSection data={dataTopAdvice} />*/}
        {/*  <AltFeatures data={dataAltFeatureMainPage} />*/}
        {/*  <SectionAbout data={dataAbout} />*/}
      </main>
    </Fragment>
  );
};

export default HomePage;
