import { useQuery } from "@tanstack/react-query";
import CourseService from "services/course.service";

export const useGetLessonBySlug = (slug, lessonSlug) => {
  //const { id, slug } = props;
  console.log("!!!", lessonSlug);

  return useQuery({
    queryFn: () => CourseService.getLessonBySlug(lessonSlug),
    queryKey: ["lessonBySlug", lessonSlug],
  });
};
