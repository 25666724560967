import classNames from "classnames";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";

import "./Hero3.scss";

const msgGetStarted = "Найти своего специалиста";

/**
 * Component for showing Component "Hero" of the site.
 *
 * @component
 */

const HeroImage = (props) => {
  const { data, ...otherProps } = props;

  return (
    <Link
      to="/matching"
      title=""
      target="_self"
      className={classNames(
        "pictures__item overflow-hidden",
        `pictures__item_${data.name}`,
        data.cn
      )}
      style={{ gridArea: data.name }}
    >
      <div className="pictures__item-inner">
        <img
          src={data.image.src}
          alt={data.image.alt}
          className="pictures__item-img"
        />
      </div>
    </Link>
  );
};

const Hero3 = (props) => {
  const { data, ...otherProps } = props;

  return (
    <div className="bg-light py-lg-9 py-6 bg-cover">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-12">
            <div>
              <div className="text-center text-md-start ">
                <h1 className="display-5 fw-bold mb-3">{data.title}</h1>

                <p className="lead p-large">{data.description}</p>
                <div className="mt-8">
                  <div className="p-md-2 p-4">
                    <button
                      onClick={otherProps.onScroll}
                      aria-label={msgGetStarted}
                      className="btn btn-primary theme-btn"
                    >
                      {msgGetStarted}
                    </button>
                  </div>
                </div>
              </div>

              <span className="fs-4"></span>
            </div>
          </div>
          <div className="offset-lg-1 col-lg-5 col-12 text-center">
            <div className="position-relative">
              <div className="pictures">
                <HeroImage
                  data={{
                    name: "p0",
                    image: {
                      src: require("assets/images/hero/persons/person1.webp"),
                      alt: "Person1",
                    },
                  }}
                />
                <HeroImage
                  data={{
                    name: "p1",
                    image: {
                      src: require("assets/images/hero/persons/person2.webp"),
                      alt: "Person2",
                    },
                  }}
                />

                <HeroImage
                  data={{
                    name: "p2",
                    cn: "pictures__item_size-small",
                    image: {
                      src: require("assets/images/hero/persons/person3.webp"),
                      alt: "Person3",
                    },
                  }}
                />

                <a
                  href="/matching"
                  title=""
                  target="_self"
                  className="pictures__item  overflow-hidden pictures__item_p2 pictures__item_size-small"
                  style={{ gridArea: "p2" }}
                >
                  <div className="pictures__item-inner">
                    <img
                      src={require("assets/images/hero/persons/person4.webp")}
                      alt="Person4"
                      className="pictures__item-img"
                    />
                  </div>
                </a>
                <a
                  href="/matching"
                  title=""
                  target="_self"
                  className="pictures__item  overflow-hidden pictures__item_p3 pictures__item_size-small"
                  style={{ gridArea: "p3" }}
                >
                  <div className="pictures__item-inner">
                    <img
                      src={require("assets/images/hero/persons/person5.webp")}
                      alt="Person5"
                      className="pictures__item-img"
                    />
                  </div>
                </a>
                <a
                  href="/matching"
                  title=""
                  target="_self"
                  className="pictures__item  overflow-hidden pictures__item_p4 pictures__item_size-large"
                  style={{ gridArea: "p4" }}
                >
                  <div className="pictures__item-inner">
                    <img
                      src={require("assets/images/hero/persons/person6.webp")}
                      alt="Person6"
                      className="pictures__item-img"
                    />
                    <div className="pictures__item-data">
                      <div className="pictures__item-tag mui-text-caption-4 px-8">
                        Гештальт-терапевт
                      </div>
                      <div className="pictures__item-info">
                        <div className="pictures__item-name mui-text-caption-1">
                          Владимир
                        </div>
                      </div>
                      <div className="pictures__item-blackout"></div>
                    </div>
                  </div>
                </a>
                <a
                  href="/matching"
                  title=""
                  target="_self"
                  className="pictures__item  overflow-hidden pictures__item_p5"
                  style={{ gridArea: "p5" }}
                >
                  <div className="pictures__item-inner">
                    <img
                      src={require("assets/images/hero/persons/person7.webp")}
                      alt="Person7"
                      data-nuxt-img=""
                      className="pictures__item-img"
                    />
                  </div>
                </a>
                <a
                  href="/matching"
                  title=""
                  target="_self"
                  className="pictures__item  overflow-hidden pictures__item_p6"
                  style={{ gridArea: "p6" }}
                >
                  <div className="pictures__item-inner">
                    <img
                      src={require("assets/images/hero/persons/person8.webp")}
                      alt="Person8"
                      className="pictures__item-img"
                    />
                  </div>
                </a>
                <a
                  href="/matching"
                  title=""
                  target="_self"
                  className="pictures__item  overflow-hidden pictures__item_p7"
                  style={{ gridArea: "p7" }}
                >
                  <div className="pictures__item-inner">
                    <img
                      src={require("assets/images/hero/persons/person9.webp")}
                      alt="Person9"
                      className="pictures__item-img"
                    />
                  </div>
                </a>
                <a
                  href="/matching"
                  title=""
                  target="_self"
                  className="pictures__item  overflow-hidden pictures__item_p8"
                  style={{ gridArea: "p8" }}
                >
                  <div className="pictures__item-inner">
                    <img
                      src={require("assets/images/hero/persons/person10.webp")}
                      alt="Person10"
                      className="pictures__item-img"
                    />
                  </div>
                </a>
                <a
                  href="/matching"
                  title=""
                  target="_self"
                  className="pictures__item  overflow-hidden pictures__item_p9 pictures__item_size-small"
                  style={{ gridArea: "p9" }}
                >
                  <div className="pictures__item-inner">
                    <img
                      src={require("assets/images/hero/persons/person11.webp")}
                      alt="Person11"
                      className="pictures__item-img"
                    />
                  </div>
                </a>
                <a
                  href="/matching"
                  title=""
                  target="_self"
                  className="pictures__item  overflow-hidden pictures__item_p10 pictures__item_size-small"
                  style={{ gridArea: "p10" }}
                >
                  <div className="pictures__item-inner">
                    <img
                      src={require("assets/images/hero/persons/person11.webp")}
                      alt="Person12"
                      className="pictures__item-img"
                    />
                  </div>
                </a>
                <a
                  href="/matching"
                  title=""
                  target="_self"
                  className="pictures__item overflow-hidden pictures__item_p11"
                  style={{ gridArea: "p11" }}
                >
                  <div className="pictures__item-inner">
                    <img
                      src={require("assets/images/hero/persons/person11.webp")}
                      alt="Person12"
                      className="pictures__item-img"
                    />
                  </div>
                </a>
                <a
                  href="/matching"
                  title=""
                  target="_self"
                  className="pictures__item  overflow-hidden pictures__item_p12"
                  style={{ gridArea: "p12" }}
                >
                  <div className="pictures__item-inner">
                    <img
                      src={require("assets/images/hero/persons/person11.webp")}
                      alt="Person13"
                      className="pictures__item-img"
                    />
                  </div>
                </a>
                <div className="pictures__dec pictures__dec_top mui-flex mui-flex-col mui-items-center">
                  <div className="pictures__dec-text mui-text-caption-3 text-accent">
                    {" "}
                    только 5% терапевтов проходят наш отбор{" "}
                  </div>
                  <img
                    src={require("assets/icons/curve1.svg")}
                    alt=""
                    className="pictures__dec-curve"
                  />
                </div>
                <div className="pictures__dec pictures__dec_bottom mui-flex mui-flex-col mui-items-center">
                  <img
                    src={require("assets/icons/curve2.svg")}
                    alt=""
                    className="pictures__dec-curve"
                  />
                  <div className="pictures__dec-text mui-text-caption-3 text-accent">
                    {" "}
                    500+
                    <br />
                    сертифицированных специалистов{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Hero3.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default Hero3;
