import { InputFloatingLabelEmail } from "components/utils/Forms";
import { useAuth } from "contexts/AuthProvider";
import { dataForm } from "data";
import { PropTypes } from "prop-types";
import { Fragment, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { _debug } from "utils";

import Image1 from "assets/images/hero/mental-health-discussion.svg";

import { isDevelopment } from "components/utils/Utils";
import "./AuthPage2.scss";

const msgSubmitted = "Сообщение отправлено";
const msgSubmit = "Восстановить";

const SectionContent = (props) => {
  const {
    methods,
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: isDevelopment ? "user4@mail.ru" : "",
    },
  });

  const AuthForm = (props) => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const onSubmit = async (data) => {
      //e.preventDefault();
      _debug("Form submit: ", data);

      setError(null);
      try {
        //gaEventTracker("submitFromCountDown");

        const response = await props.onSubmit({
          email: data.email,
        });

        if (response && response.error) {
          setError(response.error);
          setSuccess(false);
          return;
        }

        setSuccess(true);
      } catch (error) {
        setError(error.message);
        setSuccess(false);
      } finally {
        reset();
      }
    };

    return (
      <div className="auth-form-wrapper">
        <FormProvider {...methods}>
          <Form
            className="auth-form"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete={"off"}
          >
            <Fragment>
              <Form.Group controlId="formAuthEmail">
                <InputFloatingLabelEmail
                  label={dataForm.email.msgLabel}
                  placeholder={dataForm.email.msgPlaceholder}
                  required={dataForm.email.msgRequired}
                  register={register}
                  errors={errors}
                />
              </Form.Group>
            </Fragment>

            <div className="auth-links">
              <div className="text-end">
                <Link className="btn btn-link" onClick={props.onChangeMode}>
                  Уже есть аккаунт
                </Link>
              </div>

              <div className="text-end">
                Нет аккаунта?{" "}
                <Link className="btn btn-link" onClick={props.onChangeMode}>
                  Зарегистрироваться
                </Link>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center mt-5 mb-5">
              <Button
                className="btn btn-primary theme-btn"
                variant="primary"
                type="submit"
              >
                {msgSubmit}
              </Button>
            </div>
          </Form>
          {error && (
            <p className="text-warning" style={{ textAlign: "center" }}>
              <small>{error}</small>
            </p>
          )}
          {success && (
            <p className="text-success" style={{ textAlign: "center" }}>
              <small>{msgSubmitted}</small>
            </p>
          )}
        </FormProvider>
      </div>
    );
  };

  return (
    <div className="row">
      <div className="col-md-8 offset-md-2">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-7 col-lg-6 auth-left">
            <img
              src={Image1}
              className="img-fluid"
              alt={"Client forgot password"}
            />
          </div>
          <div className="col-md-12 col-lg-6 auth-right">
            <div className="auth-header mb-5">
              <h3>Восстановление пароля</h3>
            </div>

            <AuthForm onAuthenticate={props.onSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Component for showing Page "Forgot Password" of the site.
 *
 * @component
 */
const ForgotPage = () => {
  const { isAuthenticated, forgot } = useAuth();

  return (
    <main className="page-forgot-password">
      {/*{isAuthenticated && <Navigate to="/profile" replace={true} />}*/}
      <div className="auth-wrapper">
        <SectionContent onSubmit={forgot} />
      </div>
    </main>
  );
};

ForgotPage.propTypes = {
  type: PropTypes.oneOf(["style1"]),
};

ForgotPage.defaultProp = {};

export default ForgotPage;

/*


  return (
    <div className="row g-0 auth-wrapper">
      <div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
        <div className="auth-background-holder"></div>
        <div className="auth-background-mask"></div>
      </div>

      <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
        <div className="d-flex flex-column align-content-end">
          <div className="auth-body mx-auto">
            <p>Forgot Password</p>
            <div className="auth-form-container text-start">
              <form
                className="auth-form"
                method="POST"
                onSubmit={forgotPassword}
                autoComplete={"off"}
              >
                <div className="email mb-3">
                  <input
                    type="email"
                    className={`form-control ${
                      validate.validate && validate.validate.email
                        ? "is-invalid "
                        : ""
                    }`}
                    id="email"
                    name="email"
                    value={email}
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <div
                    className={`invalid-feedback text-start ${
                      validate.validate && validate.validate.email
                        ? "d-block"
                        : "d-none"
                    }`}
                  >
                    {validate.validate && validate.validate.email
                      ? validate.validate.email[0]
                      : ""}
                  </div>
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary theme-btn mx-auto"
                  >
                    Forgot Password
                  </button>
                </div>
              </form>

              <hr />
              <div className="auth-option text-center pt-2">
                <Link className="text-link" to="/login">
                  Back to Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
*/
