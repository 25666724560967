import { Loading } from "components/utils";
import { Fragment, Suspense } from "react";
import { Row } from "react-bootstrap";
import { BsGear, BsPerson } from "react-icons/bs";
import { FaSignOutAlt } from "react-icons/fa";
import { Outlet } from "react-router-dom";
import { PageSection } from "../Home";
import { ProfileSidebar } from "./components/core/ProfileSidebar";

// NOTE : https://www.bootdey.com/snippets/view/bs5-profile-billing-page

//   { title: "Bio", path: "/customer/profile/bio", icon: <BsGear /> },

const sidebarMenu = [
  { title: "Профиль", path: "/customer/profile", icon: <BsPerson /> },
  {
    title: "Записи",
    path: "/customer/profile/appointments",
    icon: <BsGear />,
  },
  { title: "Orders", path: "/customer/profile/orders", icon: <BsGear /> },
  { title: "Payments", path: "/customer/profile/payments", icon: <BsGear /> },
  { title: "Wishlist", path: "/customer/profile/wishlist", icon: <BsGear /> },
  { title: "Settings", path: "/customer/profile/settings", icon: <BsGear /> },
  {
    title: "Удалить профиль",
    path: "/customer/profile/account-delete",
    icon: <FaSignOutAlt />,
  },
];

const SectionContent = (props) => {
  const { data } = props;
  const { profile } = data;

  // console.log("pppp", props);

  return (
    <Fragment>
      <Row>
        {/*<!-- Sidebar START -->*/}
        <div className="col-lg-4 col-xl-3">
          <Suspense fallback={<Loading />}>
            <ProfileSidebar data={{ profile, menu: sidebarMenu }} />
          </Suspense>
        </div>
        {/*<!-- Sidebar END -->*/}

        {/*<!-- Main content START -->*/}
        <div className="col-lg-8 col-xl-9">
          <Suspense fallback={<Loading />}>
            <Outlet context={{ profile }} />
          </Suspense>
        </div>
        {/*<!-- Main content END -->*/}
      </Row>
    </Fragment>
  );
};

/**
 * Component for showing Section "Customer Profile" of the site.
 *
 * @component
 **/
const CustomerProfile = (props) => {
  const { data, id = "customer-profile", cn = "p-3 pt-80 pb-80" } = props;
  // data - содержит еще и заголовки для секции
  // console.log("111!", data);

  // const location = useLocation();
  // const { slug } = useParams();
  // const { auth, isAuthenticated } = useAuth();
  // const [profileData, setProfileData] = useState(null);

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionContent data={data} />
      </div>
    </PageSection>
  );
};

export default CustomerProfile;

/*
  const mutation = useMutation({
    mutationFn: async (newOrder) => OrderService.createOrder(newOrder),
    mutationKey: ["createOrder"],
  });

  const { products } = useProducts();

  useEffect(() => {}, []);
  const showToastMessage = () => {
    toast.success("Success Notification !");
  };

  const handleCreateOrder = () => {
    const token = jwtDecode(auth.data.accessToken);
    const product =
      products.items instanceof Array ? products.items[0].id : undefined;
    const order = {
      customer: token.id,
      product: product,
      quantity: 1,
      price: "1.5",
      deliveryEmail: "",
      deliveryAddress: "",
      description: "Новый заказ",
    };
    console.log("???", order, products, auth);
    mutation.mutate(order);
    // toast.success("Success Notification !");
  };

  */
