import { useQuery } from "@tanstack/react-query";
import PsychologistService from "services/psychologist.service";

export const useGetTherapistList = () => {
  //const { id, slug } = props;
  // console.log("!!!", slug);

  return useQuery({
    queryFn: () => PsychologistService.getAll(),
    queryKey: ["therapists"],
  });
};
