import { useMutation } from "@tanstack/react-query";
import { CustomFormInputText2 } from "components/pages/Customer/components/core/FormInput/FormInput";
import { useProfile } from "contexts/ProfileProvider";
import { DateTime } from "luxon";
import { Fragment, useEffect, useState } from "react";
import { Form as BSForm } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AppointmentService from "services/appointment.service";
import PaymentService from "services/payment.service";

// https://www.pga.gazprombank.ru/pages-timelimit/?lang_code=RU&merch_id=28F7099E5CA956CFCAE3&back_url_s=https://alter.ru/booking?payment_done=1%26hash_id=7dbfcadc4b9bc2edfb1ac2790fb8f9c6b0a51b8a%26booking_hash=8f7a7b4b2552449ea1265285ff4ada20&back_url_f=https://alter.ru/booking?hash_id=7dbfcadc4b9bc2edfb1ac2790fb8f9c6b0a51b8a%26position=2%26from=profile%26from_link=%252Fpsychologists%252Fufa%252Fgulnaz-saiakhova%253Fposition%253D2%2526from%253Dsearch_page%2526from_link%253D%25252Fpsychologists%25253Falter_campaign%25253Dlanding-menu%26booking_hash=8f7a7b4b2552449ea1265285ff4ada20%26instant_widget=1&o.order_id=8f7a7b4b2552449ea1265285ff4ada20#7RT447DRPR9GDL9B

/**
 * Component for showing Component "Payment Details" of the site.
 *
 * @component
 **/
const PaymentDetails = (props) => {
  const { data, therapist, dataForm } = props;
  const { profile } = useProfile();

  const [isProcessing, setIsProcessing] = useState(false);
  // const [isPaid, setIsPaid] = useState(false);
  const [redirectURL, setRedirectURL] = useState("");
  const [example, setExemple] = useState(false);
  const [actionStatus, setActionStatus] = useState(0);

  const navigate = useNavigate();

  console.log(profile, dataForm);

  const handlePay = () => {
    const newAppointment = {
      customer: profile.id,
      product: dataForm.productId,
      deliveryName: data.name,
      deliveryEmail: data.email,
      description: "",
    };
    createAppointment.mutate({
      psychologistId: dataForm.therapist.id,
      time: DateTime.fromJSDate(dataForm.time).toISO({
        includeOffset: false,
        extendedZone: false,
        suppressSeconds: true,
        suppressMilliseconds: true,
      }),
      date: DateTime.fromJSDate(dataForm.date).toISO({
        includeOffset: false,
        extendedZone: false,
        suppressSeconds: true,
        suppressMilliseconds: true,
      }),
      datetime: DateTime.fromJSDate(dataForm.datetime).toISO({
        includeOffset: false,
        extendedZone: false,
        suppressSeconds: false,
        suppressMilliseconds: true,
      }),
    });
  };

  const createAppointment = useMutation({
    mutationFn: async (newAppointment) =>
      AppointmentService.create(newAppointment),
    mutationKey: ["createAppointment"],
    onSuccess: (data) => {
      console.log("Appointment created successfully:", data);

      setActionStatus(1);

      //
      const requestBody = {
        customer: profile.id,
        product: dataForm.productId,
        deliveryName: data.name,
        deliveryEmail: data.email,
        description: "",
      };
      createPayment.mutate(requestBody);
    },
    onError: (error) => {
      console.error("Error create appointment:", error);
    },
  });

  const createPayment = useMutation({
    mutationFn: async (newOrder) =>
      PaymentService.createPaymentWithOrder(newOrder),
    mutationKey: ["createPaymentWithOrder"],
    onSuccess: (data) => {
      console.log("Payment created successfully:", data);
      // queryClient.refetchQueries("profile");
      console.log(data.confirmation.confirmation_url);
      setRedirectURL(data.confirmation.confirmation_url);
      setIsProcessing(true);
      setExemple(true);
      console.log(redirectURL, isProcessing, example);
      // redirect(redirectURL);
      // navigate(redirectURL, { replace: true });

      // window.location.replace(redirectURL);
      // window.location.replace("https://www.google.com");
    },
    onError: (error) => {
      console.error("Error create payment:", error);
    },
  });

  useEffect(() => {
    console.log("Redirecting...", isProcessing, redirectURL, example);
    if (redirectURL) {
      setTimeout(() => {
        // 👇 Redirects to about page, note the `replace: true`
        console.log(redirectURL);
        // redirect(redirectURL);
        // navigate(redirectURL, { replace: true });
        // window.location.replace(redirectURL);

        window.location.replace(redirectURL);
      }, 1000);
      setIsProcessing(false);
    }
  }, [isProcessing, redirectURL, example]);

  useEffect(() => {
    console.log(actionStatus);
  }, [actionStatus]);

  return (
    <Fragment>
      <div className="payment-and-contact-data-block">
        <div className="booking-form">
          {/*  <div className="form-booking-input-container">
            <div className="form-booking-input-container__bottom-gap">
              Номер телефона
            </div>
            <div>
              <input
                type="tel"
                className="contact-request-popup__input transition-ease"
                name="phone"
                value="+7 926 785 52 40"
              />
            </div>
          </div>*/}

          {/*<!-- Display name -->*/}
          <BSForm.Group className="mb-3" controlId="formPhone">
            <CustomFormInputText2
              label="Номер телефона"
              required={false}
              helpText={`Пришлем контакты психолога и напомним ${"\u00A0"}сессии.`}
            />
          </BSForm.Group>

          {/* <div className="user-details-form__phone-info"></div>
          <div className="form-booking-input-container">
            <div className="form-booking-input-container__bottom-gap">
              Почта
            </div>
            <div>
              <input
                className="contact-request-popup__input transition-ease"
                name="email"
                value="kllt4okv3be4@mail.ru"
              />
            </div>
          </div>*/}

          {/*<!-- Display name -->*/}
          <BSForm.Group className="mb-3" controlId="formEmail">
            <CustomFormInputText2 label="Почта" required={false} />
          </BSForm.Group>

          {/* <div>
            <div className="form-booking-input-container">
              <div className="form-booking-input-container__bottom-gap">
                Имя
              </div>
              <div>
                <input
                  className="contact-request-popup__input transition-ease"
                  name="name"
                  value="Alexander L"
                />
              </div>
            </div>
          </div>*/}

          {/*<!-- Display name -->*/}
          <BSForm.Group className="mb-3" controlId="formName">
            <CustomFormInputText2 label="Имя" required={false} />
          </BSForm.Group>

          <button onClick={handlePay}>PAY ME</button>

          {/* <div className="booking-form__checkboxes">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="check_offer"
                name="offerAccepted"
                checked=""
              />
              <label
                className="custom-control-label booking-form__checkbox"
                for="check_offer"
              >
                <div className="booking-form__checkbox__text">
                  Соглашаюсь{" "}
                  <a
                    href="/offer-psychologist?name=%D0%93%D1%83%D0%BB%D1%8C%D0%BD%D0%B0%D0%B7%20%D0%93%D0%B0%D0%B1%D0%B4%D1%83%D0%BB%D0%BB%D0%BE%D0%B2%D0%BD%D0%B0%20%D0%A1%D0%B0%D1%8F%D1%85%D0%BE%D0%B2%D0%B0"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="personal-info__link"
                  >
                    с офертой психолога
                  </a>{" "}
                  и&nbsp;подтверждаю, что мне есть 18&nbsp;или{" "}
                  <div className="is-inline-block">
                    <span className="personal-info__link">
                      согласие родителей
                    </span>
                  </div>
                </div>
              </label>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="check_personal_data"
                name="personal_data_accepted"
                checked=""
              />
              <label
                className="custom-control-label booking-form__checkbox"
                for="check_personal_data"
              >
                <div className="booking-form__checkbox__text">
                  Соглашаюсь на обработку{" "}
                  <a
                    href="https://alter.ru/personalinfo"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="personal-info__link"
                  >
                    персональных данных
                  </a>
                </div>
              </label>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="check_age"
                name="ageAccepted"
                checked=""
              />
              <label
                className="custom-control-label booking-form__checkbox"
                for="check_age"
              >
                <div className="booking-form__checkbox__text">
                  Принимаю{" "}
                  <a
                    href="https://alter.ru/individual_offer"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="personal-info__link"
                  >
                    оферту сервиса
                  </a>
                </div>
              </label>
            </div>
          </div> */}
        </div>
        <div className="booking-form">
          {/* <div className="booking-form__title-wrapper">
            <span className="blm-text mgb-4 is-inline-block blm-text-accent">
              Способ оплаты
            </span>
          </div>
          <div className="currency-selector__buttons-group is-flex">
            <label
              for="booking-form-currency-RU"
              className="blm-button button is-borderless currency-selector__button is-fullwidth is-active"
            >
              <input
                type="radio"
                name="currency"
                id="booking-form-currency-RU"
                className="currency-selector__input"
                readonly=""
                value="RUB"
                checked=""
              />
              <span className="blm-text currency-selector__label blm-text-accent">
                <span className="is-hidden-mobile">Российская карта</span>
                <span className="is-hidden-tablet">Российский</span>
              </span>
            </label>
            <label
              for="booking-form-currency-USD"
              className="blm-button button is-borderless currency-selector__button is-fullwidth"
            >
              <input
                type="radio"
                name="currency"
                id="booking-form-currency-USD"
                className="currency-selector__input"
                readonly=""
                value="USD"
              />
              <span className="blm-text currency-selector__label blm-text-accent">
                <span className="is-hidden-mobile">Зарубежная карта</span>
                <span className="is-hidden-tablet">Зарубежный</span>
              </span>
            </label>
          </div> */}

          <hr className="booking-form__discount-devider" />
          {/* <div className="">
            <div className="is-flex is-align-items-center">
              <img
                alt=""
                width="18"
                height="16"
                src="https://alter.ru/images/discounts.png"
              />
              <span className="blm-text px-1 is-flex-grow-1">Промокод</span>
            </div>
            <label for="promoCode" className="is-block mb-0">
              <div className="mt-2">
                <div className="is-flex">
                  <input className="input blm-input is-flex-grow-1" value="" />
                  <button className="button-secondary ml-2" disabled="">
                    <span className="blm-text blm-text-accent">Применить</span>
                  </button>
                </div>
              </div>
            </label>
            <div className="modal blm-modal verification-code-modal">
              <div className="modal-background"></div>
              <div className="modal-content">
                <div className="box blm-modal__content verification-code-modal__wrapper is-flex is-flex-direction-column is-justify-content-center blm-box">
                  <div className="is-flex-grow-1 is-flex is-justify-content-center is-align-items-center">
                    <div className="verification-code-modal__content has-text-centered">
                      <div>
                        <h3 className="blm-h3 verification-code-modal__title">
                          Введите код из&nbsp;письма
                        </h3>
                      </div>
                      <div className="verification-code-modal__code">
                        <input
                          className="verification-code-modal__code-input has-text-centered"
                          type="text"
                          value="—————"
                        />
                      </div>
                      <span className="blm-text verification-code-modal__text blm-text-secondary">
                        Мы&nbsp;прислали письмо с&nbsp;кодом
                        <br />
                        на kllt4okv3be4@mail.ru.
                        <br />
                        <button className="verification-code-modal__resend is-underlined">
                          Прислать еще раз
                        </button>
                      </span>
                    </div>
                  </div>
                  <hr className="verification-code-modal__hr" />
                  <button className="button-secondary ">
                    <span className="blm-text blm-text-accent">
                      <span className="blm-text blm-text-accent">
                        Продолжить без промокода{" "}
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="booking-form__session-info-mobile">
          {/* <div className="booking-form__session-info-mobile__top">
            <div className="booking-form__session-info-mobile-headline">
              Информация о&nbsp;сессии
            </div>
            <div className="booking-form__session-info-mobile-family-or-single-person">
              <img
                alt=""
                className="booking__emoji"
                src="https://alter.ru/images/person-with-laptop-emoji.png"
              />
              Онлайн
              <span className="booking-form__session-info-mobile-bullet">
                •
              </span>
              только вы
            </div>
            <div className="session-date-time__session-info-date mgb-4">
              16 августа, пятница, 08:00
            </div>
            <div className="booking-form__session-info-mobile-duration">
              Длительность 60 минут
            </div>
          </div>
          <div className="booking-form__session-info-mobile__price-container">
            <div className="booking-form__session-info-mobile__price">
              <img
                alt=""
                className="booking__small-emoji"
                src="https://alter.ru/images/credit-card-emoji.png"
              />
              Стоимость&nbsp;
              <span className="is-lowercase">
                <span className="is-nowrap">3800 ₽</span>
              </span>
            </div>
          </div> */}
        </div>
      </div>
    </Fragment>
  );
};

PaymentDetails.propTypes = {};

export default PaymentDetails;
