import { createContext, useContext, useEffect, useState } from "react";
import ProductService from "services/product.service";
import { _debug } from "utils";

const initialState = {
  items: [],
};

export const productsContext = createContext({});

const ProductsProvider = ({ children }) => {
  const [products, setProducts] = useState(initialState);
  const [isInitialized, setIsInitialized] = useState(false);

  const fetch = async () => {
    _debug("Let's fetch products...");

    try {
      const fetchData = await ProductService.getAll();
      _debug("Data fetched:", fetchData);

      // setProducts({
      //   ...products,
      //   items: fetchData,
      // });
      // setIsInitialized(true);
    } catch (error) {
      console.error("Fetch products error: ", error);

      throw new Error(`HTTP error: ${error}`);
    }
  };

  useEffect(() => {
    fetch()
      .then((result) => {
        setProducts({
          ...products,
          items: result,
        });
        setIsInitialized(true);
      })
      .catch((err) => {
        setProducts(initialState);
        setIsInitialized(false);
      });
  }, []);

  return (
    <productsContext.Provider
      value={{
        products,
        fetch,
      }}
    >
      {children}
    </productsContext.Provider>
  );
};

const useProducts = () => {
  return useContext(productsContext);
};

export { ProductsProvider, useProducts };
