import { useQuery } from "@tanstack/react-query";
import BlogService from "services/blog.service";

export const useGetBlogCategories = (options = {}) => {
  return useQuery({
    queryFn: () => BlogService.getBlogCategories(),
    queryKey: ["blog_categories"],
    // enabled: typeof slug !== typeof undefined,
  });
};
