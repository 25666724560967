import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { useOutletContext } from "react-router-dom";
import { _debug } from "utils";
import FormProfileBio from "./FormProfileBio";

/**
 * Component for showing Section "Psychologist Profile (Tab) - Psychologist Bio" of the site.
 *
 * @component
 **/
const ProfileTabBio = () => {
  const { profile } = useOutletContext();

  _debug("PROFILE:", profile);

  return (
    <Fragment>
      <div className="vstack gap-4">
        {/*<!-- Personal info START -->*/}
        <div className="card border">
          {/*<!-- Card header -->*/}
          <div className="card-header border-bottom">
            <h4 className="card-header-title">Personal Information</h4>
          </div>

          {/*<!-- Card body START -->*/}
          <div className="card-body">
            {/*<!-- Form START -->*/}

            <FormProfileBio data={profile} />

            {/*<!-- Form END -->*/}
          </div>
          {/*<!-- Card body END -->*/}
        </div>
        {/*<!-- Personal info END -->*/}
      </div>
    </Fragment>
  );
};

ProfileTabBio.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default ProfileTabBio;
