/**
 * Data of page "Daily Videos" of the site.
 *
 *
 */

import { dataMetaDailyVideosPage } from "./meta/dataMetaDailyVideosPage";

export const dataDailyVideosPage = {
  title: "Актуальные темы и интервью",
  subtitle: "Актуальные темы и интервью",
  description: "Актуальные темы и интервью",
  meta: dataMetaDailyVideosPage,
};
