import classNames from "classnames";
import { withAsync } from "components/core/Async";
import Dates from "date-math";
import { DateTime } from "luxon";
import { Fragment, useEffect, useState } from "react";
import { useGetTherapistAvailabilityByDate } from "../../../../utils/useGetTherapistAvailabilityByDate.hook";

function entry(start, end) {
  return { start: start, end: end };
}

function duration(start, end, duration) {
  var math = Dates[duration];
  if (!math) throw new Error("unsupported duration " + duration);

  var roundStart = math.ceil(start);
  var roundEnd = math.floor(end);
  var diff = roundEnd < roundStart ? 0 : math.diff(roundStart, roundEnd);
  if (!diff) return [entry(start, end)]; // they are on the same interval

  var arr = [];
  if (+start !== +roundStart) arr.push(entry(start, roundStart));
  for (var i = roundStart; i < roundEnd; i = math.shift(i, 1)) {
    arr.push(entry(i, math.shift(i, 1)));
  }
  if (+roundEnd !== +end) arr.push(entry(roundEnd, end));
  return arr;
}

const TimeItem = (props) => {
  const { data, selected, handleSelected } = props;
  // console.log(props);

  const isSelected = data.start === selected;
  return (
    <div
      className={classNames("date-picker__slots__slot", {
        "date-picker__slots__slot-selected": isSelected,
      })}
      onClick={handleSelected}
    >
      {DateTime.fromJSDate(data.start).toFormat("HH:mm")}
    </div>
  );
};

const TimeSlots = (props) => {
  const {
    data: availableSlots,
    isLoading,
    isError,
    errors,
    selected,
    onTimeSelected,
  } = props;

  const [slotId, setSlotId] = useState([]);

  useEffect(() => {
    setSlotId([]);

    if (availableSlots) {
      // _debug("AVAILABLE SLOTS:", availableSlots);

      // console.log(DateTime.fromFormat("09:00", "HH:mm").toJSDate());
      let slots = [];
      availableSlots.forEach((item) => {
        const currentSlots = duration(
          DateTime.fromFormat(item.startTime, "HH:mm").toJSDate(),
          DateTime.fromFormat(item.endTime, "HH:mm").toJSDate(),
          "hour"
        );
        // console.log(slots);
        slots = [...slots, ...currentSlots];
      });
      setSlotId(slots);
      // console.log(slotId);
      // availableSlots.map((item, index) => {
      //   slots = duration(
      //     DateTime.fromFormat(item.startTime, "HH:mm").toJSDate(),
      //     DateTime.fromFormat(item.endTime, "HH:mm").toJSDate(),
      //     "hour"
      //   );
      //   console.log(slots);
      //   setSlotId();
      // });
    }
  }, [availableSlots]);

  if (isError && isError?.status === 204) {
    return <div className="error">Нет данных о терапевте</div>;
  }

  return (
    <Fragment>
      <div className="date-picker__slots">
        {slotId &&
          slotId.length > 0 &&
          slotId.map((item, index) => (
            <TimeItem
              data={item}
              key={index}
              selected={selected}
              handleSelected={(e) => onTimeSelected(e, item.start)}
            />
          ))}

        {/* <div className="date-picker__slots__slot date-picker__slots__slot-selected">
      14:00
    </div>
    <div className="date-picker__slots__slot">14:00</div>
    <div className="date-picker__slots__slot date-picker__slots__slot-selected">
      14:00
    </div>*/}
      </div>
    </Fragment>
  );
};

const TimeSlotsAsync = withAsync(TimeSlots);

/**
 * Component for showing Component "Availability Calendar of the site.
 *
 * @component
 **/
const TimeSlotsByDate = (props) => {
  const { visitDate, psychologistId, onTimeSelected } = props;

  const [timeSelected, setTimeSelected] = useState("00:00");

  const {
    data: availableSlots,
    isLoading: isLoadingPsychologistAvailableSlots,
    isError: isErrorPsychologistAvailableSlots,
    error: errorPsychologistAvailableSlots,
    refetch,
  } = useGetTherapistAvailabilityByDate({
    psychologistId: psychologistId,
    date: visitDate,
  });

  const isLoading = isLoadingPsychologistAvailableSlots;
  const isError = isErrorPsychologistAvailableSlots;
  const errors = [errorPsychologistAvailableSlots];

  const handleTimeSelected = (e, index) => {
    e.preventDefault();

    setTimeSelected(index);
    onTimeSelected(index);
  };

  useEffect(() => {
    refetch();
  }, [visitDate]);

  if (isError && isError?.status === 204) {
    return <div className="error">Нет данных о терапевте</div>;
  }

  return (
    <Fragment>
      <div className="date-picker__select-time">Выберите время</div>
      <div className="date-picker__timezone">
        Указано ваше время: Europe/Moscow
      </div>
      <TimeSlotsAsync
        data={availableSlots}
        isLoading={isLoading}
        isError={isError}
        errors={errors}
        msgLoading="PSYCHOLOGIST TIME SLOTS"
        selected={timeSelected}
        onTimeSelected={handleTimeSelected}
      />

      {/*  <div className="date-picker__slots">
        {slotId &&
          slotId.length > 0 &&
          slotId.map((item, index) => (
            <TimeItem
              data={item}
              key={index}
              selected={timeSelected}
              handleSelected={(e) => handleTimeSelected(e, item.start)}
            />
          ))}*/}

      {/* <div className="date-picker__slots__slot date-picker__slots__slot-selected">
      14:00
    </div>
    <div className="date-picker__slots__slot">14:00</div>
    <div className="date-picker__slots__slot date-picker__slots__slot-selected">
      14:00
    </div>*/}
    </Fragment>
  );
};

TimeSlotsByDate.propTypes = {};

export default TimeSlotsByDate;
