import React from "react";
import { Banner } from "./Banner";
import FAQAccordion from "./FAQAccordion";
import Feature from "./Feature";
import Feature1 from "./Feature1";
import Feature10 from "./Feature10";

const Subscribe = React.lazy(() => import("./Subscribe/Subscribe"));
const Subscribe2 = React.lazy(() => import("./Subscribe/Subscribe2"));
const Testimonial = React.lazy(() => import("./Testimonial"));
const Process = React.lazy(() => import("./Process"));
const FeatureRound = React.lazy(() => import("./FeatureRound"));
const Overview2 = React.lazy(() => import("./Overview2"));
const Service = React.lazy(() => import("./Service"));
const Pricing = React.lazy(() => import("./Pricing"));
const FeatureTabs = React.lazy(() => import("./FeatureTabs"));
const CallToAction = React.lazy(() => import("./CTA/CallToAction"));
const SectionSlogan = React.lazy(() => import("./CTA/SectionSlogan"));
const OurProducts = React.lazy(() => import("./OurProducts/OurProducts"));

export {
  Banner,
  CallToAction,
  FAQAccordion,
  Feature,
  Feature1,
  Feature10,
  FeatureRound,
  FeatureTabs,
  OurProducts,
  Overview2,
  Pricing,
  Process,
  SectionSlogan,
  Service,
  Subscribe,
  Subscribe2,
  Testimonial,
};
