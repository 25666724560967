/**
 * Data of page "Gifts" of the site.
 *
 *
 */

export const dataHeroGlossaryPage = {
  title: "Подарочный сертификат",
  subtitle: "Самый важный подарок",
  description:
    "Нам всем иногда нужна помощь, но иногда её бывает сложно попросить. Позаботьтесь о близком человеке — подарите ему сессию с психологом.",
  //"Хранение документов еще никогда не было таким простым - услуги по организации и ведению электронных архивов",
  items: null,
  image: require("assets/images/hero/hero2-920x820.webp"),
  imageAlt: "Сервис психологической помощи - «PsyWealth»",
};
