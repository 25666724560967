//import { Form as FormValidator } from "components/utils/Forms/Forms";
import { Spinner } from "components/utils/Spinner";
import { useAuth } from "contexts/AuthProvider";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FaLock } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

import Image1 from "assets/images/hero/mental-health-discussion.svg";

import "./AuthPage.css";

// NOTE :

const CustomerLogoutPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    await logout();
    setIsLoading(false);
    navigate("/");
  };

  const LogoutForm = () => {
    return (
      <Form noValidate>
        <p className="mb-3 fw-normal">
          Нажмите кнопку <strong>Выйти</strong> чтобы выйти из личного кабинета
          и перейти на главную страницу.
        </p>
        <Button
          className="w-100 theme-btn"
          type="button"
          variant="primary"
          disabled={isLoading}
          onClick={handleLogout}
        >
          <FaLock className="auth-icon my-4" />
          <Spinner hidden={!isLoading} />
          <span className="px-2">Выйти</span>
        </Button>
      </Form>
    );
  };

  const SectionContent = (props) => {
    return (
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-7 col-lg-6 auth-left">
              <img src={Image1} className="img-fluid" alt="Client logout" />
            </div>
            <div className="col-md-12 col-lg-6 auth-right">
              <div className="auth-header mb-5">
                <h3>Выйти из личного кабинета</h3>
              </div>

              <LogoutForm onSubmit={props.onSubmit} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <main className="page-logout">
      <div className="container">
        <SectionContent />
      </div>
    </main>
  );
};

CustomerLogoutPage.propTypes = {};

CustomerLogoutPage.defaultProp = {};

export default CustomerLogoutPage;
