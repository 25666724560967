import { useAuth } from "contexts/AuthProvider";
import { dataSite, dataTopMenu } from "data";
import { useMatchPath } from "hooks";
import { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";

//import ModalContact from "../sections/Home/ModalContact/ModalContact";

// http://themazine.com/html/Lebari/lebari/index.html

/**
 * Component for showing "Top menu" of the site.
 *
 * @component
 */
const TopMenu = () => {
  const [isSticky, setSticky] = useState(false);
  const { auth, isAuthenticated } = useAuth();
  const currentPermission = auth.permissions;
  const location = useLocation();
  const isPsychologistRoute = useMatchPath([
    "/psychologist",
    "/psychologist/:id",
  ]);

  // _debug("TopMenu:", location);
  // _debug("TopMenu:", isAuthenticated);
  // _debug("TopMenu:", currentPermission);
  // _debug("TopMenu:", currentRoute);

  const renderMenuItems = () => {
    return dataTopMenu.map((menuItem, index) => {
      // _debug("TopMenu (Item):", {
      //   menuItem,
      //   currentPermission,
      //   isPsychologistRoute,
      // });

      if (!menuItem.path) {
        return null;
      }
      if (menuItem.showOnlyWhenLoggedIn && !isAuthenticated) {
        return null;
      }
      if (menuItem.hideWhenLoggedIn && isAuthenticated) {
        return null;
      }
      if (menuItem.role === "GUEST") {
        if (
          currentPermission &&
          currentPermission.length &&
          (currentPermission.includes("PSYCHOLOGIST") ||
            currentPermission.includes("CUSTOMER"))
        )
          return null;
      } else if (menuItem.role !== "ANY") {
        if (
          isPsychologistRoute === "/psychologist" &&
          menuItem.role !== "PSYCHOLOGIST"
        ) {
          return null;
        }
        if (
          isPsychologistRoute !== "/psychologist" &&
          menuItem.role === "PSYCHOLOGIST"
        ) {
          return null;
        }
      }

      return (
        <Nav.Item
          key={index}
          className={menuItem.showIcon ? "align-items-center" : ""}
        >
          {menuItem.showIcon && menuItem.icon}
          <Nav.Link
            key={menuItem.path || menuItem.title}
            href={menuItem.path}
            bsPrefix="top-menu-nav-link nav-link"
          >
            {menuItem.title}
          </Nav.Link>
        </Nav.Item>
      );
    });
  };

  const handleScroll = () => {
    const show = window.scrollY >= 200;

    if (show) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      id="top-menu"
      className={isSticky ? "header navbar-area sticky" : "header navbar-area"}
    >
      <Container>
        <div className="row align-items-center">
          <div className="col-lg-12">
            <Navbar expand="lg" className="">
              <Navbar.Brand href="/" className="">
                <img
                  alt="psywealth"
                  src={require("assets/icons/logo-64x64.png")}
                  width="30"
                  height="30"
                  className="d-inline-block align-top top-menu-navbar-icon"
                />
                <span className="top-menu-navbar-title">
                  {dataSite.main.title}
                </span>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="top-menu-navbar-nav" />
              <Navbar.Collapse
                id="navbar-collapse"
                style={{ justifyContent: "flex-end" }}
                className="top-menu-navbar-bar"
              >
                {/*<Nav className="d-none d-xl-flex me-auto">
                  {renderMenuItems(dataTopMenuMain)}
                </Nav>*/}
                <Nav className="">{renderMenuItems(dataTopMenu)}</Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default TopMenu;

/*
<div>
                    <Nav.Item>
                      <div
                        class="tn-atom"
                        style={{ display: "inline", lineHeight: "25px" }}
                      >
                        <BiPhone />
                        <Nav.Link href="tel:+78005504662">
                          8 (800) 550-46-62
                        </Nav.Link>
                      </div>
                    </Nav.Item>
                  </div>
                  <div>
                    <Nav.Item>
                      <div class="tn-atom" style={{ lineHeight: "25px" }}>
                        <BiSupport />
                        <Nav.Link href="mailto:info@psywealth.com">
                          info@psywealth.com
                        </Nav.Link>
                      </div>
                    </Nav.Item>
                  </div>
*/
