import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { useOutletContext } from "react-router-dom";
import PsychologistService from "services/psychologist.service";
import ProfileForm from "./ProfileForm";
//import { CustomFormInputText2 } from "../../core/FormInput/FormInput";

// NOTE :

/**
 * Component for showing Section "Customer Profile (Tab) - Psychologist Profile" of the site.
 *
 * @component
 **/
const ProfileTabProfile = () => {
  const { profile } = useOutletContext();

  const queryClient = useQueryClient();

  // console.log("111!", profile);

  const mutationUpdateProfile = useMutation({
    mutationFn: async (newProfile) =>
      PsychologistService.updateProfile({ id: profile.id, data: newProfile }),
    onSuccess: (data) => {
      console.log("Profile updated successfully:", data);
      // queryClient.refetchQueries("profile");
    },
    onError: (error) => {
      console.error("Error update profile:", error);
    },
  });

  return (
    <Fragment>
      <div className="vstack gap-4">
        {/*<!-- Verified message -->*/}
        <div className="bg-light rounded p-3">
          {/*<!-- Progress bar -->*/}
          <div className="overflow-hidden">
            <h6>Complete Your Profile</h6>
            <div className="progress progress-sm bg-success bg-opacity-10">
              <div
                className="progress-bar bg-success aos aos-init aos-animate"
                role="progressbar"
                data-aos="slide-right"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                style={{ width: "85%" }}
                aria-valuenow="85"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <span className="progress-percent-simple h6 fw-light ms-auto">
                  85%
                </span>
              </div>
            </div>
            <p className="mb-0">
              Get the best out of booking by adding the remaining details!
            </p>
          </div>
          {/*<!-- Content -->*/}
          <div className="bg-body rounded p-3 mt-3">
            <ul className="list-inline hstack flex-wrap gap-2 justify-content-between mb-0">
              <li className="list-inline-item h6 fw-normal mb-0">
                <a href="#">
                  <i className="bi bi-check-circle-fill text-success me-2"></i>
                  Verified Email
                </a>
              </li>
              <li className="list-inline-item h6 fw-normal mb-0">
                <a href="#">
                  <i className="bi bi-check-circle-fill text-success me-2"></i>
                  Verified Mobile Number
                </a>
              </li>
              <li className="list-inline-item h6 fw-normal mb-0">
                <a href="#" className="text-primary">
                  <i className="bi bi-plus-circle-fill me-2"></i>Complete Basic
                  Info
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/*<!-- Personal info START -->*/}
        <div className="card border">
          {/*<!-- Card header -->*/}
          <div className="card-header border-bottom">
            <h4 className="card-header-title">Personal Information</h4>
          </div>

          {/*<!-- Card body START -->*/}
          <div className="card-body">
            {/*<!-- Form START -->*/}

            <ProfileForm
              handleSubmit={mutationUpdateProfile}
              profile={profile}
            />

            {/*<!-- Form END -->*/}
          </div>
          {/*<!-- Card body END -->*/}
        </div>
        {/*<!-- Personal info END -->*/}
      </div>
    </Fragment>
  );
};

ProfileTabProfile.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default ProfileTabProfile;
