import { CustomFormSelect } from "components/pages/Customer/components/core/FormInput/FormInput";
import { useGetPsyMethods } from "components/pages/Psychologist/utils/useGetPsyMethods";
import { Spinner } from "components/utils/Spinner";
import { dataMessages } from "data";
import { useEffect } from "react";
import { Form as BSForm, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { _debug } from "utils";

/**
 * Component for showing Orders table.
 *
 * @component
 */
const MethodModalForm = (props) => {
  const {
    showModal,
    handleClose,
    modalFormData = {},
    setModalFormData,
  } = props;

  // console.log(props);

  const {
    //methods,
    register,
    control,
    handleSubmit,
    formState: { formErrors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      method: modalFormData?.method,
    },
  });

  const {
    data: methods,
    isLoading: isLoadingPsyMethods,
    isError: isErrorPsyMethods,
    error: errorPsyMethods,
  } = useGetPsyMethods();

  const isLoading = isLoadingPsyMethods;
  const isError = isErrorPsyMethods;
  const errors = [errorPsyMethods];

  // useEffect(() => {
  //   reset({
  //     mail,
  //   });
  // }, [reset, mail]);

  const onSubmit = (data) => {
    const methodId = methods.filter((item) => item.name === data.method);

    console.log("SUBMIT:", data, methodId[0].id, methods);
    // setModalFormData(data);
    handleClose({ id: methodId[0].id });
  };
  const onError = (error) => {
    console.log("ERROR:::", error);
  };

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (methods && methods.length) {
      _debug("PSY METHODS:", methods);
    }
  }, [methods]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Modal heading</Modal.Title>
      </Modal.Header>
      <form className="" onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Body>
          <BSForm.Group className="mb-3" controlId="formMethod">
            <CustomFormSelect
              label="Method"
              required
              error={errors.method}
              options={methods}
              control={control}
              name="method"
            />
          </BSForm.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default MethodModalForm;
