import { PropTypes } from "prop-types";

import ImageHero from "assets/images/hero/job-hero-section2.webp";

const msgGetStarted = "Заказать";

/**
 * Component for showing Component "Hero" of the site.
 *
 * @component
 */

const Hero2 = (props) => {
  const { data, ...otherProps } = props;

  return (
    <div className="bg-light py-lg-9 py-6 bg-cover">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 col-12">
            <div>
              <div className="text-center text-md-start ">
                <h1 className="display-5 fw-bold mb-3">{data.title}</h1>
                <p className="lead p-large">{data.description}</p>
              </div>
              <div className="mt-8">
                <div className="p-md-2 p-4">
                  <div className="col-12 col-md-3  text-end d-grid">
                    <button
                      onClick={otherProps.onScroll}
                      aria-label={msgGetStarted}
                      className="btn btn-primary theme-btn"
                    >
                      {msgGetStarted}
                    </button>
                  </div>
                </div>
              </div>

              <span className="fs-4"></span>
            </div>
          </div>
          <div className="offset-lg-1 col-lg-4 col-12 text-center">
            <div className="position-relative ">
              <img src={ImageHero} className="img-fluid" alt={data.image.alt} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Hero2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default Hero2;
