import { CustomFormSelect } from "components/pages/Customer/components/core/FormInput/FormInput";
import { useGetPsySpecializations } from "components/pages/Psychologist/utils/useGetPsySpecializations";
import { Spinner } from "components/utils/Spinner";
import { dataMessages } from "data";
import { useEffect } from "react";
import { Form as BSForm, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { _debug } from "utils";

/**
 * Component for showing Orders table.
 *
 * @component
 */
const SpecializationModalForm = (props) => {
  const {
    showModal,
    handleClose,
    modalFormData = {},
    setModalFormData,
  } = props;

  // console.log(props);

  const {
    //methods,
    register,
    control,
    handleSubmit,
    formState: { formErrors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      specialization: modalFormData?.specialization,
    },
  });

  const {
    data: specializations,
    isLoading: isLoadingPsySpecializations,
    isError: isErrorPsySpecializations,
    error: errorPsySpecializations,
  } = useGetPsySpecializations();

  const isLoading = isLoadingPsySpecializations;
  const isError = isErrorPsySpecializations;
  const errors = [errorPsySpecializations];

  // useEffect(() => {
  //   reset({
  //     mail,
  //   });
  // }, [reset, mail]);

  const onSubmit = (data) => {
    console.log(data);
    const curSpecialization = specializations.filter(
      (item) => item.title === data.specialization
    );

    console.log("SUBMIT:", data, curSpecialization[0].id);
    // setModalFormData(data);
    handleClose({ id: curSpecialization[0].id });
  };
  const onError = (error) => {
    console.log("ERROR:::", error);
  };

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (specializations && specializations.length) {
      _debug("PSY SPECIALIZATIONS:", specializations);
    }
  }, [specializations]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Modal heading</Modal.Title>
      </Modal.Header>
      <form className="" onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Body>
          <BSForm.Group className="mb-3" controlId="formSpecialization">
            <CustomFormSelect
              label="Specialization"
              required
              error={errors.specialization}
              options={specializations}
              control={control}
              name="specialization"
            />
          </BSForm.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default SpecializationModalForm;
