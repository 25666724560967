import { useQuery } from "@tanstack/react-query";
import CourseService from "services/course.service";

export const useGetCourseBySlug = (slug) => {
  //const { id, slug } = props;
  //console.log("!!!", slug);

  return useQuery({
    queryFn: () => CourseService.getBySlug(slug),
    queryKey: ["courseBySlug", slug],
  });
};
