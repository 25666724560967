import Banner from "components/sections/Banner/Banner";
import { SEO } from "components/utils/Analytics";
import { dataAskQuestion, dataFAQ, dataFAQPage, dataSubscribe } from "data";
import { Fragment } from "react";
import { Subscribe } from "../Home";
import SectionAskQuestion from "./AskQuestion";
import SectionFAQ from "./FAQ";

/**
 * Component for showing Page "FAQ" of the site.
 *
 * @component
 */
const FAQPage = () => {
  return (
    <Fragment>
      <SEO data={dataFAQPage.meta}>
        <link rel="canonical" href="https://psywealth.com/faq/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="page-faq" id="faq-page">
        <Banner data={dataFAQPage} />
        <SectionFAQ data={dataFAQ} />
        <SectionAskQuestion data={dataAskQuestion} />
        <Subscribe data={dataSubscribe} />
      </main>
    </Fragment>
  );
};

export default FAQPage;
