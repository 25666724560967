import { dataContact } from "data";
import { registerUserContact } from "services";
import { PageSection, SectionHeader } from "../../sections/CommonSection";
import FormContact from "./FormContact";

const SectionContent = (props) => {
  const { data, id = "contact-content", cn = "" } = props;

  const ContactItem = (props) => {
    const { item, index } = props;
    return (
      <div className="col-12 col-md-6 col-xl-12">
        <div className="contact-item">
          <div className="contact-icon">{item.icon}</div>
          <div className="contact-content">
            <h4>{item.title}</h4>
            {item.items.map((item1, index1) => {
              return <p key={index1}>{item1.title}</p>;
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-4">
          <div className="contact-item-wrapper">
            <div className="row">
              {dataContact.items.map((item, index) => (
                <ContactItem item={item} index={index} key={index} />
              ))}
            </div>
          </div>
        </div>
        <div className="col-xl-8">
          {/*   <div className="contact-form-wrapper">*/}
          {/*  <SectionContentHeader data={data.extraData} id={id} cn={cn} />*/}
          <FormContact onSubmit={registerUserContact} />
        </div>
      </div>
    </div>
  );
};

/**
 * Component for showing Section "Contact" of the site.
 *
 * @component
 */
const SectionContact = (props) => {
  const { data, id = "contact", cn = "pt-130" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} />
        <SectionContent data={data} id={id} cn={cn} />
      </div>
    </PageSection>
  );
};

export default SectionContact;

/*

const ContactUs = () => {
  return (
    <section
      className="subscribe-section pt-70 pb-70 img-bg"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="section-title mb-30">
              <span
                className="text-white wow fadeInDown"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInDown",
                }}
              >
                <font style="vertical-align: inherit;">Подписаться</font>
              </span>
              <h2
                className="text-white mb-40 wow fadeInUp"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInUp",
                }}
              >
                <font style="vertical-align: inherit;">
                  Подпишитесь на нашу рассылку
                </font>
              </h2>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <form
              action="#"
              className="subscribe-form wow fadeInRight"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: "0.4s",
                animationName: "fadeInRight",
              }}
            >
              <input
                type="text"
                name="subs-email"
                id="subs-email"
                placeholder="Ваш адрес электронной почты"
              />
              <button type="submit">
                <i className="lni lni-telegram-original"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

const ContactFormTitle = () => {
  return (
    <div className="section-title text-center mb-50">
      <span
        className="wow fadeInDown"
        data-wow-delay=".2s"
        style={{
          visibility: "visible",
          animationDelay: "0.2s",
          animationName: "fadeInDown",
        }}
      >
        Связаться
      </span>
      <h2
        className="wow fadeInUp"
        data-wow-delay=".4s"
        style={{
          visibility: "visible",
          animationDelay: "0.4s",
          animationName: "fadeInUp",
        }}
      >
        Готовы начать работу
      </h2>
      <p
        className="wow fadeInUp"
        data-wow-delay=".6s"
        style={{
          visibility: "visible",
          animationDelay: "0.6s",
          animationName: "fadeInUp",
        }}
      >
        At vero eos et accusamus et iusto odio dignissimos ducimus quiblanditiis
        praesentium
      </p>
    </div>
  );
};

*/
