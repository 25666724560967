import { dataFAQComponent1, dataFAQComponent2 } from "./dataFAQComponents";

export const dataFAQ = {
  title: "Ответы на частые вопросы",
  subtitle: "Ответы на частые вопросы",
  description: "Свяжитесь с нами, если у вас есть вопрос",
  content: {
    title: "Остались вопросы?",
    subtitle: "Остались вопросы?",
    description: "Напишите нам и специалист свяжется с вами",
    image: require("assets/images/question-image3_rb.webp"),
    imageAlt:
      "" ||
      "Психолог онлайн, сервис онлайн консультация с психологами - PsyWealth",
  },
  items: [
    {
      title: "Кто такой психолог?",
      description:
        "В широком смысле психолог — это профессионал, который помогает людям справиться со своими мыслями, чувствами и поведением. В психотерапии можно улучшить качество своей жизни: наладить отношения с людьми, найти источник проблем и тревоги, изучить самого себя.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Что делает психолог?",
      description: "",
      componentHTML: dataFAQComponent1,
      main: true,
      disable: false,
    },
    {
      title: "Какие документы можно хранить в электронном виде?",
      description:
        "",
      componentHTML: dataFAQComponent2,
      main: true,
      disable: false,
    },
  ],
};
