import { useQuery } from "@tanstack/react-query";
import PsychologistService from "services/psychologist.service";

export const useGetTherapistAvailabilityByDate = (props) => {
  const { psychologistId, date } = props;
  // console.log(props);

  return useQuery({
    queryFn: () =>
      PsychologistService.getPsychologistAvailabilityByDate({
        psychologistId,
        date,
      }),
    queryKey: ["psychologistAvailabilityByDate", { psychologistId, date }],
  });
};
