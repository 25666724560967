export const dataFAQComponent1 = {
  data:
    `<p>Психолог выслушивает ваш запрос или помогает его сформулировать — и затем бережно направляет, помогая разобраться в себе или ситуации.</p>` +
    `<p>Многие специалисты PsyWealth работают с тонкими настройками человеческой психики: защитами, бессознательным, установками, внутренними конфликтами — и тем, как всё это влияет на повседневную жизнь.</p>`,
};

export const dataFAQComponent2 = {
  data:
    `<p>Психолог нужен, если хочется привести в порядок свои мысли и чувства.</p>` +
    `<p>К примеру, у вас не складываются отношения с партнёром. Или никак не получается "найти себя" и раздражает работа. Или кажется, что в будущем ждёт только плохое — и от этого тревожно. На сеансах с терапевтом можно поговорить об этом, изучить прошлый опыт, обнаружить негативные модели мышления и научиться реагировать на ситуации иначе.</p>` +
    `<p>Иногда бывает, что у клиента вроде бы нет готовой темы для разговора с терапевтом, но этот разговор почему-то нужен. А порой люди, переживающие тяжёлый развод или смерть близких, не испытывают потребности в терапии — и это тоже нормально.</p>`,
};

export const dataFAQComponent3 = {
  data:
    `<p>Различия в сроках хранения бумажных и электронных документов законодательством не установлены. Определены лишь общие сроки хранения:</p>` +
    `<ul className="list-styled">` +
    `<li> в течение четырех лет налогоплательщики обязаны обеспечивать сохранность данных бухгалтерского и налогового учета и других документов, необходимых для исчисления и уплаты налогов, в том числе документов, подтверждающих получение доходов, осуществление расходов (для организаций и ИП), а также уплату (удержание) налогов, если иное не предусмотрено НК РФ (подп. 8 п. 1 ст. 23 НК РФ); </li>` +
    `<li> в течение шести лет плательщики страховых взносов обязаны обеспечивать сохранность документов, необходимых для исчисления и уплаты страховых взносов (подп. 6 п. 3.4 ст. 23 НК РФ); </li>` +
    `<li> первичные учетные документы, регистры бухгалтерского учета, бухгалтерская (финансовая) отчетность, аудиторские заключения о ней подлежат хранению в течение сроков, устанавливаемых в соответствии с правилами организации государственного архивного дела, но не менее пяти лет после отчетного года; </li>` +
    `<li> документы учетной политики, стандарты организации, другие документы, связанные с организацией и ведением бухгалтерского учета, в том числе средства, обеспечивающие воспроизведение электронных документов, а также проверку подлинности электронной подписи, подлежат хранению не менее пяти лет после года, в котором они использовались для составления бухгалтерской (финансовой) отчетности в последний раз (п. 1, 2 ст. 29 Закона «О бухгалтерском учете»). </li>` +
    `</ul>` +
    `<p>Наиболее полный список документов с указанием сроков их хранения содержится в Приказе Росархива от 20 декабря 2019 г. № 236.</p>` +
    `<p>Для акционерных обществ сроки хранения документов определены Положением о порядке и сроках хранения документов акционерных обществ (утв. Постановлением ФКЦБ России от 16 июля 2003 г. № 03-33/пс).</p>` +
    `<p>То есть при определении сроков хранения документов нужно учитывать в совокупности требования корпоративного, гражданского, налогового законодательства и указания регуляторов (например, указание Банка России от 25 ноября 2009 г. № У-2346).</p>`,
};

export const dataFAQComponent4 = {
  data:
    `<p>Сегодня в России нет барьеров в виде ограничений или запрета на создание электронных архивов длительного хранения. Более того, в ближайшее время будут предусмотрены все условия для легитимного создания электронных архивов, которые позволят сократить издержки, возникающие при хранении документов в бумажном виде. Так, за последний год в рамках национального проекта «Цифровая экономика» и отдельных экспериментов профильных министерств уже утверждены или рассматриваются изменения, касающиеся работы с электронными документами.</p>` +
    `<p>1. В 2021 г. вступили в силу поправки о дистанционной работе в ТК РФ (ст. 312.1–312.3). Принят в первом чтении законопроект <a target="_blank" href="https://sozd.duma.gov.ru/bill/1162885-7">№ 1162885-7</a>, которым предлагается внести изменения в ТК РФ в части регулирования электронного документооборота в сфере трудовых отношений. Данные изменения дадут возможность легитимно создавать электронные архивы кадровых документов.</p>` +
    `<p>2. В мае 2021 г. на рассмотрение в Госдуму передан законопроект <a target="_blank" href="https://sozd.duma.gov.ru/bill/1173189-7">№ 1173189-7</a>, направленный на регулирование использования и хранения электронных документов. Законопроект предполагает внесение изменений в НК РФ, федеральные законы и приказы профильных министерств, касающихся хранения документов и легитимного создания электронных архивов без дублирования на бумаге. Законопроект планируется утвердить до конца 2021 г., что позволит уже в следующем году перейти на цифровое хранение документов.</p>}`,
};

export const dataFAQComponent5 = {
  data:
    `<p>Конечно, иначе в чем смысл? Достоверность документации обеспечивается за счет создания в электронном архиве надежной среды хранения и контроля целостности каждого документа в фоновом режиме.</p>` +
    `<p>Для обеспечения юридической значимости документа при сохранении его в архиве используются усовершенствованная электронная подпись и процедура «перештамповки». Электронный документ хранится в виде контейнера, который содержит: файл проверки электронной подписи и отсоединенную электронную подпись. При «перештамповке» в отсоединенную подпись добавляются архивные штампы времени, которые позволяют обеспечить проверку структуры контейнера, ссылочную целостность файлов контейнера и доказательство времени подписания документа. Процедуры «перештамповки» и проверки позволяют сохранить целостный электронный документ, который будет доступен для экспорта и передачи по требованию.</p>` +
    `<p>В системе электронного длительного архива валидность электронной подписи проверяется при приеме документов по настроенным процедурам и операциям, например при импорте документов из различных источников – ERP, СЭД. Как правило, для обеспечения легитимности, длительного хранения и открытия документов (даже, скажем, по прошествии 20–30 лет) используется импорт документов в PDF/A и добавляется штамп с информацией о подписи. Формат PDF/A рекомендован ВНИИДАД и является сертифицированным стандартом архивирования по <a target="_blank" href="https://www.iso.org/obp/ui#iso:std:iso:19005:-1:ed-1:v1:ru">ISO 19005-1:2005</a>.</p>`,
};

// Какие документы можно хранить в электронном виде?
export const dataFAQComponent6 = {
  data:
    `<p>Хранению в течение установленных сроков подлежат подлинники документов (подлинником является первый или единичный экземпляр документа<sup>1</sup>). Если документ был изначально составлен и подписан в бумажной форме, то в ней он и должен храниться. Оцифрованный документ (электронный образ – электронная копия документа, изготовленного на бумажном носителе<sup>2</sup>) даже после проставления на нем электронной подписи равнозначным оригиналу не считается. Поэтому создание электронного архива оцифрованных документов в первую очередь необходимо для быстрого поиска документов и доступа к ним. Оригиналы бумажных документов уничтожать нельзя, так как контролирующие органы вправе потребовать их предоставления в течение установленных сроков хранения.</p>` +
    `<p>Документы, изначально составленные и подписанные с помощью систем электронного документооборота с проставлением электронной подписи, могут храниться в течение установленных сроков без распечатывания на бумаге. Минфин России неоднократно давал такие разъяснения в своих письмах.</p>`,
};
