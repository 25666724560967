import { APP_API_URI } from "config/config";
import { _debug } from "utils";
import { requestGlobalHeaders, requestGlobalOptions } from "./auth.service";
import { getResultOrError } from "./course.service";

const SERVICE_DEBUG = true;

const SERVICE_URL = `${APP_API_URI}/v1/event/videos`;

const getServiceURL = (URL) =>
  URL ? `${SERVICE_URL}/${URL}` : `${SERVICE_URL}`;

const LOGGER = (message, obj, type) => {
  if (SERVICE_DEBUG) _debug(message, obj, type);
};

export const getVideoBySlug = async (eventVideoId) => {
  _debug("Event Video fetched by Slug API", eventVideoId);

  const URL = getServiceURL(`videos/${eventVideoId}`);

  const headers = {
    ...requestGlobalHeaders,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(headers),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  return result;
};

export const getAll = async () => {
  _debug("Event Video fetched all API");

  const URL = getServiceURL("");

  const headers = {
    ...requestGlobalHeaders,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(headers),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  return result;
};

export const getTop = async () => {
  _debug("Event Video fetched all API (top)");

  const URL = getServiceURL("top");

  const headers = {
    ...requestGlobalHeaders,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(headers),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  return result;
};

const DailyVideoService = {
  getAll,
  getTop,
  getVideoBySlug,
};

export default DailyVideoService;
