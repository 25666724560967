import { Loading } from "components/utils";
import { SEO } from "components/utils/Analytics";
import { Spinner } from "components/utils/Spinner";
import { dataBlogPage, dataMessages } from "data";
import { Fragment, Suspense, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Outlet, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { Banner } from "../../sections/Banner";
import { PageSection } from "../Home";
import BlogCategories from "./BlogCategories";
import BlogSocial from "./BlogSocial";
import BlogTopTags from "./BlogTopTags";
import { useGetMetaBlogPost } from "./utils";

// NOTE : https://preview.uideck.com/items/cryptoland/

const SectionContent = (props) => {
  const { data } = props;

  // NOTE : https://github.com/CodeCompleteYT/react-search-bar/blob/main/src/components/SearchResult.jsx

  return (
    <Row>
      <div className="col-xl-8 col-lg-9">
        <div className="left-side-wrapper">
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
      <div className="col-xl-4 col-lg-3">
        <div className="sidebar-wrapper">
          <Suspense fallback={<Loading />}>
            <BlogCategories />
          </Suspense>
          <Suspense fallback={<Loading />}>
            <BlogTopTags />
          </Suspense>
          <BlogSocial />
        </div>
      </div>
    </Row>
  );
};

/**
 * Component for showing Page "Blog Layout" of the site.
 *
 * @component
 */
const BlogLayout = () => {
  const { slug } = useParams();
  const {
    data: postMetadata,
    isLoading: isLoadingPostMetadata,
    isError: isErrorPostMetadata,
    error: errorPostMetadata,
  } = useGetMetaBlogPost(slug);
  const [meta, setMeta] = useState({});

  // console.log("!!!", slug);

  const isLoading = isLoadingPostMetadata;
  const isError = isErrorPostMetadata;
  const errors = [errorPostMetadata];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (postMetadata) {
      _debug("METADATA:", postMetadata);

      try {
        const metadataObj = postMetadata?.toHashMap("type");
        // 1. check data valid
        // const metaLD = JSON.stringify(metadataObj?.LD.content);
        const metaMain = JSON.parse(metadataObj?.Main.content);
        const metaOG = JSON.parse(metadataObj?.OG.content);
        const metaTW = JSON.parse(metadataObj?.TW.content);
        console.log(metaMain, metaOG);

        const metaNew = { ...dataBlogPage.meta, ...metaMain };

        // if (meta.OG && meta.OG.length > 0)
        // metaNew.OG = [...metaNew.OG, ...metaOG];

        const map1 = new Map(
          [...metaNew.OG, ...metaOG].map((obj) => [obj.property, obj])
        );
        metaNew.OG = Array.from(map1.values());

        // if (meta.TW && meta.TW.length > 0)
        // metaNew.TW = [...metaNew.TW, ...metaTW];
        const map2 = new Map(
          [...metaNew.TW, ...metaTW].map((obj) => [obj.property, obj])
        );
        metaNew.TW = Array.from(map2.values());

        _debug("METADATA FULL:", metaNew);
        setMeta(metaNew);
      } catch (err) {
        console.error(err);
        setMeta({});
      }
    }
  }, [postMetadata]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isLoadingPostMetadata && errorPostMetadata?.status === 204) {
    //   return <div className="error">Нет платежей</div>;
  }

  // console.log(postMetadata);

  return (
    <Fragment>
      <SEO data={meta}>
        <link rel="canonical" href="https://psywealth.com/blog/" />
        {meta && meta.LD && (
          <script type="application/ld+json">{meta.LD}</script>
        )}
      </SEO>
      <main className="page-blog">
        <Banner data={dataBlogPage} />
        <PageSection id="blog-post-list" cn="blog-section pt-130">
          <div className="container">
            {/*<SectionHeader data={dataSection}  />*/}
            <SectionContent />
          </div>
        </PageSection>
      </main>
    </Fragment>
  );
};

export default BlogLayout;
