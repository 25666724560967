//import { Form as FormValidator } from "components/utils/Forms/Forms";
import { useAuth } from "contexts/AuthProvider";
import { dataSite } from "data";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import AuthForm from "./AuthForm";

import "./AuthPage.css";

// NOTE : https://demo.bootstrapdash.com/login-templates-pro/login-8/#!

// TODO : Добавить вход по телефону или почте, вход по телефону через СМС

const msgSignIn = "Войти";
const msgSignUp = "Зарегистрироваться";
const msgAgreement =
  "Создавая аккаунт, вы соглашаетесь с условиями предоставления услуг и даёте согласие на обработку персональных данных.";
const msgSubmitted = "Сообщение успешно отправлено";
const msgSubmit = "Отправить сообщение";

const data = {
  title: "Вход для психологов",
  subtitle: "",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
  copyright: "Copyright 2024",
};

const SectionContent = (props) => {
  const { mode, onSubmit, onChangeMode } = props;

  return (
    <div className="row">
      <div className="col-sm-5 intro-section">
        <div className="brand-wrapper">
          <img
            src={require("assets/icons/logo-64x64.png")}
            alt=""
            className="logo"
          />
        </div>
        <div className="intro-content-wrapper">
          <h1 className="intro-title">{data.title}</h1>
          <p className="intro-text">{data.description}</p>
        </div>
        <div className="intro-section-footer">
          <div className="copyright">
            © Copyright {dataSite.main.copyrightYear}{" "}
            <strong>
              <span className="copyright-text">
                {dataSite.main.copyrightText}
              </span>
            </strong>
          </div>
        </div>
      </div>
      <div className="col-sm-7 form-section">
        <div className="login-wrapper-signup-text text-center">
          {mode === "signin" ? "Need an account?" : "Already registered?"}{" "}
          <span className="link-primary" onClick={onChangeMode}>
            {mode === "signin" ? "Sign In" : "Sign Up"}
          </span>
        </div>

        <div className="login-wrapper">
          <h2 className="login-title">
            {mode === "signin" ? "Sign In" : "Sign Up"}
          </h2>
          <AuthForm
            mode={mode}
            onChangeMode={onChangeMode}
            onSubmit={onSubmit}
          />
          <h6 className="social-login-title">OR</h6>
        </div>
      </div>
    </div>
  );
};

/**
 * Component for showing Page "Customer Auth" of the site.
 *
 * @component
 */
const PsychologistAuthPage = (props) => {
  const { mode = "signin" } = props;
  let [authMode, setAuthMode] = useState(mode);

  const {
    isAuthenticated,
    psychologistLogin: signIn,
    psychologistRegister: signUp,
    logout,
  } = useAuth();

  const labels = {};

  const handleChangeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin");
  };

  return (
    <main className="page-auth">
      {isAuthenticated && (
        <Navigate to="/psychologist/profile" replace={true} />
      )}
      <div className="container-fluid">
        <div className="auth-wrapper">
          <SectionContent
            mode={authMode}
            onChangeMode={handleChangeAuthMode}
            onSubmit={authMode === "signin" ? signIn : signUp}
          />
        </div>
      </div>
    </main>
  );
};

PsychologistAuthPage.propTypes = {
  mode: PropTypes.oneOf(["signin", "signup"]),
  type: PropTypes.oneOf(["style1"]),
};

PsychologistAuthPage.defaultProp = {};

export default PsychologistAuthPage;
