import { Banner } from "components/sections";
import { SEO } from "components/utils/Analytics";
import { dataContactsPage, dataSubscribe } from "data";
import { dataContact } from "data/dataContact";
import { Fragment } from "react";
import { Subscribe } from "../Home";
import SectionContact from "./SectionContact";
import SectionMap from "./SectionMap";

/**
 * Component for showing Page "Contacts" of the site.
 *
 * @component
 */
const ContactsPage = () => {
  return (
    <Fragment>
      <SEO data={dataContactsPage.meta}>
        <link rel="canonical" href="https://psywealth.com/contacts/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="page-contact">
        <Banner data={dataContactsPage} />
        <SectionContact data={dataContact} cn="pt-130 pb-80" />
        <SectionMap data={dataContact} />
        <Subscribe data={dataSubscribe} />
      </main>
    </Fragment>
  );
};

export default ContactsPage;
