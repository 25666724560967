export const dataSite = {
  main: {
    address: "Москва, 123100, Пресненская наб. 12, Город Столиц",
    phone: "+7(925)995-87-90",
    phoneLink: "tel:+79259958790",
    email: "info@psywealth.com",
    emailLink: "mailto:info@psywealth.com",
    title: "psywealth",
    subtitle: "psywealth.com",
    description: "PsyWealth - сервис для оказания психологической помощи",
    copyrightYear: "2023-2024",
    copyrightText: "psywealth.com",
    copyrightDescription:
      "Все права защищены. Полное или частичное копирование материалов запрещено. Используем cookies для корректной работы сайта, персонализации пользователей и других целей, предусмотренных политикой обработки персональных данных.",
    wh: "Открыто: 24 часа / 7 дней",
    wh1: "Время работы офиса: 10:00 – 17:30",
    name: "ИП Ловков Александр Вячеславович",
    INN: "7801649294",
    KPP: "780101001",
  },
  design: {
    title: "LovkovAV.PRO",
    link: "https://lovkovav.pro/",
  },
  owner: {
    title: "123123",
  },
  countdown: {
    title: "Ваш личный психолог онлайн - PsyWealth",
    subtitle: "Сервис для оказания психологической помощи онлайн",
    datetime: ["дней", "часов", "минут", "секунд"],
    description:
      "Подберем специалиста, который услышит, поймет, определит проблемы. Поможет поставить цель терапии и приведет к результату",
  },
};
