import classNames from "classnames";
import { PageSection } from "core/components";
import { PropTypes } from "prop-types";
import Hero1 from "./Hero1";
import Hero2 from "./Hero2";
import Hero3 from "./Hero3";

/**
 * Component for showing Section "Hero" of the site.
 *
 * @component
 */
const Hero = (props) => {
  const { data, id = "hero-section", cn = "", type, ...otherProps } = props;

  return (
    <PageSection id={id} cn={classNames(cn, `hero-section-${type}`)}>
      {(() => {
        if (type === "style2") {
          return <Hero2 data={data} {...otherProps} />;
        } else if (type === "style3") {
          return <Hero3 data={data} {...otherProps} />;
        }
        return <Hero1 data={data} {...otherProps} />;
      })()}
    </PageSection>
  );
};

Hero.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1", "style2", "style3"]),

  // header: PropTypes.header({
  //   showTitle: PropTypes.bool ?? true,
  //   showSubtitle: PropTypes.bool ?? false,
  //   showDescription: PropTypes.bool ?? false,
  // }),

  useAnimation: PropTypes.bool ?? false,
};

export default Hero;
