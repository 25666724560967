import { CallToAction, Feature, Overview2 } from "components/sections";
import { SEO } from "components/utils/Analytics";
import {
  dataAboutPsychologistPage as dataAbout,
  dataCTAPsychologistPage as dataCTA,
  dataFAQPsychologistPage as dataFAQ,
  dataFeaturePsychologistPage as dataFeature,
  dataHeroPsychologistPage as dataHero,
  dataMobileAppPsychologistPage as dataMobileApp,
  dataOurAdvantagesPsychologistPage as dataOurAdvantages,
  dataOverviewPsychologistPage as dataOverview,
  dataPsychologistPage,
} from "data";
import { Fragment } from "react";
import { Hero2 as Hero } from ".";
import { SectionFAQ } from "../Home";
import { OurAdvantages } from "../Home/Features1";
import { MobileAppSection } from "../Home/MobileApp";
import SectionAbout from "./SectionAbout";

// NOTE : https://demo.graygrids.com/themes/space/index.html

const PsychologistPage = () => {
  return (
    <Fragment>
      <SEO data={dataPsychologistPage.meta}>
        <link rel="canonical" href="https://psywealth.com/psychologist/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="psychologist-page" id="main">
        <Hero data={dataHero} />
        <Feature data={dataFeature} type="style1" />
        <Overview2 data={dataOverview} cn="pt-120 pb-120" />

        <OurAdvantages data={dataOurAdvantages} />
        <MobileAppSection data={dataMobileApp} />

        <CallToAction data={dataCTA} />
        <SectionFAQ data={dataFAQ} />
        <SectionAbout data={dataAbout} />
      </main>
    </Fragment>
  );
};

export default PsychologistPage;
