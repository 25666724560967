import { useIsMobile } from "hooks";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PageSection, SectionHeader } from "../../../sections/CommonSection";

import "./TopAdvice.scss";

// NOTE : https://promo.youtalk.ru/rich/?_gl=1*13awslr*_gcl_au*MTE5ODIzNTM1MS4xNzIwNDAyNDI2*_ga*MzYwODY2NTUxLjE3MjA0MDI0MjY.*_ga_14P9R9JJ4H*MTcyMDQwMjQyNi4xLjEuMTcyMDQwNDMzMi41Ny4wLjA.

const dataTopAdvice = {
  title: "Возможности",
  subtitle: "Возможности",
  description: "Возможности",
  items: [
    {
      title: "Что такое стресс?",
      subtitle: "",
      description: "",
      description1:
        "Обычно стрессом мы называем все, что заставляет нас находиться не в своей тарелке и нервничать.",
      description2:
        "На самом деле у стресса есть и позитивное предназначение - он помогает подготовиться нам к переменам, происходящим во внешнем мире. При чем телу все равно, позитивные или негативные это изменения с точки зрения сознания и ума. Для нашего организма одинаковыми поводами для стресса будут и развод и свадьба, повышение по работе и увольнение.",
      image: require("assets/images/demo/image1-1.webp"),
      imageAlt: "",
    },
    {
      title: "Почему стресс считается вредным?",
      subtitle: "",
      description: "",
      description1:
        "Если события, вызвавшие стресс, были чересчур яркими или их воздействие имело продолжительный период времени, мы переходим на стадию истощения или хронического стресса.",
      description2:
        "Если не позаботиться о себе вовремя, то он может привести к выгоранию, депрессии и другим негативным последствиям.",
      image: require("assets/images/demo/image1-2.webp"),
      imageAlt: "",
    },
    {
      title: "Как себя поддержать?",
      subtitle: "",
      description: "",
      description1:
        "ЕВажная основа для прохождения периодов стресса - то, как мы общаемся с собой.",
      description2:
        "Если в моменты, когда нам и так плохо поддаваться тревоге, критиковать себя или полагаться только на внешнюю поддержку - справиться со стрессом будет непросто.",
      image: require("assets/images/demo/image1-3.webp"),
      imageAlt: "",
    },
  ],
};

const SectionGoToCourses = (props) => {
  // const {} = props;
  // console.log("@@@@", data, index);

  return (
    <div className="col-lg-6 col-md-12 col-12">
      <div className="card blog-grid">
        <div className="card-content blog-grid-text p-4 align-middle">
          <h2 className="card-title h5 mb-3">
            <Link to={"/courses"} className="btn btn-primary theme-btn">
              Смотреть все курсы
            </Link>
          </h2>
          <p className="display-30">Смотреть все курсы</p>
        </div>
      </div>
    </div>
  );
};

const VideoSection1 = (props) => {
  const { data } = props;

  // console.log(data);

  return (
    <div className="row gx-0">
      <div
        className="col-lg-6 d-flex align-items-center aos-init aos-animate"
        data-aos="zoom-out"
        data-aos-delay="200"
      >
        <div className="shadow-md hover-shadow-soft">
          <img
            className="img-fluid w-100 rounded-soft"
            src={data.image || require("assets/images/demo/one-page.webp")}
            alt={data.imageAlt || ""}
          />
        </div>
      </div>
      <div className="col-lg-6 d-flex flex-column justify-content-center">
        <div className="content">
          <h3>{data.title}</h3>
          <p>{data.description1}</p>
          <p>{data.description2}</p>
          <div className="text-center text-lg-start"></div>
        </div>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data, isMobile } = props;

  // const {
  //   data: topCourses,
  //   isLoading: isLoadingTopCourses,
  //   isError: isErrorTopCourses,
  //   error: errorTopCourses,
  // } = useGetTopCourses();

  // const isLoading = isLoadingTopCourses;
  // const isError = isErrorTopCourses;
  // const error = [errorTopCourses];

  // _debug("isMobile", isMobile);

  // useEffect(() => {
  //   if (isError) {
  //     error.forEach((err) => {
  //       if (err) {
  //         console.error(err);
  //         toast.error(`Something went wrong: ${error?.message}`);
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isError]);

  // useEffect(() => {
  //   if (topCourses) {
  //     _debug("TOP COURSES:", topCourses);
  //   }
  // }, [topCourses]);

  // // Show a loading message while data is fetching
  // if (isLoading) {
  //   return (
  //     <Spinner
  //       hidden={!isLoading}
  //     />
  //   );
  // }

  // if (isErrorTopCourses && errorTopCourses?.status === 204) {
  //   return <div className="error">Нет курсов</div>;
  // }

  return (
    <Fragment>
      <div className="row">
        <Carousel variant="dark">
          {dataTopAdvice.items.map((item, index) => {
            return (
              <Carousel.Item index={index} key={index}>
                <div className="row equal">
                  <VideoSection1 data={item} />
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </Fragment>
  );
};

/**
 * Component for showing Section "Top Advice" of the site.
 *
 * @component
 */
const TopAdvice = (props) => {
  const { data, id = "top-advice", cn = "pt-80" } = props;
  const isMobile = useIsMobile(768);

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} />
        <SectionContent data={data} isMobile={false} />
      </div>
    </PageSection>
  );
};

TopAdvice.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default TopAdvice;
