import { useQuery } from "@tanstack/react-query";
import PsychologistService from "services/psychologist.service";

export const useGetTherapistBySlug = (slug) => {
  //const { id, slug } = props;
  //console.log("!!!", slug);

  return useQuery({
    queryFn: () => PsychologistService.getTherapistBySlug(slug),
    queryKey: ["therapistBySlug", slug],
  });
};
