import { Fragment } from "react";
import { Form } from "react-bootstrap";

const FormWrapper = ({ children }) => {
  return { children };
};

const UserForm1 = ({ type, updateFields }) => {
  const kindOfStand = "";
  return (
    <Fragment>
      <div key={`inline-radio`} className="mb-3">
        <Form.Group controlId="kindOfStand">
          <Form.Check
            label="для себя"
            name="group1"
            type="radio"
            id={`inline-radio-1`}
            value={type || "for_myself"}
            checked={type === "for_myself"}
          />
          <Form.Check
            label="для пары"
            name="group1"
            for_myself
            type="radio"
            id={`inline-radio-2`}
            value={type || "for_pair"}
            checked={type === "for_pair"}
          />
          <Form.Check
            label="для ребенка"
            name="group1"
            type="radio"
            id={`inline-radio-3`}
            value={type}
          />
        </Form.Group>
      </div>
    </Fragment>
  );
};

export default UserForm1;

/*

 <Form.Check
          inline
          label="для себя"
          name="group1"
          type="radio"
          id={`inline-radio-1`}
        />

*/
