import { Banner } from "components/sections";
import { SEO } from "components/utils/Analytics";
import { dataTherapistsPage } from "data";
import { Fragment, useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";

import { queryStringToObject } from "utils/helpers";
import "./CalendarTest.style.scss";
import AppointmentStepsSection from "./TherapistAppointment.section";

/**
 * Component for showing Section "Therapist List" of the site.
 *
 * @component
 **/
const TherapistAppointmentPage = () => {
  const { search } = useLocation();
  // let [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(
    () => queryStringToObject(search, { therapist: "" }),
    [search]
  );

  // console.log(search, queryParams, searchParams);

  return (
    <Fragment>
      <SEO data={dataTherapistsPage.meta}>
        <link rel="canonical" href="https://psywealth.com/therapists/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="therapist-appointment-page" id="main">
        <Banner data={dataTherapistsPage} />
        <AppointmentStepsSection
          data={dataTherapistsPage}
          params={queryParams}
        />
      </main>
    </Fragment>
  );
};

export default TherapistAppointmentPage;
