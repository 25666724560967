import { APP_API_URI } from "config/config";
import { _debug } from "utils";
import {
  getGlobalAuthHeader,
  requestGlobalHeaders,
  requestGlobalOptions,
} from "./auth.service";
import { getResultOrError } from "./course.service";

const SERVICE_DEBUG = true;

const SERVICE_URL = `${APP_API_URI}/psychologists/psychologist`;

const getServiceURL = (URL) =>
  URL ? `${SERVICE_URL}/${URL}` : `${SERVICE_URL}`;

const LOGGER = (message, obj, type) => {
  if (SERVICE_DEBUG) _debug(message, obj, type);
};

export const getAll = async () => {
  _debug("Psychologist fetched all API");

  const URL = getServiceURL();

  LOGGER("URL:", URL);

  const headers = {
    ...requestGlobalHeaders,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(headers),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getTop = async () => {
  _debug("Psychologist fetched all Top API");

  const URL = getServiceURL("top");

  LOGGER("URL:", URL);

  const headers = {
    ...requestGlobalHeaders,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(headers),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getTherapistBySlug = async (slug) => {
  _debug("Therapist fetched by Slug API");

  const URL = getServiceURL(`${slug}`);

  LOGGER("URL:", URL);

  const headers = {
    ...requestGlobalHeaders,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(headers),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getAvailabilityBySlug = async (slug) => {
  _debug("Therapist availability fetched by Slug API");

  const URL = getServiceURL(`/availability/${slug}`);

  LOGGER("URL:", URL);

  const headers = {
    ...requestGlobalHeaders,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(headers),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const updateBio = async ({ id, data }) => {
  const URL = getServiceURL(`profile/bio`);

  LOGGER("Psychologist add Education:", { URL, id, data });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "PUT",
    headers: new Headers(requestHeaders),
    body: JSON.stringify(data),
  };

  console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const updateProfile = async ({ id, data }) => {
  const URL = getServiceURL(`profile`);

  LOGGER("Update psychologist profile:", { URL, id, data });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "PUT",
    headers: new Headers(requestHeaders),
    body: JSON.stringify(data),
  };

  console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getProfileEducationById = async (id) => {
  const URL = getServiceURL(`profile/education/${id}`);

  LOGGER("Psychologist profile get Education by Id:", { URL, id });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    // body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const addEducation = async ({ id, data }) => {
  const URL = getServiceURL(`profile/education`);

  LOGGER("Psychologist add Education:", { URL, id, data });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "POST",
    headers: new Headers(requestHeaders),
    body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const deleteEducation = async ({ id }) => {
  const URL = getServiceURL(`profile/education/${id}`);

  LOGGER("Psychologist delete Education", { URL, id });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "DELETE",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const addMethod = async ({ data }) => {
  const URL = getServiceURL(`profile/method`);

  LOGGER("Psychologist add Method:", { URL, data });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "POST",
    headers: new Headers(requestHeaders),
    body: JSON.stringify(data),
  };

  console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const deleteMethod = async ({ id }) => {
  const URL = getServiceURL(`profile/education/${id}`);

  LOGGER("Psychologist delete Education", { URL, id });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "DELETE",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const addSpecialization = async ({ data }) => {
  const URL = getServiceURL(`profile/specialization`);

  LOGGER("Psychologist add Specialization:", { URL, data });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "POST",
    headers: new Headers(requestHeaders),
    body: JSON.stringify(data),
  };

  console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const deleteSpecialization = async ({ id }) => {
  const URL = getServiceURL(`profile/specialization/${id}`);

  LOGGER("Psychologist delete Specialization", { URL, id });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "DELETE",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const addProduct = async ({ data }) => {
  const URL = getServiceURL(`profile/product`);

  LOGGER("Psychologist add Product:", { URL, data });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "POST",
    headers: new Headers(requestHeaders),
    body: JSON.stringify(data),
  };

  console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const deleteProduct = async ({ id }) => {
  const URL = getServiceURL(`profile/product/${id}`);

  LOGGER("Psychologist delete Product", { URL, id });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "DELETE",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getProfileDocuments = async () => {
  const URL = getServiceURL(`profile/documents`);

  LOGGER("Psychologist profile get Documents", { URL });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const addProfileDocuments = async ({ data }) => {
  const URL = getServiceURL(`profile/documents/upload`);

  LOGGER("Psychologist add Documents", { data });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    //"Content-Type": "application/json",
    //"Content-Type": "multipart/form-data",
    ...headers,
  };

  console.log(data);

  const requestOptions = {
    ...requestGlobalOptions,
    method: "POST",
    headers: new Headers(requestHeaders),
    body: data,
    //body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  return fetch(URL, requestOptions);
};

export const getPsychologistOrders = async () => {
  const URL = getServiceURL(`profile/documents`);

  LOGGER("Get Psychologist orders", { URL });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getPsychologistPayments = async () => {
  const URL = getServiceURL(`profile/documents`);

  LOGGER("Get Psychologist payments", { URL });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getPsychologistWorkingPlan = async () => {
  const URL = getServiceURL(`availability`);

  LOGGER("Get Psychologist working plan", { URL });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getPsychologistAvailabilityByDate = async ({
  psychologistId,
  date,
}) => {
  const URL = getServiceURL(
    `v6/availability?id=${psychologistId}&date=${date}`
  );

  LOGGER("Get Psychologist availability by date", {
    URL,
    psychologistId,
    date,
  });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const addTimeSlot = async (data) => {
  const URL = getServiceURL(`v2/availability`);

  LOGGER("Psychologist add Documents", { data });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  console.log(data);

  const requestOptions = {
    ...requestGlobalOptions,
    method: "POST",
    headers: new Headers(requestHeaders),
    //body: data,
    body: JSON.stringify(data),
  };

  console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const setProfileImage = async (data) => {
  const URL = getServiceURL(`profile/image/add`);

  LOGGER("Set profile image", { URL });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    // "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "POST",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
    body: data,
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

const PsychologistService = {
  addEducation,
  addMethod,
  addProduct,
  addProfileDocuments,
  addSpecialization,
  addTimeSlot,
  deleteEducation,
  deleteMethod,
  deleteProduct,
  deleteSpecialization,
  getAll,
  getAvailabilityBySlug,
  getProfileDocuments,
  getProfileEducationById,
  getPsychologistAvailabilityByDate,
  getPsychologistOrders,
  getPsychologistPayments,
  getPsychologistWorkingPlan,
  getTherapistBySlug,
  getTop,
  setProfileImage,
  updateBio,
  updateProfile,
};

export default PsychologistService;
