import { PageSection } from "core/components";
import { PropTypes } from "prop-types";

// NOTE : https://codescandy.com/geeks-bootstrap-5/docs/cta-snippet.html

export const msgCTA = "Написать в поддержку";
export const msgSubmitted = "Написать в поддержку";
export const msgSubmit = "Написать в поддержку";

const SectionContent1 = (props) => {
  const { data, ...otherProps } = props;

  return (
    <div className="row">
      <div className="col-md-12 col-12">
        <div className="bg-primary py-6 px-6 px-xl-0 rounded-4 ">
          <div className="row align-items-center">
            <div className="offset-xl-1 col-xl-5 col-md-6 col-12">
              <div>
                <h2 className="h1 text-white mb-3">{data.title}</h2>
                <p className="text-white mb-4">{data.description}</p>
                <button className="btn btn-dark">{msgSubmit}</button>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 col-12">
              <div className="text-center">
                <img
                  src={require("assets/images/demo/course.webp")}
                  alt="learning"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Component for showing Section "Help Desk" of the site.
 *
 * @component
 */
const HelpDesk = (props) => {
  const {
    data,
    id = "call-to-action-new",
    cn = "pt-70 pb-70 call-to-action-new",
    type,
    showDescription = true,
    showSubtitle = false,
    useAnimation = false,
    innerRef,
    ...otherProps
  } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionContent1
          data={data}
          id={id}
          cn={cn}
          type={type}
          showDescription={showDescription}
          showSubtitle={showSubtitle}
          innerRef={innerRef}
          {...otherProps}
        />
      </div>
    </PageSection>
  );
};

HelpDesk.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1", "style2"]),
  showSubtitle: PropTypes.bool ?? false,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default HelpDesk;
