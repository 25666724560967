import { registerSubscription } from "services";
import { PageSection, SectionHeader } from "../Home";
import FormAskQuestion from "./FormAskQuestion";

const SectionAskQuestion = (props) => {
  const { data, id = "ask-question", cn = "pt-140 pb-100" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} />
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <FormAskQuestion onSubmit={registerSubscription} />
          </div>
        </div>
      </div>
    </PageSection>
  );
};

export default SectionAskQuestion;
