import { createColumnHelper } from "@tanstack/react-table";
import { CustomTable } from "components/pages/Customer/components/core/CustomTable";
import { Fragment } from "react";

const columnHelper = createColumnHelper();

/**
 * Component for showing Appointments table.
 *
 * @component
 */
const AppointmentsTable = (props) => {
  const {
    data: orders,
    tableClass,
    headerClass,
    onDelete,
    ...otherProps
  } = props;

  // console.log(orders);

  const columns = [
    // Grouping Column
    columnHelper.group({
      header: "Name",
      columns: [
        columnHelper.accessor("startDateTime", {
          header: "Start",
        }),
        columnHelper.accessor("endDateTime", {
          header: "End",
        }),
      ],
    }),

    columnHelper.accessor("psychologist.name", {
      header: "Psychologist",
    }),
    columnHelper.accessor("customer.name", {
      header: "Customer",
    }),
    columnHelper.accessor("id", {
      header: "Id",
    }),
    columnHelper.accessor("status", {
      header: "Status",
    }),
  ];

  return (
    <Fragment>
      {orders && (
        <CustomTable
          data={orders}
          columns={columns}
          tableClass={tableClass}
          headerClass={headerClass}
          {...otherProps}
        />
      )}
    </Fragment>
  );
};

export default AppointmentsTable;
