import { Fragment } from "react";
import { PageSection, SectionHeader } from "../Home";

import "./Map.style.scss";

import Image1 from "assets/icons/map-2.svg";

// NOTE : https://athemeart.dev/html/hostula/assets/demo/cloud-hosting.html

const SectionContent = (props) => {
  const { data } = props;

  return (
    <Fragment>
      <div className="row coverage">
        <div className="map">
          {/*<img src={require("assets/icons/map-2.svg")} alt="map" />*/}
          <img src={Image1} alt="map" />
          <div className="pointer pointer-1">
            <span className="txt">russia</span>
          </div>
          <div className="pointer pointer-2">
            <span className="txt">bangladesh</span>
          </div>
          <div className="pointer pointer-3">
            <span className="txt">india</span>
          </div>
          <div className="pointer pointer-4">
            <span className="txt">usa</span>
          </div>
          <div className="pointer pointer-5">
            <span className="txt">austrailia</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

/**
 * Component for showing Section "Map" of the site.
 *
 * @component
 */
const MapSection = (props) => {
  const { data, id = "map", cn = "pt-100 pb-100" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />
        <SectionContent data={data} />
      </div>
    </PageSection>
  );
};

export default MapSection;
