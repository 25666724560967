import { SectionHeader } from "components/sections/CommonSection";
import DOMPurify from "isomorphic-dompurify";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { ProfileSection } from "./ProfileSection.section";

// NOTE : https://alter.ru/psychologists/kajjani/ivan-gorbachev?position=1&from=search_page&from_link=%2Fpsychologists%3Falter_campaign%3Dlanding-menu

const SectionContent = (props) => {
  const { data, isMobile } = props;

  return (
    <Fragment>
      <div className="d-flex flex-column">
        {data.bio2 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data.bio2),
            }}
          ></div>
        ) : (
          <p className="font-futura-400 mb-3 d-block line-height-2 font-weight-0 font-size-4">
            {data.bio}
          </p>
        )}

        {/* <p className="font-futura-400 mb-3 d-block line-height-2 font-weight-0 font-size-4">
          Член Международной Ассоциации Схема-Терапии (ISST).
        </p>
        <p className="font-futura-400 mb-3 d-block line-height-2 font-weight-0 font-size-4">
          Тренер/Супервизор по схема-терапии пар (ISST). Директор первой в
          России сертификационной программы для специалистов по схема-терапии
          пар (ISST)." и "Соруководитель Санкт-Петербургского Института
          Схема-терапии Пар.
        </p>
        <p className="font-futura-400 mb-3 d-block line-height-2 font-weight-0 font-size-4">
          Каждый человек для меня — совершенная личность со своим опытом
          становления. По моему мнению, в работе с клиентом самое важное —
          принять человека как личность и подтвердить значимость и
          обоснованность его переживаний. Для того, чтобы понять клиента я
          стремлюсь увидеть его картину мира для того, чтобы в последствии
          помочь ему увидеть ситуацию со стороны.
        </p> */}

        <button className="align-self-start px-0 font-weight-1 t-decor-underline link-like-button">
          Читать дальше
        </button>
      </div>
    </Fragment>
  );
};

/**
 * Component for showing Section "Therapist Bio" of the site.
 *
 * @component
 */
const TherapistBio = (props) => {
  const { data, id = "therapist-bio", cn = "pt-80 pb-80" } = props;

  return (
    <ProfileSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} />
        <SectionContent data={data.therapist} isMobile={false} />
      </div>
    </ProfileSection>
  );
};

TherapistBio.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default TherapistBio;
