export const dataSubscribe = {
  title: "Подпишитесь на рассылку",
  title1: "Получайте последние обновления",
  title2: "Прямо на вашу почту",
  subtitle: "Присоединиться",
  description: "",
  componentHTML: {
    data:
      `<ul className="list-styled">` +
      `<li>Рассказываем про новинки и обновления наших продуктов</li>` +
      `<li>Онлайн и оффлайн мероприятия, в которых вы можете принять участие</li>` +
      `<li>Раз в месяц присылаем рассылку с последними новостями</li>` +
      `</ul>`,
  },
};

export const dataCTALetsContact = {
  title: "Оставить заявку на консультацию",
  title1: "Заявка на консультацию",
  title2: "",
  subtitle: "Оставить заявку",
  description:
    "Оставить заявку на консультацию, наш менеджер свяжется с вами и поможет подобрать решение для вашей задачи",
  msg: {
    msgCTA: "Отправить",
  },
  backgroundImage: require("assets/images/bg/cta-bg2.webp"),
};

export const dataCTASubscribe = {
  title: "Подпишитесь на нашу рассылку",
  title1: "Получайте последние обновления",
  title2: "Прямо на вашу почту",
  subtitle: "Присоединиться",
  description: "",
};

export const dataSubscribeComponent1 = {
  data:
    `<ul className="list-styled">` +
    `<li>Рассказываем про новинки и обновления наших продуктов</li>` +
    `<li>Онлайн и оффлайн мероприятия, в которых вы можете принять участие</li>` +
    `<li>Раз в месяц присылаем рассылку с последними новостями</li>` +
    `</ul>`,
};
