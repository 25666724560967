import { SocialLinks } from "components/utils/Social";
import { dataSite } from "data";
import { dataFooter } from "data/dataFooter";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  dataFooterMenu,
  dataFooterServices,
  dataSocialLinks,
} from "../../../data/dataSections";
import {
  ContentSiteEmail,
  ContentSitePhone,
  ExternalLink2,
} from "../../utils/Utils";

/**
 * Component for showing "Footer" of the site.
 *
 * @component
 */
const Footer = () => {
  const FooterMenu = (props) => {
    const { data } = props;

    const renderMenuItems = (menuItems) => {
      return menuItems.map((menuItem, index) => {
        if (!menuItem.path) {
          return null;
        }

        return (
          <li className="footer-links-item" key={index}>
            <Nav.Link href={menuItem.path} bsPrefix="footer-menu-link">
              {menuItem.title}
            </Nav.Link>
          </li>
        );
      });
    };

    return (
      <div className="footer-menu">
        <Nav className="footer-links flex-column" as={"ul"}>
          {renderMenuItems(data)}
        </Nav>
      </div>
    );
  };

  return (
    <footer className="footer pt-100">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="footer-widget">
              <Link to="/" className="mb-3">
                <div className="navbar-brand">
                  <img
                    src={require("assets/icons/logo-64x64.png")}
                    alt="Электронный архив для организаций - psywealth"
                  />
                  <span className="top-menu-navbar-title">
                    {dataSite.main.title}
                  </span>
                </div>
              </Link>
              <p className="footer-title">{dataFooter.title}</p>
            </div>
          </div>
          <div className="col-xl-2 offset-xl-1 col-lg-2 col-md-6">
            <div className="footer-widget">
              <h4 className="mb-3">Cсылки</h4>
              <FooterMenu data={dataFooterMenu} />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6">
            <div className="footer-widget">
              <h4 className="mb-3">Услуги</h4>
              <FooterMenu data={dataFooterServices} />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6">
            <div className="footer-widget">
              <h4 className="mb-3">Контакты</h4>
              <ul className="footer-contact mb-4">
                <li>
                  <ContentSitePhone title={dataSite.main.phone} />
                </li>
                <li>
                  <ContentSiteEmail title={dataSite.main.email} />
                </li>
                <li>
                  <p>{dataSite.main.address}</p>
                </li>
              </ul>
              <SocialLinks className="" data={dataSocialLinks} />
            </div>
          </div>
          <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="footer-widget">
              <p className="my-3 footer-desc">{dataFooter.subtitle}</p>
            </div>
          </div>
        </div>

        {/*
         <div
              className="col-md-6 aos-animate"
              data-aos="fade-down"
              data-aos-delay="300"
            >
              <SocialLinks />
            </div>
 <div
              className="col-md-6 aos-animate"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <p className="">
                Сайт разработан{" "}
                <ExternalLink2 to={dataSite.design.link}>
                  {dataSite.design.title}
                </ExternalLink2>
              </p>
            </div>
        */}

        <div className="copyright-area">
          <div className="row align-items-center">
            <div className="col-md-6">
              <SocialLinks />
            </div>
            <div className="col-md-6">
              <p className="">
                Сайт разработан{" "}
                <ExternalLink2 to={dataSite.design.link}>
                  {dataSite.design.title}
                </ExternalLink2>
              </p>
            </div>
          </div>
          <div className="row align-items-center mt-3">
            <div className="col-md-12">
              <div className="copyright text-center">
                Все права защищены ©{" "}
                <strong>
                  <span className="copyright-text">
                    {dataSite.main.copyrightText}
                  </span>
                </strong>{" "}
                {dataSite.main.copyrightYear}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

/*


<div class="row">

                       
                </div>

  const Fo1 = () => {
    return (
      <Row className="align-items-center justify-content-center">
        <Col lg="6">
          <h2 className="footer-text mb-4"> {data.subtitle}</h2>
          <p className="footer-text mb-4">{data.description}</p>

          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Subscribe to our newsletter.
            </h2>
            <p className="mt-4 text-lg leading-8 text-gray-300">
              Nostrud amet eu ullamco nisi aute in ad minim nostrud adipisicing
              velit quis. Duis tempor incididunt dolore.
            </p>
            <div className="mt-6 flex max-w-md gap-x-4">
              <label for="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required=""
                className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                placeholder="Enter your email"
              />
              <button
                type="submit"
                className="flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Subscribe
              </button>
            </div>
          </div>
        </Col>
        <Col lg="6">
          {/*<Contacts />*}
          {/*<FooterMenu />*}
          </Col>
          </Row>
        );
      };
    
      const Old = () => {
        return (
          <footer className="main-footer">
            <Container className="footer-top">
              <Row className="align-items-center justify-content-center">
                <Col>
                  {/*<Contacts />*}
                  {/*<FooterMenu />*}
                </Col>
              </Row>
            </Container>
            <Container className="footer-bottom border-top border-smooth">
              <div className="copyright">
                © Copyright{" "}
                <strong>
                  <span className="footer-text">{data.dataSite.copyright}</span>
                </strong>
                . {"texts.All rights reserved"}.{" "}
                <Link to="/privacy-policy">{"texts.Privacy Policy"}.</Link>
              </div>
              <div className="credits d-block">
                {"texts.Designed"}{" "}
                <ExternalLink to="https://lovkovav.pro/" label="LovkovAV.PRO" />
              </div>
            </Container>
          </footer>
        );
      };
    
*/
