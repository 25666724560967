import { APP_API_URI } from "config/config";
import { _debug } from "utils";
import { getGlobalAuthHeader, requestGlobalOptions } from "./auth.service";
import { getResultOrError } from "./course.service";

const SERVICE_DEBUG = true;

const SERVICE_URL = `${APP_API_URI}/payments/products`;

const getServiceURL = (URL) =>
  URL ? `${SERVICE_URL}/${URL}` : `${SERVICE_URL}`;

const LOGGER = (message, obj, type) => {
  if (SERVICE_DEBUG) _debug(message, obj, type);
};

const getAll = async () => {
  const URL = getServiceURL(`all`);

  _debug("Product fetched all API", { URL });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(headers),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

const ProductService = { getAll };

export default ProductService;
