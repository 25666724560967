import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";

import { withAsync } from "components/core/Async";
import { useGetTherapistBySlug } from "components/pages/Therapists/utils/useGetTherapistBySlug.hook";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import "./booking-cards.style.scss";

const ProductCard = (props) => {
  const { data, onChange, currentValue } = props;

  console.log(props);

  const renderProductImage = (code) => {
    if (code === "0002001")
      return (
        <img
          alt="Иллюстрация семейной сессии"
          className="booking-cards__card-illustration"
          src="https://alter.ru/images/family-session.svg"
        />
      );

    return (
      <img
        alt="Иллюстрация личной сессии"
        className="booking-cards__card-illustration"
        src="https://alter.ru/images/one-person-session.svg"
      />
    );
  };

  return (
    <div
      className={classNames("booking-cards__card", {
        "is-selected": currentValue === data.id,
      })}
      id={data.id}
      onClick={(e) => {
        console.log(e.target.id);
        onChange({ id: data.id, name: data.name });
      }}
    >
      <div className="booking-cards__text-and-icon">
        <div className="booking-cards__text-and-icon-subject">
          {data.product.name}
        </div>
        <div className="booking-cards__session-info-price-container is-nowrap-desktop">
          <div className="booking-cards__text-and-icon-price">
            <span>
              <span className="is-nowrap">{data.price} ₽</span>
            </span>
          </div>
        </div>
      </div>
      {renderProductImage(data.product.code)}
      <div
        className={classNames("booking-cards__card__icon", {
          "is-selected": currentValue === data.id,
        })}
      >
        <IoCheckmarkCircleSharp size={24} className="text-success" />
      </div>
    </div>
  );
};

const PsychologistProduct = (props) => {
  const { data, isLoading, isError, errors, handleChange } = props;
  const [check, setCheck] = useState("");

  console.log("!!!", props);

  useEffect(() => {
    if (data) {
      props.handleChange((prevState) => ({
        ...prevState,
        therapist: data,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isError]);

  useEffect(() => {
    if (check) {
      props.handleChange((prevState) => ({
        ...prevState,
        productId: check,
        productName: data.products.find((item) => item.id === check).name,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check]);

  return (
    <Fragment>
      {/* <div
      className="card"
      style={check === "male" ? { border: "5px solid #000" } : null}
      onClick={() => setCheck("male")}
    >
      <img className="card-img-top" src="" alt="Cardimage" />
      <div className="card-body">
        <h4 className="card-title">John Doe</h4>
        <p className="card-text">Some Text</p>
      </div>
    </div>

    <div
      className="card"
      style={check === "female" ? { border: "5px solid #000" } : null}
      onClick={() => setCheck("female")}
    >
      <img className="card-img-top" src="" alt="Cardimage" />
      <div className="card-body">
        <h4 className="card-title">John Doe</h4>
        <p className="card-text">Some Text</p>
      </div>
    </div>*/}

      {data.products.map((item, index) => {
        return (
          <ProductCard
            data={item}
            onChange={(item) => setCheck(item.id)}
            currentValue={check}
            key={item.id}
          />
        );
      })}

      {/*  <div
      className={classNames("booking-cards__card", {
        "is-selected": check === "male",
      })}
      onClick={() => setCheck("male")}
    >
      <div className="booking-cards__text-and-icon">
        <div className="booking-cards__text-and-icon-subject">
          С партнером/семьей
        </div>
        <div className="booking-cards__session-info-price-container is-nowrap-desktop">
          <div className="booking-cards__text-and-icon-price">
            <span>
              <span className="is-nowrap">18,000 ₽</span>
            </span>
          </div>
        </div>
      </div>
      <img
        alt="Иллюстрация семейной сессии"
        className="booking-cards__card-illustration"
        src="https://alter.ru/images/family-session.svg"
      />
      <div
        className={classNames("booking-cards__card__icon", {
          "is-selected": check === "male",
        })}
      >
        <svg
          width="24"
          height="24"
          fill="none"
          className=" flex-shrink-0"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 22C6.47967 21.9939 2.00606 17.5203 2 12V11.8C2.10993 6.30452 6.63459 1.92794 12.1307 2.00087C17.6268 2.07379 22.0337 6.56887 21.9978 12.0653C21.9619 17.5618 17.4966 21.9989 12 22ZM7.41 11.59L6 13L10 17L18 8.99999L16.59 7.57999L10 14.17L7.41 11.59Z"
            fill="#29B981"
          ></path>
        </svg>
      </div>
    </div>

    <div
      className={classNames("booking-cards__card", {
        "is-selected": check === "female",
      })}
      onClick={() => setCheck("female")}
    >
      <div className="booking-cards__text-and-icon">
        <div className="booking-cards__text-and-icon-subject">
          Индивидуально
        </div>
        <div className="booking-cards__session-info-price-container is-nowrap-desktop">
          <div className="booking-cards__text-and-icon-price">
            <span>
              <span className="is-nowrap">14,000 ₽</span>
            </span>
          </div>
        </div>
      </div>
      <img
        alt="Иллюстрация личной сессии"
        className="booking-cards__card-illustration"
        src="https://alter.ru/images/one-person-session.svg"
      />
      <div
        className={classNames("booking-cards__card__icon", {
          "is-selected": check === "female",
        })}
      >
        <svg
          width="24"
          height="24"
          fill="none"
          className=" flex-shrink-0"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 22C6.47967 21.9939 2.00606 17.5203 2 12V11.8C2.10993 6.30452 6.63459 1.92794 12.1307 2.00087C17.6268 2.07379 22.0337 6.56887 21.9978 12.0653C21.9619 17.5618 17.4966 21.9989 12 22ZM7.41 11.59L6 13L10 17L18 8.99999L16.59 7.57999L10 14.17L7.41 11.59Z"
            fill="#29B981"
          ></path>
        </svg>
      </div>
    </div>
    */}
    </Fragment>
  );
};

const PsychologistProductAsync = withAsync(PsychologistProduct);

/**
 * Component for showing Component "Type Of Therapy" of the site.
 *
 * @component
 **/
const TypeOfTherapy = (props) => {
  const {
    data,
    params,
    id = "type-of-therapy",
    cn = "pt-80 pb-80",
    handleChange,
  } = props;

  const {
    data: therapist,
    isLoading: isLoadingTherapist,
    isError: isErrorTherapist,
    error: errorTherapist,
  } = useGetTherapistBySlug(params.therapist);

  const isLoading = isLoadingTherapist;
  const isError = isErrorTherapist;
  const errors = [errorTherapist];

  if (isError && errors.some((err) => err?.status === 204)) {
    return <div className="error">Нет данных о терапевте</div>;
  }

  return (
    <PsychologistProductAsync
      data={therapist}
      isLoading={isLoading}
      isError={isError}
      errors={errors}
      msgLoading="THERAPISTS"
      handleChange={handleChange}
    />
  );
};

TypeOfTherapy.propTypes = {};

export default TypeOfTherapy;
