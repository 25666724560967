import debounce from "lodash/debounce";
import { useLayoutEffect, useState } from "react";

const useIsMobile = (breakpoint = 768) => {
  const checkForDevice = () => window.innerWidth < breakpoint;

  const [isMobile, setIsMobile] = useState(checkForDevice());

  useLayoutEffect(() => {
    const handlePageResized = () => {
      setIsMobile(checkForDevice());
    };

    if (typeof window !== "undefined") {
      window.addEventListener("resize", debounce(handlePageResized, 250));
      window.addEventListener("orientationchange", handlePageResized);
      window.addEventListener("load", handlePageResized);
      window.addEventListener("reload", handlePageResized);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", handlePageResized);
        window.removeEventListener("orientationchange", handlePageResized);
        window.removeEventListener("load", handlePageResized);
        window.removeEventListener("reload", handlePageResized);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isMobile,
  };
};

export default useIsMobile;
