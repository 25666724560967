import { PropTypes } from "prop-types";
import { Fragment } from "react";

/**
 * Component for showing Section "Customer Profile (Tab) - Delete Profile" of the site.
 *
 * @component
 **/
const ProfileTabDelete = (props) => {
  const { data } = props;

  return (
    <Fragment>
      {/*<!-- Card START -->*/}
      <div className="card border">
        {/*<!-- Card header -->*/}
        <div className="card-header border-bottom">
          <h4 className="card-header-title">Delete Account</h4>
        </div>

        {/*<!-- Card body START -->*/}
        <div className="card-body">
          <h6>Before you go...</h6>
          <ul>
            <li>
              Take a backup of your data <a href="#">Here</a>{" "}
            </li>
            <li>If you delete your account, you will lose your all data.</li>
          </ul>
          <div className="form-check form-check-md my-4">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="deleteaccountCheck"
            />
            <label className="form-check-label" for="deleteaccountCheck">
              Yes, I'd like to delete my account
            </label>
          </div>
          <a href="#" className="btn btn-success-soft btn-sm mb-2 mb-sm-0">
            Keep my account
          </a>
          <a href="#" className="btn btn-danger btn-sm mb-0">
            Delete my account
          </a>
        </div>
        {/*<!-- Card body END -->*/}
      </div>
      {/*<!-- Card END -->*/}
    </Fragment>
  );
};

ProfileTabDelete.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default ProfileTabDelete;
