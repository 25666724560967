import { BiSupport } from "react-icons/bi";
import { dataSite } from "./dataSite";

export const dataMessages = {
  msgError: "Похоже, что-то пошло не так",
  msgWarning: "Похоже, что-то пошло не так",
  msgInfo: "Похоже, что-то пошло не так",
};

export const dataForm = {
  msgReadMore: "Подробнее",
  msgSubmitted: "Сообщение успешно отправлено",
  msgSubmit: "Отправить сообщение",
  msgPolicyAgreed: "Принять",

  giftOrderGift: {
    msgLabel: "Выберите сертификат",
    msgHelp: "Выберите сертификат",
    msgPlaceholder: "Сертификат",
    msgRequired: "Требуется выбрать сертификат",
  },

  giftOrderName: {
    msgLabel: "Имя получателя",
    msgHelp: "Укажите имя получателя",
    msgPlaceholder: "Имя получателя",
    msgRequired: "Требуется ввести имя",
  },

  giftOrderEmail: {
    msgLabel: "Ваша эл. почта",
    msgHelp: "Укажите имя получателя",
    msgPlaceholder: "Ваша эл. почта",
    msgRequired: "Требуется ввести вашу электронную почту",
  },

  username: {
    msgLabel: "Username (how your name will appear to other users on the site)",
    msgHelp: "Укажите ваше имя",
    msgPlaceholder: "Enter your username",
    msgRequired: "Требуется ввести имя",
  },

  name: {
    msgLabel: "Имя",
    msgHelp: "Укажите ваше имя",
    msgPlaceholder: "Ваше имя",
    msgRequired: "Требуется ввести имя",
  },

  firstName: {
    msgLabel: "First name",
    msgHelp: "Укажите ваше имя",
    msgPlaceholder: "Enter your first name",
    msgRequired: "Требуется ввести имя",
  },

  lastName: {
    msgLabel: "Last name",
    msgHelp: "Укажите ваше имя",
    msgPlaceholder: "Enter your last name",
    msgRequired: "Требуется ввести имя",
  },

  email: {
    msgLabel: "Почта",
    msgHelp: "Укажите вашу электронную почту",
    msgPlaceholder: "Ваша почта",
    msgRequired: "Требуется ввести адрес электронной почты",
  },

  password: {
    msgLabel: "Пароль",
    msgHelp: "Укажите ваш пароль",
    msgPlaceholder: "Ваш пароль",
    msgRequired: "Требуется ввести пароль",
  },

  confirmPassword: {
    msgLabel: "Пароль",
    msgHelp: "Укажите подтверждение пароля",
    msgPlaceholder: "Ваша пароль",
    msgRequired: "Требуется ввести подтверждение пароля",
  },

  message: {
    msgLabel: "Сообщение",
    msgMessage: "Сообщение",
    msgHelp: "Введите ваше сообщение",
    msgPlaceholder: "Сообщение",
    msgRequired: "Требуется ввести текст сообщения",
  },

  subject: {
    msgLabel: "ема сообщения",
    msgHelp: "Выберите тему сообщения",
    msgPlaceholder: "Тема сообщения",
    msgRequired: "Требуется указать тему сообщения",
  },

  phone: {
    msgLabel: "Телефон",
    msgHelp: "Укажите ваш телефон",
    msgPlaceholder: "Телефон",
    msgRequired: "Требуется ввести телефон",
  },

  birthday: {
    msgLabel: "Birthday",
    msgHelp: "Укажите ваш телефон",
    msgPlaceholder: "Enter your birthday",
    msgRequired: "Требуется ввести телефон",
  },

  msgSubscriptionCreated: "Вы успешно подписались на обновления!",
  megSubscribe: "Подписаться",

  errors: {},
};

export const dataSocialLinks = [
  {
    link: "https://rbc.ru",
    type: "fb",
    status: false,
    name: "Facebook",
  },
  {
    link: "https://rbc.ru",
    type: "ln",
    status: false,
    name: "LinkedIn",
  },
  {
    link: "https://www.youtube.com/@psywealth",
    type: "yt",
    status: true,
    name: "Youtube",
  },
  {
    link: "https://dzen.ru/id/66c6f447e27d5e1ed7b8bacb",
    type: "dz",
    status: true,
    name: "DZen",
  },
  {
    link: "https://t.me/psywealth_ru",
    type: "tg",
    status: true,
    name: "Telegram",
  },
  {
    link: "https://rbc.ru",
    type: "tw",
    status: false,
    name: "Tweeter",
  },
  {
    link: "https://vk.com/psywealth_ecm",
    type: "vk",
    status: true,
    name: "VK",
  },
];

export const dataTopMenu = [
  { path: "/home", title: "Главная", showOnlyWhenLoggedIn: false, role: "ANY" },
  { path: "/blog", title: "Блог", showOnlyWhenLoggedIn: false, role: "ANY" },
  {
    path: "/faq",
    title: "Вопросы и ответы",
    showOnlyWhenLoggedIn: false,
    role: "ANY",
  },
  //{ path: "/contacts", title: "Контакты", showOnlyWhenLoggedIn: false },
  {
    path: "/psychologist",
    title: "Для психологов",
    showOnlyWhenLoggedIn: false,
    role: "GUEST",
  },

  {
    path: "/psychologist/profile",
    title: "Профиль",
    showOnlyWhenLoggedIn: true,
    role: "PSYCHOLOGIST",
  },
  {
    path: "/psychologist/auth",
    title: "Войти",
    showOnlyWhenLoggedIn: false,
    hideWhenLoggedIn: true,
    role: "PSYCHOLOGIST",
  },
  {
    path: "/psychologist/logout",
    title: "Выйти",
    showOnlyWhenLoggedIn: true,
    role: "PSYCHOLOGIST",
  },

  {
    path: "/customer/profile",
    title: "Профиль",
    showOnlyWhenLoggedIn: true,
    role: "CUSTOMER",
  },
  {
    path: "/auth",
    title: "Войти",
    showOnlyWhenLoggedIn: false,
    hideWhenLoggedIn: true,
    role: "CUSTOMER",
  },
  {
    path: "/logout",
    title: "Выйти",
    showOnlyWhenLoggedIn: true,
    role: "CUSTOMER",
  },
];

export const dataTopMenuMain = [
  {
    show: true,
    path: "mailto:info@psywealth.com",
    title: "info@psywealth.com",
    showOnlyWhenLoggedIn: false,
    showIcon: true,
    icon: <BiSupport />,
  },
  // {
  //   show: false,
  //   path: dataSite.main.phoneLink,
  //   title: dataSite.main.phone,
  //   showOnlyWhenLoggedIn: false,
  //   showIcon: true,
  //   icon: <BiPhone />,
  // },
];

export const dataTopMenuSupport = [
  { path: "/faq", title: "Задать вопрос?", showOnlyWhenLoggedIn: false },
  {
    path: "mailto:help@psywealth.com",
    title: "help@psywealth.com",
    showOnlyWhenLoggedIn: false,
  },
  {
    path: "https://t.me/agvento-test",
    title: "Telegram",
    showOnlyWhenLoggedIn: false,
  },
  {
    path: "https://wa.me/4957777777",
    title: "WhatsApp",
    showOnlyWhenLoggedIn: false,
  },
  {
    path: dataSite.main.phoneLink,
    title: dataSite.main.phone,
    showOnlyWhenLoggedIn: false,
  },
];

export const dataFooterMenu = [
  { path: "/home", title: "Главная", showOnlyWhenLoggedIn: false },
  { path: "/blog", title: "Блог", showOnlyWhenLoggedIn: false },
  { path: "/faq", title: "Вопросы и ответы", showOnlyWhenLoggedIn: false },
  { path: "/contacts", title: "Контакты", showOnlyWhenLoggedIn: false },
  {
    path: "/psychologist",
    title: "Для психологов",
    showOnlyWhenLoggedIn: false,
  },
];

export const dataFooterServices = [
  // { path: "/home", title: "Внедрение", showOnlyWhenLoggedIn: false },
  // { path: "/home", title: "Распознавание", showOnlyWhenLoggedIn: false },
  //{
  //  path: "/partners",
  //  title: "Партнерство",
  //  showOnlyWhenLoggedIn: false,
  //},
  //{ path: "/faq", title: "Веь дизайн", showOnlyWhenLoggedIn: false },
];
