import { Spinner } from "components/utils/Spinner";
import { BLOG_ID } from "config/config";
import { dataMessages } from "data";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { useGetBlogTags } from "./utils";

const BlogTopTags = () => {
  const {
    data: blogTags,
    isLoading: isLoadingBlogTags,
    isError: isErrorBlogTags,
    error: errorBlogTags,
  } = useGetBlogTags();

  const isLoading = isLoadingBlogTags;
  const isError = isErrorBlogTags;
  const errors = [errorBlogTags];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (blogTags) {
      _debug("BLOG TAGS:", blogTags);
    }
  }, [blogTags]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isError && errorBlogTags?.status === 204) {
    return <div className="error">Нет данных о тэгах в блоге</div>;
  }

  // const URL = `${BLOG_API_URI}/tags/all?blog=${BLOG_ID}`;
  // const { data: tagsData, isLoading, hasError, errorMessage } = useFetch(URL);
  // const tagsDataTop = tagsData ? tagsData.slice(0, 3) : undefined;

  // _debug(URL);

  // useEffect(() => {
  //   if (tagsData) _debug("Data fetched: ", tagsData);
  //   if (hasError) _debug("Error: ", errorMessage);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoading, hasError, tagsData]);

  // if (isLoading || hasError) {
  //   return (
  //     <Fragment>
  //       {isLoading && <Spinner />}
  //       {hasError && <p>{errorMessage}</p>}
  //     </Fragment>
  //   );
  // }

  // if (!tagsData) {
  //   return <h5>No tags founds</h5>;
  // }

  return (
    <div className="sidebar-box tags-box mb-30">
      <h4>Популярные тэги</h4>
      <ul>
        {blogTags.map((item, index) => {
          return (
            <li key={index}>
              <Link to={`/blog/tag/${item.id}?blog=${BLOG_ID}`}>
                {item.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default BlogTopTags;
