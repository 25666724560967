import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useGetProfileDocuments } from "components/pages/Psychologist/utils/useGetProfileDocuments";
import { Spinner } from "components/utils/Spinner";
import { dataMessages } from "data";
import { Fragment, useEffect, useState } from "react";

import { IoDocumentOutline } from "react-icons/io5";
import { TbLoader } from "react-icons/tb";
import { toast } from "react-toastify";
import PsychologistService from "services/psychologist.service";
import { _debug } from "utils";

const STATUS_IDLE = 0;
const STATUS_UPLOADING = 1;

/**
 * Component for showing Orders table.
 *
 * @component
 */
const EducationDocuments = (props) => {
  const { inputRef, onSubmit } = props;
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState(STATUS_IDLE);

  const queryClient = useQueryClient();

  const {
    data: documents,
    isLoading: isLoadingPsychologistProfileDocuments,
    isError: isErrorPsychologistProfileDocuments,
    error: errorPsychologistProfileDocuments,
  } = useGetProfileDocuments();

  const isLoading = isLoadingPsychologistProfileDocuments;
  const isError = isErrorPsychologistProfileDocuments;
  const errors = [errorPsychologistProfileDocuments];

  // console.log(documents);

  const mutation = useMutation({
    mutationFn: async (data) => uploadFiles(data),
    mutationKey: ["createProfileDocuments"],
    onSuccess: () => {
      //refetchQueries
      queryClient.refetchQueries("profileDocuments");
    },
  });

  const uploadFiles = (data) => {
    console.log("object");
    setStatus(STATUS_UPLOADING);

    // fetch(API_URL, {
    //   method: API_METHOD,
    //   body: data,
    // })
    PsychologistService.addProfileDocuments({ data })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.error(err))
      .finally(() => {
        setStatus(STATUS_IDLE);
        setFiles([]);
      });
  };

  const packFiles = (files) => {
    console.log(files);

    const data = new FormData();
    [...files].forEach((file, i) => {
      //data.append(`file-${i}`, file, file.name);
      data.append(`content`, file, file.name);
    });

    console.log(data);
    return data;
  };

  // const handleUploadClick = (event) => {
  //   event.preventDefault();

  //   if (files.length) {
  //     const data = packFiles(files);
  //     uploadFiles(data);
  //   }
  // };

  const handleFilesChange = (e) => {
    console.log("handleFilesChange", e);
    e.preventDefault();
    e.stopPropagation();

    setFiles(e.target.files);
  };

  const renderDocumentList = () => (
    <ol>
      {documents &&
        documents.map((item, index) => (
          <li key={index}>
            <IoDocumentOutline />
            {item.originalFileName} - {item.id}
          </li>
        ))}
    </ol>
  );

  const renderFileList = () => (
    <ol>
      {[...files].map((item, index) => {
        // console.log(item);

        return (
          <li key={index}>
            <IoDocumentOutline />
            {item.originalFileName} - {item.id}
          </li>
        );
      })}
    </ol>
  );

  const getButtonStatusText = () =>
    status === STATUS_IDLE ? "Send to server" : <TbLoader />;

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (documents && documents.content) {
      _debug("PSYCHOLOGIST PROFILE DOCUMENTS:", documents.content);
    }
  }, [documents]);

  useEffect(() => {
    // const uploadFiles = async () => {
    //   if (files && files.length) {
    //     const data = packFiles(files);
    //     uploadFiles(data);
    //   }
    // };
    // uploadFiles();
    console.log("Files changed...", files);
    if (files && files.length) {
      const data = packFiles(files);
      // uploadFiles(data);
      mutation.mutate(data);
    }
  }, [files]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  /*  style={{ display: "none" }}
   */

  return (
    <Fragment>
      {renderDocumentList()}
      <div>
        <input
          type="file"
          accept="application/pdf"
          multiple
          onChange={handleFilesChange}
          ref={inputRef}
        />
        {renderFileList()}
      </div>
      {/* <button
        onClick={(e) => handleUploadClick(e)}
        disabled={status === STATUS_UPLOADING}
      >
        {getButtonStatusText()}
      </button>*/}
    </Fragment>
  );
};

export default EducationDocuments;
