import { PageSection } from "core/components";
import { Fragment } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { SectionHeader } from "../Home";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./Cases.style.scss";

// NOTE :

const SectionItemImage = (props) => {
  const { data, ...otherProps } = props;
  const { src, alt, title } = data;

  console.log(props);

  return <img src={src} alt={alt} title={title} {...otherProps} />;
};

const SectionContentItem = (props) => {
  const { data, id = "" } = props;

  // console.log(data);

  return (
    <div className="custom-block bg-white shadow-lg">
      <div className="mb-5 image-wrapper">
        <SectionItemImage
          data={data.image}
          width="310"
          height="200"
          className="img-fluid"
        />
      </div>
      <div className="d-flex">
        <div>
          <h5 className="mb-2">{data.title}</h5>
          <p className="mb-0">{data.description}</p>
        </div>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data, id = "" } = props;

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
              // when window width is >= 640px
              // 640: {
              //   width: 640,
              //   slidesPerView: 1,
              //   spaceBetween: 20,
              // },
              // when window width is >= 768px
              768: {
                // width: 768,
                slidesPerView: 2,
                spaceBetween: 20,
              },
              // when window width is >= 768px
              992: {
                // width: 992,
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            loop
            pagination={{
              // type: "fraction",
              clickable: true,
            }}
            navigation
            modules={[Pagination, Navigation]}
            className="cases-of-use-swiper"
          >
            {data.items.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <SectionContentItem data={item} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </Fragment>
  );
};

/**
 * Component for showing Section "Cases of Used Free Help" of the site.
 *
 * @component
 */
const CasesOfUsedSection = (props) => {
  const { data, id = "cases-of-used", cn = "pt-100 pb-100" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader
          data={{ ...data }}
          showSubtitle={false}
          showDescription={false}
        />
        <SectionContent data={{ ...data }} />
      </div>
    </PageSection>
  );
};

export default CasesOfUsedSection;
