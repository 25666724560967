import background from "assets/images/testimonial/testimonial-bg.svg";
import { SectionContentHeader } from "components/sections/CommonSection";
import { PageSection } from "core/components";
import { PropTypes } from "prop-types";
import { Fragment } from "react";

const SectionContent = (props) => {
  const { data } = props;

  return (
    <div className="slogan-content mt-35">
      {data.description ? (
        <p>{data.description1}</p>
      ) : (
        <Fragment>
          <p className="mb-2">{data.description1}</p>
          <p className="">{data.description2}</p>
        </Fragment>
      )}
    </div>
  );
};

/**
 * Component for showing Section "Call to action - Slogan" of the site.
 *
 * @component
 */
const SectionSlogan = (props) => {
  const { data, id = "slogan", cn = "pt-80 pb-80 img-bg" } = props;

  return (
    <PageSection
      id={id}
      cn={cn}
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-10 mx-auto">
            <div className="slogan-wrapper my-3">
              <SectionContentHeader data={data} />
              <SectionContent data={data} />
            </div>
          </div>
        </div>
      </div>
    </PageSection>
  );
};

SectionSlogan.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default SectionSlogan;
