import classNames from "classnames";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { Link } from "react-router-dom";

import "./PurchaseProcess.scss";

// NOTE : https://bootsnipp.com/snippets/O4O8
// NOTE : https://bootsnipp.com/snippets/8ONK

const steps = [
  {
    id: 1,
    stepTitle: "Step 1",
    title: " Lorem ipsum dolor sit amet.",
    link: "#",
  },
  {
    id: 2,
    stepTitle: "Step 1",
    title: " Lorem ipsum dolor sit amet.",
    link: "#",
  },
  {
    id: 3,
    stepTitle: "Step 1",
    title: " Lorem ipsum dolor sit amet.",
    link: "#",
  },
];

const PurchaseProcessItem = (props) => {
  const { data, status } = props;
  console.log(data, status);

  const getCNByStatus = (status) => {
    if (status === "complete") {
      return "complete";
    } else if (status === "active") {
      return "active";
    }
    return "disabled";
  };

  return (
    <div
      className={classNames("col-lg-4 bs-wizard-step", getCNByStatus(status))}
    >
      <div className="text-center bs-wizard-stepnum">{data.stepTitle}</div>
      <div className="progress">
        <div className="progress-bar"></div>
      </div>
      <Link to={data} className="bs-wizard-dot" />
      <div className="bs-wizard-info text-center">{data.title}</div>
    </div>
  );
};

/**
 * Component for showing Section "PurchaseProcess" of the site.
 *
 * @component
 */
const PurchaseProcess = (props) => {
  const { currentStep } = props;

  return (
    <Fragment>
      <div className="row bs-wizard" style={{ borderBottom: "0" }}>
        {steps.map((item, index) => (
          <PurchaseProcessItem data={item} key={index} />
        ))}

        {/*
        <div className="col-lg-4 bs-wizard-step active">
          <div className="text-center bs-wizard-stepnum">Step 2</div>
          <div className="progress">
            <div className="progress-bar"></div>
          </div>
          <a href="#" className="bs-wizard-dot"></a>
          <div className="bs-wizard-info text-center">item.title</div>
        </div>

        <div className="col-lg-4 bs-wizard-step disabled">
          <div className="text-center bs-wizard-stepnum">Step 3</div>
          <div className="progress">
            <div className="progress-bar"></div>
          </div>
          <a href="#" className="bs-wizard-dot"></a>
          <div className="bs-wizard-info text-center">item.title</div>
        </div>*/}
      </div>
    </Fragment>
  );
};

PurchaseProcess.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default PurchaseProcess;
