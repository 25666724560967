import { useQuery } from "@tanstack/react-query";
import ProductService from "services/product.service";

export const useGetPayProducts = (options = {}) => {
  // //const { handle } = props;
  // const URL = `${APP_API_URI}/payments/orders/all`; //${slug}
  // var localData = localStorage.getItem("authData");

  // const authData = JSON.parse(localData);

  // // if (authData && authData.data && authData.data.accessToken) {
  // //   return null;
  // // }

  // console.log("!!!", URL);

  return useQuery({
    queryFn: () => ProductService.getAll(),
    queryKey: ["payProducts"],
  });
};
