import { APP_API_URI } from "config/config";
import { _debug } from "utils";
import { getGlobalAuthHeader, requestGlobalOptions } from "./auth.service";
import { getResultOrError } from "./course.service";

const SERVICE_DEBUG = true;

const SERVICE_URL = `${APP_API_URI}/payments/payment`;

const getServiceURL = (URL) =>
  URL ? `${SERVICE_URL}/${URL}` : `${SERVICE_URL}`;

const LOGGER = (message, obj, type) => {
  if (SERVICE_DEBUG) _debug(message, obj, type);
};

export const getAll = async () => {
  const URL = `${APP_API_URI}/payments/payment/all`;

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);
  // _debug("getAll", URL);
  // _debug("getAll", response);

  if (response.ok && response.status === 200) {
    // return data ? JSON.parse(data) : {};
    return response.json();
  } else if (response.ok && response.status === 204) {
    //throw new FetchError(response);
    const responseError = {
      type: "Error",
      message: "No content",
      data: [],
      code: response.code || "",
      status: response.status || 500,
    };

    let error = new Error("HTTP error");
    error = { ...error, ...responseError };
    throw error;
  }

  const result_2 = await response.text();
  const responseError_1 = {
    type: "Error",
    message: result_2.message || "Something went wrong",
    data: undefined || "",
    code: "" || "",
    status: response.status || 500,
  };
  let error_1 = new Error("HTTP error");
  error_1 = { ...error_1, ...responseError_1 };
  throw error_1;
};

export const createPaymentWithOrder = async (data) => {
  const URL = getServiceURL(`v3`);

  LOGGER("Create new payment with order:", { URL, data });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "POST",
    headers: new Headers(requestHeaders),
    body: JSON.stringify(data),
  };

  console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

const PaymentService = {
  getAll,
  createPaymentWithOrder,
};

export default PaymentService;
