import { Fragment } from "react";

export const NoContentCard = (props) => {
  const { title } = props;

  return (
    <Fragment>
      <div className="vstack gap-4">
        <div className="card border">
          <div className="m-4 error">
            <h4 className="alert alert-success">{title}</h4>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
