import {
  ContactsSection,
  FeatureAdditionalSection,
} from "components/pages/FreeHelp";
import CasesOfUsedSection from "components/pages/FreeHelp/Cases.section";
import { SectionFAQ } from "components/pages/Home";
import { OurAdvantages } from "components/pages/Home/Features1";
import { CallToAction as SectionCTA } from "components/sections";
import { SEO } from "components/utils/Analytics";
import {
  dataCasesOfUseFreeHelpPage as dataCasesOfUse,
  dataConsultationsFreeHelpPage as dataConsultations,
  dataContactsFreeHelpPage as dataContacts,
  dataCTAFreeHelpPage as dataCTA,
  dataDisadvantagesFreeHelpPage as dataDisadvantages,
  dataFAQFreeHelpPage as dataFAQ,
  dataFeature1FreeHelpPage as dataFeature1,
  dataHeroFreeHelpPage as dataHero,
} from "data";
import { dataFreeHelpPage } from "data/dataPages";
import { Fragment, useRef } from "react";
import { Hero } from "../components/pages/Home/Hero";

/**
 * Component for showing Page "Free Psychological Help" of the site.
 *
 * @component
 */
const FreePsychologicalHelpPage = () => {
  const refCAT = useRef(null);

  const executeScroll = (e) => {
    refCAT.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  return (
    <Fragment>
      <SEO data={dataFreeHelpPage.meta}>
        {/*Only canonicalise pages which are a duplicate or are near-identical.*/}
        <link
          rel="canonical"
          href="https://psywealth.com/free/psychological-help"
        />
        {dataFreeHelpPage.metaLdJson && (
          <script type="application/ld+json">
            {JSON.stringify(dataFreeHelpPage.metaLdJson)}
          </script>
        )}
      </SEO>
      <main className="page-main" id="top">
        <Hero data={dataHero} type={"style2"} onScroll={executeScroll} />
        {/*<SectionCountUp data={dataCountUpMainPage} />*/}

        <FeatureAdditionalSection data={dataFeature1} type="style1" />
        {/*  <FeatureAdditionalSection data={dataConsultations} type="style2" /> */}

        <CasesOfUsedSection data={dataCasesOfUse} />

        <ContactsSection data={dataConsultations} />

        <OurAdvantages data={dataDisadvantages} />

        <SectionCTA data={dataCTA} innerRef={refCAT} />

        <ContactsSection data={dataContacts} />

        <SectionFAQ data={dataFAQ} />
      </main>
    </Fragment>
  );
};

export default FreePsychologicalHelpPage;
