import { APP_API_URI } from "config/config";
import { _debug } from "utils";
import { requestGlobalHeaders, requestGlobalOptions } from "./auth.service";

const getServiceURL = (URL) => `${APP_API_URI}/course/${URL}`;

export const getResultOrError = async (response) => {
  // console.log(
  //   "OrderService => getAll: ",
  //   URL,
  //   requestOptions,
  //   response,
  //   response.status,
  //   response.status === 204
  // );

  if (response.ok && response.status === 200) {
    // return data ? JSON.parse(data) : {};
    const res = await response.text();
    // console.log(res);
    return res ? JSON.parse(res) : {};
    // return response.json();
  } else if (response.ok && response.status === 201) {
    // return data ? JSON.parse(data) : {};
    const res = await response.text();
    // console.log(res);
    return res ? JSON.parse(res) : {};
    // return response.json();
  } else if (response.ok && response.status === 204) {
    //throw new FetchError(response);
    const responseError = {
      type: "info",
      message: "No content",
      data: [],
      code: response.code || "",
      status: response.status || 500,
    };

    let error = new Error("HTTP error");
    error = { ...error, ...responseError };
    throw error;
  }

  if (!response.ok) {
    if (response.status === 404) throw new Error("404, Not found");
    if (response.status === 500) throw new Error("500, internal server error");
    throw new Error(`HTTP error: Status ${response.status}`);
  }

  //}
  // if (!response.ok) {
  //   throw new Error(`HTTP error: Status ${response.status}`);
  // }
  const result_2 = response.text();
  const responseError_1 = {
    type: "Error",
    message: result_2.message || "Something went wrong",
    data: undefined || "",
    code: "" || "",
    status: response.status || 500,
  };
  let error_1 = new Error("HTTP error");
  error_1 = { ...error_1, ...responseError_1 };
  throw error_1;
};

export const getBySlug = async (slug) => {
  _debug("Course fetched by Slug API");

  const URL = getServiceURL(`topic/${slug}`);

  console.log("!!!!2", URL);

  const headers = {
    ...requestGlobalHeaders,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(headers),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);
  //const result = {};

  console.log("!!!!2", result);
  //console.log("!!!!2", response.test());

  return result;
};

export const getLessonBySlug = async (slug) => {
  _debug("Lesson fetched by Slug API");

  const URL = getServiceURL(`lessons/${slug}`);

  console.log("!!!!2", URL);

  const headers = {
    ...requestGlobalHeaders,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(headers),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);
  //const result = {};

  console.log("!!!!2", result);
  //console.log("!!!!2", response.test());

  return result;
};

export const getFAQByCourse = async (CourseId) => {
  _debug("Course (FAQ) fetched by Slug API", CourseId);

  const URL = getServiceURL(`topic/${CourseId}/faq`);

  console.log("!!!!3", URL);

  const headers = {
    ...requestGlobalHeaders,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(headers),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);
  //const result = {};

  console.log("!!!!2", result);
  //console.log("!!!!2", response.test());

  return result;
};

export const getAll = async () => {
  _debug("Course fetched all API");

  const URL = getServiceURL("topic/all");

  console.log("!!!!2", URL);

  const headers = {
    ...requestGlobalHeaders,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(headers),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);
  //const result = {};

  console.log("!!!!2", result);
  //console.log("!!!!2", response.test());

  return result;
};

export const getTop = async () => {
  const URL = getServiceURL("topic/all");

  const headers = {
    ...requestGlobalHeaders,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(headers),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  return result;
};

const CourseService = {
  getAll,
  getTop,
  getBySlug,
  getFAQByCourse,
  getLessonBySlug,
};

export default CourseService;
