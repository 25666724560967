import { CustomFormInputTime } from "components/pages/Customer/components/core/FormInput/FormInput";
import { Form as BSForm, Button, Col, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

// TODO : Добавить проверку, что дата конца меньше даты начала, пока так

/**
 * Component for showing Orders table.
 *
 * @component
 */
const AvailableTimeModalForm = (props) => {
  const {
    showModal,
    handleClose,
    setShowModal,
    modalFormData = {},
    setModalFormData,
  } = props;

  const {
    //methods,
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      startTime: modalFormData?.startTime,
      endTime: modalFormData?.endTime,
    },
  });

  const onSubmit = (data) => {
    console.log("SUBMIT:", data);
    // setModalFormData(data);
    handleClose(data);
  };
  const onError = (error) => {
    console.log("ERROR:::", error);
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Appointment Details</Modal.Title>
      </Modal.Header>
      <BSForm className="" onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Body>
          <div className="timing-modal">
            <Row className="mb-3">
              <BSForm.Group className="" as={Col} controlId="formStartTime">
                <CustomFormInputTime
                  control={control}
                  name="startTime"
                  label="Start time"
                  required
                  error={errors.startDate}
                  defaultValue={modalFormData?.startDate}
                  rules={{ required: true }}
                />
              </BSForm.Group>

              <BSForm.Group className="" as={Col} controlId="formEndTime">
                <CustomFormInputTime
                  control={control}
                  name="endTime"
                  label="End time"
                  required
                  error={errors.endTime}
                  defaultValue={modalFormData?.endTime}
                  rules={{ required: true }}
                  minDate={new Date(modalFormData?.startDate)}
                />
              </BSForm.Group>
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
      </BSForm>
    </Modal>
  );
};

export default AvailableTimeModalForm;
