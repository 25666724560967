import { Spinner } from "components/utils/Spinner";
import { dataMessages } from "data";
import { Fragment, useEffect } from "react";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { PageSection, SectionHeader } from "../Home";
import CardCourse from "./CardCourse";
import { useGetCourseBySlug } from "./useGetCourseBySlug";

// NOTE :

const SectionContent = (props) => {
  const { data } = props;
  const { course } = data;

  return (
    <Fragment>
      <div className="row gy-3">
        {course.lessons.map((item, index) => {
          return (
            <CardCourse data={item} index={index} key={index} type="lesson" />
          );
        })}
      </div>
    </Fragment>
  );
};

/**
 * Component for showing Section "Courses" of the site.
 *
 * @component
 */
const CourseSection = (props) => {
  const { data, id = "course", cn = "pt-100 pb-100" } = props;
  const { courseSlug: slug } = data;

  const {
    data: course,
    isLoading: isLoadingCourse,
    isError: isErrorCourse,
    error: errorCourse,
  } = useGetCourseBySlug(slug);

  const isLoading = isLoadingCourse;
  const isError = isErrorCourse;
  const errors = [errorCourse];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (course) {
      _debug("COURSE:", course);
    }
  }, [course]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isErrorCourse && errorCourse?.status === 204) {
    return <div className="error">Нет курсов</div>;
  }

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader
          data={{ ...data, title: course.name, course: course }}
          showSubtitle={false}
          showDescription={false}
        />
        <SectionContent data={{ ...data, course: course }} />
      </div>
    </PageSection>
  );
};

export default CourseSection;
