import { PageSection } from "core/components";
import { PropTypes } from "prop-types";
import { Fragment, useRef, useState } from "react";
import { MdOutlinePlayCircle } from "react-icons/md";

const SectionContentItem = (props) => {
  const { data } = props;

  return (
    <Fragment>
      <div className="text-muted">{data.icon}</div>
      <div className="ms-4">
        <h5 className="fw-bold">{data.title}</h5>
        <p className="fw-light">{data.description}</p>
      </div>
    </Fragment>
  );
};

const SectionContent = (props) => {
  const { data, showTitle, showDescription } = props;
  const videoRef = useRef();
  const [stop, setStop] = useState(false);

  const showImage = false;

  const handleVideo = () => {
    setStop(!stop);
    if (stop === true) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  return (
    <div className="row">
      <div
        className="justify-content-center align-items-center"
        itemtype="https://schema.org/VideoObject"
        style={{
          position: "relative",
          maxWidth: "720px",
          width: "100%",
          height: "405px",
          marginBottom: "73px",
          backgroundColor: "#e5f1ff",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
        >
          <video
            ref={videoRef}
            src={require("assets/videos/32297-391427172_medium.mp4")}
            preload="auto"
            autoplay=""
            controls=""
            type="video/mp4"
            style={{ width: "100%", height: "100%" }}
          ></video>
          <button
            className="btn btn-link player-box__play"
            onClick={handleVideo}
          >
            <MdOutlinePlayCircle />
          </button>
        </div>
      </div>
    </div>
  );
};

/**
 * Component for showing Section "Feature - Our Advantages" of the site.
 *
 * @component
 */
const OurAdvantagesVideo = (props) => {
  const {
    data,
    id = "our-advantages-video",
    cn = "pt-130 pb-80",

    showHeaderSubtitle,
    showHeaderDescription,
    showTitle = true,
    showDescription = true,
  } = props;

  //console.log("11", props);

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionContent
          data={data}
          showTitle={showTitle}
          showDescription={showDescription}
        />
      </div>
    </PageSection>
  );
};

OurAdvantagesVideo.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showHeaderSubtitle: PropTypes.bool ?? true,
  showHeaderDescription: PropTypes.bool ?? true,
  showTitle: PropTypes.bool ?? false,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default OurAdvantagesVideo;
