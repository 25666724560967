import { chunkArray } from "components/utils/Utils";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { PageSection, SectionHeader } from "../../../sections/CommonSection";

import { useIsMobile } from "hooks";
import "./Approaches.scss";

// NOTE : https://promo.youtalk.ru/rich/?_gl=1*13awslr*_gcl_au*MTE5ODIzNTM1MS4xNzIwNDAyNDI2*_ga*MzYwODY2NTUxLjE3MjA0MDI0MjY.*_ga_14P9R9JJ4H*MTcyMDQwMjQyNi4xLjEuMTcyMDQwNDMzMi41Ny4wLjA.
// NOTE : https://themewagon.github.io/gohub/v1.0.0/
// NOTE : https://zigmund.online/psikhoterapiya-vidy-tipy-podkhody/

const dataApproaches = {
  title: "Психотерапия: виды, типы, подходы",
  subtitle: "Психотерапия: виды, типы, подходы",
  description: "Психотерапия: виды, типы, подходы",
  items: [
    {
      id: 1,
      title: "Психоаналитическая терапия",
      subtitle: "",
      description: "",
      image: require("assets/images/demo/icon1.webp"),
      imageAlt: "",
    },
    {
      id: 2,
      title: "Транзактный анализ",
      subtitle: "",
      description: "",
      image: require("assets/images/demo/icon1.webp"),
      imageAlt: "",
    },
    {
      id: 3,
      title: "Системная семейная терапия",
      subtitle: "",
      description: "",
      image: require("assets/images/demo/icon1.webp"),
      imageAlt: "",
    },

    {
      id: 4,
      title: "Экзистенциальная терапия",
      subtitle: "",
      description: "",
      image: require("assets/images/demo/icon1.webp"),
      imageAlt: "",
    },
    {
      id: 5,
      title: "Гештальт-терапия",
      subtitle: "",
      description: "",
      image: require("assets/images/demo/icon1.webp"),
      imageAlt: "",
    },
    {
      id: 6,
      title: "Юнгианский анализ",
      subtitle: "",
      description: "",
      image: require("assets/images/demo/icon1.webp"),
      imageAlt: "",
    },
    {
      id: 7,
      title: "Системная семейная терапия",
      subtitle: "",
      description: "",
      image: require("assets/images/demo/icon1.webp"),
      imageAlt: "",
    },
    {
      id: 8,
      title: "Системная семейная терапия",
      subtitle: "",
      description: "",
      image: require("assets/images/demo/icon1.webp"),
      imageAlt: "",
    },
    {
      id: 9,
      title: "Системная семейная терапия",
      subtitle: "",
      description: "",
      image: require("assets/images/demo/icon1.webp"),
      imageAlt: "",
    },
    {
      id: 10,
      title: "Системная семейная терапия",
      subtitle: "",
      description: "",
      image: require("assets/images/demo/icon1.webp"),
      imageAlt: "",
    },
    {
      id: 11,
      title: "Системная семейная терапия",
      subtitle: "",
      description: "",
      image: require("assets/images/demo/icon1.webp"),
      imageAlt: "",
    },
    {
      id: 12,
      title: "Системная семейная терапия",
      subtitle: "",
      description: "",
      image: require("assets/images/demo/icon1.webp"),
      imageAlt: "",
    },
  ],
};

const SectionGoToCourses = (props) => {
  // const {} = props;
  // console.log("@@@@", data, index);

  return (
    <div className="col-lg-6 col-md-12 col-12">
      <div className="card blog-grid">
        <div className="card-content blog-grid-text p-4 align-middle">
          <h2 className="card-title h5 mb-3">
            <Link to={"/courses"} className="btn btn-primary theme-btn">
              Смотреть все курсы
            </Link>
          </h2>
          <p className="display-30">Смотреть все курсы</p>
        </div>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data, isMobile } = props;

  const array = chunkArray(dataApproaches.items, 4);

  console.log(array);

  // const {
  //   data: topCourses,
  //   isLoading: isLoadingTopCourses,
  //   isError: isErrorTopCourses,
  //   error: errorTopCourses,
  // } = useGetTopCourses();

  // const isLoading = isLoadingTopCourses;
  // const isError = isErrorTopCourses;
  // const error = [errorTopCourses];

  // _debug("isMobile", isMobile);

  // useEffect(() => {
  //   if (isError) {
  //     error.forEach((err) => {
  //       if (err) {
  //         console.error(err);
  //         toast.error(`Something went wrong: ${error?.message}`);
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isError]);

  // useEffect(() => {
  //   if (topCourses) {
  //     _debug("TOP COURSES:", topCourses);
  //   }
  // }, [topCourses]);

  // // Show a loading message while data is fetching
  // if (isLoading) {
  //   return (
  //     <Spinner
  //       hidden={!isLoading}
  //     />
  //   );
  // }

  // if (isErrorTopCourses && errorTopCourses?.status === 204) {
  //   return <div className="error">Нет курсов</div>;
  // }

  const Card = (props) => {
    const { data } = props;
    return (
      <div className="col-md-6 col-lg-3 text-center">
        <img
          src={require("assets/images/demo/icon1.webp")}
          alt="Dashboard"
          style={{ width: "95px" }}
        />
        <h4 className="mt-3 lh-base">{data.title}</h4>
        <p className="fs-0">
          {data.description ||
            "Convert more wesite visitors in signups ans purchases."}
        </p>
      </div>
    );
  };

  return (
    <Fragment>
      {array.map((itemRow, indexRow) => {
        return (
          <div className="row mb-4 mt-6" key={`row_${indexRow}`}>
            {itemRow.map((itemCol, indexCol) => {
              return (
                <Card
                  data={itemCol}
                  key={`col_${itemCol.index}`}
                  index={itemCol.index}
                />
              );
            })}
          </div>
        );
      })}
    </Fragment>
  );
};

/**
 * Component for showing Section "Top Approaches" of the site.
 *
 * @component
 */
const Approaches = (props) => {
  const { data, id = "approaches", cn = "pt-80" } = props;
  const isMobile = useIsMobile(768);

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} />
        <SectionContent data={data} isMobile={false} />
      </div>
    </PageSection>
  );
};

Approaches.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default Approaches;
