import { dataSite } from "data/dataSite";

export const dataMetaPsychologistPage = {
  title: "Вакансии психологов и психотерапевтов",
  description: `Ищем психотерапевтов для совместной работы. Телефон: ${dataSite.phone}, почта: ${dataSite.email}.`,
  name: "",
  type: "",
  author: "PsyWealth LLC, Inc.",
  ExtraMetaTags: [],
  OG: [
    {
      property: "og:title",
      content: "Вакансии психологов и психотерапевтов",
    },
    {
      property: "og:description",
      content: `Ищем психотерапевтов для совместной работы. Телефон: ${dataSite.phone}, почта: ${dataSite.email}.`,
    },
    {
      property: "og:url",
      content: "https://psywealth.com/psychologist/",
    },
    {
      property: "og:type",
      content: "article",
    },
    {
      property: "og:image",
      content:
        "https://static.tildacdn.com/tild6464-3961-4635-b834-633364363338/Group_4.svg",
    },
    {
      property: "og:image:alt",
      content: "Вакансии психологов и психотерапевтов",
    },
    {
      property: "og:locale",
      content: "ru_RU",
    },
    {
      property: "og:site_name",
      content: "https://psywealth.com",
    },
  ],
  TW: [
    { property: "twitter:creator", content: "" },
    { property: "twitter:card", content: "summary_large_image" },
    { property: "twitter:title", content: "" },
    { property: "twitter:description", content: "" },
    { property: "twitter:label1", content: "Примерное время для чтения" },
    { property: "twitter:data1", content: "10 минуты" },
  ],
};
