import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Final from "./Final";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";

// NOTE : https://medium.com/how-to-react/create-multi-step-form-in-react-with-validation-4ac09129a3a8

const MultiStepForm = () => {
  //state for steps
  const [step, setStep] = useState(1);

  //state for form data
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    age: "",
    email: "",
  });

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setStep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setStep(step - 1);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input) => (e) => {
    // input value from the form
    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  // javascript switch case to show different form in each step
  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 1:
      return (
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }} className="custom-margin">
              <StepOne
                nextStep={nextStep}
                handleFormData={handleInputData}
                values={formData}
              />
            </Col>
          </Row>
        </Container>
      );
    // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 2:
      return (
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }} className="custom-margin">
              <StepTwo
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={handleInputData}
                values={formData}
              />
            </Col>
          </Row>
        </Container>
      );
    // Only formData is passed as prop to show the final value at form submit
    case 3:
      return (
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }} className="custom-margin">
              <Final values={formData} />
            </Col>
          </Row>
        </Container>
      );
    // default case to show nothing
    default:
      return null;
  }
};

export default MultiStepForm;
