import {
  CallControls,
  SpeakerLayout,
  StreamCall,
  StreamTheme,
  StreamVideo,
  StreamVideoClient,
  useCall,
  useCallStateHooks,
  useStreamVideoClient,
} from "@stream-io/video-react-sdk";
import { Fragment, useEffect, useState } from "react";
import { PageSection, SectionHeader } from "../Home";

import "@stream-io/video-react-sdk/dist/css/styles.css";
import { Spinner } from "components/utils/Spinner";
import "./VideoCalls.style.scss";

// NOTE :

const apiKey = "tvdduxytkrz5";
// const token =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiRHVyZ2UifQ.5wkisfOO0_UtxFRzMWKcoUpZySqtEAKmliIjFd5PSTw";
// const userId = "af084a6f-14f0-4f4e-ab0d-5af666fc5b97";

const token =
  "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiYWYwODRhNmYtMTRmMC00ZjRlLWFiMGQtNWFmNjY2ZmM1Yjk3Iiwic3ViIjoicHN5MUBtYWlsLnJ1IiwiaWF0IjoxNzIyNDE0OTk1LCJleHAiOjE3MjI1MDEzOTV9.1Ycf7tZAeCh_e6Zgj5_8QM9pfGEYYzAbloH5Hamii18";
const userId = "af084a6f-14f0-4f4e-ab0d-5af666fc5b97";

// const userId2 = "Anakin_Solo";
const token2 =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiQW5ha2luX1NvbG8iLCJpc3MiOiJodHRwczovL3Byb250by5nZXRzdHJlYW0uaW8iLCJzdWIiOiJ1c2VyL0FuYWtpbl9Tb2xvIiwiaWF0IjoxNzIyMzg2OTIwLCJleHAiOjE3MjI5OTE3MjV9.TnrzbgPwktFWvKwPx05CXoOGo-bRPJQJl9NfPO9u5ZU";
const callId = "default_46c1ccc4-ab4f-41b5-bfd6-ae54204b9d24";

// set up the user object
const user = {
  id: userId,
  name: "Tim Lee",
  image: "https://getstream.io/random_svg/?name=TimLee",
  //type: "guest",
};

const MyCallUI = () => {
  const call = useCall();

  const { useCallCallingState, useParticipants } = useCallStateHooks();
  const callingState = useCallCallingState();
  const participants = useParticipants();

  return (
    <div>
      <div>Call: {call?.cid}</div>
      <div>State: {callingState}</div>
      <div>Participants: {participants.length}</div>
    </div>
  );
};

const MyCallUI2 = () => {
  const [call, setCall] = useState(null);
  const client = useStreamVideoClient();

  const handleClick = async () => {
    if (call) {
      // leave the call and dispose all allocated resources
      await call.leave();
    }
  };

  // Join the call
  useEffect(() => {
    if (!client || call) return;

    const joinCall = async () => {
      const call = client?.call("development", callId);
      await call.join({ create: true });
      setCall(call);
    };

    joinCall();
  }, [call]);

  if (!call) return null;

  // console.log(call);

  return (
    <Fragment>
      <Spinner visible={!call} />
      <button onClick={handleClick}>leave</button>
      <StreamCall call={call}>
        <SpeakerLayout participantsBarPosition="bottom" />
        <CallControls />
      </StreamCall>
    </Fragment>
  );
};

const SectionContent = (props) => {
  const { data } = props;
  const [videoClient, setVideoClient] = useState(null);
  //const [call, setCall] = useState(null);
  // const streamVideoClient = new StreamVideoClient({ apiKey });

  const [theme, setTheme] = useState("dark");

  const handleClick = () => {
    if (videoClient) {
      const myCall = videoClient.call("default", callId);
      myCall.join({ create: true });
      // setCall(call);
    }
  };

  // const [client, setClient] = useState<StreamVideoClient>();
  useEffect(() => {
    // const tokenProvider = () => Promise.resolve('<token>');
    const myClient = new StreamVideoClient({
      apiKey,
      user,
      token,
      options: {
        logLevel: "info",
      },
    });
    setVideoClient(myClient);
    return () => {
      myClient.disconnectUser();
      setVideoClient(undefined);
    };
  }, []);

  // useEffect(() => {
  //   let didUserConnectInterrupt = false;

  //   const videoConnectionPromise = streamVideoClient
  //     .connectUser(user, token)
  //     .then(() => {
  //       if (!didUserConnectInterrupt) {
  //         setVideoClient(streamVideoClient);
  //       }
  //     });

  //   return () => {
  //     didUserConnectInterrupt = true;
  //     setVideoClient(undefined);
  //     // wait for connection to finish before initiating closing sequence
  //     videoConnectionPromise
  //       .then(() => streamVideoClient.disconnectUser())
  //       .then(() => {
  //         console.log("video connection closed");
  //       });
  //   };
  // }, []);

  useEffect(() => {
    if (videoClient) {
      // const call = videoClient.call("default", callId);
      // // call.join({ create: true });
      // setCall(call);
    }
  }, [videoClient]);

  if (!videoClient) return <div>Loading1...</div>;

  return (
    <Fragment>
      <StreamVideo client={videoClient}>
        <button onClick={() => setTheme("dark")}>Dark theme</button>
        <button onClick={() => setTheme("light")}>Light theme</button>
        <button onClick={handleClick}>test</button>
        <StreamTheme className={theme}>
          <MyCallUI2 />
        </StreamTheme>
      </StreamVideo>
    </Fragment>
  );
};

/**
 * Component for showing Section "Video Calls" of the site.
 *
 * @component
 */
const VideoCallsSection = (props) => {
  const { data, id = "video-calls", cn = "pt-100 pb-100" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />
        {/**/}
        <SectionContent data={data} />
      </div>
    </PageSection>
  );
};

export default VideoCallsSection;
